import React from "react";

export const NumZero = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4988 24.968C20.7428 24.968 20.0661 24.7673 19.4688 24.366C18.8808 23.9647 18.4188 23.3907 18.0828 22.644C17.7561 21.888 17.5928 21.0013 17.5928 19.984C17.5928 18.9667 17.7561 18.0847 18.0828 17.338C18.4188 16.582 18.8808 16.0033 19.4688 15.602C20.0661 15.2007 20.7428 15 21.4988 15C22.2548 15 22.9268 15.2007 23.5148 15.602C24.1121 16.0033 24.5788 16.582 24.9148 17.338C25.2508 18.0847 25.4188 18.9667 25.4188 19.984C25.4188 21.0013 25.2508 21.888 24.9148 22.644C24.5788 23.3907 24.1121 23.9647 23.5148 24.366C22.9268 24.7673 22.2548 24.968 21.4988 24.968ZM21.4988 24.044C22.0681 24.044 22.5674 23.8853 22.9968 23.568C23.4354 23.2507 23.7761 22.7887 24.0188 22.182C24.2614 21.5753 24.3828 20.8427 24.3828 19.984C24.3828 19.1253 24.2614 18.3927 24.0188 17.786C23.7761 17.1793 23.4354 16.7173 22.9968 16.4C22.5674 16.0827 22.0681 15.924 21.4988 15.924C20.9294 15.924 20.4254 16.0827 19.9868 16.4C19.5574 16.7173 19.2214 17.1793 18.9788 17.786C18.7361 18.3927 18.6148 19.1253 18.6148 19.984C18.6148 20.8427 18.7361 21.5753 18.9788 22.182C19.2214 22.7887 19.5574 23.2507 19.9868 23.568C20.4254 23.8853 20.9294 24.044 21.4988 24.044Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
