import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Bowser from 'bowser';
import { connect } from 'react-redux';
import { Button, ButtonTypes, ButtonVariants } from '../shared/Button';
import { Footer } from '../shared/Footer';
import { ButtonsHolder } from '../shared/layout/ButtonHolder';
import { Page } from '../shared/layout';


const PageStyled = styled(Page)`
  position: absolute;
  z-index: 999;
  color: white;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    &:before,
    &:after {
      height: calc(100% - 80px);
    }
  }
  @media (max-height: 414px) {
    &:before,
    &:after {
      height: calc(100% - 54px);
    }
  }
`;

export const HelpStyled = styled.div`
  z-index: 3;
  padding: 60px 7% 20px 7%;
  height: calc(100% - 80px);
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    margin: 0 5%;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    max-width: calc(900px + 10%);
    -webkit-overflow-scrolling: touch;
  }
`;

const FooterStyled = styled(Footer)`
  display: block;
  position: fixed;
  bottom: 0;
  min-height: 80px;
  height: 80px;
  width: 100%;
  z-index: 4;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    background-color: ${({ theme }) => theme.colors.font};
    display: flex;
    align-items: center;
  }
  @media (max-height: 414px) {
    min-height: 54px;
    height: 54px;
  }
`;

const ButtonsHolderStyled = styled(ButtonsHolder)`
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 0 5%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 300px;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    min-width: 300px !important;
  }
`;

const Desktop = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;

  svg {
    filter: drop-shadow(${(props) => props.theme.shadows.modalShadow});
    margin: initial;
    margin-right: 5px;
    //width: auto; //not working in IE
    height: 5vw;
    min-height: 44px;
    max-height: 56px;
    &:hover {
      filter: none;
    }
  }
`;

const NotDesktop = styled.div`
  height: auto;
  max-height: 100%;

  svg {
    filter: none;
    margin: auto;
    width: initial;
    height: initial;
    min-height: initial;
    max-height: initial;
    &:hover {
      filter: none;
    }
  }
`;

const TranscriptModalComponent = ({ onClose, transcriptText, title }) => {
  const { t } = useTranslation();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const isDesktop = platform.type === 'desktop';

  return (
    <PageStyled withBackgroundMobile withBlurOpacity>
      <HelpStyled>
        {!isDesktop ? (
          <NotDesktop>
            <h3>Video Transcript </h3>
            <h4> {title} </h4>
            <div dangerouslySetInnerHTML={{ __html: transcriptText }} />
          </NotDesktop>
        ) : (
          <Desktop>
            <h3>Video Transcript </h3>
            <h4> {title} </h4>
            <div dangerouslySetInnerHTML={{ __html: transcriptText }} />
          </Desktop>
        )}
      </HelpStyled>
      <FooterStyled>
        <ButtonsHolderStyled>
          <ButtonStyled variant={ButtonVariants.PRIMARY} type={ButtonTypes.BUTTON} onClick={onClose} autoFocus>
            {t('ok')}
          </ButtonStyled>
        </ButtonsHolderStyled>
      </FooterStyled>
    </PageStyled>
  );
};

const mapStateToProps = (state) => {
  const {
    testDetails: { config },
  } = state;

  return {
    config,
  };
};

export const TranscriptModal = connect(mapStateToProps)(TranscriptModalComponent);