import React from 'react';
import Bowser from 'bowser';
import { useOrientation } from '../../shared/utils/isLandscape';

export const useCurrentDeviceLayout = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const orientation = useOrientation();

  const camelCasedOrientation = React.useMemo(() => {
    return orientation.replace(/^\w/, (match) => match.toUpperCase());
  }, [orientation]);

  const deviceLayout = React.useMemo(() => {
    return `${platform.type}${camelCasedOrientation}`;
  }, [platform.type, camelCasedOrientation]);

  return deviceLayout;
};
