import React from "react";
import { Switcher, neutralValue } from "./Switcher";

export const SwitcherWrapper = ({ values, selectedResponse, onResponse }) => {
  /*
    Values set is an array of integers, by default with all elements as a "neutral value".
    In template we are working with integers, so default value will be -> [2, 2, 2, 2], meaning all questions
    are not answered or decided as neutral
   */
  const handleSelection = (index, selectionValue) => {
    const updatedSet = selectedResponse.map((response) => {
      return response === selectionValue ? neutralValue : response;
    });

    updatedSet[index] = selectionValue;
    onResponse(updatedSet);
  };

  return (
    <Switcher
      values={values}
      handleSelection={handleSelection}
      valuesSet={selectedResponse}
    />
  );
};
