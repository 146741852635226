import React from "react";
import styled from "styled-components";
import { useLangDirection } from "../../../utils/hooks/useLangDirection";

const ButtonsHolderStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    justify-content: space-between;
  }
`;

export const ButtonsHolder = ({ children, ...props }) => {
  const direction = useLangDirection();

  return (
    <ButtonsHolderStyled direction={direction} {...props}>
      {children}
    </ButtonsHolderStyled>
  );
};
