import React from "react";
import styled from "styled-components";
import {
  FeedbackStyled,
  FullHeight,
  QuestionTextStyled,
} from "./shared/components";
import { CardsList } from "../shared/CardsList";
import * as images from "./images/mrt2";

const TemplateStyled = styled.div`
    margin-bottom: 40px;
    width: 100%;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 37vh;
        @media (max-width: ${({ theme }) => theme.viewports.sm}) {
          height: 36vw;
        }
        max-height: 500px;
          @media (min-width: ${({ theme }) => theme.viewports.xl})  {
              height: 43vh;
          }
      }
    }
  }
`;

const QuestionWichTextStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.67;
  z-index: 5;
  position: relative;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 1.67;
  }
`;

export const WhichOfThree = ({
  values: { mainitem, responses, image, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
}) => {
  const ImageComponent = images[image];

  return (
    <>
      <FullHeight>
        <TemplateStyled>
          <div>
            <ImageComponent />
          </div>
        </TemplateStyled>
        <QuestionWichTextStyled
          dangerouslySetInnerHTML={{
            __html: mainitem,
          }}
        />
        {!!feedback && (
          <FeedbackStyled
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </FullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className="which-of-three"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
      />
    </>
  );
};
