import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import {
  GlyphStyled,
  GlyphsWrapperStyled,
  FeedbackStyled,
  QuestionTextStyled,
  GlyphsContainerStyled,
  FullHeight,
} from './shared/components';
import { InstructionModal } from '../shared/InstructionModal';
import { CardsList } from '../shared/CardsList';
import { GlyphSet } from './shared/GlyphSet';
import { LangDirectionContext } from '../../context/LangDirectionContext';
import { isMobile } from '../shared/utils/isMobile';

const QuestionTextReStyled = styled.div`
  margin-bottom: 30px;

  ${({ theme }) => theme.xs`
      order: 1;
      margin-top: 2rem;
      margin-bottom: 2rem;
   `} 

  ${({ theme }) => theme.xxs`
      order: 1;
      margin-top: 1rem;
   `};
  
    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
      order: 1;
      margin-bottom: 0;
    }
  }
  
  & > div {
    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    font-weight: 600;
    padding: 5px 0; 
  }
`;

export const WhatComesNext = ({
  values: { mainitem, responses, wcn1, wcn2, wcn3, wcn4, wcn5, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
}) => {
  const testClass = testId.toLowerCase();
  const direction = React.useContext(LangDirectionContext);

  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);

  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <FullHeight>
        <GlyphsWrapperStyled className="whatComesNext" >
          <QuestionTextReStyled>
            <QuestionTextStyled
              dangerouslySetInnerHTML={{
                __html: mainitem,
              }}
            />
          </QuestionTextReStyled>
          <GlyphsContainerStyled>
            <GlyphStyled>
              <GlyphSet className={`icon icon-${wcn1} ${testClass}`} />
            </GlyphStyled>
            <GlyphStyled>
              <GlyphSet className={`icon icon-${wcn2} ${testClass}`} />
            </GlyphStyled>
            <GlyphStyled>
              <GlyphSet className={`icon icon-${wcn3} ${testClass}`} />
            </GlyphStyled>
            <GlyphStyled>
              <GlyphSet className={`icon icon-${wcn4} ${testClass}`} />
            </GlyphStyled>
            {wcn5 && (
              <GlyphStyled>
                <GlyphSet className={`icon icon-${wcn5} ${testClass}`} />
              </GlyphStyled>
            )}
            <GlyphStyled className="whatComesNext question">
              <GlyphSet className={`icon icon-question ${testClass} ${direction}`} noPath />
            </GlyphStyled>
          </GlyphsContainerStyled>
          {!!feedback && !isMobile && (
            <FeedbackStyled
              dangerouslySetInnerHTML={{
                __html: feedback,
              }}
            />
          )}
        </GlyphsWrapperStyled>
      </FullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className={clsx({ 'svg-image': config.isAbstract, whatComesNext: true })}
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
