import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useKey from '@rooks/use-key';
import clsx from 'clsx';
import { useTimer } from '../../../utils/hooks/useTimer';
import { LangDirectionContext } from '../../../context/LangDirectionContext';
import { submitResults, updateAnswer, updateRemainingTime } from '../../../store/actions/questionnaireDetailsActions';
import { PageHeader } from '../PageHeader';
import { Main } from '../layout';
import { ButtonNext, ButtonPrevious, Navigation } from '../Navigation';
import { PageTemplate } from '../../templates/PageTemplateComponent';
import { Footer } from '../Footer';
import { InfoIcon } from '../InfoIcon';
import { ProgressBar } from '../ProgressBar';
import { BlockScreenModal } from '../BlockScreenModal';
import { UserReturnModal } from '../UserReturnModal';
import { HelpButtonStyled, PageStyled, ProgressInicatorStyled } from './AdaptiveTest.styled';
import { HelpModal } from '../HelpModal';
import { PostTest } from '../../pages/PostTest';
import { RunOutOfTimeStyled } from '../QuestionsPlayer/QuestionsPlayer.styled';
import { Error } from '../Error';
import { Review } from '../../pages/Review';
import { useSaveSessionTimer } from '../../../utils/hooks/useSaveSessionTimer';
import { useTimeSpentPerQuestion } from '../../../utils/hooks/useTimeSpentPerQuestion';

export const AdaptiveTest = ({ qEngine }) => {
  const { currentQuestionnaire } = qEngine;
  const currentQuestionIndex = currentQuestionnaire.SelectedItemIndex;
  const question = qEngine.questionnaire.questions[currentQuestionIndex];

  const { t } = useTranslation();
  const direction = React.useContext(LangDirectionContext);
  /**
   * Redux State
   */
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state.testDetails);
  const { answers, session, remainingTime, isResultSubmitted, timeSpentPerQuestion } = useSelector(
    (state) => state.questionnaireDetails,
  );

  /**
   * Local State
   */
  const [activePageIndex, setActivePageIndex] = useState(answers.length ? answers.length - 1 : 0);
  const [activePageAnswer, setActivePageAnswer] = useState(0);
  const [isRunOutOfTimeVisible, setRunOutOfTimeVisible] = useState(false);
  const [isNextInBattery, setNextInBattery] = useState(false);
  const [isUserReturnScreenVisible, setUserReturnScreenStatus] = useState(!!answers.length);
  const [isHelpVisible, setHelpVisible] = React.useState(false);
  const [isBlockModalVisible, setBlockModalVisible] = React.useState(false);
  const [showPostTest, setShowPostTest] = React.useState(false);
  const [showPostTestModal, setShowPostTestModal] = useState(activePageIndex > currentQuestionnaire.Nitems - 1);
  const [isPending, setPending] = useState(false);
  const [isTestFinished, setTestFinished] = useState(false);

  const pageData = activePageIndex < currentQuestionnaire.Nitems ? question : {};

  const timeSpentForCurrentQuestion = timeSpentPerQuestion[activePageIndex];
  useTimeSpentPerQuestion({
    questionIndex: activePageIndex,
    spentTime: timeSpentForCurrentQuestion,
    isCountingBlocked:
      isRunOutOfTimeVisible || isBlockModalVisible || showPostTest || showPostTestModal || isUserReturnScreenVisible,
  });

  // Save used item index when entering question for the first time,
  // to have ability to recover the same question during next reloads
  useEffect(() => {
    if (activePageIndex < currentQuestionnaire.Nitems && !isRunOutOfTimeVisible) {
      const answered = answers.find((a) => a.Id === pageData.id);

      if (!answered) {
        dispatch(
          updateAnswer({
            Index: activePageIndex,
            Id: pageData.id,
            RId: '',
            RIdx: 0,
            AdaptItems: `EAP:${qEngine.currentQuestionnaire.EAP},qid:${pageData.qid}`,
          }),
        );
      }
    }
  }, [
    dispatch,
    pageData,
    qEngine.currentQuestionnaire.EAP,
    activePageIndex,
    currentQuestionnaire.Nitems,
    isRunOutOfTimeVisible,
    answers,
  ]);

  const moveToNextActivePage = useCallback(
    (shouldResetAnswer) => {
      if (activePageIndex >= currentQuestionnaire.Nitems - 1) {
        setShowPostTestModal(true);
      }

      if (shouldResetAnswer) {
        setActivePageAnswer(0);
      }

      setActivePageIndex(activePageIndex + 1);
    },
    [currentQuestionnaire.Nitems, activePageIndex, setShowPostTestModal, setActivePageAnswer, setActivePageIndex],
  );

  const processQuestionResponse = useCallback(
    ({ silent, isLast } = {}) => {
      currentQuestionnaire.processQuestionResponse(activePageAnswer);

      const adaptResponse = `EAP:${qEngine.currentQuestionnaire.EAP},qid:${pageData.qid},a:${pageData.a},b:${pageData.b},c:${pageData.c},res:${activePageAnswer}`;

      dispatch(
        updateAnswer(
          {
            Index: activePageIndex,
            Id: pageData.id,
            RId: `r${activePageAnswer}`,
            RIdx: activePageAnswer,
            AdaptItems: adaptResponse,
          },
          silent,
        ),
      );

      if (!isLast) {
        currentQuestionnaire.getNextQuestion();
        moveToNextActivePage(true);
      }
    },
    [
      currentQuestionnaire,
      activePageAnswer,
      qEngine.currentQuestionnaire.EAP,
      pageData.qid,
      pageData.a,
      pageData.b,
      pageData.c,
      pageData.id,
      dispatch,
      activePageIndex,
      moveToNextActivePage,
    ],
  );

  const handleNextButton = useCallback(() => {
    if (!isHelpVisible && !activePageAnswer) {
      setBlockModalVisible(true);
    }

    if (activePageAnswer) {
      processQuestionResponse();
    }
  }, [isHelpVisible, activePageAnswer, setBlockModalVisible, processQuestionResponse]);

  const handleResponse = useCallback(
    (res) => {
      if (!activePageAnswer || activePageAnswer !== res) {
        setActivePageAnswer(res);
      }
    },
    [activePageAnswer],
  );

  const handlePreviousPage = React.useCallback(() => {}, []);

  useEffect(() => {
    if (isRunOutOfTimeVisible) {
      if (activePageIndex >= currentQuestionnaire.Nitems) {
        setTestFinished(true);
        return;
      }
      if (activePageIndex < currentQuestionnaire.Nitems - 1) {
        processQuestionResponse({ silent: true });
      } else {
        processQuestionResponse({ silent: true, isLast: true });
        setTestFinished(true);
      }
    }
  }, [isRunOutOfTimeVisible, activePageIndex]);

  useEffect(() => {
    if (isTestFinished) {
      dispatch(
        submitResults(
          {
            answers,
            session,
            config,
            qEngine,
          },
          (err, data) => {
            setPending(false);
            setNextInBattery(data && typeof data === 'string');
          },
        ),
      );
    }
  }, [dispatch, isTestFinished, session, config, qEngine, setPending, setNextInBattery, answers]);

  const { clearSaveSessionTimer } = useSaveSessionTimer({
    isUserReturnScreenVisible,
    isResultSubmitted,
    is360: config.is360,
  });

  const { time, start, reset: clearSaveRemainingTimer } = useTimer({
    initialTime: remainingTime !== null ? remainingTime : config.timeAllowed * 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeRunOut: () => {
      setPending(true);
      setRunOutOfTimeVisible(true);
      clearSaveSessionTimer();
    },
  });

  useEffect(() => {
    if (config.timeAllowed && !isUserReturnScreenVisible && !isRunOutOfTimeVisible) {
      start();
    }
  }, [isUserReturnScreenVisible, isRunOutOfTimeVisible, config.timeAllowed, start]);

  useEffect(() => {
    if (config.timeAllowed && time !== 0) {
      dispatch(updateRemainingTime(time));
    }
  }, [dispatch, config, time]);

  const isKeyboardNavigationBlocked =
    isBlockModalVisible ||
    isRunOutOfTimeVisible ||
    isHelpVisible ||
    isUserReturnScreenVisible ||
    showPostTestModal ||
    showPostTest;

  useKey(['ArrowRight'], () => {
    if (!isKeyboardNavigationBlocked) {
      if (direction === 'rtl') {
        handlePreviousPage();
      } else {
        handleNextButton();
      }
    }
  });

  useKey(['ArrowLeft'], () => {
    if (!isKeyboardNavigationBlocked) {
      if (direction === 'rtl') {
        handleNextButton();
      } else {
        handlePreviousPage();
      }
    }
  });

  useKey(['PageDown', 'Enter'], () => {
    if (!isKeyboardNavigationBlocked) {
      handleNextButton();
    }
  });

  useKey(['PageUp', 'Backspace'], () => {
    if (!isKeyboardNavigationBlocked && !config.isWAI) {
      handlePreviousPage();
    }
  });

  useKey(['F1'], () => {
    if (!isKeyboardNavigationBlocked && pageData.template.view !== 'Cover') {
      setHelpVisible(true);
    }
  });

  const handleNextAfterRunOutOfTime = useCallback(() => {
    if (isNextInBattery) {
      window.location.reload();
    } else {
      setShowPostTest(true);
    }
  }, [isNextInBattery, setShowPostTest]);

  /**
   * User Recovery Session Modal
   */
  if (isUserReturnScreenVisible) {
    return <UserReturnModal onClose={() => setUserReturnScreenStatus(false)} testName={session.testId} />;
  }

  /**
   * Review Modal
   */
  if (showPostTestModal && !isRunOutOfTimeVisible) {
    return (
      <Review
        qEngine={qEngine}
        onReview={() => setShowPostTestModal(false)}
        isRunOutOfTimeVisible={showPostTestModal}
        onReset={() => {
          clearSaveRemainingTimer();
          clearSaveSessionTimer();
        }}
        onSubmit={() => {
          setShowPostTest(true);
          setShowPostTestModal(false);
        }}
      />
    );
  }

  /**
   * Help Modal
   */
  if (isHelpVisible) {
    return <HelpModal onClose={() => setHelpVisible(false)} />;
  }

  /**
   * Redirect to Post Test Page
   */
  if (showPostTest) {
    return <PostTest />;
  }

  /**
   * User Recovery Session Modal
   */
  if (isRunOutOfTimeVisible) {
    return (
      <RunOutOfTimeStyled>
        <Error title={t('testfinished')} onNext={handleNextAfterRunOutOfTime} isPending={isPending}>
          {t('outOfTime')}
        </Error>
      </RunOutOfTimeStyled>
    );
  }

  const isResponsesAvailable = pageData.template && pageData.template.values && pageData.template.values.responses;

  return (
    <PageStyled isResponsesAvailable={isResponsesAvailable}>
      <PageHeader
        pageData={pageData}
        activePageIndex={activePageIndex}
        innerTime={time}
        itemsLength={currentQuestionnaire.Nitems}
      />
      <Main>
        <Navigation>
          <ButtonPrevious onClick={handlePreviousPage} disabled={activePageIndex === 0} />
          <ButtonNext onClick={handleNextButton} className={clsx({ active: activePageAnswer })} />
        </Navigation>
        <PageTemplate
          page={pageData}
          onResponse={handleResponse}
          selectedResponse={activePageAnswer}
          config={config}
          isBlockModalVisible={isBlockModalVisible}
          isHelpVisible={isHelpVisible}
          onNext={() => {}}
        />
      </Main>

      <Footer>
        <ProgressInicatorStyled>
          <InfoIcon>
            <span>{t('Progress')}&nbsp;&nbsp;</span>
            <span>{Math.floor((100 * Number(activePageIndex)) / Number(currentQuestionnaire.Nitems))}</span>
          </InfoIcon>
        </ProgressInicatorStyled>
        <ProgressBar
          max={currentQuestionnaire.Nitems}
          current={activePageIndex + 1}
          progress={activePageIndex + 1}
          onChange={() => {}}
          allowOverlap={false}
        />
        <HelpButtonStyled
          onClick={() => {
            setHelpVisible(true);
          }}
        >
          {t('help')}
        </HelpButtonStyled>
      </Footer>
      <BlockScreenModal isBlockModalVisible={isBlockModalVisible} setBlockModalVisible={setBlockModalVisible} />
    </PageStyled>
  );
};
