import styled from "styled-components";

export const Body = styled.div`
  padding-top: 32px;
`;

export const BodyItem = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: 24px;
  padding-bottom: 10px;
`;

export const BodyList = styled.ul`
  padding-left: 15px;
`;

export const BodyListItem = styled.li`
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;
