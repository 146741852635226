import { testApi } from '../../utils/agent';

export const FETCH_QUESTIONNAIRE_CONFIG = 'FETCH_QUESTIONNAIRE_CONFIG';
export const FETCH_QUESTIONNAIRE_CONFIG_ERROR = 'FETCH_QUESTIONNAIRE_CONFIG_ERROR';
export const FETCH_QUESTIONNAIRE_CONFIG_SUCCESS = 'FETCH_QUESTIONNAIRE_CONFIG_SUCCESS';

const fetchQuestionnaireConfigStart = () => {
  return {
    type: FETCH_QUESTIONNAIRE_CONFIG,
  };
};

const fetchQuestionnaireConfigError = () => {
  return {
    type: FETCH_QUESTIONNAIRE_CONFIG_ERROR,
  };
};

export const fetchQuestionnaireConfigSuccess = (data) => {
  return {
    type: FETCH_QUESTIONNAIRE_CONFIG_SUCCESS,
    payload: data,
  };
};

export const fetchQuestionnaireConfig = (langId, testId) => {
  return (dispatch) => {
    dispatch(fetchQuestionnaireConfigStart());
    
      return testApi.getQuestionnaireConfig(langId, testId)
      .then((data) => {
        dispatch(fetchQuestionnaireConfigSuccess(data));
      })
      .catch((e) => {
        console.error('e', e);
        dispatch(fetchQuestionnaireConfigError());
      })
  };

};
export const fetchCustomQuestionnaireConfig = (testDetails) => {
  return (dispatch) => {
    return dispatch(fetchQuestionnaireConfigSuccess(testDetails));
  };

};