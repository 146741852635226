import React from "react";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { compose } from "redux";
import { connect } from "react-redux";
import clsx from "clsx";
import useKey from "@rooks/use-key";
import { FormField } from "../../shared/FormField";
import {
  Input,
  Select,
  DateSelect,
  RadioGroup,
} from "../../shared/formElements";
import { ButtonTypes, ButtonVariants } from "../../shared/Button";
import { Spinner } from "../../shared/Spinner";
import {
  BioStyled,
  ButtonStyled,
  DividerStyled,
  FooterStyled,
  HalfWrapperStyled,
  StyledPage,
  ButtonsHolderStyled,
  TitleStyled,
} from "./Bio.styled";
import { testApi } from "../../../utils/agent";

import isChineseEnv from "../../../utils/isChineseEnv";

export const BioComponent = ({
  submitForm,
  isSubmitting,
  errors,
  touched,
  onBioEdited,
  biodataTemplate,
  biodataTemplateId
}) => {
  const { t, i18n } = useTranslation();

  const langId = i18n.language;
  const langList = ["vn", "ch", "chtr", "chhk", "ko", "jp", "hu", "mn"];
  const isCH = langList.indexOf(langId.toLowerCase()) !== -1;

  const optionsMapper = {
    ethnicity: t("ethnicityvalues", { returnObjects: true }),
    education: t("educationvalues", { returnObjects: true }),
    jobArea: t("jobareavalues", { returnObjects: true }),
    industry: t("industryvalues", { returnObjects: true }),
    firstLanguage: t("firstlanguagevalues", { returnObjects: true }),
    sector: t("sectorvalues", { returnObjects: true }),
  };


  const handleFormSubmit = React.useCallback(() => {
    const isTouched = !!Object.keys(touched).length;
    const isErrors = !!Object.keys(errors).length;

    if (isTouched && !isErrors) {
      submitForm();
    } else if (!isErrors) {
      onBioEdited();
    }
  }, [touched, errors, submitForm, onBioEdited]);

  useKey(["Enter"], () => {
    if (!isSubmitting) {
      handleFormSubmit();
    }
  });

  React.useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, []);

  const biodataValuesMapper = (data) => {
    const mappedBioData = [];
    data.bioOptions.forEach(({ selector, values }) => {
      const mappedValues = [];
      if (values[0] === "") return;
      mappedValues.push({ label: t('pleaseselect'), value: "" });
      const bioSelectOptions = optionsMapper[selector];
      values.forEach((selectedItem) => {
        bioSelectOptions.forEach((categoryItem) => {
          if (categoryItem.label === '') return;
          if (categoryItem.value === selectedItem) {
            mappedValues.push({ label: categoryItem.label, value: selectedItem });
          }
        });
      });

      return mappedBioData.push({
        category: selector,
        values: mappedValues
      });
    });

    return mappedBioData;
  };

  const defaultCompleteBioTemplate = [
    { category: 'ethnicity', values: optionsMapper.ethnicity },
    { category: 'education', values: optionsMapper.education },
    { category: 'jobArea', values: optionsMapper.jobArea },
    { category: 'sector', values: optionsMapper.sector },
    { category: 'industry', values: optionsMapper.industry },
    { category: 'firstLanguage', values: optionsMapper.firstLanguage },
  ];

  const defaultEmptyBioTemplate = [];

  const biodataDataTemplateMapper = {
    0: defaultCompleteBioTemplate,
    '-1': defaultEmptyBioTemplate,
  }

  const biodataMappedValues = biodataTemplate ? biodataValuesMapper(biodataTemplate) : biodataDataTemplateMapper[biodataTemplateId];

  const renderWithoutBio = biodataTemplateId === -1;

  return (
    <StyledPage withBackgroundMobile withOpacity>
      <div className="bio-wrapper">
        <TitleStyled fullSize={renderWithoutBio}>{t("editpersonalinformation")}</TitleStyled>
        <BioStyled fullSize={renderWithoutBio}>
          <HalfWrapperStyled>
            {(isCH || isChineseEnv) ?
              <FormField
                name="firstName"
                label={t("firstname")}
                key="firstName"
                component={Input}
                classic
                className={clsx({
                  // TODO: add shake on error on submit
                  shake: submitForm && errors.firstName,
                })}
                isDisabled
                disabled
              /> :
              <>
                <FormField
                  name="firstName"
                  label={t("firstname")}
                  key="firstName"
                  component={Input}
                  classic
                  className={clsx({
                    // TODO: add shake on error on submit
                    shake: submitForm && errors.firstName,
                  })}
                />,
                <FormField
                  name="familyName"
                  label={t("familyname")}
                  key="familyName"
                  component={Input}
                  classic
                  bigger
                  className={clsx({
                    // TODO: add shake on error on submit
                    shake: submitForm && errors.familyName,
                  })}
                />
              </>
            }
            <FormField
              name="sex"
              label={t("gender")}
              component={RadioGroup}
              options={t("gendervalues", { returnObjects: true })}
              flexGrow
            />
            <FormField
              name="dob"
              label={t("dateofbirth")}
              component={DateSelect}
              isNonFormElement
              classic
            />
            {!isChineseEnv && <FormField
              name="reference"
              label={t("reference")}
              key="reference"
              component={Input}
              classic
            />}
          </HalfWrapperStyled>
          {!renderWithoutBio && (<DividerStyled />)}
          {!renderWithoutBio && (<HalfWrapperStyled>
            {biodataMappedValues.map(({ category, values }) => (
              <FormField key={category}
                name={category}
                label={t(category)}
                component={Select}
                options={values}
                classic
              />
            ))}
          </HalfWrapperStyled>)}
        </BioStyled>
      </div>
      <FooterStyled>
        <ButtonsHolderStyled>
          <ButtonStyled
            variant={ButtonVariants.PRIMARY}
            type={ButtonTypes.BUTTON}
            onClick={submitForm}
          >
            {isSubmitting ? <Spinner small /> : t("continue")}
          </ButtonStyled>
        </ButtonsHolderStyled>
      </FooterStyled>
    </StyledPage>
  );
};

const mapStateToProps = (state,) => {
  const {
    questionnaireDetails: {
      details: {
        firstName,
        familyName,
        userName,
        invitationId,
        testId,
        langId,
        accountId,
        userId,
        dob,
        sex,
        reference,
        ethnicity,
        education,
        jobArea,
        sector,
        industry,
        firstLanguage,
      },
    },
  } = state;

  const langList = ["vn", "ch", "chtr", "chhk", "ko", "jp", "hu", "mn"];
  const isChineseEnv = langList.indexOf(langId.toLowerCase()) !== -1;

  return {
    respondent: {
      firstName: isChineseEnv ? `${familyName} ${firstName}` : firstName,
      familyName: isChineseEnv ? '' : familyName,
      userName,
      dob,
      sex,
      reference,
      ethnicity,
      education,
      jobArea,
      sector,
      industry,
      firstLanguage,
    },
    invitation: {
      testId,
      langId,
      invitationId,
      accountId,
      userId,
    },
  };
};

export const Bio = compose(
  connect(mapStateToProps),
  withFormik({
    mapPropsToValues: ({ respondent }) => {
      return {
        firstName: respondent.firstName,
        familyName: respondent.familyName,
        dob: respondent.dob,
        sex: respondent.sex,
        reference: respondent.reference,
        ethnicity: respondent.ethnicity,
        education: respondent.education,
        jobArea: respondent.jobArea,
        sector: respondent.sector,
        industry: respondent.industry,
        firstLanguage: respondent.firstLanguage,
      };
    },
    handleSubmit: (values, { setSubmitting, props }) => {
      setSubmitting(true);

      testApi
        .preTest({
          ...props.invitation,
          data: { ...values, gender: values.sex, lastName: values.familyName },
        })
        .then(() => {
          props.onBioEdited();
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    validationSchema: () => {
      return Yup.object().shape({
        firstName: Yup.string().required("This field is required"),
        familyName: Yup.string().when('isChineseEnv', {
          is: true,
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
      });
    },
  })
)(BioComponent);
