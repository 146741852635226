import React from 'react';
import { array, string, func } from 'prop-types';
import styled from 'styled-components';

import StaticCard from './StaticCard';

const RatingColumn = ({ title, cards, onCardSelect, type }) => {
  return (
    <ColumnContainer>
      <ColumnTitle>
        <span>{title}</span>
      </ColumnTitle>
      <CardsList>
        {cards.map((item) => (
          <StaticCard
            key={item.id}
            card={item}
            isSelected={type === 'negative' ? item.weight === -2 : item.weight === 2}
            onCardClick={() => onCardSelect(item.id)}
          />
        ))}
      </CardsList>
    </ColumnContainer>
  );
};

RatingColumn.propTypes = {
  cards: array.isRequired,
  title: string.isRequired,
  onCardSelect: func.isRequired,
  type: string.isRequired,
};

const ColumnContainer = styled.div`
  width: 32rem;
  min-height: 45rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: #ffffff;
  overflow: auto;
`;

const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  background-color: ${(props) => props.isDraggingOver && '#e0f1f7'};
  border-radius: 4px;
`;

const ColumnTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 2.4rem;
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.88);
  text-align: left;
`;

export default RatingColumn;
