import React, { useEffect } from "react";
import styled from "styled-components";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { observer } from "mobx-react-lite";
import { AddRatersPage, SubmitResultPage, WelcomePage } from "./pages";
import { Spinner } from "../../shared/Spinner";
import { self360Store } from "./store";
import { createToastNotification } from "../../shared/utils/createToastNotification";
import { useSelf360RouteConfig } from "./hooks/useSelf360RouteConfig";
import { Self360StoreContext } from "./components";

const Wrapper = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.new.darkBlue2};
`;

const Body = styled.div`
  max-width: 640px;
  width: 640px;
  position: relative;
  top: 40px;
  padding-bottom: 80px;
  overflow: auto;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.viewports.sm}) {
    width: auto;
    margin: 0 40px;
  }
`;

const Header = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  height: 40px;
  background: ${(props) => props.theme.colors.new.lightBlue};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.new.darkBlue};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  z-index: 2;
`;

const Self360 = observer(() => {
  const { invitationId, respondentId, lang } = useParams();
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const routeConfig = useSelf360RouteConfig({
    invitationId,
    respondentId,
    lang,
  });

  useEffect(() => {
    self360Store.setRouteDetails({ invitationId, respondentId, lang });
    self360Store.fetchInvitation();

    i18n.changeLanguage(lang.toLowerCase());
  }, [invitationId, respondentId, lang]);

  useEffect(() => {
    const error = self360Store.error || self360Store.invitationDetails.error;
    if (error) {
      createToastNotification({
        title: t("error"),
        type: "error",
        message: error,
      });
    }
  }, [self360Store.error, self360Store.invitationDetails.error]);

  useEffect(() => {
    if (
      self360Store.invitationDetails.error ||
      self360Store.ratersDetails.error
    ) {
      history.push("/");
    }
  }, [self360Store.invitationDetails.error, self360Store.ratersDetails.error]);

  if (!self360Store.hasInvitation) {
    return <Spinner isFullScreen />;
  }

  return (
    <Wrapper>
      <Header>{t("specify my own raters for the 360 session")}</Header>

      <Body>
        <Switch>
          <Route path={routeConfig.welcome()} exact>
            <WelcomePage />
          </Route>
          <Route path={routeConfig.addRaters()} exact>
            <AddRatersPage />
          </Route>
          <Route path={routeConfig.submit()} exact>
            <SubmitResultPage />
          </Route>
        </Switch>
      </Body>
    </Wrapper>
  );
});

export const Self360Page = () => (
  <Self360StoreContext.Provider value={self360Store}>
    <Self360 />
  </Self360StoreContext.Provider>
);
