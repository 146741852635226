import styled from "styled-components";
import { ModalBox } from "../../shared/layout/Page";
import { Page } from "../../shared/layout";
import { ButtonsHolder } from "../../shared/layout/ButtonHolder";

export const TermsStyled = styled.div`
  color: ${({ theme }) => theme.colors.medGray};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;

  br {
    display: flex;
    margin-bottom: 10px;
    content: " ";
  }

  b:first-of-type {
    font-weight: bold;
    font-size: 20px;
  }

  b {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.5px;
    font-weight: bold;
  }

  p,
  span {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.medGray};
    margin-top: 0;
    line-height: 1.4;
  }
`;

export const ModalBoxStyled = styled(ModalBox)`
  max-width: 1024px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  justify-content: flex-start;
  align-items: baseline;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
    height: 80vh;
    width: 90%;
    padding-top: 50px;
    padding-bottom: 0;
  }
`;

export const TermsPage = styled(Page)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;

  @media (min-width: ${({ theme }) => theme.viewports.xs}) and (min-height: 400px) {
    padding-bottom: 0;
    padding-top: 0;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const TermsBodyStyled = styled.div`
  width: auto;
  padding-bottom: 140px;

  @media (min-width: ${({ theme }) => theme.viewports.sm})  and (min-height: 400px) {
    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
    display: block;
    width: 100%;

    &:before {
      content: " ";
      position: sticky;
      width: 100%;
      background: linear-gradient(
        to top,
        rgba(249, 249, 249, 0) 0%,
        rgba(249, 249, 249, 1) 100%
      );
      height: 50px;
      display: flex;
      top: -2px;
    }

    &:after {
      width: 5vw;
      height: 0;
      content: " ";
      display: inline-block;
    }

    /* ScrollBar Start */
    /* width */
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.gray};
      opacity: 0.5;
      border-left: 9px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      border-right: 9px solid
        ${({ theme }) => theme.colors.modalBackgroundColor};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primaryBlue};
      border-left: 8px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      border-right: 8px solid
        ${({ theme }) => theme.colors.modalBackgroundColor};
      transition: all 0.2s;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      border-left: 7px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      border-right: 7px solid
        ${({ theme }) => theme.colors.modalBackgroundColor};
      transition: all 0.2s;
    }
    /* ScrollBar End */
  }
`;

export const ButtonsHolderStyled = styled(ButtonsHolder)`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
    width: auto;
    align-items: center;
  }
`;

export const MobileContentStyled = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.viewports.sm})  and (min-height: 400px) {
    display: none;
  }
`;
