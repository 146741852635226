import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toUpper } from 'lodash';
import { fetchQuestionnaireConfig, fetchCustomQuestionnaireConfig } from '../../store/actions/testDetailsActions';
import { setIconsModule } from '../../store/actions/questionnaireDetailsActions';
import { Spinner } from '../shared/Spinner';
import { TestPlayer } from '../shared/TestPlayer';
import { Error } from '../shared/Error';
import { AdaptiveTestPlayer } from '../shared/adaptive';
import { navigateToLogin } from '../../utils/navigator';

const TestComponent = ({
  fetchQATestDetails,
  setIconsModule,
  isFetching,
  isError,
  session,
  config,
  details,
  is360,
  isLandscapeOnly,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    // TODO: how to determine if the test contains graphic content
    import(`../../assets/svg-icons/${session.testId.toLowerCase()}`)
      .then((module) => {
        setIconsModule(module);
      })
      .catch((e) => console.log('error fetching icons', e));
  }, []);

  useEffect(() => {
    if (session.testId && details.langId && !is360) {
      fetchQATestDetails(details.langId.toLowerCase(), session.testId.toLowerCase(), session.test );
    }
  }, [fetchQATestDetails, session.testId, details.langId, is360]);

  if (isFetching) return <Spinner text={t('loadingAssessmentDetails')} />;

  if (isError) return <Error onNext={navigateToLogin}>{t('invalidassessmentdetails')}</Error>;

  return config.isAdaptive ? <AdaptiveTestPlayer /> : <TestPlayer isLandscapeOnly={isLandscapeOnly} is360={is360} />;
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: { details, session },
    testDetails: { isFetching, isError, config },
  } = state;
  return {
    details,
    session,
    isFetching,
    isError,
    config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQATestDetails: (langId, testId, test) => {
      const customquestionnaires = ['GCAT', 'CT1'];

      if (customquestionnaires.includes(toUpper(testId))) {
        return dispatch(fetchCustomQuestionnaireConfig(test));
      }
      return dispatch(fetchQuestionnaireConfig(langId, testId));
    },
    setIconsModule: (module) => {
      return dispatch(setIconsModule(module));
    },
  };
};

export const Test = connect(mapStateToProps, mapDispatchToProps)(TestComponent);