import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Timer } from './Timer';

import isPresumablyDesktopByHeight from './utils/isPresumablyDesktop';

const PageHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 9px 16px;
  z-index: 10;
  top: 0;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.bg};
  backdrop-filter: none;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 72px 5% 10px 5%;
    background: transparent;
    max-width: calc(${({ theme }) => theme.widthWrapper} + 10%);
    box-shadow: none;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    .button {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
    padding: 32px 5% 10px 5%;
    align-items: flex-start;
  }

  @media (max-height: 414px) {
    height: 35px;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    background-color: transparent;
    backdrop-filter: blur(10px);
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
    theme.viewports.mdx}) and (orientation: portrait) {
    max-width: calc(${({ theme }) => theme.widthWrapper} + 10%);
    padding-top: 33px;
    padding-bottom: 10px;
    box-shadow: none;
    position: fixed;
    background-color: transparent;
    //backdrop-filter: blur(10px);
    height: auto;
    span {
      align-self: flex-start !important;
    }
  }

  &.instructions {
    box-shadow: none;
    position: fixed;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.4);

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      position: fixed;
      background: none;
      backdrop-filter: none;
    }

    @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
    theme.viewports.mdx}) and (orientation: portrait) {
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.4);
      padding-top: 0;
      padding-bottom: 0;
      min-height: 52px;
      align-items: center;
    }

    @media (max-height: 414px) {
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.4);
      :before {
        position: absolute;
        content: ' ';
        backdrop-filter: blur(10px);
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        left: -100%;
      }

      :after {
        position: absolute;
        content: ' ';
        backdrop-filter: blur(10px);
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        right: -100%;
      }
    }
    div,
    span {
      color: white;
      justify-content: flex-start;
      max-width: 110px;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        justify-content: center;
      }
    }
  }
`;

const LabelStyled = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkFontColor};
  width: auto;
  font-size: 2.5rem;
  letter-spacing: -0.38px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 34px;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    flex-grow: initial;
    font-size: 2.5rem;
    letter-spacing: -0.46px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-height: 414px) {
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
    theme.viewports.mdx}) and (orientation: portrait) {
    font-size: 22px;
    letter-spacing: -0.46px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 14px;
  }

  span {
    display: inline-flex;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 26px;
      letter-spacing: -0.58px;
      align-self: flex-start;
    }
    @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-height: 414px) {
      font-size: 22px;
      letter-spacing: -0.38px;
      align-self: center;
    }
  }
`;

const PageHeaderComponent = ({
  children,
  pageData,
  isStarted,
  activePageIndex,
  className,
  config,
  itemsLength,
  instructions,
  innerTime,
}) => {
  let showTitleCount = true && config.showQuestionCounter;
  if ((true && config.showQuestionCounter) == null) { showTitleCount = true; }

  const { isExample, template } = pageData;
  const { t } = useTranslation();

  const validTemplate = Object.keys(template).includes('values') ? Object.keys(template.values).includes('testId') : false;

  const isVideoTemplate = validTemplate ? template.values.testId.includes('SJT') : false;


  const title = isStarted ? pageData.title : isVideoTemplate ? pageData.title : isExample ? t('example') : pageData.title;
  // const titleCount = isStarted
  //   ? activePageIndex + 1
  //   : isExample
  //   ? pageData.id.slice(1)
  //   : "";

  const titleCount = isExample ? pageData.id.slice(1) : activePageIndex + 1;
  // const totalCount = isStarted ? itemsLength : instructions.length;
  const totalCount = pageData.type === "instruction" ? instructions.length : itemsLength;

  const uniqueExamples = instructions.reduce((acc, next) => {
    if (next.id) {
      acc.add(next.id);
    }

    return acc;
  }, new Set());

  return (
    <PageHeaderStyled className={className}>
      {children}
      {(pageData.type !== 'instruction' || isExample) && (
        <LabelStyled>
          {isPresumablyDesktopByHeight && <span>{title} &nbsp;</span>}
          {showTitleCount && <span>{isExample ? `${titleCount} / ${uniqueExamples.size}` : `${titleCount} / ${totalCount}`}</span>}
        </LabelStyled>
      )}
      {pageData.type === 'instruction' && !isExample && <LabelStyled>{title}</LabelStyled>}
      {config.timeAllowed && isStarted && <Timer innerTime={innerTime} />}
    </PageHeaderStyled>
  );
};

const mapStateToProps = (state) => {
  const {
    testDetails: { items, instructions, config },
    questionnaireDetails: { isStarted },
  } = state;

  return {
    isStarted,
    items,
    config,
    instructions,
  };
};

export const PageHeader = connect(mapStateToProps)(PageHeaderComponent);
