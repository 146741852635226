import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { FullHeight, GlyphStyled, QuestionTextStyled } from './shared/components';
import { CardsList } from '../shared/CardsList';
import { GlyphSet } from './shared/GlyphSet';

const QuestionWhichTextStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 20px;
  line-height: 24px;
  z-index: 5;
  position: relative;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 1.67;
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    //font-size: 14px;
  }
`;

const CustomFullHeight = styled(FullHeight)`
  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    justify-content: center;
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (min-width: ${({ theme }) =>
      theme.viewports.xs}) and (orientation: landscape) {
    justify-content: flex-end;
  }

  @media (max-height: 414px) and (orientation: landscape) {
    justify-content: center;
  }
`;

const GlyphWrapperStyled = styled(GlyphStyled)`
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    margin-bottom: 1rem;
  }
`;

const StyledGlyphSet = styled(GlyphSet)`
  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    //font-size: 48px !important;
  }
`;

export const HowFitsTogether = ({
  values: { mainitem, mainshape, responses },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
}) => {
  const testClass = testId.toLowerCase();

  return (
    <>
      <CustomFullHeight>
        <GlyphWrapperStyled>
          <StyledGlyphSet className={`mainshape icon icon-${mainshape} ${testClass}`} />
        </GlyphWrapperStyled>
        <QuestionWhichTextStyled
          dangerouslySetInnerHTML={{
            __html: mainitem,
          }}
        />
      </CustomFullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className={clsx({ 'svg-image': config.isAbstract, howFitsTogether: true })}
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
