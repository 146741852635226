import { css } from 'styled-components';

export const viewports = {
  xxs: '320px',
  xs: '576px',
  sm: '768px',
  presumablyDesktopHeight: '800px',
  md: '968px',
  mdx: '1024px',
  lg: '1280px',
  lgx: '1441px',
  xl: '1800px',
};

export default Object.keys(viewports).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${viewports[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
