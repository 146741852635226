import React from 'react';
import { connect } from 'react-redux';
import { Logout } from './Logout';
import { Pin } from './Pin';

const PostTestComponent = ({ details }) => {
  return details.isAdministered ? <Pin /> : <Logout />;
};

const mapStateToProps = (state) => {
  return { details: state.questionnaireDetails.details };
};

export const PostTest = connect(mapStateToProps)(PostTestComponent);
