import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';
import { LangDirectionContext } from './context/LangDirectionContext';
import { Router } from './Router';
import { theme } from './assets/styles/theme';
import { GlobalStyles } from './assets/styles/global';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './App.css';

export const App = () => {
  const { i18n } = useTranslation();
  const language = i18n.language || 'en';

  return (
    <LangDirectionContext.Provider value={i18n.dir(language)}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastProvider>
          <Router />
        </ToastProvider>
      </ThemeProvider>
    </LangDirectionContext.Provider>
  );
};
