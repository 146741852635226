import * as workerTimers from 'worker-timers';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useTimer from 'easytimer-react-hook';

import { saveTimeSpentOnQuestion } from '../../store/actions/questionnaireDetailsActions';

const transformTo2NumbersAfterDecimal = (num) => Number((num / 1000).toFixed(2));

// const AVERAGE_DELAY_RATIO = 0.125;

export const useTimeSpentPerQuestion = ({ questionIndex, spentTime, isCountingBlocked, isMex }) => {
  const [timer] = useTimer();

  let time = spentTime ? Math.round(spentTime * 1000) : 0;
  const dispatch = useDispatch();
  const savingIntervalId = useRef();

  useEffect(() => {
    if (isCountingBlocked || isMex) return;

    timer.start({
      precision: 'secondTenths',
      callback: () => {
        time += 100;
      },
    });

    savingIntervalId.current = workerTimers.setInterval(() => {
      dispatch(saveTimeSpentOnQuestion(questionIndex, transformTo2NumbersAfterDecimal(time)));
    }, 5000);

    return () => {
      timer.stop();
      workerTimers.clearInterval(savingIntervalId.current);
      dispatch(saveTimeSpentOnQuestion(questionIndex, transformTo2NumbersAfterDecimal(time)));
    };
  }, [questionIndex, isCountingBlocked, isMex]);

  useEffect(() => {
    if (isCountingBlocked || isMex) {
      timer.stop();
      workerTimers.clearInterval(savingIntervalId.current);
    }
  }, [isCountingBlocked, isMex]);
};
