import React from "react";
import styled from "styled-components";
import { Logo } from "./Logo";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 32px 0 16px 0;
  border-bottom: ${(props) => `1px ${props.theme.colors.new.darkBlue2} solid`};
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
`;

export const Header = ({ title }) => {
  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>

      <Logo />
    </HeaderWrapper>
  );
};
