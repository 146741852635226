import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FeedbackStyled, FullHeight, QuestionTextStyled } from './shared/components';
import { InstructionModal } from '../shared/InstructionModal';
import { CardsList } from '../shared/CardsList';
import { isMobile } from '../shared/utils/isMobile';

const TemplateStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
    flex-direction: row;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 30vh;
      max-height: 350px;
      max-width: 100%;
      width: 85rem;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
        max-width: 100%;
      }
      @media (min-width: ${({ theme }) => theme.viewports.xl}) {
        height: 43vh;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    display: flex;
    margin-bottom: 40px;

    .image-container {
      order: 1;
    }
  }
`;

const QuestionWhichTextStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.67;
  z-index: 5;
  position: relative;

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    font-size: 24px;
    line-height: 1.67;
    margin-right: 20%;
    text-align: left;
  }
`;

export const WhichOfImageSRTi = ({
  values: { mainitem, responses, image, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  iconsModule,
}) => {
  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);

  const ImageComponent = iconsModule[image];

  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <FullHeight>
        <TemplateStyled>
          <div className="image-container">
            <ImageComponent />
          </div>

          <QuestionWhichTextStyled
            dangerouslySetInnerHTML={{
              __html: mainitem,
            }}
          />
        </TemplateStyled>

        {!!feedback && !isMobile && (
          <FeedbackStyled
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </FullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        // isImage
        isImageBorder
        className="which-of-three which-of-image for-text"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        iconsModule={iconsModule}
      />
    </>
  );
};
