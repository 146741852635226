import React from 'react';
import styled from 'styled-components';

/* eslint-disable react/no-unescaped-entities */

export const MEXHelp = () => {
  return (
    <Container>
      <List>
        <ListItem>To move a card, 'drag & drop' it or click on an arrow located inside title row</ListItem>
        <ListItem>
          To re-sort cards, click on the 'x' at the top-right corner of each card. This will return the card to the sort
          pile.
        </ListItem>
      </List>
      <Label>Contact us: support@chineselect.cn</Label>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

const ListItem = styled.li`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 2rem;
`;

const Label = styled.span`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.white};
`;
