import React from "react";
import { any, bool, func, oneOf, string, number } from "prop-types";
import styled from "styled-components";
import { ReactComponent as Spinner } from "../icons/spinner.svg";

const paddingSizesMap = {
  xl: "2rem 3rem",
  l: "1.6rem 3rem",
  m: "1.4rem 2rem",
  s: "0.8rem 1rem",
};

const fontSizesMap = (themeFontSizes) => ({
  xl: themeFontSizes.medium,
  l: themeFontSizes.normal,
  m: "1.4rem",
  s: themeFontSizes.small,
});

const BaseButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  transition: all 0.2s;
  ${(props) => !!props.width && `width: ${props.width}px`};
  ${(props) => !!props.height && `height: ${props.height}px`};
  background-color: ${(props) =>
    props.disabled && !props.isLoading && props.theme.colors.grey2};

  :hover {
    cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
  }

  :active {
    transform: scale(0.98);
  }
`;

const PrimaryButton = styled(BaseButton)`
  background-color: ${(props) =>
    props.isLoading ? props.theme.colors.mediumBlue : props.theme.colors.light};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  font-size: ${(props) => fontSizesMap(props.theme.fontSizes)[props.size]};
  padding: ${(props) => paddingSizesMap[props.size]};
  border-radius: 6rem;

  :hover {
    background-color: ${(props) => props.theme.colors.mediumBlue};
  }

  :active {
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const SecondaryButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  border-radius: 6rem;
  color: ${(props) => props.theme.colors.lightBlue};
  font-weight: bold;
  font-size: ${(props) => fontSizesMap(props.theme.fontSizes)[props.size]};
  padding: ${(props) => paddingSizesMap[props.size]};

  :hover {
    color: ${(props) => props.theme.colors.mediumBlue};
    border-color: ${(props) => props.theme.colors.mediumBlue};
  }

  :active {
    color: ${(props) => props.theme.colors.darkBlue};
    border-color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const RoundedButton = styled(BaseButton)`
  background-color: ${(props) => props.theme.colors.lightBlue};
  border-radius: 6rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  padding: 1rem 2rem;
  :hover {
    background-color: ${(props) => props.theme.colors.mediumBlue};
  }

  :active {
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const SpinnerStyled = styled(Spinner)`
  position: absolute;
`;

const types = ["primary", "secondary", "rounded"];

const Components = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  rounded: RoundedButton,
};

export const CustomButton = ({
  handler,
  variant,
  size,
  disabled,
  className,
  children,
  width,
  isLoading,
  ...rest
}) => {
  const Button = Components[variant];
  return (
    <Button
      onClick={handler}
      size={size}
      disabled={disabled}
      width={width}
      className={className}
      isLoading={isLoading}
      {...rest}
    >
      {isLoading ? <SpinnerStyled /> : children}
    </Button>
  );
};

CustomButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: any.isRequired,
  handler: func,
  size: string,
  disabled: bool,
  variant: oneOf(types),
  className: string,
  width: number,
  height: number,
  isLoading: bool,
};

CustomButton.defaultProps = {
  size: "m",
  disabled: false,
  variant: "primary",
  className: "",
  handler: undefined,
  width: 0,
  height: 0,
  isLoading: false,
};
