import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

export class LoggerService {
  static init() {
    Sentry.init({
      dsn:
        "https://4a8816136bdc42c2a4aa9132ceeb2bb5@o508797.ingest.sentry.io/5601849",
      autoSessionTracking: true,
      // TODO: restore after changing timeout req/res approach
      // integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      // tracesSampleRate: 1.0,
    });
  }
}
