import styled from "styled-components";

export const ScrollIcon = styled.span`
    position: absolute;
    left: 40%;
    width: 25px;
    height: 44px;
    border: 1px solid ${({ theme }) => theme.formElements.fontColor};
    border-radius: 50px;
    box-sizing: border-box;
    z-index: -1;
    
    &:before {
      position: absolute; 
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 6px;
      height: 6px;
      background-color: ${({ theme }) => theme.formElements.fontColor};
      border-radius: 100%;
      animation: flash 2s infinite;
      box-sizing: border-box;
  
  @keyframes flash {
    0% {
      -webkit-transform: translate(-50%, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(-50%, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;
