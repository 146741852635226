import React from "react";
import { toast } from "react-toastify";
import { Notification } from "../Notification";

const typesMap = {
  success: "toast-success",
  error: "toast-failure",
  info: "toast-info",
  copy: "toast-success-copy",
};

export const createToastNotification = ({
  title,
  message,
  type = "success",
  options,
}) => {
  toast(<Notification title={title} body={message} type={type} />, {
    type,
    className: typesMap[type],
    ...options,
  });
};
