import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Bowser from "bowser";
import { LangDirectionContext } from "../../context/LangDirectionContext";
import { Button, ButtonVariants } from "../shared/Button";
import { Page } from "../shared/layout";
import { ButtonsHolder } from "../shared/layout/ButtonHolder";
import { Divider } from "./shared/components";
import { Footer } from "../shared/Footer";

const CoverStyled = styled(Page)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
   
  .container {
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 75vw;
    align-items: flex-start;
    transform: translateY(-50%);
    top: 45%;
    left: 0;
    right: 0;
    margin: auto;
  
    h1 {
      font-size: 32px;
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      line-height: 1;
      letter-spacing: -0.92px;
      max-width: 40vw;
  
      @media (min-width: ${({ theme }) => theme.viewports.xs}) {
        font-size: 44px;
        max-width: 50vw;
        width: 100%;
      }
  }
`;

const FooterStyled = styled(Footer)`
  min-height: 80px;
  z-index: 3;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (max-height: 414px) {
    min-height: 54px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    background-color: ${({ theme }) => theme.colors.font};
  }

  .wrapper {
    min-height: 80px;
    background: transparent;
    flex-direction: row;
    @media (max-height: 414px) {
      min-height: 54px;
    }
  }
`;
const DividerStyled = styled(Divider)`
  display: none;
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    display: block;
  }
`;

const ButtonsHolderStyled = styled(ButtonsHolder)`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 5%;
  
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
  }
  
  h2 {
    display: none;

    @media (min-width: ${({ theme }) => theme.viewports.xs}) {
      padding: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0;
      color: ${({ theme }) => theme.colors.white};
      display: inline-block;
    }
`;

const ButtonStyled = styled(Button)`
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    width: auto;
  }
`;

const HiddenIconStyled = styled.div`
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  font-size: 1px;
`;

export const Cover = ({ values: { category }, onNext }) => {
  const { t } = useTranslation();

  const direction = React.useContext(LangDirectionContext);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const isTablet = platform.type === "tablet";

  return (
    <CoverStyled direction={direction} withBackgroundMobile withOpacity>
      <div className="container">
        <h1>{category}</h1>
        <HiddenIconStyled className="icon icon-question" />
      </div>
      <FooterStyled>
        <ButtonsHolderStyled>
          {!isTablet && (
            <h2 dangerouslySetInnerHTML={{ __html: t("pressEnter") }} />
          )}
          <DividerStyled />
          <ButtonStyled
            variant={ButtonVariants.PRIMARY}
            onClick={onNext}
            autoFocus
          >
            {t("continue")}
          </ButtonStyled>
        </ButtonsHolderStyled>
      </FooterStyled>
    </CoverStyled>
  );
};
