import React from "react";
import styled, { css } from "styled-components";
import select from "../../templates/images/select.svg";
import { LangDirectionContext } from "../../../context/LangDirectionContext";

export const SelectStyled = styled.select`
  position:relative;
  width: 100%;
  height: ${({ theme }) => theme.formElements.height};
  z-index: 1;
  outline: none;
  border: none;
  border-radius: ${({ theme }) => theme.formElements.borderRadius};
  font-size: ${({ theme }) => theme.formElements.fontSize};
  line-height: 1.5;
  padding: 0 25px 0 10px;
  background-image: url(${select});
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  background-color: transparent;
  color: ${({ theme }) => theme.formElements.fontColor};
  cursor: pointer;
  font-weight: 500;
  appearance: none; 
  
       @media (min-width: ${({ theme }) => theme.viewports.md}) {
       padding-left: 12px;
       padding-right: 30px;
       background-position: right 12px center;
        ${(props) =>
          props.direction === "rtl" &&
          css`
            background-position: left 12px center;
            padding-left: 30px;
            padding-right: 12px;
          `}
       }
       
  ${(props) =>
    props.direction === "rtl" &&
    css`
      background-position: left 10px center;
      padding-left: 25px;
      padding-right: 10px;
    `}  
    
  &:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }  

  &.error {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.formElements.error};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ~ label {
    will-change: transform;
    display: block;
    position: absolute;
      bottom: 14px;
    left: 10px;
    width: auto;
    padding-bottom: 0;
    font-weight: 500;
    line-height: 1.5;
    font-size: 16px;
    transform-origin: 0 50%;
    transform: translateY(0) scale(1) translateZ(0);
    transition: transform 0.3s, color 0.3s;
    color: #5b5b5b;
    margin-bottom: 0; 
        
    @media (min-width: ${({ theme }) => theme.viewports.md}) {
    left: 12px;
    
    ${(props) =>
      props.direction === "rtl" &&
      css`
        right: 12px;
        left: auto;
      `}  
    }
    
    ${(props) =>
      props.direction === "rtl" &&
      css`
        right: 10px;
        left: auto;
        transform-origin: 100% 0;
      `}     
          
    :hover {
      cursor: pointer;
    }
    
  }
  
  &.fill,
  &:focus {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.light};
    padding-top: 20px;
    & + label {
      transform: translateY(-10px) scale(0.8);  
      color: #008ac0;
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.light};
    background-color: rgba(0, 185, 255, 0.1);
  }

  &.fill:hover,
  &:hover {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.light};
    cursor: pointer;
  }

  &.fill {
    box-shadow: none;
    &:focus {
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.light};
      background-color: rgba(0, 185, 255, 0.1);
    }
  }

  &.error {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.formElements.error};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.medGray};
  }  
  
  /* IE11 */
  &::-ms-expand {
    display: none;
  }
  
  ${(props) =>
    props.modern &&
    css`
      border: none;
      border-radius: 0;
      outline: none;
      display: block;
      appearance: none;
      position: relative;
      z-index: 1;
      padding: 0;
      width: 100%;
      font-size: 16px;
      line-height: 1.5;
      background-color: transparent;
      caret-color: ${({ theme }) => theme.colors.light};
      font-weight: 500;
      margin: 20px 0 0;

      &:focus {
        background-color: transparent;
      }

      ~ label {
        will-change: transform;
        display: block;
        position: absolute;
        z-index: 0;
        bottom: 7px;
        left: 0;
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 0;
        font-weight: 500;
        line-height: 1.5;
        font-size: 16px;
        transform-origin: 0 50%;
        transform: translateY(0) scale(1) translateZ(0);
        transition: transform 0.3s, color 0.5s;
        color: #787878;
        opacity: 1;
      }

      ~ hr {
        display: block !important;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border: 0;
        border-radius: 4px;
        margin: 0;
        background: #cccccc;
        z-index: 1;

        &:after {
          transition: all 250ms ease-out;
          transform: scaleX(0) translateZ(0);
          transform-origin: left top;
          will-change: transform;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background: ${({ theme }) => theme.colors.light};
        }
      }

      &.fill,
      &:focus {
        ~ hr:after {
          transform: scaleX(1) translateZ(0);
        }
      }

      &.fill,
      &:focus {
        box-shadow: none;
        & + label {
          transform: translateY(-25px) scale(0.8);
          z-index: 2;
        }
      }

      &:focus {
        box-shadow: none;
        background-color: transparent;
      }
      &.fill:hover,
      &:hover {
        box-shadow: none;
      }

      &.fill {
        box-shadow: none;
        &:focus {
          box-shadow: none;
          background-color: transparent;
        }
      }
    `}
    
  ${(props) =>
    props.date &&
    css`
      ~ label {
        width: auto;
      }
      ${(props) =>
        props.direction === "rtl" &&
        css`
          //background-position: left 12px center;
        `}
    `}


  ${(props) =>
    props.direction === "rtl" &&
    css`
      background-position: left 12px center;
    `}
    
  &:hover {
    border-color: ${({ theme }) => theme.formElements.focusBorderColor};
  }

  &:focus {
    transition: color 0.7s;
    &:invalid {
      color: #787878;
    }
  }

  &:invalid {
    color: rgba(255,255,255,0);
  }
`;

export const Select = ({ options, className, date, modern, ...props }) => {
  const direction = React.useContext(LangDirectionContext);
  return (
    <SelectStyled
      className={className}
      modern={modern}
      date={date}
      {...props}
      required
      direction={direction}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectStyled>
  );
};
