import React from "react";

export const KeyPageDown = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.9643 40H2.72287C1.60664 40 0.693359 39.0886 0.693359 37.9747V2.02532C0.693359 0.911392 1.60664 0 2.72287 0H39.9643C41.0805 0 41.9938 0.911392 41.9938 2.02532V37.9747C41.9938 39.0886 41.1313 40 39.9643 40Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M12.2037 10.01C13.2637 10.01 14.037 10.2133 14.5237 10.62C15.0104 11.0267 15.2537 11.6533 15.2537 12.5C15.2537 13.3467 15.0037 13.9667 14.5037 14.36C14.0104 14.7533 13.2504 14.95 12.2237 14.95H10.9837V17H9.80371V10.01H12.2037ZM12.3537 13.88C13.007 13.88 13.4504 13.7567 13.6837 13.51C13.9237 13.2567 14.0437 12.89 14.0437 12.41C14.0437 11.9233 13.8937 11.58 13.5937 11.38C13.2937 11.18 12.8237 11.08 12.1837 11.08H10.9837V13.88H12.3537Z"
        fill="white"
      />
      <path
        d="M20.692 17H19.672V16.29C19.232 16.8167 18.642 17.08 17.902 17.08C17.3487 17.08 16.8887 16.9233 16.522 16.61C16.162 16.2967 15.982 15.88 15.982 15.36C15.982 14.8333 16.1753 14.44 16.562 14.18C16.9487 13.92 17.472 13.79 18.132 13.79H19.582V13.59C19.582 12.8833 19.1887 12.53 18.402 12.53C17.9087 12.53 17.3953 12.71 16.862 13.07L16.362 12.37C17.0087 11.8567 17.742 11.6 18.562 11.6C19.1887 11.6 19.6987 11.76 20.092 12.08C20.492 12.3933 20.692 12.89 20.692 13.57V17ZM19.572 15.02V14.57H18.312C17.5053 14.57 17.102 14.8233 17.102 15.33C17.102 15.59 17.202 15.79 17.402 15.93C17.602 16.0633 17.8787 16.13 18.232 16.13C18.592 16.13 18.9053 16.03 19.172 15.83C19.4387 15.63 19.572 15.36 19.572 15.02Z"
        fill="white"
      />
      <path
        d="M27.1711 11.68V16.32C27.1711 17.26 26.9145 17.9633 26.4011 18.43C25.8945 18.9033 25.2411 19.14 24.4411 19.14C23.6411 19.14 22.9178 18.89 22.2711 18.39L22.8011 17.54C23.3211 17.9333 23.8445 18.13 24.3711 18.13C24.9045 18.13 25.3245 17.9933 25.6311 17.72C25.9445 17.4467 26.1011 17.01 26.1011 16.41V15.73C25.9345 16.0433 25.6945 16.2933 25.3811 16.48C25.0745 16.6667 24.7278 16.76 24.3411 16.76C23.6345 16.76 23.0545 16.5133 22.6011 16.02C22.1478 15.5267 21.9211 14.9133 21.9211 14.18C21.9211 13.4467 22.1478 12.8333 22.6011 12.34C23.0545 11.8467 23.6311 11.6 24.3311 11.6C25.0378 11.6 25.6111 11.89 26.0511 12.47V11.68H27.1711ZM23.0211 14.14C23.0211 14.5733 23.1545 14.95 23.4211 15.27C23.6945 15.5833 24.0578 15.74 24.5111 15.74C24.9711 15.74 25.3411 15.5867 25.6211 15.28C25.9078 14.9733 26.0511 14.5933 26.0511 14.14C26.0511 13.68 25.9078 13.2933 25.6211 12.98C25.3345 12.66 24.9645 12.5 24.5111 12.5C24.0578 12.5 23.6945 12.6633 23.4211 12.99C23.1545 13.3167 23.0211 13.7 23.0211 14.14Z"
        fill="white"
      />
      <path
        d="M33.7441 14.83H29.5741C29.6008 15.2167 29.7774 15.53 30.1041 15.77C30.4308 16.0033 30.8041 16.12 31.2241 16.12C31.8908 16.12 32.3974 15.91 32.7441 15.49L33.3841 16.19C32.8108 16.7833 32.0641 17.08 31.1441 17.08C30.3974 17.08 29.7608 16.8333 29.2341 16.34C28.7141 15.84 28.4541 15.1733 28.4541 14.34C28.4541 13.5 28.7208 12.8333 29.2541 12.34C29.7874 11.8467 30.4141 11.6 31.1341 11.6C31.8608 11.6 32.4774 11.82 32.9841 12.26C33.4908 12.6933 33.7441 13.2933 33.7441 14.06V14.83ZM29.5741 13.95H32.6241C32.6241 13.5033 32.4841 13.16 32.2041 12.92C31.9241 12.68 31.5774 12.56 31.1641 12.56C30.7574 12.56 30.3908 12.6867 30.0641 12.94C29.7374 13.1867 29.5741 13.5233 29.5741 13.95Z"
        fill="white"
      />
      <path
        d="M9.93809 22.01C11.1914 22.01 12.1548 22.3167 12.8281 22.93C13.5014 23.5367 13.8381 24.3833 13.8381 25.47C13.8381 26.5567 13.5114 27.4167 12.8581 28.05C12.2048 28.6833 11.2048 29 9.85809 29H7.53809V22.01H9.93809ZM12.6481 25.5C12.6481 23.9 11.7314 23.1 9.89809 23.1H8.71809V27.89H10.0281C10.8748 27.89 11.5214 27.69 11.9681 27.29C12.4214 26.8833 12.6481 26.2867 12.6481 25.5Z"
        fill="white"
      />
      <path
        d="M16.4404 27.6C16.7604 27.92 17.1637 28.08 17.6504 28.08C18.137 28.08 18.537 27.92 18.8504 27.6C19.1704 27.2733 19.3304 26.8533 19.3304 26.34C19.3304 25.8267 19.1704 25.41 18.8504 25.09C18.537 24.7633 18.137 24.6 17.6504 24.6C17.1637 24.6 16.7604 24.7633 16.4404 25.09C16.127 25.41 15.9704 25.8267 15.9704 26.34C15.9704 26.8533 16.127 27.2733 16.4404 27.6ZM19.6504 28.29C19.117 28.8167 18.4504 29.08 17.6504 29.08C16.8504 29.08 16.1837 28.8167 15.6504 28.29C15.117 27.7567 14.8504 27.1067 14.8504 26.34C14.8504 25.5733 15.117 24.9267 15.6504 24.4C16.1837 23.8667 16.8504 23.6 17.6504 23.6C18.4504 23.6 19.117 23.8667 19.6504 24.4C20.1837 24.9267 20.4503 25.5733 20.4503 26.34C20.4503 27.1067 20.1837 27.7567 19.6504 28.29Z"
        fill="white"
      />
      <path
        d="M24.1454 29H22.8654L21.0254 23.68H22.1854L23.5054 27.7L24.8254 23.68H25.9854L27.3054 27.7L28.6254 23.68H29.7854L27.9454 29H26.6654L25.4054 25.36L24.1454 29Z"
        fill="white"
      />
      <path
        d="M31.8965 26.12V29H30.7765V23.68H31.8965V24.65C32.0765 24.3233 32.3232 24.0667 32.6365 23.88C32.9565 23.6933 33.2999 23.6 33.6665 23.6C34.2665 23.6 34.7499 23.7833 35.1165 24.15C35.4899 24.5167 35.6765 25.0467 35.6765 25.74V29H34.5565V26.08C34.5565 25.1 34.1499 24.61 33.3365 24.61C32.9499 24.61 32.6132 24.74 32.3265 25C32.0399 25.2533 31.8965 25.6267 31.8965 26.12Z"
        fill="white"
      />
    </svg>
  );
};
