import React from "react";
import { Field, ErrorMessage } from "formik";
import styled, { css } from "styled-components";
import clsx from "clsx";
import { uniqueId } from "../../utils/tools/uniqueId";
import { shake } from "../../utils/animations";

const FormFieldStyled = styled.div`
  margin-bottom: 10px;
  position: relative;
  width: 100%;

  @media (min-height: 800px) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  + div {
    position: relative;
    width: 100%;
    z-index: 2;
  }

  hr {
    display: none;
  }

  .flex-box {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    position: relative;

    ${(props) =>
      props.classic &&
      css`
        height: auto;
        border-radius: 4px;
        background: rgba(248, 248, 248, 0.9);

        &.noBg {
          background: transparent;
          .error ~ span {
            bottom: 0;
          }
        }

        &.disabled {
          background: #f8f8f894;
          cursor: not-allowed;
        }

        @media (min-width: ${({ theme }) => theme.viewports.md}) {
          &.bigger {
            min-width: 330px;
          }
        }
        .error select {
          box-shadow: inset 0 0 0 2px ${({ theme }) => theme.formElements.error};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        div ~ label {
          color: white;
          font-size: 18px;
          line-height: 22px;
        }

        &:focus {
          box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.light};
        }
      `}
  }

  &.shake {
    animation: 0.3s both ${shake};

    hr:after {
      background-color: ${({ theme }) => theme.colors.error};
    }
  }

  ${(props) =>
    props.classic &&
    css`
      min-height: 68px;
    `}

  ${(props) =>
    props.flexGrow &&
    css`
      flex-grow: 1;
      display: flex;
      align-items: center;
    `}
`;

const LabelStyled = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 4px;
`;

const ErrorMessageStyled = styled.span`
  color: ${({ theme }) => theme.formElements.error};
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  font-style: italic;
  order: -1;

  ${(props) =>
    props.classic &&
    css`
      background: ${({ theme }) => theme.formElements.error};
      color: ${({ theme }) => theme.colors.white};
      font-size: 12px;
      line-height: 16px;
      font-weight: bold;
      font-style: initial;
      padding-left: 10px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const FormField = ({
  className,
  name,
  label,
  flexGrow,
  component: Comp,
  isNonFormElement,
  isDisabled,
  bigger,
  classic,
  ...props
}) => {
  const id = uniqueId(7);

  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <FormFieldStyled
            className={className}
            flexGrow={flexGrow}
            classic={classic}
          >
            <div
              className={clsx(
                "flex-box",
                { noBg: isNonFormElement },
                { disabled: isDisabled },
                { bigger },
                className
              )}
            >
              <Comp
                className={clsx(
                  { error: meta.error },
                  { fill: !!field.value },
                  className
                )}
                id={id}
                {...props}
                {...field}
                form={isNonFormElement && form}
              />
              {label && <LabelStyled htmlFor={id}>{label}</LabelStyled>}
              <ErrorMessage
                name={name}
                classic={classic}
                component={ErrorMessageStyled}
              />
              <hr />
            </div>
          </FormFieldStyled>
        );
      }}
    </Field>
  );
};
