import React from 'react';
import styled from 'styled-components';

const FooterStyled = styled.div`
  display: none;
  z-index: 5;
  position: relative;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    position: fixed;
    right: 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.whiteFontColor};
    min-height: ${({ theme }) => theme.footer.height};
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    background: ${({ theme }) => theme.colors.bgFooter};
    bottom: 0;
    top: auto;
    height: ${({ theme }) => theme.footer.height};
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-height: 414px) {
    height: 35px;
    min-height: 35px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: ${({ theme }) => theme.widthWrapper};
    margin: auto;
    width: 100%;
    flex-direction: column;
    backdrop-filter: blur(30px);

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      backdrop-filter: none;
      flex-direction: row;
      margin: 0 auto;
      min-height: ${({ theme }) => theme.footer.height};
    }
    @media (max-height: 414px) {
      min-height: 54px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
        theme.viewports.mdx}) and (orientation: portrait) {
      padding: 0 2%;
    }
  }
`;

export const Footer = ({ children, ...props }) => {
  return (
    <FooterStyled {...props}>
      <div className="wrapper">{children}</div>
    </FooterStyled>
  );
};
