import React from 'react';
import styled, { css } from 'styled-components';
import Bowser from 'bowser';
import { LangDirectionContext } from '../../context/LangDirectionContext';
import { Page } from '../shared/layout';

const PageStyled = styled(Page)`
  top: 0;
  left: 0;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
  }
`;

const InstructionsStyled = styled.div`
  z-index: 3;
  padding: 60px 5% 20px 5%;
  position: relative;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    margin: 0 5%;
    padding: 60px 7% 20px 7%;
    height: 100%;
    display: flex;
    align-items: center;
    width: 80%;
    z-index: 4;
    max-width: calc(900px + 10%);
    -webkit-overflow-scrolling: touch;
  }

  h1 {
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      max-width: 640px;
      width: 100%;
      margin-bottom: 15px;
      font-size: 1.5em;
    }

    @media (min-width: ${({ theme }) => theme.viewports.lgx}) {
      margin-bottom: 50px;
      font-size: 2em;
    }
  }

  br {
    display: block;
  }

  & span,
  & b {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    line-height: 1.6;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 18px;
      line-height: 26px;
      max-width: 640px;
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    @media (min-width: ${({ theme }) => theme.viewports.lgx}) {
      font-size: 22px;
      line-height: 34px;
      max-width: 640px;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  & p {
    text-align: initial;
  }
  & div > p,
  & span li {
    font-size: 16px;
    margin-bottom: 30px;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    list-style: none;
    line-height: 1.4;
    font-weight: normal;
    padding-left: 16px;
    text-align: initial;
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 17px;
      padding-left: 24px;
      max-width: 640px;
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    &:before {
      height: 6px;
      min-width: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.white};
      content: '';
      position: absolute;
      display: inline-flex;
      vertical-align: middle;
      top: 10px;
      left: 0;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        height: 8px;
        min-width: 8px;
        top: 8px;
      }
    }
  }

  ${(props) =>
    props.direction === 'rtl' &&
    css`
      & span li {
        padding-right: 16px;

        @media (min-width: ${({ theme }) => theme.viewports.sm}) {
          padding-right: 24px;
        }

        padding-left: 0;
        &:before {
          left: auto;
          right: 0;
        }
      }
    `}

  & span li {
    margin-bottom: 0;
  }
`;

export const Instruction = ({ values: { mainitem, mobileitem } }) => {
  const direction = React.useContext(LangDirectionContext);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const isDesktop = platform.type === 'desktop';

  return (
    <PageStyled withBlurOpacity>
      <InstructionsStyled
        direction={direction}
        dangerouslySetInnerHTML={{
          __html: !isDesktop && mobileitem ? mobileitem : mainitem,
        }}
      />
    </PageStyled>
  );
};
