import React from "react";

export const NumEight = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.378 19.746C23.994 19.9513 24.4653 20.2593 24.792 20.67C25.128 21.0807 25.296 21.5753 25.296 22.154C25.296 22.7233 25.142 23.2227 24.834 23.652C24.526 24.072 24.0827 24.3987 23.504 24.632C22.9347 24.856 22.2627 24.968 21.488 24.968C20.312 24.968 19.388 24.716 18.716 24.212C18.044 23.708 17.708 23.022 17.708 22.154C17.708 21.566 17.8667 21.0713 18.184 20.67C18.5107 20.2593 18.982 19.9513 19.598 19.746C19.094 19.55 18.7113 19.27 18.45 18.906C18.1887 18.5327 18.058 18.094 18.058 17.59C18.058 16.7967 18.366 16.1667 18.982 15.7C19.6073 15.2333 20.4427 15 21.488 15C22.1787 15 22.7807 15.1073 23.294 15.322C23.8167 15.5273 24.2227 15.826 24.512 16.218C24.8013 16.61 24.946 17.0673 24.946 17.59C24.946 18.094 24.8107 18.5327 24.54 18.906C24.2693 19.27 23.882 19.55 23.378 19.746ZM19.066 17.618C19.066 18.1593 19.2807 18.5887 19.71 18.906C20.1393 19.2233 20.732 19.382 21.488 19.382C22.244 19.382 22.8367 19.2233 23.266 18.906C23.7047 18.5887 23.924 18.164 23.924 17.632C23.924 17.0813 23.7 16.6473 23.252 16.33C22.8133 16.0127 22.2253 15.854 21.488 15.854C20.7413 15.854 20.1487 16.0127 19.71 16.33C19.2807 16.6473 19.066 17.0767 19.066 17.618ZM21.488 24.114C22.356 24.114 23.0327 23.9367 23.518 23.582C24.0127 23.2273 24.26 22.7467 24.26 22.14C24.26 21.5333 24.0127 21.0573 23.518 20.712C23.0327 20.3573 22.356 20.18 21.488 20.18C20.6293 20.18 19.9527 20.3573 19.458 20.712C18.9727 21.0573 18.73 21.5333 18.73 22.14C18.73 22.756 18.9727 23.2413 19.458 23.596C19.9433 23.9413 20.62 24.114 21.488 24.114Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
