import React from "react";
import { useTranslation } from "react-i18next";
import { KeyEnter } from "./icons";
import { KeysHelpHolder } from "./KeysHelpHolder";

export function EnterKey({ ...props }) {
  const { t } = useTranslation();
  return (
    <KeysHelpHolder
      desktop
      title={t("pressEnterToConfirm")}
      subtitle={t("thisWillAutomaticallyMove")}
      {...props}
    >
      <KeyEnter />
    </KeysHelpHolder>
  );
}
