import React from "react";
import styled, { css } from "styled-components";
import SlidingPanel from "react-sliding-side-panel";
import { QuestionnaireNavigator } from "./QuestionnaireNavigator";
import { LangDirectionContext } from "../../context/LangDirectionContext";
import close from "../../assets/images/close.svg";

const SidePanelStyled = styled.div`
  .panel {
    max-width: 100%;
    background: ${({ theme }) => theme.colors.bgFooter};
    width: 100vw !important;
    position: relative !important;
    top: 0;
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      max-height: 200px !important;
      top: auto;
      bottom: ${({ theme }) => theme.footer.height} !important;
    }
  }

  .panel-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .sliding-panel-container {
    height: 100vh;
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      height: calc(100% - ${({ theme }) => theme.footer.height};);
    }
    //z-index: -1 !important;
  }

  .sliding-panel-container.active {
    //background-color: transparent;
    overflow: hidden;
    transition: ease-in all 0.3s;
    backdrop-filter: blur(5px);
    z-index: 99 !important;
  }

  .panel-container-bottom-enter-done {
    height: 100%;
  }

  .glass {
    min-width: 100vw;
    height: calc(100vh - 200px) !important;
    background: none !important;
  }
`;

const CloseButtonStyled = styled.button`
  z-index: 10;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 16px;
  left: 18px;
  cursor: pointer;
  border: none;
  background-image: url("${close}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  padding: 0;
  right: auto;
  
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    right: 2vw;
    top: 28px;
    left: auto;
      ${(props) =>
        props.direction === "rtl" &&
        css`
          left: 2vw;
          right: auto;
        `}
  }

  ${(props) =>
    props.direction === "rtl" &&
    css`
      left: auto;
      right: 26px;
    `}
`;

export const SidePanel = ({ isOpen, onClose, activeQuestion, onNavigate }) => {
  const direction = React.useContext(LangDirectionContext);

  const isMobile = window.matchMedia("(max-width: 767px)");
  const type = isMobile.matches ? "top" : "bottom";

  return (
    <SidePanelStyled>
      <SlidingPanel type={type} isOpen={isOpen} size={100}>
        <>
          <CloseButtonStyled onClick={onClose} direction={direction} />
          <QuestionnaireNavigator
            activeQuestion={activeQuestion}
            onNavigate={onNavigate}
          />
        </>
      </SlidingPanel>
    </SidePanelStyled>
  );
};
