import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.new.grey1};
  z-index: 2;
`;

const FooterBody = styled.div`
  width: 640px;
  margin: 0 40px;
  display: flex;
  justify-content: ${({ isSingleItem }) =>
    isSingleItem ? "flex-end" : "space-between"};
`;

export const StepEnum = {
  welcome: 0,
  addRaters: 1,
  submit: 2,
};

export const Footer = ({ isSingleItem, children }) => {
  return (
    <FooterWrapper>
      <FooterBody isSingleItem={isSingleItem}>{children}</FooterBody>
    </FooterWrapper>
  );
};
