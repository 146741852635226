import React from 'react';
import styled, { css } from 'styled-components';

const KeysHelpHolderStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};

  svg {
    z-index: 2;
  }
`;

const TitleStyled = styled.b`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const SubTitleStyled = styled.span`
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const ChildrenImageStyled = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  width: auto;
  position: relative;
  margin: auto;
  ${(props) =>
    props.desktop &&
    css`
      width: 100%;
    `};
`;

export const KeysHelpHolder = ({ children, desktop, title, subtitle, ...props }) => {
  return (
    <KeysHelpHolderStyled {...props}>
      <TitleStyled>{title}</TitleStyled>
      <SubTitleStyled>{subtitle}</SubTitleStyled>
      <ChildrenImageStyled desktop={desktop}>{children}</ChildrenImageStyled>
    </KeysHelpHolderStyled>
  );
};
