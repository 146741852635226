import React from "react";
import styled from "styled-components";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LangDirectionContext } from "../../../context/LangDirectionContext";
import { SelectStyled } from "./Select";
import { Divider } from "../../templates/shared/components";

const monthMap = [
  { key: "1", value: "January" },
  { key: "2", value: "February" },
  { key: "3", value: "March" },
  { key: "4", value: "April" },
  { key: "5", value: "May" },
  { key: "6", value: "June" },
  { key: "7", value: "July" },
  { key: "8", value: "August" },
  { key: "9", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];

const yearsMap = {
  yearFrom: 1917,
  yearTo: new Date().getFullYear(),
};

const DateSelectStyled = styled.div`
  .dateContainer {
    z-index: 5;
    margin-bottom: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    > div {
      position: relative;
      width: 40%;
      background: rgba(248, 248, 248, 0.9);
      border-radius: 4px;
  }
`;

const DividerSrtyled = styled(Divider)`
  min-width: 15px;

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    min-width: 20px;
  }
`;

export const DateSelect = ({ value, name, form }) => {
  const [month, setMonth] = React.useState(() => {
    return value ? new Date(value).getUTCMonth() + 1 : "";
  });
  const [day, setDay] = React.useState(() => {
    return value ? new Date(value).getUTCDate() : "";
  });
  const [year, setYear] = React.useState(() => {
    return value ? new Date(value).getUTCFullYear() : "";
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    const val =
      !!month && !!day && !!year
        ? dateFormat(new Date(year, month - 1, day), "yyyy-mm-dd")
        : "";

    if (form.values[name] !== val) {
      form.setFieldValue(name, val);
    }
  }, [month, day, year, form, name]);

  const direction = React.useContext(LangDirectionContext);

  return (
    <DateSelectStyled className={clsx({ error: form.errors.dateOfBirth })}>
      <div className="dateContainer">
        <div>
          <SelectStyled
            className={clsx({ fill: month })}
            date
            direction={direction}
            required
            onChange={(e) => {
              setMonth(e.target.value);
            }}
            value={month}
          >
            <option value="">{t("month")}</option>
            {monthMap.map((month) => (
              <option key={month.key} value={month.key}>
                {t(month.value.toLowerCase())}
              </option>
            ))}
          </SelectStyled>
          <label>{t("month")}</label>
          <hr />
        </div>
        <DividerSrtyled />
        <div>
          <SelectStyled
            className={clsx({ fill: day })}
            direction={direction}
            date
            required
            onChange={(e) => {
              setDay(e.target.value);
            }}
            value={day}
          >
            <option value="">{t("day")}</option>
            {new Array(31).fill().map((e, i) => (
              <option key={`${i + 1}`} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </SelectStyled>
          <label>{t("day")}</label>
          <hr />
        </div>
        <DividerSrtyled />
        <div>
          <SelectStyled
            className={clsx({ fill: year })}
            date
            direction={direction}
            required
            onChange={(e) => {
              setYear(e.target.value);
            }}
            value={year}
          >
            <option value="">{t("year")}</option>
            {new Array(yearsMap.yearTo - yearsMap.yearFrom)
              .fill()
              .map((e, i) => (
                <option key={`${i + 1}`} value={yearsMap.yearTo - i}>
                  {yearsMap.yearTo - i}
                </option>
              ))}
          </SelectStyled>
          <label>{t("year")}</label>
          <hr />
        </div>
      </div>
    </DateSelectStyled>
  );
};
