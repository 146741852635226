export const textOnlyTests = [
  '18FQ',
  '15FQ',
  '15FQ+',
  '15FQ+AZ3',
  '15FQ+C',
  '15FQ+J',
  '15FQ+VALUES',
  '15FQ+VALUESC',
  '15FQ+VN4',
  '5PF',
  'BIGFIVETR',
  'EWQ',
  'HSIP2009',
  'IPP',
  'JTI',
  'LSI',
  'OIP',
  'OIP+',
  'OIP+AZ1',
  'OIP+ES1',
  'OIP+SA1',
  'OPP',
  'OPPJ',
  'PVQ',
  'PVQTH1',
  'SPI',
  'SPIT',
  'SPT',
  'TST',
  'ADAPTGN',
  'ADAPTGV',
  'ADAPTGRTN',
  'ADAPTGRTV',
  'CTB2C',
  'CTB2S',
  'CTB2V',
  'GRT1N',
  'GRT1V',
  'GRT2N',
  'GRT2V',
  'IPTN',
  'IRT1NCH',
  'IRT1VCH',
  'IRT2NCH',
  'IRT2VCH',
  'IRT2N',
  'IRT2V',
  'IRT3N',
  'IRT3V',
  'IRT4N',
  'IRT4V',
  'APE1',
  'BRIGHT',
  'CCAC',
  'CCI',
  'CEQ',
  'CM',
  'CMQ',
  'VMI',
  'VMIPL3',
  'ARTI',
  'ARTI2',
  'SRTI2',
  'GRT2MV',
  'GRT2MN',
  'GRT2MA',
  'ART',
  'WAI',
  'MRTI',
  'VACI',
  '360D',
  'ARTi',
  'SRTi',
  'MRTi',
  'VACi',
  'GRTiV',
  'GRTiN',
  'GRTiA',
  'SJM1',
  'lsjt2020liteg',
  'RTQ'
];

export const phoneLandscapeTests = ['VACi', 'MRTi', 'SRTi', 'VACI', 'MRTI', 'SRTI'];

export const LoginTypes = {
  login: 'login',
  login360: 'login360',
  auto360: 'auto360',
};

export const sameInstructionQuestionnaires = ['PaW', 'PVQ'];

export const personalityInstructionQuestionnaires = ['PaW', 'PVQ'];

export const occupationalInstructionQuestionnaires = ['OIP+', 'TST'];