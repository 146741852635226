import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import useKey from '@rooks/use-key';
import { PageStyled } from '../templates/Help';
import { PageHeader } from './PageHeader';
import { Main } from './layout';
import { ButtonNext, ButtonPrevious, Navigation } from './Navigation';
import { PageTemplate } from '../templates/PageTemplateComponent';
import { Footer } from './Footer';
import { setStarted } from '../../store/actions/questionnaireDetailsActions';
import { LangDirectionContext } from '../../context/LangDirectionContext';
import { useOrientation } from './utils/isLandscape';
import { RotateToLandscapeScreen } from './BlockMobileScreen';

const InstructionsPlayerComponent = ({ instructions, config, setStarted, isLandscapeOnly }) => {
  const direction = React.useContext(LangDirectionContext);
  const [activePageIndex, setActivePageIndex] = React.useState(0);

  const pageData = instructions[activePageIndex];
  const isCover = pageData.template.view === 'Cover';
  const { isExample } = pageData;
  const isResponsesAvailable = pageData.template.values && pageData.template.values.responses;
  const defaultPageAnswer = config.isWAI ? [2, 2, 2, 2] : 0;

  const [activePageAnswer, setActivePageAnswer] = React.useState(defaultPageAnswer);

  const isDefaultPageAnswer = config.isWAI
    ? activePageAnswer.filter((i) => i === 2).length !== 2
    : activePageAnswer === 0;

  const handlePreviousStep = React.useCallback(() => {
    if (activePageIndex > 0) {
      setActivePageIndex(activePageIndex - 1);
    }
  }, [activePageIndex, setActivePageIndex]);

  const handleNextStep = React.useCallback(() => {
    if (activePageIndex < instructions.length - 1) {
      setActivePageIndex(activePageIndex + 1);
    } else {
      setStarted();
    }
  }, [activePageIndex, setActivePageIndex, instructions, setStarted]);

  const handleResponse = React.useCallback(
    (res) => {
      setActivePageAnswer(res);
    },
    [setActivePageAnswer],
  );

  React.useEffect(() => {
    if (pageData.id) {
      setActivePageAnswer(defaultPageAnswer);
    }
  }, [pageData.id]);

  useKey(['ArrowRight'], () => {
    if (direction === 'rtl') {
      handlePreviousStep();
    } else {
      handleNextStep();
    }
  });

  useKey(['ArrowLeft'], () => {
    if (direction === 'rtl') {
      handleNextStep();
    } else {
      handlePreviousStep();
    }
  });

  useKey(['PageDown', 'Enter'], () => {
    handleNextStep();
  });

  useKey(['PageUp', 'Backspace'], () => {
    handlePreviousStep();
  });

  const isLandscape = useOrientation() === 'landscape';
  const orientationAllowed = isLandscapeOnly ? isLandscape : true;

  if (!orientationAllowed) {
    return <RotateToLandscapeScreen />;
  }

  return (
    <PageStyled
      isResponsesAvailable={isResponsesAvailable}
      withBackgroundMobile={!isExample}
      className={clsx({
        noPadding: isExample,
      })}
    >
      {!isCover && (
        <PageHeader
          pageData={pageData}
          activePageIndex={activePageIndex}
          itemsLength={instructions.length}
          className={clsx({
            instructions: !isExample,
          })}
        />
      )}
      <Main
        className={clsx({
          'noPadding fullHeight': !isExample,
        })}
      >
        {!isCover && (
          <Navigation>
            <ButtonPrevious onClick={handlePreviousStep} disabled={activePageIndex === 0} forDarkBg={!isExample} />
            <ButtonNext
              onClick={handleNextStep}
              className={clsx({
                active: isExample && !isDefaultPageAnswer,
              })}
              forDarkBg={!isExample}
            />
          </Navigation>
        )}
        <PageTemplate
          page={pageData}
          onResponse={handleResponse}
          selectedResponse={activePageAnswer}
          config={config}
          onNext={() => {
            if (isCover) {
              handleNextStep();
            }
          }}
          className={clsx({
            'noPadding fullHeight': !isExample,
          })}
        />
      </Main>
      {isExample && <Footer />}
    </PageStyled>
  );
};

const mapStateToProps = (state) => {
  const {
    testDetails: { instructions, config },
  } = state;

  return {
    instructions,
    config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStarted: (answer) => {
      dispatch(setStarted(answer));
    },
  };
};

export const InstructionsPlayer = connect(mapStateToProps, mapDispatchToProps)(InstructionsPlayerComponent);
