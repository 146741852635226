import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import clsx from 'clsx';
import useKey from '@rooks/use-key';
import useThrottle from '@rooks/use-throttle';
import { Button, ButtonVariants, ButtonTypes } from '../../shared/Button';
import { Checkbox } from '../../shared/formElements';
import { Divider, StyledFooter } from '../../templates/shared/components';
import { ScrollIcon } from '../../shared/ScrollIcon';
import {
  ButtonsHolderStyled,
  MobileContentStyled,
  ModalBoxStyled,
  TermsBodyStyled,
  TermsPage,
  TermsStyled,
} from './Terms.styled';

const SCROLL_STEP = 100;

export const Terms = ({ onNext, onCancel, removeCloseButton }) => {
  const { t } = useTranslation();
  const buttonRefPrev = React.createRef();
  const buttonRefNext = React.createRef();
  const checkboxRef = React.createRef();
  const [isChecked, setChecked] = React.useState(false);
  const [isCheckboxAvailable, setCheckboxAvailable] = React.useState(false);
  const [currentScrollPosition, setCurrentScrollPosition] = React.useState(0);

  const dataProtectionBody = t('dataProtectionBody');

  const scrollAreaRef = useBottomScrollListener(() => {
    setCheckboxAvailable(true);
  }, 50);

  React.useEffect(() => {
    scrollAreaRef.current.focus();
  }, [scrollAreaRef]);

  React.useEffect(() => {
    const { scrollHeight, clientHeight } = scrollAreaRef.current;

    if (scrollHeight <= clientHeight) {
      setCheckboxAvailable(true);
    }
  }, [scrollAreaRef, setCheckboxAvailable, dataProtectionBody]);

  React.useEffect(() => {
    if (isCheckboxAvailable) {
      checkboxRef.current.focus();
    }
  }, [isCheckboxAvailable, checkboxRef]);

  React.useEffect(() => {
    if (isChecked) {
      buttonRefNext.current.focus();
    }
  }, [isChecked, buttonRefNext]);

  const [setCurrentScrollPositionThrottled] = useThrottle((e) => {
    setCurrentScrollPosition(e.target.scrollTop);
  }, 40);

  React.useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    scrollArea.addEventListener('scroll', setCurrentScrollPositionThrottled);

    return () => {
      scrollArea.removeEventListener('scroll', setCurrentScrollPositionThrottled);
    };
  }, [scrollAreaRef, setCurrentScrollPositionThrottled]);

  React.useEffect(() => {
    scrollAreaRef.current.scroll({
      top: currentScrollPosition,
      behavior: 'smooth',
    });
  }, [scrollAreaRef, currentScrollPosition]);

  useKey(['ArrowDown', 'PageDown', 'Enter'], () => {
    const { scrollHeight, clientHeight } = scrollAreaRef.current;
    const scrollHeightDiff = scrollHeight - clientHeight;
    const nextScrollPosition = currentScrollPosition + SCROLL_STEP;

    setCurrentScrollPosition(nextScrollPosition < scrollHeightDiff ? nextScrollPosition : scrollHeightDiff);
  });

  useKey(['ArrowUp', 'PageUp'], () => {
    const nextScrollPosition = currentScrollPosition - SCROLL_STEP;
    setCurrentScrollPosition(nextScrollPosition > 0 ? nextScrollPosition : 0);
  });

  return (
    <TermsPage withBackground>
      <ModalBoxStyled>
        <TermsBodyStyled ref={scrollAreaRef}>
          <TermsStyled dangerouslySetInnerHTML={{ __html: t('dataProtectionBody') }} />
          <MobileContentStyled>
            <Checkbox
              className={clsx({ active: isCheckboxAvailable && !isChecked })}
              label={t('iAccept')}
              checked={isChecked}
              onChange={(evt) => {
                setChecked(evt.target.checked);
              }}
            />
          </MobileContentStyled>
        </TermsBodyStyled>
        <StyledFooter>
          <Checkbox
            ref={checkboxRef}
            className={clsx({ active: isCheckboxAvailable && !isChecked })}
            label={t('iAccept')}
            disabled={!isCheckboxAvailable}
            checked={isChecked}
            onChange={(evt) => {
              setChecked(evt.target.checked);
            }}
          />
          {!isCheckboxAvailable && <ScrollIcon />}
          <ButtonsHolderStyled>
            {!removeCloseButton && (
              <Button
                ref={buttonRefPrev}
                variant={ButtonVariants.SECONDARY}
                type={ButtonTypes.BUTTON}
                onClick={onCancel}
              >
                {t('close')}
              </Button>
            )}
            <Divider />
            <Button
              ref={buttonRefNext}
              variant={ButtonVariants.PRIMARY}
              type={ButtonTypes.BUTTON}
              onClick={onNext}
              disabled={!isChecked}
            >
              {t('continue')}
            </Button>
          </ButtonsHolderStyled>
        </StyledFooter>
      </ModalBoxStyled>
    </TermsPage>
  );
};
