import React, { useState } from 'react';
import styled from 'styled-components';
import { QuestionTextStyled } from './shared/components';
import { TranscriptModal } from './TranscriptModal'
import { SliderItem } from '../shared/SliderItem';
import { LocalStorageService } from '../../utils/LocalStorage.service';

const StyledTableContainer = styled.div`
  width: 100%;

  .container {
    padding: 0;
    width: 95%;
  }

  @media (max-width: ${({ theme }) => theme.viewports.sm}){
    .container {
      padding: 0;
      width: 70%;
      text-align: inherit !important;
    }  
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-height: 700px) {
    padding-top: 20px;
  }

  .half-height {
    @media (min-width: ${({ theme }) => theme.viewports.md}) {
      height: 115px;
    }
  }
`;

const StyledTable = styled.div`
  width: 75rem;
  margin: auto;
  color: #375563 !important;
  overflow: auto;

  h3,
  p {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 18px !important;
    }
  }
`;

const StyledFullHeight = styled.div`
  width: 60%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
  margin-top: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5;
  display: flex;
  position: relative;
  overflow: hidden;

  @media (max-height: 414px) {
    justify-content: flex-start;
  }
  @media (max-height: 414px) and (max-width: 818px) {
    padding-top: 0;
  }
`;

const QuestionWichTextStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 1.7rem;
  line-height: 1.1;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    display: none;
  }

  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    padding-bottom: 0;
    padding-top: 10px;
    font-size: 1.7rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
    theme.viewports.mdx}) and (orientation: portrait) {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 18px;
    margin-top: 25px;
  }
`;

const StyledButton = styled.button`
  margin-left: 14%;
  font-size: 13px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
    margin-left: 14%;
  }
  
  @media (min-width: ${({ theme }) => theme.viewports.lg}) and (max-width: 4000px) { 
    margin-left: 22%;
  }
`;

export const VideoItem = ({
  values: { mainitem, questions, table, transcription, videoTitle, hasScenarioQuestions, isBaseScenario },
  selectedResponse,
  selectedResponseId,
  onResponse,
  testId,
  page: { id },
  ...props
}) => {
  const [isTranscriptVisible, setTranscriptVisible] = useState(false);
  const marks = {
    0: 'Very ineffective',
    1: 'Ineffective',
    2: 'In between',
    3: 'Effective',
    4: 'Very effective'
  };
  const { accountId, invitationId, password } = props.details || '';
  const {
    answers
  } = LocalStorageService.getSession({
    accountId,
    invitationId,
    password,
  });
  
  return (
    <>
      <StyledFullHeight>
        <StyledTableContainer>
          <StyledTable
            smallerFontSize={testId === 'CRTBIN'}
            dangerouslySetInnerHTML={{
              __html: table,
            }}
          />
          <StyledButton type='button' onClick={() => setTranscriptVisible(true)}>Video Transcript</StyledButton>
          {mainitem  && <QuestionWichTextStyled
            dangerouslySetInnerHTML={{
              __html: mainitem,
            }}
          />}
        </StyledTableContainer>
      </StyledFullHeight>
      <SliderItem
        marks={marks}
        questions={questions}
        hasScenarioQuestions={hasScenarioQuestions}
        selectedResponseId={id}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        isBaseScenario={isBaseScenario}
        answers={answers}
        {...props}
      />
      {isTranscriptVisible &&
        <TranscriptModal transcriptText={transcription} title={videoTitle} onClose={() => { setTranscriptVisible(false); }} />
      }
    </>
  );
};
