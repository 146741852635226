import React from 'react';
import styled, { css } from 'styled-components';
import { rotate, dash } from '../../utils/animations';

const SpinnerBackdropStyled = styled.div`
  ${(props) =>
    props.isFullScreen &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, -50%);
  `};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    animation: ${rotate} 2s linear infinite;
  }

  circle {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke: #17a9e1;
    animation: ${dash} 1.5s ease-in-out infinite;
  }

  ${(props) =>
    props.small &&
    css`
      svg {
        width: 20px;
      }
      circle {
        stroke-width: 5px;
      }
    `}
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif, -apple-system;
`;

export const Spinner = ({ small, text, ...props }) => {
  return (
    <SpinnerBackdropStyled small={small} {...props}>
      <svg width="44" height="44" viewBox="0 0 44 44">
        <circle cx="22" cy="22" r="20" fill="none" strokeWidth="4" />
      </svg>
      {text && <Label>{text}</Label>}
    </SpinnerBackdropStyled>
  );
};
