import React from 'react';
import styled from 'styled-components';
import { FeedbackStyled, FullHeight, GlyphStyled, QuestionTextStyled } from './shared/components';
import { CardsList } from '../shared/CardsList';
import { GlyphSet } from './shared/GlyphSet';
import { LangDirectionContext } from '../../context/LangDirectionContext';

const FlexBoxRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    align-items: flex-end;
    flex-direction: row;
  }
`;

const RowHolderStyled = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
`;

const QuestionTextReStyled = styled(QuestionTextStyled)`
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 26px;
    line-height: 32px;
    text-align: initial;
    margin: 0 0 25px 20px;
    padding: 0;
  }
`;

const GlyphReStyled = styled(GlyphStyled)`
  padding: 0;
  margin: 0 10px;
`;

const FullHeightStyled = styled(FullHeight)`
  max-width: 540px;
  padding-top: 20px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    max-width: 540px;
  }

  @media (min-height: 700px) {
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) and (min-height: 700px) {
    max-width: 660px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    max-width: calc(74.21875vw + 90px);
  }

  @media (min-width: ${({ theme }) => theme.viewports.lg}) and (min-height: 800px) {
    max-width: 900px;
  }
`;

const FullWidth = styled.div`
  width: 100%;
`;

export const WhatFits = ({
  values: { responses, item1, item2, item3, item4, item5, item6, item7, item8, mainitem, feedback },
  config,
  selectedResponse,
  onResponse,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
}) => {
  const testClass = testId.toLowerCase();
  const direction = React.useContext(LangDirectionContext);

  return (
    <>
      <FullHeightStyled>
        <FullWidth>
          <FlexBoxRow>
            <div>
              <RowHolderStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item1} ${testClass}`} />
                </GlyphReStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item2} ${testClass}`} />
                </GlyphReStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item3} ${testClass}`} />
                </GlyphReStyled>
              </RowHolderStyled>
              <RowHolderStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item4} ${testClass}`} />
                </GlyphReStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item5} ${testClass}`} />
                </GlyphReStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item6} ${testClass}`} />
                </GlyphReStyled>
              </RowHolderStyled>
              <RowHolderStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item7} ${testClass}`} />
                </GlyphReStyled>
                <GlyphReStyled>
                  <GlyphSet className={`for-what-fits icon icon-${item8} ${testClass}`} />
                </GlyphReStyled>
                <GlyphReStyled className="question">
                  <GlyphSet className={`for-what-fits icon icon-question ${testClass} ${direction}`} noPath />
                </GlyphReStyled>
              </RowHolderStyled>
            </div>
            <QuestionTextReStyled
              dangerouslySetInnerHTML={{
                __html: mainitem,
              }}
            />
          </FlexBoxRow>
        </FullWidth>
        {!!feedback && (
          <FeedbackStyled
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </FullHeightStyled>

      <CardsList
        isAbstract={config.isAbstract}
        className="for-what-fits"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
