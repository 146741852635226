import React from 'react';
import styled, { css } from 'styled-components';
import Bowser from 'bowser';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LangDirectionContext } from '../../context/LangDirectionContext';

const NavigationStyled = styled.div`
  pointer-events: none;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  height: auto;
  background: ${({ theme }) => theme.colors.light};
  left: 0;
  z-index: 6;
  font-size: 1.6rem;


  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    background: none;
    max-width: ${({ theme }) => theme.widthWrapper};
    bottom: auto;
    top: 50%;
    left: 50%;
    height: ${(props) => (props.hideInvisibleBg ? 0 : 'calc(100% - 110px)')};
    transform: translate(-50%, -50%);
    z-index: 5;

    ${(props) =>
    props.isSJT &&
    css`
          top: 30% !important;
          height: ${(props) => (props.hideInvisibleBg ? 0 : '45vh')} !important;
    `}
  }
`;

const ButtonStyled = styled.div`
  height: 100%;
  pointer-events: all;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  padding: 0;
  width: 100%;
  touch-action: manipulation;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    width: auto;

    &:after,
    &:before {
      display: inline-block;
      width: 5vw;
      content: ' ';
    }
  }

  span {
    content: ' ';
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    position: relative;
    width: 100%;
    padding: 0 25px;
    @media (max-height: 414px) and (max-width: 800px) {
      height: 35px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      border: solid 3px #d4e8f3;
      transition: 0.05s ease-in-out border;
      border-radius: 4px;
      width: 54px;
      padding: 0;
    }

    b {
      display: none;
    }

    strong {
      display: inline-flex;
      color: ${({ theme }) => theme.colors.white};
      padding: 0 20px;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        display: none;
      }
    }

    .arrow {
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.white};
      border-width: 0 4px 4px 0;
      transition: 0.05s ease-in-out padding;
      padding: 6px;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        border-color: #1caae1;
      }
    }
  }

  &.prev {
    justify-content: center;

    span {
      justify-content: flex-start;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.widthWrapper}) {
        margin-left: -3px;
      }
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        justify-content: center;
      }
    }

    .arrow {
      transform: rotate(135deg);
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        margin-left: 5px;
        ${(props) =>
    props.direction === 'rtl' &&
    css`
            margin-left: 0;
          `}
      }
    }

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      justify-content: flex-start;

      &:before {
        content: ' ';
        position: absolute;
        width: 300px;
        height: 100%;
        left: -21%;
      }
    }
  }

  &.next {
    justify-content: center;

    span {
      justify-content: flex-end;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.widthWrapper}) {
        margin-right: -3px;
      }
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        justify-content: center;
      }
    }

    .arrow {
      transform: rotate(-45deg);
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        margin-right: 5px;
        ${(props) =>
    props.direction === 'rtl' &&
    css`
            margin-right: 0;
          `}
      }
    }

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      justify-content: flex-end;
      margin: 0;

      &:after {
        content: ' ';
        position: absolute;
        width: 300px;
        height: 100%;
        right: -21%;
      }
    }
  }

  &.active {
    background-color: #008ac0;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      background-color: transparent;

      span {
        background-color: #1caae1;
        border: solid 3px #1caae1;
      }

      .arrow {
        border-color: ${({ theme }) => theme.colors.white};
        padding: 6px;
      }

      b {
        position: absolute;
        display: block;
        top: -55px;
        width: 80px;
        text-align: right;
        right: 0;
        color: #1caae1;
        letter-spacing: -0.33px;
        font-size: 16px;
        font-weight: 500;
      }

      &:hover:not(:active) {
        span {
          border: solid 3px #06668a;
          background: #06668a !important;
        }

        b {
          color: #06668a;
        }
      }
    }
  }

  &:not([disabled]):hover {
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      cursor: pointer;

      .arrow {
        padding: 6px;
        transition: 0.05s ease-in-out padding;
      }

      span {
        border: solid 3px #1caae1;
        transition: 0.05s ease-in-out border;
      }
    }
  }

  &:disabled,
  &[disabled] {
    .arrow {
      border-color: #1caae1;
      opacity: 0.2;
    }

    strong {
      color: #1caae1;
      opacity: 0.2;
    }

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      span {
        cursor: not-allowed;
      }
    }
  }

  ${(props) =>
    props.forDarkBg &&
    css`
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        span .arrow {
          border-color: #d1eef9;
        }

        &:not([disabled]):hover span {
          background-color: white;
          border: white;

          .arrow {
            border-color: #1caae1;
          }
        }

        &:active {
          span {
            background-color: #008ac0 !important;
            border: #008ac0 !important;

            .arrow {
              border-color: white !important;
            }
          }
        }
      }
    `}


  ${(props) =>
    props.direction === 'rtl' &&
    css`
      span .arrow {
        border-width: 4px 0 0 4px;
      }

      &.next {
        @media (min-width: ${({ theme }) => theme.viewports.sm}) {
          &:after {
            right: auto;
            left: -21%;
          }

          span {
            padding-left: 4px;
            padding-right: 0;
          }
        }

        @media (max-width: ${({ theme }) => theme.widthWrapper}) {
          margin-left: -3px;
          margin-right: auto;
        }
      }

      &.prev {
        @media (min-width: ${({ theme }) => theme.viewports.sm}) {
          &:before {
            right: -21%;
            left: auto;
          }

          span {
            padding-left: 0;
            padding-right: 4px;
          }
        }

        @media (max-width: ${({ theme }) => theme.widthWrapper}) {
          margin-right: -3px;
          margin-left: auto;
        }
      }

      &.active b {
        text-align: left;
        left: 0;
        right: auto;
      }
    `}
`;
const PressEnter = styled.b`
  @media (max-width: ${({ theme }) => theme.viewports.sm}) {
    display: none !important;
  }
`;

export const ButtonNext = ({ className, disabled, forDarkBg, onClick, ...props }) => {
  const direction = React.useContext(LangDirectionContext);
  const { t } = useTranslation();

  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const isMobile = platform.type === 'mobile';

  return (
    <ButtonStyled
      direction={direction}
      className={clsx('next', { [className]: className })}
      disabled={disabled}
      forDarkBg={forDarkBg}
      onClick={disabled ? null : onClick}
      {...props}
    >
      <span>
        {!isMobile && <PressEnter>{t('press')}</PressEnter>}
        <strong>{t('next')}</strong>
        <div className="arrow" />
      </span>
    </ButtonStyled>
  );
};

export const ButtonPrevious = ({ className, disabled, forDarkBg, onClick, ...props }) => {
  const direction = React.useContext(LangDirectionContext);
  const { t } = useTranslation();

  return (
    <ButtonStyled
      direction={direction}
      className={clsx('prev', { [className]: className })}
      disabled={disabled}
      forDarkBg={forDarkBg}
      onClick={disabled ? null : onClick}
      {...props}
    >
      <span>
        <div className="arrow" />
        <strong>{t('back')}</strong>
      </span>
    </ButtonStyled>
  );
};

export const Navigation = ({ children, ...props }) => {
  return <NavigationStyled {...props}>{children}</NavigationStyled>;
};