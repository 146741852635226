import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import Slider from 'rc-slider';
import { GeneralSliderStyled, QuestionStyledContainer, QuestionStyledItem, QuestionStyled, StyledLabel } from './SliderItem.styled';
 
const StyledSlider = styled(Slider)`
  ${(props) =>
    props.selected &&
    css`
      .rc-slider-handle  {
        background-color: rgb(0, 66, 192) !important;
      }
      .rc-slider-track {
        background-color: rgb(0, 66, 192) !important;
      }
    `}
`;

export const SliderItem = ({
  marks,
  questions,
  hasScenarioQuestions,
  isBaseScenario,
  selectedResponse,
  selectedResponseId,
  onResponse,
  answers,
  ...props
}) => {
  const { numberOfResponses } = props.config;

  const ANSWERED_QUESTION_STATE = 1;
  const INITIAL_ANSWER_IDX = 0;
  const LAST_ANSWER_IDX = 4;
  const MARK_STEP_VALUE = 1;
  const DEFAULT_ANSWER = 2;
  const themeContext = useContext(ThemeContext);
  const { colors } = themeContext;
  const [isBaseScenarioState, setIsBaseScenarioState] = useState(false);
  
  const initialState = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= numberOfResponses; i++) {
    initialState.push({ state: 0, response: 2, label: marks[DEFAULT_ANSWER] }); 
  }
  
  const [answeredQuestions, setAnsweredQuestions] = useState(initialState);

  const handleResponse = React.useCallback(
    (id) => {
      onResponse(id);
    },
    [onResponse],
  );

  const resetAnsweredQuestions = () => {
    const resetState = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= numberOfResponses; i++) {
      resetState.push({ state: 0, response: 2, label: marks[DEFAULT_ANSWER] });
    }
    setAnsweredQuestions(resetState);
  };

  const handleQuestionResponse = (QIdx, event) => {
    setAnsweredQuestions(() => {
      answeredQuestions[QIdx - 1].state = 1;
      answeredQuestions[QIdx - 1].response = event;
      answeredQuestions[QIdx - 1].label = marks[event];
      return [...answeredQuestions];
    });
  };

  useEffect(() => {
    const initialValue = 0;

    const allQuestionsResponded = answeredQuestions.reduce((total, current) => {
      return total + current.state;
    }, initialValue);


    if (allQuestionsResponded === numberOfResponses && hasScenarioQuestions !== false) {
      const allResponses = answeredQuestions.reduce((total, current) => {
        // +1 to match raw response scale from 1-5 instead of 0-4 as defined from marks native object
        return total.concat(parseInt(current.response, 10)+1);
      }, []);

      handleResponse(allResponses);
    }

    return () => { }
  }, [answeredQuestions])

  /** If it is a BASE SCENARIO the engine recieves a mock response of -1 */
  useEffect(() => {
    if (!hasScenarioQuestions) {
      setTimeout(() => {
        handleResponse([-1]);
        setIsBaseScenarioState(() => !isBaseScenarioState);
      }, 100);
    }
    return () => { }
  }, [hasScenarioQuestions, isBaseScenario]);

  useEffect(() => {
    let qId = [];
    let keyCondition1 = false;
    let keyCondition2 = false;
    if (answers && selectedResponseId) {
      qId = answers.filter((response) => response.Id === selectedResponseId);
      keyCondition1 = qId[0] ? ('SjtItems') in qId[0] : false;
      keyCondition2 = qId[0] ? ('sjtItems') in qId[0] : false;
    }

    if (keyCondition1) {
      if (qId[0] && qId[0].SjtItems !== null && qId[0].SjtItems.length > 0 && qId[0].SjtItems !== 0) {
        qId[0].SjtItems.forEach((responseItem, index) => {
        // -1 to match raw response scale from 1-5 instead of 0-4 as defined from marks native object
          setAnsweredQuestions(() => {
            answeredQuestions[index].state = 1;
            answeredQuestions[index].response = parseInt(responseItem, 10) - 1;
            return [...answeredQuestions];
          });
        });
      }
    }
    else if (keyCondition2) {
      if (qId[0] && qId[0].sjtItems !== null && qId[0].sjtItems.length > 0 && qId[0].sjtItems !== 0) {
        qId[0].sjtItems.forEach((responseItem, index) => {
        // -1 to match raw response scale from 1-5 instead of 0-4 as defined from marks native object
          setAnsweredQuestions(() => {
            answeredQuestions[index].state = 1;
            answeredQuestions[index].response = parseInt(responseItem, 10) - 1;
            answeredQuestions[index].label = marks[responseItem];
            return [...answeredQuestions];
          });
        });
      }
    }
      else {
        resetAnsweredQuestions();
      }

    return () => { }
  }, [questions])
  
  return (
    <>
      {hasScenarioQuestions && questions.map(({ QId, QIdx, value }) => {
        return (
          <QuestionStyledContainer key={QId}>
            <QuestionStyledItem>
              <QuestionStyled>
                {value.default}
              </QuestionStyled>
            </QuestionStyledItem>
            <GeneralSliderStyled>
              <StyledSlider
                marks={marks}
                min={INITIAL_ANSWER_IDX}
                max={LAST_ANSWER_IDX}
                step={MARK_STEP_VALUE}
                value={answeredQuestions[QIdx - 1].response}
                defaultValue={DEFAULT_ANSWER}
                dots
                railStyle={{ backgroundColor: '#CDCDCD' }}
                dotStyle={{ width: '0.8rem', height: '0.8rem', backgroundColor: '#CDCDCD', borderColor: '#CDCDCD' }}
                handleStyle={{
                  width: '1.6rem',
                  height: '1.6rem',
                  marginTop: '-0.7rem',
                  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 6px rgba(55, 85, 99, 0.5)',
                  border: '4px solid #fff',
                  backgroundColor: colors.mediumBlue,
                }}
                selected={answeredQuestions[QIdx - 1].state === ANSWERED_QUESTION_STATE}
                onBeforeChange={(e) => {
                  if (e.detail === 0) return;
                  handleQuestionResponse(QIdx, e);
                }}
                onChange={(e) => {
                  if (e.detail === 0) return;
                  handleQuestionResponse(QIdx, e);
                }}
                />
              <StyledLabel>{answeredQuestions[QIdx - 1].label}</StyledLabel>
            </GeneralSliderStyled>
          </QuestionStyledContainer>
        );
      })}
    </>
  );
};
