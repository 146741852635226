import React from 'react';
import styled from 'styled-components';
import { object } from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import DraggableCard from './DraggableCard';

import { ReactComponent as EmptyStackIcon } from '../../../../assets/images/empty-stack.svg';

const Stack = ({ cardOnTop, stack, evaluatedCardsQuantity, allCardsQuantity }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Droppable droppableId={stack.id}>
        {(provided, snapshot) => (
          <StackContainer {...provided.droppableProps} ref={provided.innerRef}>
            {cardOnTop ? (
              <>
                {cardOnTop ? <CardOnTop card={cardOnTop} index={0} unremovable /> : <MockCard />}
                <FirstBackgroundDiv />
                <SecondBackgroundDiv />
              </>
            ) : (
              <EmptyPlaceholder>
                <EmptyStackIcon />
                <span>{t('EMPTY')}</span>
              </EmptyPlaceholder>
            )}

            {snapshot.draggingFromThisWith && provided.placeholder}
          </StackContainer>
        )}
      </Droppable>
      <QuantityLabel>
        {evaluatedCardsQuantity}/{allCardsQuantity} {t('evaluated')}
      </QuantityLabel>
    </Wrapper>
  );
};

Stack.propTypes = {
  stack: object.isRequired,
  cardOnTop: object,
};

Stack.defaultProps = {
  cardOnTop: null,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.viewports.lgx}) {
    margin-bottom: 4rem;
  }
`;

const StackContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 1.6rem;
`;

const CardOnTop = styled(DraggableCard)`
  position: relative;
  z-index: 3;
  max-width: 28rem;
`;

const MockCard = styled.div`
  opacity: 0;
  min-width: 28rem;
  min-height: 6rem;
  padding: 1.6rem;
  background-color: ${(props) => (props.isDragging ? '#4dadd3' : '#008ac0')};
  border-radius: 4px;
  margin-bottom: 0.8rem;
`;

const FirstBackgroundDiv = styled.div`
  width: 27rem;
  min-height: 6rem;
  position: absolute;
  top: 0.8rem;
  background: #b3dcec;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 2;
`;

const SecondBackgroundDiv = styled.div`
  width: 26rem;
  min-height: 6rem;
  position: absolute;
  top: 1.6rem;
  background: #e0f1f7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1;
`;

const QuantityLabel = styled.p`
  width: 30rem;
  padding-left: 1.6rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
`;

const EmptyPlaceholder = styled.div`
  width: 28rem;
  height: 6.8rem;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0, 4);
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
`;

export default Stack;
