import React from 'react';
import get from 'lodash/get';
import { object, string } from 'prop-types';
import { CustomInput } from './Input';

export const FormikInput = ({ name, errors, touched, ...rest }) => {
  const error = get(errors, name);
  const isTouched = get(touched, name);
  const isError = isTouched && error ? error : '';
  return <CustomInput name={name} error={isError} {...rest} />;
};

FormikInput.propTypes = {
  name: string.isRequired,
  errors: object,
  touched: object,
};

FormikInput.defaultProps = {
  errors: {},
  touched: {},
};
