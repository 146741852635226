import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { intersection } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Bowser from 'bowser';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../utils/hooks/useQuery';
import {
  fetchQuestionnaireDetails,
  fetchManagedGroupDetails,
  fetchOpenGroupDetails,
} from '../../store/actions/questionnaireDetailsActions';
import { Spinner } from '../shared/Spinner';
import { PreTest } from '../pages/PreTest';
import { Test } from '../pages/Test';
import { Error } from '../shared/Error';
import { BlockMobileScreen } from '../shared/BlockMobileScreen';
import { textOnlyTests, phoneLandscapeTests } from './constants';
import { LocalStorageService } from '../../utils/LocalStorage.service';

import ManagedGroupPlayer from '../shared/ManagedGroup/ManagedGroupPlayer';
import OpenGroupPlayer from '../shared/OpenGroup/OpenGroupPlayer';

const QuestionnairePlayer = ({ isStarted, isBattery, isFirstInBattery, is360, areTermsSkipped, isLandscapeOnly }) => {
  const { t } = useTranslation();

  const [isPreTestCompleted, setPreTestCompleted] = React.useState(isStarted || (isBattery && !isFirstInBattery));

  const onComplete = React.useCallback(() => {
    setPreTestCompleted(true);
  }, [setPreTestCompleted]);

  return React.useMemo(() => {
    switch (true) {
      case !isPreTestCompleted:
        return <PreTest onComplete={onComplete} is360={is360} areTermsSkipped={areTermsSkipped} />;
      case !!isPreTestCompleted:
        return <Test isLandscapeOnly={isLandscapeOnly} is360={is360} />;
      default:
        return <Error>{t('errorQuestionnairePlayer')}</Error>;
    }
  }, [is360, isPreTestCompleted, onComplete, t]);
};

const QuestionnaireComponent = ({
  match,
  history,
  fetchQuestionnaireDetails,
  fetchManagedGroupDetails,
  fetchOpenGroupDetails,
  isFetching,
  isError,
  error,
  isStarted,
  activeQuestion,
  isFirstInBattery,
  isBattery,
  testId,
  dependencies,
  is360,
  isManagedGroup,
  isOpenGroup,
  skipPrivacyPolicy,
}) => {
  const { t } = useTranslation();
  const { accountWithInvitation, password, accessCode } = match.params;
  const query = useQuery();

  useEffect(() => {
    const token = query.get('token');
    if (token) {
      LocalStorageService.saveToken(token);
      history.replace(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (!isManagedGroup && !isOpenGroup) fetchQuestionnaireDetails(accountWithInvitation, password, is360);
  }, [accountWithInvitation, password, fetchQuestionnaireDetails, is360, isManagedGroup, isOpenGroup]);

  useEffect(() => {
    if (isManagedGroup) fetchManagedGroupDetails(accessCode);
    if (isOpenGroup) fetchOpenGroupDetails(accessCode);
  }, []);

  const PlayerComponent = useMemo(() => {
    if (isManagedGroup) return ManagedGroupPlayer;
    if (isOpenGroup) return OpenGroupPlayer;
    return QuestionnairePlayer;
  }, [isManagedGroup, isOpenGroup]);

  if (isFetching) return <Spinner text={t('loadingSessionDetails')} />;
  if (isError)
    return (
      <Error
        title={t('invalidInvitation')}
        onNext={() => {
          history.push(`/`);
        }}
      >
        {error.message === '' ? t('SomethingIsNotRightPleasCheckYourInvitation') : error.message}
      </Error>
    );

  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const isMobile = platform.type === 'mobile';
  const isBatteryTextOnly = intersection(textOnlyTests, dependencies).length === dependencies.length;
  const isTextOnly = isBattery ? isBatteryTextOnly : textOnlyTests.includes(testId);
  const isLandscapeOnly = phoneLandscapeTests.includes(testId);

  const showMobileBlockingScreen = isMobile && !isTextOnly && !isLandscapeOnly;

  return showMobileBlockingScreen ? (
    <BlockMobileScreen />
  ) : (
    <PlayerComponent
      isLandscapeOnly={isLandscapeOnly}
      isStarted={isStarted}
      activeQuestion={activeQuestion}
      isFirstInBattery={isFirstInBattery}
      isBattery={isBattery}
      is360={is360}
      isManagedGroup={isManagedGroup}
      areTermsSkipped={skipPrivacyPolicy}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: {
      isFetching,
      isError,
      error,
      isStarted,
      session: { testId, batteryDependencies = '', batteryId = -1 } = {},
      details: { skipPrivacyPolicy } = {},
      openGroup,
      managedGroup,
    },
  } = state;
  const resolvedTestId = openGroup?.testID || managedGroup.sessionData?.testID || testId;
  const resolvedBatteryId = openGroup?.batteryID || managedGroup.sessionData?.batteryID || batteryId;
  const resolvedBatteryDependencies =
    openGroup?.batteryDependencies || managedGroup.sessionData?.batteryDependencies || batteryDependencies;

  const isFirstInBattery =
    typeof resolvedBatteryDependencies === 'string' && resolvedBatteryDependencies.split(';')[0] === resolvedTestId;
  return {
    isFetching,
    isError,
    error,
    isStarted,
    isFirstInBattery,
    isBattery: resolvedBatteryId && resolvedBatteryId !== -1,
    testId: resolvedTestId,
    dependencies: resolvedBatteryDependencies.split(';'),
    skipPrivacyPolicy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuestionnaireDetails: (accountWithInvitation, password, is360) => {
      return dispatch(fetchQuestionnaireDetails(accountWithInvitation, password, is360));
    },
    fetchManagedGroupDetails: (accessCode) => {
      return dispatch(fetchManagedGroupDetails(accessCode));
    },
    fetchOpenGroupDetails: (accessCode) => {
      return dispatch(fetchOpenGroupDetails(accessCode));
    },
  };
};

export const Questionnaire = compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(QuestionnaireComponent);
