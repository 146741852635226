export const parseAdaptResponseItem = (item) => {
  const itemParsed = item.split(",").reduce((acc, next) => {
    const [key, value] = next.split(":");

    acc[key] = value;
    return acc;
  }, {});

  return itemParsed.qid && itemParsed.res
    ? `${itemParsed.qid}(a=${itemParsed.a};b=${itemParsed.b};c=${itemParsed.c}):${itemParsed.res}`
    : "";
};

export const concatAdaptResponses = (qEngine, answers) => {
  const { currentQuestionnaire } = qEngine;
  const { EAP } = currentQuestionnaire;
  const answersString = answers
    .filter((item, i) => i < 15)
    .reduce((acc, next, i) => {
      return i === answers.filter((item, i) => i < 15).length - 1
        ? acc + parseAdaptResponseItem(next.AdaptItems)
        : `${acc + parseAdaptResponseItem(next.AdaptItems)},`;
    }, "");
  return [
    {
      AdaptItems: `EAP:${EAP};PSD:0.00;SEInfo:0.00;TestInfo:0.00/${answersString}`,
    },
  ];
};
