import React from 'react';
import styled, { css } from 'styled-components';
import { IconStyled } from '../templates/shared/components';

const CardStyled = styled.button`
  display: ${(props) => (props.separateSvgIcon ? 'flex' : '-webkit-box')};
  background: transparent;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex-direction: column;
  height: 100%;
  box-shadow: none;
  //border-radius: 4px;
  transition: box-shadow 0.2s;
  padding: 10px;
  line-height: 1.3;
  font-size: 20px;
  letter-spacing: -0.32px;
  flex-grow: 1;
  border: solid 1px ${(props) => props.theme.colors.darkFontColor};
  color: ${(props) => props.theme.colors.darkFontColor};
  text-align: center;
  position: relative;
  width: 100%;
  outline: none;
  touch-action: manipulation;

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 16px;
  }
  .svg-image & {   
    ${({ theme }) => theme.xs`
      width: 80px;
      height: 80px;
      flex-grow: 0;
    `};
  }
 
  
  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    .howFitsTogether & {
      .icon-answer {
        //font-size: 48px;
      }
    }
  }
  
  @media (max-width: ${({ theme }) => theme.viewports.xs}) and (orientation: portrait) {
    .whatComesNext & {
      width: auto;
      height: auto;
    }
    
    .howFitsTogether & {
      width: auto;
      height: auto;
      .icon-answer {
        font-size: 19.3vw;
      }
    }
    
    .xIsToY & {
      width: auto;
      height: auto;
      .icon-answer {
        font-size: 19.3vw;
      }
    }
    
    .textOnly & {
      width: auto;
      height: auto;
      .icon-answer {
        font-size: 19.3vw;
      }
    }
  }
    

  &.abstract {
    padding: 0;
    border: none;
    background: #f9f9f9;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    //padding: 9px 8px;

    padding: 8px 8px; //TODO check every test for new paddings
    font-size: 18px;

    &:hover:not(:active) {
      cursor: pointer;
      box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
      border-color: ${({ theme }) => theme.colors.black};
    }
  }

  ${(props) =>
    props.isImage &&
    css`
      width: auto;
    `}

  ${(props) =>
    props.isImageBorder &&
    css`
      border: solid 1px ${(props) => props.theme.colors.darkFontColor}!important;
      border-radius: 4px;
    `}
  
  ${(props) =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.colors.darkFontColor}!important;
      border-color: #1baae1 !important;
      box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.light};
      transition: none;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.light};

        &:hover {
          box-shadow: 0 0 0 4px #1baae1 !important;
        }
      }

      svg {
        fill: ${({ theme }) => theme.colors.darkFontColor}!important;
      }
    `}

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    svg {
      width: auto;
      height: 100%;
      fill: ${(props) => props.theme.colors.darkFontColor};

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  
  ${(props) =>
    props.isMultiple === false &&
    css`
        svg {
          width: 100px;
          height: 100px;
        }   
    `}

  ${(props) =>
    props.isMultiple === true &&
    css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 3px;
        grid-row-gap: 3px;

        justify-items: center;
        align-items: center;

        color: white;

        --cc1: #565656;
        --cc2: #4285f4;
        --cc3: #ecb501;
        --cc4: #00a875;

        svg {
          &.c1 {
        
                polyline, polygon, path, circle, rect {
                    fill: var(--cc1);
                }
            }
        
            &.c2 {
        
                polyline, polygon, path, circle, rect {
                    fill: var(--cc2);
                }
            }
        
            &.c3 {
        
                polyline, polygon, path, circle, rect {
                    fill: var(--cc3);
                }
            }
        
            &.c4 {
        
                polyline, polygon, path, circle, rect {
                    fill: var(--cc4);
                }
            }
        }

        border: 1px solid black;
        // background-color: #886464;
        padding-bottom: 0;    
    `}
`;

// TODO: merge isAbstract/isImage
export const Card = ({ content, onClick, selected, isAbstract, isImage, testId, isImageBorder, type, iconsModule }) => {
  const IconComponent = iconsModule && iconsModule[content];
  const clearedContent = typeof content === 'string' && content.startsWith('@') ? content.slice(1) : content;

  return isAbstract ? (
    <CardStyled
      onClick={onClick}
      selected={selected}
      isImage={isImage}
      isImageBorder={isImageBorder}
      className={`abstract ${content}`}
      separateSvgIcon={type === 'svg-single'}
    >
      {type === 'svg-single' ? (
        <IconComponent />
      ) : (
        <IconStyled
          isImage
          isImageBorder={isImageBorder}
          className={`icon icon-${content} ${testId} icon-answer`}
          isAnswer
        >
          {/* TODO: pathN isnot scalable */}
          <i className="icon-path path1" />
          <i className="icon-path path2" />
          <i className="icon-path path3" />
          <i className="icon-path path4" />
        </IconStyled>
      )}
    </CardStyled>
  ) : (
    <CardStyled
      role="button"
      tabIndex="0"
      onClick={onClick}
      selected={selected}
      dangerouslySetInnerHTML={{
        __html: typeof clearedContent === 'object' ? clearedContent.join("") : clearedContent,
      }}
      isMultiple={typeof clearedContent === 'object' && clearedContent.length > 2}
    />
  );
};