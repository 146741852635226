import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Body, BodyItem, Header } from "../components";

const BodyItemStyled = styled(BodyItem)`
  width: 480px;
  margin: 0 auto;
  font-weight: 600;
  text-align: center;
`;

export function SubmitResultPage() {
  const { t } = useTranslation();
  return (
    <div>
      <Header title={`${t("Step 2")}`} />

      <Body>
        <BodyItemStyled>
          {t(
            "your appraisal administrator successfully informed that your raters have been selected"
          )}
        </BodyItemStyled>
      </Body>
    </div>
  );
}
