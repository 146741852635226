import React from 'react';
import { useTranslation } from 'react-i18next';
import { Test } from '../../pages/Test';
import { Error } from '../Error';
import { ManagedGroupPreTest } from './ManagedGroupPreTest';

const ManagedGroupPlayer = ({ isStarted, isBattery, isFirstInBattery }) => {
  const { t } = useTranslation();

  // TODO somehow track which step are we on or always start from the beginning?   REMOVE LATER
  const [isPreTestCompleted, setPreTestCompleted] = React.useState(isStarted || (isBattery && !isFirstInBattery));

  const onComplete = React.useCallback(() => {
    setPreTestCompleted(true);
  }, [setPreTestCompleted]);

  return React.useMemo(() => {
    switch (true) {
      case !isPreTestCompleted:
        return <ManagedGroupPreTest onComplete={onComplete} />;
      case !!isPreTestCompleted:
        return <Test />;
      default:
        return <Error>{t('errorQuestionnairePlayer')}</Error>;
    }
  }, [isPreTestCompleted, onComplete, t]);
};

export default ManagedGroupPlayer;
