import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FeedbackStyled, FullHeight, QuestionTextStyled as _QuestionTextStyled } from './shared/components';
import { InstructionModal } from '../shared/InstructionModal';
import { CardsList } from '../shared/CardsList';
import { isMobile } from '../shared/utils/isMobile';

const QuestionTextLayout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 4;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 30vh;
      max-height: 350px;
      max-width: 100%;
      width: 85rem;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
        max-width: 100%;
      }
      @media (min-width: ${({ theme }) => theme.viewports.xl}) {
        height: 43vh;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    display: flex;
    margin-bottom: 40px;
  }
`;

const QuestionTextStyled = styled(_QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.67;
  z-index: 5;
  position: relative;

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) and (max-height: 414px) {
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    font-size: 24px;
    line-height: 1.67;
    margin-right: 20%;
    text-align: left;
  }
`;

const QuestionImageLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;

  flex: 5;

  svg {
    width: 100%;
  }
`;

const ControlsWrapper = styled.div`
  width: 80%;

  .control-list-mobile-small {
    flex-wrap: nowrap;
  }
`;

export const WhichOfImageSRTiHorizontal = ({
  values: { mainitem, responses, image, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  iconsModule,
}) => {
  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);

  const ImageComponent = iconsModule[image];

  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <FullHeight>
        <QuestionWrapper>
          <QuestionTextLayout>
            <QuestionTextStyled
              dangerouslySetInnerHTML={{
                __html: mainitem,
              }}
            />
            {!!feedback && !isMobile && (
              <FeedbackStyled
                dangerouslySetInnerHTML={{
                  __html: feedback,
                }}
              />
            )}
          </QuestionTextLayout>

          <QuestionImageLayout>
            <ImageComponent />
          </QuestionImageLayout>
        </QuestionWrapper>
      </FullHeight>
      <ControlsWrapper>
        <CardsList
          isAbstract={config.isAbstract}
          // isImage
          isImageBorder
          className="which-of-three which-of-image for-text mobile-small"
          listClassName="control-list-mobile-small"
          items={responses}
          selectedResponse={selectedResponse}
          onResponse={onResponse}
          config={config}
          isBlockModalVisible={isBlockModalVisible}
          isHelpVisible={isHelpVisible}
          isSidePanelOpen={isSidePanelOpen}
          iconsModule={iconsModule}
        />
      </ControlsWrapper>
    </>
  );
};
