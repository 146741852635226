import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../../Spinner';
import { FormikInput } from '../../imported-from-admin/FormikInput';
import { CustomButton } from '../../imported-from-admin/Button';
import { CustomSelect } from '../../FormComponents';
import DateSelect from '../../imported-from-admin/DateSelect';
import { Container, ContentHolder, Title } from '../../imported-from-admin/styles';
import { ReactComponent as ArrowBack } from '../../../../assets/images/arrow-back.svg';

import {
  createNewRespondent,
  updateRespondent,
  inviteRespondentToSession,
  createNewOpenGroupRespondent,
} from '../../../../store/actions/questionnaireDetailsActions';
import { errorMessages } from '../../constants/errorMessages';

import isChineseEnv from '../../../../utils/isChineseEnv';

export const RespondentForm = ({ onSuccess, isOpenGroup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    managedGroup: { respondent },
  } = useSelector((state) => state.questionnaireDetails);

  const [isLoading, setLoadingState] = useState(false);

  const ethnicityOptions = t('ethnicityvalues', { returnObjects: true }).slice(1);
  const educationOptions = t('educationvalues', { returnObjects: true }).slice(1);
  const jobareaOptions = t('jobareavalues', { returnObjects: true }).slice(1);
  const industryOptions = t('industryvalues', { returnObjects: true }).slice(1);
  const languageOptions = t('firstlanguagevalues', { returnObjects: true }).slice(1);
  const sectorOptions = t('sectorvalues', { returnObjects: true }).slice(1);
  
  const genderOptions = t('gendervalues', { returnObjects: true });

  const defaultSexValue = genderOptions.find((item) => item.value === 'N/A' || item.value === 'U').value;

  const { managedGroup, openGroup } = useSelector((state) => state.questionnaireDetails);

  const dataSource = isOpenGroup ? openGroup : managedGroup.sessionData;
  const { accountID, userID, accessCode, isEmailRequired = true } = dataSource;

  const createValidationSchema = (errorMessages) =>
  Yup.object().shape({
    firstName: Yup.string().trim().required(errorMessages.inputField),
    familyName: isChineseEnv ? 
      Yup.string().trim():
      Yup.string().required(errorMessages.inputField),
    email: Yup.string().trim().email(errorMessages.emailField).when('isEmailRequired', {
      is: true,
      then: Yup.string().required(errorMessages.inputField)
    }),
    reference: Yup.string().max(150, errorMessages.tooLong(150)),
  });

  const validationSchema = createValidationSchema(errorMessages(t));
  const { values, errors, setFieldError, setFieldValue, handleSubmit, isSubmitting, handleChange, touched } = useFormik(
    {
      initialValues: {
        firstName: isOpenGroup ? '' :  isChineseEnv ? respondent ? `${respondent?.familyName} ${respondent?.firstName}` : '' : respondent?.firstName  || '',
        familyName: isOpenGroup ? '' : isChineseEnv ? "" : respondent?.familyName || '',
        email: isOpenGroup ? '' : respondent?.email || '',
        sex: isOpenGroup ? defaultSexValue : respondent?.sex || defaultSexValue,
        dob: isOpenGroup ? null : respondent?.dob ? new Date(respondent.dob) : null,
        reference: isOpenGroup ? '' : respondent?.reference || '',
        // ethnicity: isOpenGroup ? null : respondent?.ethnicity || null,
        education: isOpenGroup ? null : respondent?.education || null,
        // jobArea: isOpenGroup ? null : respondent?.jobArea || null,
        // sector: isOpenGroup ? null : respondent?.sector || null,
        // industry: isOpenGroup ? null : respondent?.industry || null,
        firstLanguage: isOpenGroup ? null : respondent?.firstLanguage || null,
        isEmailRequired: isOpenGroup ? isEmailRequired : false,
      },
      validationSchema,
      validateOnChange: false,
      onSubmit: async (values) => {
        const data = { ...values, accountId: accountID, userId: userID, accessCode };

        if (isOpenGroup) {
          dispatch(
            createNewOpenGroupRespondent(data, (e, data) => {
              if (!e) onSuccess({ ...data, accountID });
              setLoadingState(false);
            }),
          );
        } else {
          const args = respondent ? [respondent.respondentId, data] : [data];
          const method = respondent ? updateRespondent : createNewRespondent;

          setLoadingState(true);
          dispatch(
            method(...args, (e, data) => {
              if (!e) {
                const inviteData = {
                  accountId: accountID,
                  userId: userID,
                  accessCode,
                  respondentId: respondent?.respondentId || data.respondentId,
                };
                dispatch(
                  inviteRespondentToSession(inviteData, (e) => {
                    if (!e) onSuccess();
                    setLoadingState(false);
                  }),
                );
              } else {
                setLoadingState(false);
              }
            }),
          );
        }
      },
    },
  );

  const onInputChange = (name) => (props) => {
    setFieldError(name, '');
    handleChange(props);
  };

  return (
    <Container withBackground>
      {isLoading && <Spinner isFullScreen />}
      <StyledContentHolder>
        <StyledTitle>{t('confirmRespForm')}</StyledTitle>
        <ColumnsWrapper>
          <Row>
            <StyledInput
              inputName={t('firstname')}
              name="firstName"
              errors={errors}
              touched={touched}
              id="firstName"
              value={values.firstName}
              onChange={onInputChange('firstName')}
            />
            {!isChineseEnv && <StyledInput
              inputName={t('familyname')}
              name="familyName"
              errors={errors}
              touched={touched}
              id="familyName"
              value={values.familyName}
              onChange={onInputChange('familyName')}
            />}
          </Row>

          <Row>
            <StyledInput
              inputName={t('Email address')}
              name="email"
              errors={errors}
              touched={touched}
              id="email"
              value={values.email}
              onChange={onInputChange('email')}
            />
            <DatePickerWrapper>
              <DatePicker
                selectedDate={values.dob}
                onDateChange={(date) => setFieldValue('dob', date)}
                label={t('dateofbirth')}
                isBottom
                calendarProps={{
                  maxDate: new Date(),
                }}
              />
            </DatePickerWrapper>
          </Row>

          <Row>
            <StyledSelect
              placeholder={t('gender')}
              options={genderOptions}
              selectProps={{
                onChange: (option) => setFieldValue('sex', option.value),
                value: genderOptions.find((item) => item.value === values.sex) || null,
                menuPosition: 'fixed',
              }}
            />
          </Row>

          {false &&
          // {!isChineseEnv &&
            <Row>
            <StyledInput
              inputName={t('reference')}
              name="reference"
              errors={errors}
              touched={touched}
              id="reference"
              value={values.reference}
              onChange={onInputChange('reference')}
            />
          </Row>}

          {false &&
          // {!isChineseEnv &&
            <Row>
              <StyledSelect
                placeholder={t('ethnicity')}
                options={ethnicityOptions}
                selectProps={{
                  onChange: (option) => setFieldValue('ethnicity', option.value),
                  value: ethnicityOptions.find((item) => item.value === values.ethnicity) || null,
                  menuPosition: 'fixed',
                }}
              />
              <StyledSelect
                placeholder={t('education')}
                options={educationOptions}
                selectProps={{
                  onChange: (option) => setFieldValue('education', option.value),
                  value: educationOptions.find((item) => item.value === values.education) || null,
                  menuPosition: 'fixed',
                }}
              />
          </Row>}

          {false &&
          // {!isChineseEnv &&
            <Row>
            <StyledSelect
              placeholder={t('jobArea')}
              options={jobareaOptions}
              selectProps={{
                onChange: (option) => setFieldValue('jobArea', option.value),
                value: jobareaOptions.find((item) => item.value === values.jobArea) || null,
                menuPosition: 'fixed',
              }}
            />
            <StyledSelect
              placeholder={t('sector')}
              options={sectorOptions}
              selectProps={{
                onChange: (option) => setFieldValue('sector', option.value),
                value: sectorOptions.find((item) => item.value === values.sector) || null,
                menuPosition: 'fixed',
              }}
            />
          </Row>}

          {false &&
          // {!isChineseEnv &&
            <Row >
            <StyledSelect
              placeholder={t('industry')}
              options={industryOptions}
              selectProps={{
                onChange: (option) => setFieldValue('industry', option.value),
                value: industryOptions.find((item) => item.value === values.industry) || null,
                menuPosition: 'fixed',
              }}
            />
            <StyledSelect
              placeholder={t('firstLanguage')}
              options={languageOptions}
              selectProps={{
                onChange: (option) => setFieldValue('firstLanguage', option.value),
                value: languageOptions.find((item) => item.value === values.firstLanguage) || null,
                menuPosition: 'fixed',
              }}
            />
          </Row>}
        </ColumnsWrapper>
        <ButtonsWrapper>
          <StyledButton handler={handleSubmit} disabled={isSubmitting || isLoading} type="button">
            {t('continue')}
            <StyledForwardArrow />
          </StyledButton>
        </ButtonsWrapper>
      </StyledContentHolder>
    </Container>
  );
};

RespondentForm.propTypes = {
  onSuccess: func.isRequired,
};

const StyledInput = styled(FormikInput)`
  width: 48%;
  margin-bottom: 2.8rem;

  ${(props) => props.theme.sm`
    margin-bottom: 1.6rem;
    width: 100%;
  `}
`;

const StyledContentHolder = styled(ContentHolder)`
  height: fit-content;
  width: 66rem;
  padding: 2.4rem;

  ${(props) => props.theme.sm`
    width: 95%;
    height: auto;
  `}
`;

const ColumnsWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.sm`
    flex-direction: column
  `}
`;

const StyledSelect = styled(CustomSelect)`
  margin-bottom: 2.8rem;
  width: 48%;
  ${(props) => props.theme.sm`
    margin-bottom: 1.6rem;
    width: 100%;
  `}
`;

const DatePickerWrapper = styled.div`
  width: 48%;

  ${(props) => props.theme.sm`
     width: 100%;
  `}
`;

const DatePicker = styled(DateSelect)`
  width: 100%;

  ${(props) => props.theme.sm`
     width: 100%;
  `}

  & > div {
    margin-bottom: 2.8rem;

    ${(props) => props.theme.sm`
      margin-bottom: 1.6rem;
  `}
  }

  & > div > div > div:first-of-type {
    width: auto;
    margin-right: 0;
  }
  & > div > div {
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 2.4rem;

  ${(props) => props.theme.sm`
    text-align: center;
  `}
`;

const StyledButton = styled(CustomButton)`
  width: 13rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.regular};

  ${(props) => props.theme.sm`
    width: 100%;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${(props) => props.theme.sm`
    margin-top: 2rem;
  `}
`;

const StyledForwardArrow = styled(ArrowBack)`
  margin-left: 0.5rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
  transform: rotate(180deg);
  & path {
    fill: #fff;
  }
`;
