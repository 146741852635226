import React from 'react';
// import { func, bool, object, number, string } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal } from './modal/Modal';
import { Button, ButtonTypes, ButtonVariants } from './Button';

import { ReactComponent as InfoIcon } from '../../assets/images/info-icon.svg';

export const InstructionModal = ({ isVisible, onClose, instruction }) => {
  const { t } = useTranslation();

  return (
    <StyledModal title="" isVisible={isVisible} onClose={onClose} isHeaderless>
      <Container>
        <InfoIcon />
        <Instruction>{instruction}</Instruction>
        <StyledButton type={ButtonTypes.BUTTON} variant={ButtonVariants.SECONDARY} onClick={onClose}>
          {t('Got it')}
        </StyledButton>
      </Container>
    </StyledModal>
  );
};

InstructionModal.propTypes = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledModal = styled(Modal)`
  width: 340px;
  min-height: 240px;
  padding: 2rem;
`;

const Instruction = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0, 88);
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
