import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import styled, {css} from 'styled-components';
import { shuffle } from 'lodash';
import {
  GlyphStyled,
  GlyphsWrapperStyled,
  FeedbackStyled,
  QuestionTextStyled,
  GlyphsContainerStyled,
  FullHeight,
} from './shared/components';
import { InstructionModal } from '../shared/InstructionModal';
import { CardsList } from '../shared/CardsList';
import { isMobile } from '../shared/utils/isMobile';

const QuestionTextReStyled = styled.div`
  margin-bottom: 30px;

  ${({ theme }) => theme.xs`
      order: 1;
      margin-top: 2rem;
      margin-bottom: 2rem;
   `} 

  ${({ theme }) => theme.xxs`
      order: 1;
      margin-top: 1rem;
   `};
  
    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
      order: 1;
      margin-bottom: 0;
    }
  }
  
  & > div {
    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    font-weight: 600;
    padding: 5px 0; 
  }

  `;
  
const FullHeightStyled = styled(FullHeight)`
  max-width: 100rem;
`;

const MultipleGlyphStyled = styled(GlyphStyled)`
  justify-content: center;
  align-items: center;
  color: black;
`;

const MultipleGlyphsContainerStyled = styled(GlyphsContainerStyled)`
dislay: flex;
flex-direction: row;
column-gap: 5px;

${(props) =>
  props.isMultiple === false &&
  css`
    scale: 1.2;
`}
  
${(props) =>
  props.isMultiple === true &&
  css`
    scale: 1.2;
`}
`;

const MultipleSVGContainerStyled = styled(GlyphsContainerStyled)`

  padding: 5px;

  ${(props) =>
    props.isMultiple === false &&
    css`
      border: 1px solid black;
        svg {
          width: 100px;
          height: 100px;
        }   
    `}

  ${(props) =>
    props.isMultiple === true &&
    css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 3px;
        grid-row-gap: 3px;


        .c1, .c2, .c3, .c4 { 
          grid-area: 1 / 1 / 3 / 3; 
        }
        
        --cc1: #565656;
        --cc2: #4285f4;
        --cc3: #ecb501;
        --cc4: #00a875;

        svg {
          &.c1 {
          
                  polyline, polygon, path, circle, rect {
                      fill: var(--cc1);
                  }
              }
          
              &.c2 {
          
                  polyline, polygon, path, circle, rect {
                      fill: var(--cc2);
                  }
              }
          
              &.c3 {
          
                  polyline, polygon, path, circle, rect {
                      fill: var(--cc3);
                  }
              }
          
              &.c4 {
          
                  polyline, polygon, path, circle, rect {
                      fill: var(--cc4);
                  }
              }
          }

        border: 1px solid black;
    `}

`;

export const WhatComesNextSVG = ({
  values: { mainitem, responses, wcn1, wcn2, wcn3, wcn4, wcn5, itemId, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
  onResponseId,
  onShufledImages,
  page,
}) => {
  const testClass = testId.toLowerCase();
  const [preSelectedResponse, setPreSelectedResponse] = useState(selectedResponse);
  const [shufledResponses, setShufledResponses] = useState(responses);
  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);

  const handleResponseId = useCallback(
    () => {
      if (typeof selectedResponse === 'object') { 
        onResponseId(selectedResponse.RId);
      }
      if (typeof selectedResponse === 'number') { 
        onResponseId(shufledResponses[selectedResponse - 1].RId);
      }
  },
  [onResponseId, selectedResponse],
  );
  
  const handleShufledImages = useCallback(
    (items) => {
    if (page.type === 'item') { 
        onShufledImages(items);
      }
  },
  [onShufledImages],
  );
  
  useEffect(() => {
    if (page.type === 'item') {
      const shufledResponses = shuffle(responses);
      setShufledResponses(shufledResponses);
      handleShufledImages(shufledResponses);
    }
  }, [itemId]);
  
  
  useEffect(() => {

    if (page.type === 'item' && selectedResponse) {
      handleResponseId();
    }
    const filteredSelectedResponse = shufledResponses.findIndex((item) => item.RIdx === selectedResponse);
    setPreSelectedResponse(filteredSelectedResponse + 1);

  }, [selectedResponse]);


  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <FullHeightStyled>
        <GlyphsWrapperStyled className="whatComesNext" >
          <QuestionTextReStyled>
            <QuestionTextStyled
              dangerouslySetInnerHTML={{
                __html: mainitem,
              }}
            />
          </QuestionTextReStyled>
          <MultipleGlyphsContainerStyled isMultiple={wcn1.length>2}>  
            <MultipleSVGContainerStyled key={Math.random()} isMultiple={wcn1.length>2}>
              {wcn1.map((item) => {
                return (
                  <MultipleGlyphStyled
                  key={item}
                  dangerouslySetInnerHTML={{
                    __html: item,
                  }} />
                );
              })}
            </MultipleSVGContainerStyled>
            <MultipleSVGContainerStyled key={Math.random()} isMultiple={wcn2.length>2}>
              {wcn2.map((item) => {
                return (
                  <MultipleGlyphStyled
                    key={item}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }} />
                );
              })}
            </MultipleSVGContainerStyled>
            <MultipleSVGContainerStyled key={Math.random()} isMultiple={wcn3.length>2}>
                {wcn3.map((item) => {
                  return (
                    <MultipleGlyphStyled
                    key={item}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }} />
                  );
                })}
            </MultipleSVGContainerStyled>
            <MultipleSVGContainerStyled key={Math.random()} isMultiple={wcn4.length>2}>
                {wcn4.map((item) => {
                  return (
                    <MultipleGlyphStyled
                    key={item}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }} />
                  );
                })}
            </MultipleSVGContainerStyled>
            {wcn5 && <MultipleSVGContainerStyled key={Math.random()} isMultiple={wcn5.length>2}>
                {wcn5.map((item) => {
                  return (
                    <MultipleGlyphStyled
                    key={item}
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }} />
                  );
                })}
            </MultipleSVGContainerStyled>}
          </MultipleGlyphsContainerStyled>
          {!!feedback && !isMobile && (
            <FeedbackStyled
              dangerouslySetInnerHTML={{
                __html: feedback,
              }}
            />
          )}
        </GlyphsWrapperStyled>
      </FullHeightStyled>
      <CardsList
        isAbstract={config.isAbstract}
        className={clsx({ 'svg-image': config.isAbstract, whatComesNext: true })}
        items={shufledResponses}
        // selectedResponse={selectedResponse}
        selectedResponse={preSelectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};