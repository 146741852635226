import React from "react";
import styled from "styled-components";
import clsx from "clsx";

const CheckboxLabelStyled = styled.label`
  display: flex;
  cursor: pointer;
  line-height: ${({ size }) => `${size}px`};
  color: ${({ theme }) => theme.colors.medGray};
  width: 100%;
  height: 100%;
  align-items: center;

  span:before {
    content: "";
    width: 8px;
    display: inline-block;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const CheckboxStyled = styled.input`
  position: relative;
  cursor: pointer;
  width: calc(${({ size }) => `${size}px`} - 4px);
  height: calc(${({ size }) => `${size}px`} - 2px);
  outline: none;

  &:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -2px;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid #295368;
    border-radius: 4px;
  }

  & {
    &:before {
      opacity: 0;
      transform: scale(0);
      content: "";
      position: absolute;
      top: -1px;
      left: -2px;
      width: ${({ size }) => `${size}px`};
      height: ${({ size }) => `${size}px`};
      z-index: 1;
      transition: 0.2s all;
      background: grey no-repeat center;
      border-radius: 4px;
    }
  }

  &:checked {
    &:before {
      background-color: ${({ theme }) => theme.colors.light};
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC42MjQiIGhlaWdodD0iOS41ODkiIHZpZXdCb3g9IjAgMCAxMC42MjQgOS41ODkiPiAgPHBhdGggZD0iTTQuMzcxLDYuOTc5bDMuNDg3LDMuMzksNC4zNS03LjIxNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIuOTU3IC0xLjc4KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==);
      opacity: 1;
      transform: scale(1);
    }
  }

  &:disabled {
    cursor: not-allowed;

    &:after {
      border: 1px solid #acacac;
    }

    & + span {
      color: #acacac;
      cursor: not-allowed;
    }
  }

  & + span {
    font-size: 1.6rem;
  }
`;

export const Checkbox = React.forwardRef(
  ({ label, size = 20, ...props }, ref) => (
    <CheckboxLabelStyled
      size={size}
      ref={ref}
      className={clsx({ disabled: props.disabled })}
    >
      <CheckboxStyled type="checkbox" size={size} {...props} />
      <span>{label}</span>
    </CheckboxLabelStyled>
  )
);
