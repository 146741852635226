import React from "react";
import {
  QuestionTextStyled,
  FeedbackStyled,
  FullHeight,
} from "./shared/components";
import { CardsList } from "../shared/CardsList";

export const DefaultQuestion = ({
  values: { mainitem, responses, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  direction,
  isSidePanelOpen,
  className,
  testId,
}) => {
  const testClass = testId.toLowerCase();

  return (
    <>
      <FullHeight>
        <QuestionTextStyled
          dangerouslySetInnerHTML={{
            __html: mainitem,
          }}
          className={className}
        />
        {!!feedback && (
          <FeedbackStyled
            direction={direction}
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </FullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className="for-text"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
