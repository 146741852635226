import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Login } from './components/routes/Login';
import { Questionnaire } from './components/routes/Questionnaire';
import { LangDirectionContext } from './context/LangDirectionContext';
import { Welcome } from './components/pages/Welcome';
import { Graphics } from './components/routes/Graphics';
import { useEnvVars } from './utils/hooks/useEnvVars';
import { Self360Page } from './components/pages/Self360/Self360Page';
import { LoginTypes } from './components/routes/constants';

export const Router = () => {
  const direction = React.useContext(LangDirectionContext);
  const { isDev } = useEnvVars();

  return (
    <div className={`lang-wrapper ${direction}`}>
      <BrowserRouter>
        <Switch>
          <Route path="/login360/:accountWithInvitation/:password/:lang">
            <Questionnaire is360 />
          </Route>
          <Route path="/login360/:accountWithInvitation/:password/">
            <Questionnaire is360 />
          </Route>

          <Route path="/login/:accountWithInvitation/:password/:lang">
            <Questionnaire />
          </Route>
          <Route path="/login/:accountWithInvitation/:password/">
            <Questionnaire />
          </Route>

          <Route path="/launch/:accessCode">
            <Questionnaire isManagedGroup />
          </Route>

          <Route path="/opnlaunch/:accessCode">
            <Questionnaire isOpenGroup />
          </Route>

          <Route path="/Start360/:accountWithInvitation/:password/:lang">
            <Welcome is360 />
          </Route>
          <Route path="/Start360/:accountWithInvitation/:password/">
            <Welcome is360 />
          </Route>
          <Route path="/Start360/:lang">
            <Login mode={LoginTypes.login360} />
          </Route>
          <Route path="/Start360">
            <Login mode={LoginTypes.login360} />
          </Route>

          <Route path="/Start/:accountWithInvitation/:password/:lang">
            <Welcome />
          </Route>
          <Route path="/Start/:accountWithInvitation/:password/">
            <Welcome />
          </Route>

          <Route path="/Start/:lang">
            <Login mode={LoginTypes.login} />
          </Route>
          <Route path="/Start">
            <Login mode={LoginTypes.login} />
          </Route>

          <Route path="/Auto360/:lang" exact>
            <Login mode={LoginTypes.auto360} />
          </Route>

          <Route path="/Auto360/::lang">
            <Self360Page />
          </Route>

          <Route path="/Auto360/:invitationId/:respondentId/:lang">
            <Self360Page />
          </Route>

          {isDev && (
            <Route path="/graphics/:testId">
              <Graphics />
            </Route>
          )}
          <Redirect from="*" to="/Start" />
        </Switch>
      </BrowserRouter>
    </div>
  );
};
