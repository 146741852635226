import React from "react";

export const KeyArrowRight = () => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9282 39.6581H1.54071C0.693317 39.6581 0 38.9662 0 38.1206V23.245C0 22.3994 0.693317 21.7075 1.54071 21.7075H29.9282C30.7756 21.7075 31.4689 22.3994 31.4689 23.245V38.1206C31.4689 38.9662 30.7756 39.6581 29.9282 39.6581Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M18 30.6865L12.8195 33.6775L12.8195 27.6955L18 30.6865Z"
        fill="white"
      />
    </svg>
  );
};
