import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { shuffle } from 'lodash';
import {
  GlyphStyled,
  GlyphsWrapperStyled,
  FeedbackStyled,
  QuestionTextStyled,
  GlyphsContainerStyled,
  FullHeight,
} from './shared/components';
import { InstructionModal } from '../shared/InstructionModal';
import { isMobile } from '../shared/utils/isMobile';
import { ImageCard } from '../shared/ImageCard';

const IMAGE_MAX_WIDTH = 290;
const CT_URL_PREFIX = 'https://media.genesysonline.net/gs2020container/Checking';

const StyledGlyphStyled = styled(GlyphStyled)`
 img {
  width: ${IMAGE_MAX_WIDTH}px;
 }
 margin: 0 5px;
`;

const StyledGlyphReferenceStyled = styled(GlyphStyled)`
 img {
  width: ${IMAGE_MAX_WIDTH}px;
 }
 padding: 8px 0;
 border: 1px solid #C0C0C0;
 border-radius: 6px;
`;

const StyledEmbed = styled.img`
  width: 100%;
  min-width: 280px;
  img:before {
    content: ' ';
    display: block;
    position: absolute;
    height: 50px;
    width: 50px;
  }
`;

const StyledGlyphsContainerHeader = styled(GlyphsContainerStyled)`
  width: 100%;
  justify-content: center;
  border-bottom: 2px solid #1AAAE1;
  margin-bottom: 20px;
`;


const StyledNumberContainer = styled.p`
    background: #1AAAE1;
    color: #fff;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 2px 6px;
`;

const QuestionTextReStyled = styled.div`
  margin-bottom: 30px;
  ${({ theme }) => theme.xs`
      order: 1;
      margin-top: 2rem;
      margin-bottom: 2rem;
  `} 
  ${({ theme }) => theme.xxs`
      order: 1;
      margin-top: 1rem;
  `};
  
    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
      order: 1;
      margin-bottom: 0;
    }
  }
  
  & > div {
    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    font-weight: 600;
    padding: 5px 0; 
  }
`;

export const ImageMatching = ({
  values: { mainitem, images, feedback },
  selectedResponse,
  onResponse,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  onResponseId,
  onShufledImages,
  page
}) => {
  const [shufledImages, setShufledImages] = useState([]);

  const handleResponse = useCallback(
    (id) => {
      onResponse(id);
      
      setTimeout(() => {
        onResponse(id);
      }, 5);
  },
  [onResponse],
  );

  const handleResponseId = useCallback(
    (resultId) => {
      if (page.type === 'item') {
        onResponseId(resultId);

        setTimeout(() => {
          onResponseId(resultId);
        }, 5);
      }
  },
  [onResponseId],
  );
  
  const handleShufledImages = useCallback(
    (images) => {
    if (page.type === 'item') { 
        onShufledImages(images);
      }
  },
  [onShufledImages],
  );
  
  const handleKeyboardEvent = (event) => {
    const key = event.which;

    if (isBlockModalVisible || isHelpVisible || isSidePanelOpen) return;

    if (key >= 49 && key <= 49 + 5) {
      const desktopRespondeIndex = key - 48;
      handleResponseId(shufledImages[desktopRespondeIndex - 1]);
      handleResponse(desktopRespondeIndex);
    }
    
    if (key >= 97 && key <= 97 + 6) {
      const mobileRespondeIndex = key - 48;
      handleResponseId(shufledImages[mobileRespondeIndex - 1]);
      handleResponse(mobileRespondeIndex);
    }
  };


  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyboardEvent);
    };
  });

  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);



  useEffect(() => {
    let shufledImageIds = null;
    if (page.type === 'item') {
      shufledImageIds = shuffle(Object.values(images).splice(1));
    }
    if (page.type === 'instruction') {
      shufledImageIds = Object.values(images).splice(1);
    }

    setShufledImages(shufledImageIds);
    handleShufledImages(shufledImageIds);
  
    return () => { };
  }, [images]);

  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <FullHeight>
        <GlyphsWrapperStyled className="whatComesNext" >
          <QuestionTextReStyled>
            <QuestionTextStyled
              dangerouslySetInnerHTML={{
                __html: mainitem,
              }}
            />
          </QuestionTextReStyled>
          <StyledGlyphsContainerHeader>
            <StyledGlyphReferenceStyled>
              <StyledEmbed src={`${CT_URL_PREFIX}/${images.reference}.svg`} alt="Checking Test Content" />
            </StyledGlyphReferenceStyled>
          </StyledGlyphsContainerHeader>
          <GlyphsContainerStyled>
            {[1, 2, 3].map((item) => {
              return (
                <StyledGlyphStyled key={`${Math.random()*item}`}>
                  <ImageCard
                    content={`${CT_URL_PREFIX}/${shufledImages[item - 1]}.svg`}
                    onClick={(e) => {
                      if (e.detail === 0) return;
                      handleResponse(item);
                      handleResponseId(shufledImages[item - 1]);
                    }}
                    selected={
                      page.type === 'item'
                        ? shufledImages[item - 1] === `${selectedResponse?.RId}`
                        : item === selectedResponse
                    }
                  />
                  <StyledNumberContainer>{item}</StyledNumberContainer>
                </StyledGlyphStyled>
              );
            })}
          </GlyphsContainerStyled>
          <GlyphsContainerStyled>
            {[4, 5, 6].map((item) => {
              return (
                <StyledGlyphStyled key={`${Math.random()*item}`}>
                  <ImageCard
                    content={`${CT_URL_PREFIX}/${shufledImages[item - 1]}.svg`}
                    onClick={(e) => {
                      if (e.detail === 0) return;
                      handleResponse(item);
                      handleResponseId(shufledImages[item - 1]);
                    }}
                    selected={
                      page.type === 'item'
                        ? shufledImages[item - 1] === `${selectedResponse?.RId}`
                        : item === selectedResponse
                    }
                  />
                  <StyledNumberContainer>{item}</StyledNumberContainer>
                </StyledGlyphStyled>
              );
            })}
          </GlyphsContainerStyled>
          {!!feedback && !isMobile && (
            <FeedbackStyled
              dangerouslySetInnerHTML={{
                __html: feedback,
              }}
            />
          )}
        </GlyphsWrapperStyled>
      </FullHeight>
    </>
  );
};