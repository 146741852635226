import {
  FETCH_QUESTIONNAIRE_DATA,
  FETCH_QUESTIONNAIRE_DATA_ERROR,
  FETCH_QUESTIONNAIRE_DATA_SUCCESS,
  SET_ACTIVE_QUESTION,
  UPDATE_ANSWER,
  UPDATE_ACTIVE_QUESTION_ID,
  SET_STARTED,
  UPDATE_REMAINING_TIME,
  SUBMIT_RESULTS_START,
  SAVE_RESPONDENT_DATA,
  SAVE_TIME_SPENT_ON_SINGLE_QUESTION,
  SET_ICONS_MODULE,
  PUSH_MEX_ASSESSMENT_HISTORY_ITEM,
  UPDATE_MEX_ASSESSMENT_CURRENT_STATE,
  FETCH_MANAGED_GROUP_DETAILS_START,
  FETCH_MANAGED_GROUP_DETAILS_FAILURE,
  FETCH_MANAGED_GROUP_DETAILS_SUCCESS,
  FETCH_OPEN_GROUP_DETAILS_START,
  FETCH_OPEN_GROUP_DETAILS_SUCCESS,
  FETCH_OPEN_GROUP_DETAILS_FAILURE,
  SET_MANAGED_GROUP_RESPONDENT_DATA,
  SET_MANAGED_GROUP_INVITE_ID,
  SET_MANAGED_GROUP_PASSWORD,
  INVITE_RESPONDENT_TO_SESSION_SUCCESS,
  SET_CHECKING_ASSESSMENT_RESULTS,
  SET_FORMATED_ASSESSMENT_RESULTS,
} from '../actions/questionnaireDetailsActions';
import { FETCH_QUESTIONNAIRE_CONFIG_SUCCESS } from '../actions/testDetailsActions';

import { LocalStorageService } from '../../utils/LocalStorage.service';

export function questionnaireDetailsReducer(
  state = {
    isFetching: true,
    isError: false,
    answers: [],
    timeSpentPerQuestion: [],
    remainingTime: null,
    isSessionSaving: false,
    respondent: null,
    icons: null,
    mexAssessment: {
      history: [],
      currentDndState: null,
    },
    managedGroup: {
      sessionData: null,
      respondent: null,
      inviteId: null,
      password: null,
    },
    openGroup: null,
    biotemplates: [],
    checkingAssessment: {
      results: [],
    },
    formatedResults: {
      results: [],
    },
  },
  action,
) {
  switch (action.type) {
    case SAVE_RESPONDENT_DATA:
      return { ...state, respondent: action.payload };
    case FETCH_QUESTIONNAIRE_DATA:
      return { ...state, isFetching: true };
    case FETCH_QUESTIONNAIRE_DATA_ERROR:
      return { ...state, isFetching: false, isError: true, error: action.err };
    case FETCH_QUESTIONNAIRE_CONFIG_SUCCESS: {
      const isAdaptive = action.payload.isAdaptive;
      const isMEX = action.payload.isMEX;
      const adaptiveQuestionsLength =
        action.payload?.maxitemsperdimension > action.payload.items.length
          ? action.payload.items.length
          : action.payload?.maxitemsperdimension;
      const nonAdaptiveQuestionsLength = isMEX ? action.payload.items.length * 3 : action.payload.items.length;
      const questionsLength = isAdaptive ? adaptiveQuestionsLength : nonAdaptiveQuestionsLength;
      return {
        ...state,
        timeSpentPerQuestion: state.timeSpentPerQuestion?.length
          ? state.timeSpentPerQuestion
          : new Array(questionsLength).fill(0),
      };
    }
    case SAVE_TIME_SPENT_ON_SINGLE_QUESTION: {
      return {
        ...state,
        timeSpentPerQuestion: state.timeSpentPerQuestion.map((spentTime, index) =>
          index === action.payload.questionIndex ? action.payload.time : spentTime,
        ),
      };
    }
    case FETCH_QUESTIONNAIRE_DATA_SUCCESS:
      const { accountId, invitationId, password, resultString, mexItems, remainingTime, itemTimings, testId } =
        action.payload.qSession;

      const answersFromServer = resultString ? JSON.parse(resultString) : [];

      const {
        answers: answersFromLocalStorage,
        remainingTime: remainingTimeFromLocalStorage,
        timeSpentPerQuestion: timeSpentPerQuestionFromLocalStorage,
        mexAssessmentState: mexAssessmentStateFromLocalStorage,
        checkingAssessmentState: checkingAssessmentStateFromLocalStorage,
        formatedResultsState: formatedResultsStateFromLocalStorage,
      } = LocalStorageService.getSession({
        accountId,
        invitationId,
        password,
      });
      // for MEX questionnaire only
      const mexAnswers = mexAssessmentStateFromLocalStorage?.currentDndState
        ? [...mexAssessmentStateFromLocalStorage.history, mexAssessmentStateFromLocalStorage.currentDndState]
        : mexItems;
      
      const checkingAnswers = checkingAssessmentStateFromLocalStorage?.results ? checkingAssessmentStateFromLocalStorage : [];
      const formatedResults = formatedResultsStateFromLocalStorage?.results ? formatedResultsStateFromLocalStorage : [];

      const answers = answersFromLocalStorage ?? answersFromServer;
      // const answers = answersFromServer ?? answersFromLocalStorage;
      const timingsFromServer = itemTimings ? itemTimings.split(',').map((item) => Number(item)) : [];
      const timeSpentPerQuestion = timeSpentPerQuestionFromLocalStorage ?? timingsFromServer;

      const isStarted =
        testId?.toLowerCase() === 'mex'
          ? Boolean(mexAnswers?.length)
          : !!answers.filter((a) => a.Id[0].toLowerCase() !== 'e').length;

      return {
        ...state,
        isFetching: false,
        isError: false,
        answers,
        details: action.payload.qInvitation,
        session: action.payload.qSession,
        isStarted,
        remainingTime: remainingTimeFromLocalStorage ?? remainingTime,
        accountWithInvitation: action.payload.accountWithInvitation,
        timeSpentPerQuestion,
        ...(mexAssessmentStateFromLocalStorage && { mexAssessment: mexAssessmentStateFromLocalStorage }),
        ...(mexItems &&
          !mexAssessmentStateFromLocalStorage && {
            mexAssessment: {
              history: mexItems.slice(0, mexItems.length - 1),
              currentDndState: mexItems[mexItems.length - 1],
            },
          }),
        ...(checkingAssessmentStateFromLocalStorage && { checkingAssessment: checkingAnswers }),
        ...(formatedResultsStateFromLocalStorage && { formatedResults }),
      };
    case FETCH_MANAGED_GROUP_DETAILS_START: {
      return { ...state, isFetching: true };
    }
    case FETCH_MANAGED_GROUP_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isError: false,
        managedGroup: { ...state.managedGroup, sessionData: action.payload },
      };
    }
    case FETCH_MANAGED_GROUP_DETAILS_FAILURE: {
      return { ...state, isFetching: false, isError: true, error: action.payload.message };
    }
    case FETCH_OPEN_GROUP_DETAILS_START: {
      return { ...state, isFetching: true };
    }
    case FETCH_OPEN_GROUP_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isError: false,
        openGroup: { ...state.openGroup, ...action.payload },
      };
    }
    case FETCH_OPEN_GROUP_DETAILS_FAILURE: {
      return { ...state, isFetching: false, isError: true, error: action.payload.message };
    }
    case SET_MANAGED_GROUP_RESPONDENT_DATA: {
      return { ...state, managedGroup: { ...state.managedGroup, respondent: action.payload } };
    }
    case SET_MANAGED_GROUP_INVITE_ID: {
      return { ...state, managedGroup: { ...state.managedGroup, inviteId: action.payload } };
    }
    case SET_MANAGED_GROUP_PASSWORD: {
      return { ...state, managedGroup: { ...state.managedGroup, password: action.payload } };
    }
    case INVITE_RESPONDENT_TO_SESSION_SUCCESS: {
      return { ...state, managedGroup: { ...state.managedGroup, inviteId: action.payload } };
    }
    case UPDATE_MEX_ASSESSMENT_CURRENT_STATE:
      return {
        ...state,
        mexAssessment: {
          ...state.mexAssessment,
          currentDndState: { ...state.mexAssessment.currentDndState, ...action.payload },
        },
      };
    case PUSH_MEX_ASSESSMENT_HISTORY_ITEM:
      return {
        ...state,
        mexAssessment: {
          ...state.mexAssessment,
          history: [...state.mexAssessment.history, action.payload],
        },
      };
    case SET_STARTED:
      return {
        ...state,
        isStarted: true,
      };
    case SET_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestion: action.payload,
      };
    case UPDATE_ANSWER:
      const previousAnswer2 = state.answers.findIndex((a) => a.Id === action.payload.answer.Id);

      return {
        ...state,
        ...(action.payload.remainingTime && {
          remainingTime: action.payload.remainingTime,
        }),
        answers:
          previousAnswer2 === -1
            ? [...state.answers, action.payload.answer]
            : state.answers.map((a) => (a.Id === action.payload.answer.Id ? action.payload.answer : a)),
      };
    case UPDATE_ACTIVE_QUESTION_ID:
      return {
        ...state,
        activeQuestionId: action.payload,
      };
    case UPDATE_REMAINING_TIME:
      return {
        ...state,
        remainingTime: action.payload,
      };
    case SUBMIT_RESULTS_START:
      return {
        ...state,
        answers: [],
        isResultSubmitted: true,
      };
    case SET_ICONS_MODULE:
      return {
        ...state,
        icons: action.payload,
      };
    // For Checking Tests ONLY
    case SET_CHECKING_ASSESSMENT_RESULTS:
      
      const resultIndex = action.payload.resultIndex;
      /* eslint-disable no-param-reassign */
      state.checkingAssessment.results[resultIndex] = action.payload.itemResult || "";
      /* eslint-disable no-param-reassign */
      
      return {
        ...state,
        checkingAssessment: {
          results: [ ...state.checkingAssessment.results ],
        },
      };
    case SET_FORMATED_ASSESSMENT_RESULTS:
      const formatedResultIndex = action.payload.resultIndex;
      /* eslint-disable no-param-reassign */
      state.formatedResults.results[formatedResultIndex] = action.payload.itemResult || "";
      /* eslint-disable no-param-reassign */
      
      return {
        ...state,
        formatedResults: {
          results: [ ...state.formatedResults.results ],
        },
      };
    default:
      return state;
  }
}