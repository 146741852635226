import { makeAutoObservable } from "mobx";
import { self360Api } from "../../../../utils/agent";

function parseResponseError(error) {
  return error?.response?.data || error.message;
}

export class Self360Store {
  // observers
  routeDetails = {};

  invitationDetails = {
    invitation: {},
    error: null,
  };

  ratersDetails = {
    raters: [],
    error: null,
    isLoading: false,
  };

  deleteRaterDetails = {
    isModalOpen: false,
    raterId: null,
    isLoading: false,
  };

  addRaterDetails = {
    isModalOpen: false,
    isLoading: false,
  };

  submitInvitationDetails = {
    isModalOpen: false,
    isLoading: false,
  };

  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  // actions
  async fetchInvitation() {
    if (this.hasInvitation) {
      return;
    }

    try {
      const { data } = await self360Api.fetchInvitation(
        this.routeDetails.invitationId,
        this.routeDetails.respondentId
      );

      this.setInvitationDetails(data.invitation);
    } catch (e) {
      this.setInvitationDetails({ error: parseResponseError(e) });
    }
  }

  async fetchRaters() {
    this.setRatersDetails({ isLoading: true });

    try {
      const { data } = await self360Api.fetchRaters(
        this.routeDetails.invitationId,
        this.routeDetails.respondentId
      );

      this.setRatersDetails({ raters: data });
    } catch (e) {
      this.setRatersDetails({ raters: [], error: parseResponseError(e) });
    } finally {
      this.setRatersDetails({ isLoading: false });
    }
  }

  async deleteRater() {
    this.setDeleteRaterDetails({ isLoading: true });

    try {
      await self360Api.removeRater(
        this.routeDetails.invitationId,
        this.routeDetails.respondentId,
        this.deleteRaterDetails.raterId
      );
    } catch (e) {
      this.setMajorError(parseResponseError(e));
    } finally {
      this.setDeleteRaterDetails({ isLoading: false });
    }
  }

  async addRater(rater) {
    this.setAddRaterDetails({ isLoading: true });

    try {
      await self360Api.addRater(
        this.routeDetails.invitationId,
        this.routeDetails.respondentId,
        rater
      );
    } catch (e) {
      this.setMajorError(parseResponseError(e));
    } finally {
      this.setAddRaterDetails({ isLoading: false });
    }
  }

  async submitInvitation() {
    this.setSubmitInvitationDetails({ isLoading: true });

    try {
      await self360Api.submitInvitation(
        this.routeDetails.invitationId,
        this.routeDetails.respondentId
      );
    } catch (e) {
      this.setMajorError(parseResponseError(e));
    } finally {
      this.setSubmitInvitationDetails({ isLoading: false });
    }
  }

  setRouteDetails(routeDetails) {
    this.routeDetails = routeDetails;
  }

  // private actions

  setInvitationDetails(details) {
    this.invitationDetails = { ...this.invitationDetails, ...details };
  }

  setRatersDetails(details) {
    this.ratersDetails = { ...this.ratersDetails, ...details };
  }

  setDeleteRaterDetails(details) {
    this.deleteRaterDetails = { ...this.deleteRaterDetails, ...details };
  }

  setAddRaterDetails(details) {
    this.addRaterDetails = { ...this.addRaterDetails, ...details };
  }

  setSubmitInvitationDetails(details) {
    this.submitInvitationDetails = {
      ...this.submitInvitationDetails,
      ...details,
    };
  }

  setMajorError(error) {
    this.majorError = error;
  }

  // computed

  get hasInvitation() {
    return Boolean(this.invitationDetails.accountId);
  }
}

export const self360Store = new Self360Store();
