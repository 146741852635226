import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, ButtonTypes, ButtonVariants } from './Button';
import { Footer } from './Footer';
import { ButtonsHolder } from './layout/ButtonHolder';
import { Page } from './layout/Page';

const PageStyled = styled(Page)`
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    &:before,
    &:after {
      height: calc(100% - 80px);
    }
  }
  @media (max-height: 414px) {
    &:before,
    &:after {
      height: calc(100% - 54px);
    }
  }
`;

const FooterStyled = styled(Footer)`
  display: block;
  position: fixed;
  bottom: 0;
  min-height: 80px;
  height: 80px;
  width: 100%;
  z-index: 4;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    background-color: ${({ theme }) => theme.colors.font};
    display: flex;
    align-items: center;
  }
  @media (max-height: 414px) {
    min-height: 54px;
    height: 54px;
  }
`;

const ButtonsHolderStyled = styled(ButtonsHolder)`
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 0 5%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 0;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 300px;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    min-width: 300px !important;
  }
`;

const TextWrapper = styled.div`
  align-items: flex-start;
  font-size: 14px;
  line-height: 22px;
  overflow: initial;
  z-index: 3;
  padding: 20px 7% 20px 7%;
  height: calc(100% - 80px);
  display: flex;
  margin-bottom: 80px;
  color: white;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 18px;
    line-height: 28px;
    height: calc(100% - 80px);
    margin: 0 5%;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    max-width: calc(900px + 10%);
    -webkit-overflow-scrolling: touch;
    margin-bottom: 80px;
  }
`;

const Container = styled.div`
  max-width: 100%;
`;

export const UserReturnModal = ({ onClose, testName }) => {
  const { t } = useTranslation();
  const config = useSelector((state) => state.testDetails.config);
  const icons = useSelector((state) => state.questionnaireDetails.icons);

  return (
    <PageStyled withBlurOpacity withBackgroundMobile>
      <TextWrapper>
        <Container>
          <span>
            {t('UserReturnModalPart1')}
            <b>{testName?.toUpperCase()}</b>
            {t('UserReturnModalPart2')}
          </span>
          <br />
          <br />
          <b>{t('UserReturnModalPart3')}</b>
          <br />
          <span>{t('UserReturnModalPart4')}</span>
        </Container>
      </TextWrapper>
      <FooterStyled>
        <ButtonsHolderStyled>
          <ButtonStyled
            variant={ButtonVariants.PRIMARY}
            type={ButtonTypes.BUTTON}
            onClick={onClose}
            disabled={config.type === 'svg-single' && !icons}
            autoFocus
          >
            {t('continue')}
          </ButtonStyled>
        </ButtonsHolderStyled>
      </FooterStyled>
    </PageStyled>
  );
};
