import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { WelcomeText } from '../../pages/WelcomeText';
import { Terms } from '../../pages/Terms';
import { Spinner } from '../Spinner';
import { UserRecognitionForm } from './LoginFlow/UserRecognitionForm';
import { RespondentForm } from './LoginFlow/RespondentForm';
import { RespondentNotFound } from './LoginFlow/RespondentNotFound';
import { PendingScreen } from './LoginFlow/PendingScreen';

// SCREENS
const WELCOME_TEXT = 'WELCOME_TEXT';
const TERMS = 'TERMS';
const RECOGNIZE_RESPONDENT = 'RECOGNIZE_RESPONDENT';
const RESPONDENT_NOT_FOUND = 'RESPONDENT_NOT_FOUND';
const RESPONDENT_FORM = 'RESPONDENT_FORM';
const PENDING_SCREEN = 'PENDING_SCREEN';

const ManagedGroupPreTestComponent = ({ welcomeText }) => {
  // const onCancelHandler = React.useCallback(() => {
  //   history.push(is360 ? '/Start360' : '/Start');
  // }, [history, is360]);
  // const { managedGroupData } = useSelector((state) => state.questionnaireDetails);

  const [activeScreen, setActiveScreen] = useState(TERMS);
  const [email, setEmail] = useState('');

  const {
    managedGroup: { sessionData },
  } = useSelector((state) => state.questionnaireDetails);

  useEffect(() => {
    if (activeScreen === RECOGNIZE_RESPONDENT) {
      setEmail('');
    }
  }, [activeScreen]);

  // useEffect(() => {
  //   const { isWelcomeTextPassed, isTermsAccepted, isBioEdited } = state;
  //   if (isWelcomeTextPassed && isTermsAccepted && isBioEdited) {
  //     onComplete();
  //   }
  // }, [state, onComplete]);

  return React.useMemo(() => {
    switch (activeScreen) {
      case WELCOME_TEXT:
        return (
          <WelcomeText
            text={welcomeText}
            onNext={() => {
              setActiveScreen('TERMS');
            }}
          />
        );
      case TERMS:
        return (
          <Terms
            onCancel={() => console.log('zzz cancel')}
            removeCloseButton
            onNext={() =>
              sessionData.skipAuthorization ? setActiveScreen(RESPONDENT_FORM) : setActiveScreen(RECOGNIZE_RESPONDENT)
            }
          />
        );
      case RECOGNIZE_RESPONDENT:
        return (
          <UserRecognitionForm
            goToRespondentForm={() => setActiveScreen(RESPONDENT_FORM)}
            goToPending={() => setActiveScreen(PENDING_SCREEN)}
            setEmail={setEmail}
            onError={() => setActiveScreen(RESPONDENT_NOT_FOUND)}
          />
        );
      case RESPONDENT_NOT_FOUND:
        return (
          <RespondentNotFound
            onBack={() => setActiveScreen(RECOGNIZE_RESPONDENT)}
            onNewRecord={() => setActiveScreen(RESPONDENT_FORM)}
            email={email}
          />
        );
      case RESPONDENT_FORM:
        return (
          <RespondentForm
            onSuccess={() => setActiveScreen(PENDING_SCREEN)}
          />
        );
      case PENDING_SCREEN:
        return <PendingScreen />;
      default:
        return <Spinner />;
    }
  }, [activeScreen, welcomeText]);
};

export const ManagedGroupPreTest = withRouter(ManagedGroupPreTestComponent);
