/* eslint-disable */
import { adaptiveTestVersions } from "./constants";

export class CAdaptiveTestNew {
  constructor(EAPpriorMean = 0, recoveryItemIDToSelect = "") {
    this.Nq = 80; // number of quadrature points for EAP estimation

    /**
     * PROGRAMMER MUST ASSIGN VALUES TO Nitems, NPool, and NCAT EXTERNALLY
     * NOTE: For DEMO, these variables were assigned the *desired or CORRECT* values at start
     *
     * 1) Set NPool to the number of items available for test administration; determines how many to read from your database
     * 2) Set NCAT to the number of options for response format (use highest value across all items; NCAT=4 if, say, (SD,D,A,SA))
     * 3) Set Nitems to the maximum number of items you want to present to an examinee
     */
    this.NPool = -1;
    this.Nitems = -1;
    this.NCAT = 6;

    this.recoveryItemIDToSelect = recoveryItemIDToSelect;
    this.completed = false;

    this.EAPpriorSD = 1; // STANDARD DEVIATION (NOT variance) of prior distribution for EAP estimation
    this.EAPpriorMean = EAPpriorMean;
  }

  initialize(questionnaire, config) {
    this.currentQuestionnaire = questionnaire;
    this.config = config;

    this.setMiscVariablesFromSettings();
    this.NPool = Object.keys(this.currentQuestionnaire.questions).length;

    this.initializeArrays();
    this.addItems();

    if (this.MaximumNumberOfItemsPerDimensions > this.NPool) {
      this.mintTotalNumberOfItemsToAdminister = this.NPool;
    } else {
      this.mintTotalNumberOfItemsToAdminister = this.MaximumNumberOfItemsPerDimensions;
    }

    this.Nitems = this.mintTotalNumberOfItemsToAdminister;

    this.countAndOrderConstructs();

    this.createLookUpTables();
    this.initializeRespondentDataArrays();

    this.ItemNumber = 1; // Assumption: Active Question Number e.g. 1-15
    this.EAP = this.EAPpriorMean;
    this.ScoredItemResponse = -1;
    this.SelectedItemIndex = 0; // Selected item index (position in pool); value returned as argument by SelectItem
  }

  scoreExam() {
    let ItemInfo = 0;

    this.TestInfo = 0;

    for (let i = 1; i <= this.U[0]; i++) {
      // number of items successfully administered is stored in zero element
      ItemInfo = this.getIIFvalueFromLookUpTable(
        this.UsedItemIndex[i],
        this.EAP
      ); // 09-18-07: modified to use this routine instead of one return Ppos too
      this.TestInfo += ItemInfo;
    }
    this.SEinfo = 1.0 / Math.sqrt(this.TestInfo);

    if (this.config.respodentIsFromSA) {
      const prob = 1 / (1 + Math.exp(-(this.EAP - -1.30865)));

      this.EAP = Math.log(prob / (1 - prob));
    }

    return `EAP:${this.EAP};PSD:${this.PSD};SEInfo:${this.SEinfo};TestInfo:${this.TestInfo}`;
  }

  countAndOrderConstructs() {
    /* ' ---------------------------------------------------------------------------------------
        ' SUB TO COUNT AND ORDER CONSTRUCTS FOR PRESENTATION
        ' ---------------------------------------------------------------------------------------


        ' ---------------------------------------------------------------------------------------
        ' MODULE SCOPE VARIABLES USED (AND REALLOCATED) HERE
        ' ---------------------------------------------------------------------------------------*/

    // Npool              'total number of items in pool (across all constructs)
    // Nconstructs        'number of constructs represented by stimuli
    // strCC()            '1-d array of construct codes as strings
    this.intCC = Array(this.NPool); // 1-d array: unique numerical construct code -- assigned here

    const thisRef = this;
    let i;
    let j;
    let n;
    let nc;
    let UsedFlag;
    let rnum;
    const strTemp = Array(this.NPool); // temporary array for holding character construct codes
    const intTemp = Array(this.NPool); // temporary array for holding character construct codes

    /* '-------------------------------------------------------------------------------
        'Count constructs and assign result to module scope variable, Nconstructs
        'Construct numbers assigned in order first corresponding statement is found in database
        '-------------------------------------------------------------------------------*/

    nc = 0;
    for (i = 1; i <= this.NPool; i++) {
      UsedFlag = false;
      for (j = i - 1; j >= 1; j--) {
        if (this.strCC[i] === this.strCC[j]) {
          UsedFlag = true;
          break;
        }
      }

      /* 'If the code is new, store the character value in a temporary array that will
				'be eventually stored in ControlTestAdmin(,) */

      if (!UsedFlag) {
        // Assign a unique, sequential number to represent the construct code
        nc++;
        this.intCC[i] = nc;
        // If the construct is unique, store the character and numerical codes in temporary arrays.
        // These values will be copied to the appropriate cells of ControlTestAdmin(,) below
        strTemp[nc] = this.strCC[i];
        intTemp[nc] = this.intCC[i];
      } else {
        this.intCC[i] = this.intCC[j];
      }
    }

    this.NConstructs = nc;
    this.intCCorder = new Array(this.NConstructs);

    // Randomize() ' Initialize random-number generator using seed from clock.

    // //Function called repeatedly in loop above//////////////////////////////////////////////////////////////////
    const Draw = function Draw() {
      // rnum = CInt(Int((NConstructs * Rnd()) + 1)) //Generate random value between 1 and Nconstructs.
      rnum = thisRef.getRandomNumber(1, thisRef.NConstructs);
      UsedFlag = false;
      for (let j = n - 1; j >= 1; j--) {
        if (thisRef.intCCorder[j] === rnum) {
          UsedFlag = true;
          break;
        }
      }
    };
    // ////////////////////////////////////////////////////////////////////////////////////////////////////////////

    for (n = 1; i <= this.NConstructs; n++) {
      UsedFlag = true;

      while (UsedFlag) {
        Draw();
      }

      this.intCCorder[n] = rnum; // Assign the random order to array ControlTestAdmin
    }

    if (this.NConstructs === 1) this.intCCorder[1] = 1;
  }

  initializeRespondentDataArrays() {
    /* ' ----------------------------------------------------------------------------------
				' MODULE SCOPE VARIABLES AND ARRAYS USED AND REALLOCATED DYNAMICALLY HERE
				' ----------------------------------------------------------------------------------
				'   NPool   : number of items in pool
				'   Nitems  : number of items to administer (for now, required to be less than NPool) */

    this.U = []; // 1-d array: response vector for current respondent
    this.EAPTrace = [];
    this.UsedItemID = []; // 1-d array: item ids read from input file
    this.UsedItemIndex = []; // 1-d array: item index (consecutive position in pool) values
    this.Availability = []; // 1-d array: item availability for test; true if available; false otherwise

    /* ' ----------------------------------------------------------------------------------
				' LOCAL DECLARATIONS
				' ----------------------------------------------------------------------------------*/
    let i;

    for (i = 1; i <= this.NPool; i++) {
      // simplified to 1 on 5-10-03 for dichotomous models
      this.Availability[i] = true;
    }
  }

  createLookUpTables() {
    let h;

    /* ' ----------------------------------------------------------------------------------
				' CREATE LOOKUP TABLES FOR IRF/IIF VALUES on [-4,+4] USED FOR ITEM SELECTION
				' SAVE RESULTS IN MODULE SCOPE ARRAYS IRF & IIF
				' ----------------------------------------------------------------------------------*/

    // SET LOWER BOUND AND STEP VALUE FOR TABLE; COMPUTE TOTAL NUMBER OF THETA POINTS
    this.LowTheta = -4.0;
    this.StepTheta = 0.01;
    this.Ntheta = (2 * Math.abs(this.LowTheta)) / this.StepTheta + 1;

    this.computeIRFsAndIIFs(
      this.NPool,
      this.Ntheta,
      this.LowTheta,
      this.StepTheta
    ); // the 3 arguments are "optional", but needed for LookUpTable

    // SAVE VALUES FOR ALL THETA POINTS IN "ZERO row/col" OF LOOKUP TABLE.
    // Specifically, save thetas, now found in array elements THETA(1 to Ntheta)
    // in IIF(i=0, h=1 to Ntheta) and ORF (i=0, k=0, h=1 to Ntheta)

    this.IIF[0] = Array(this.Ntheta);
    this.IRF[0] = Array(this.Ntheta);

    for (h = 1; h <= this.Ntheta; h++) {
      this.IIF[0][h] = Math.round(this.Theta[h] * 100) / 100; // Round(this.Theta[h], 2)  // 2 corresponds to number of decimal places for steptheta (.01)
      this.IRF[0][h] = Math.round(this.Theta[h] * 100) / 100; // Round(this.Theta[h], 2)
    }

    /* ' ----------------------------------------------------------------------------------
				' CREATE LOOKUP TABLE FOR QUAD NODES on [-3,+3] AND PROB OF POSITIVE RESPONSE AT NODE VALUES
				' AND SAVE IN MODULE SCOPE ARRAY Pikh.  These will be used by EAP routine.
				' Note, prob. for both nonendorsed and endorsed options are needed, even for dichotomous models
				' ----------------------------------------------------------------------------------*/
    this.getQuadValuesForEAP(); // returns nodes for standard normal, so scale if necessary
    // Scale quad values for EAP: not necessary here because using N(0,1) on [-3,+3]
  }

  computeIRFsAndIIFs(NI, Nt, Tlow, Tstep) {
    /* ' -------------------------------------------------------------------------------------
				' MODULE SCOPE VARIABLES USED (AND ALLOCATED DYNAMICALLY) HERE
				' -------------------------------------------------------------------------------------*/

    this.Theta = Array(Nt);
    this.IRF = Array(NI);
    this.TCC = Array(Nt);
    this.IIF = Array(NI);
    this.TIF = Array(Nt);
    this.SE = Array(Nt);

    /* ' -------------------------------------------------------------------------------------
				' LOCAL VARIABLE DECLARATIONS
				' -------------------------------------------------------------------------------------*/

    let Ptheta; // probability of positive response at theta value
    let i; // index for items
    let h; // index for discrete theta values
    let t; // current value of theta

    t = Tlow;
    for (h = 1; h <= Nt; h++) {
      this.Theta[h] = t;
      t += Tstep;
      this.TIF[h] = 0;
      this.TCC[h] = 0;
    }

    /* ' -------------------------------------------------------------------------------------
				' COMPUTE  IRFS, IIFS, AND TIF
				' -------------------------------------------------------------------------------------*/
    for (i = 1; i <= NI; i++) {
      this.IRF[i] = Array(Nt);
      this.IIF[i] = Array(Nt);

      for (h = 1; h <= Nt; h++) {
        // Compute the probability of a positive response. Save in module scope array IRF
        Ptheta = this.computeProbPositiveResponse(this.Theta[h], i);
        this.IRF[i][h] = Ptheta;

        // Add value to TCC
        this.TCC[h] = this.IRF[i][h] + this.TCC[h];

        // Compute IIF value and save in module scope array
        // var IIFVal:Number = (1.702 * this.A[i]) ^ 2 * (1 - Ptheta) * (Ptheta - this.C[i]) ^ 2 / ((1 - this.C[i]) ^ 2 * Ptheta);
        const IIFVal =
          (this.square(1.702 * this.A[i]) *
            (1 - Ptheta) *
            this.square(Ptheta - this.C[i])) /
          (this.square(1 - this.C[i]) * Ptheta);

        this.IIF[i][h] = IIFVal;

        // Add value to TIF.
        this.TIF[h] = this.IIF[i][h] + this.TIF[h];
      }
    }

    for (h = 1; h <= Nt; h++) {
      this.TCC[h] = this.TCC[h] / NI;
    }

    /* ' -------------------------------------------------------------------------------------
				' COMPUTE THE SE(THETA) FUNCTION -- 1/SQRT(TIF)
				' -------------------------------------------------------------------------------------*/
    for (h = 1; h <= Nt; h++) {
      if (this.TIF[h] <= 0.0000000001) this.TIF[h] = 0.0000000001;

      this.SE[h] = 1 / Math.sqrt(this.TIF[h]);
    }
  }

  computeProbPositiveResponse(t, i) {
    let P;
    // Compute the probability of a positive response
    P = 1 / (1 + Math.exp(-1.702 * this.A[i] * (t - this.B[i])));
    P = Number(this.C[i]) + Number(1 - this.C[i]) * P;

    return P;
  }

  square(x) {
    return x * x;
  }

  getQuadValuesForEAP() {
    this.AXh = Array(this.Nq); // 1-D DYNAMIC ARRAY OF QUADRATURE WEIGHTS, COMPUTED AT NODES Xh(H)
    this.Xh = Array(this.Nq); // 1-D DYNAMIC ARRAY OF QUADRATURE NODES, midpoints of intervals
    this.Pikh = Array(this.NPool); // , 1, this.Nq); //3-D DYNAMIC ARRAY OF PROB. OF ENDORSING ITEM I, OPTION K, AT QUADPT H

    let SUMY;
    let SUMA;
    let SUMXA;
    let SUMXSQRA;
    let TMIN;
    let TMAX;
    const TMAXMID = 3;
    const TMINMID = -3;
    const PI = 3.14159265359;
    const Yh = Array(this.Nq);
    let WIDTH;

    let h;
    let i;
    let k;
    let Ptemp;

    for (i = 1; i <= this.NPool; i++) {
      this.Pikh[i] = Array(2);
      this.Pikh[i][0] = Array(this.Nq);
      this.Pikh[i][1] = Array(this.Nq);
    }

    WIDTH = (TMAXMID - TMINMID) / (this.Nq - 1);

    // Get the minimum value of std normal scale to start locating midpoints
    TMIN = TMINMID - WIDTH / 2;
    TMAX = TMAXMID + WIDTH / 2; // not needed for calculations

    // Get the quadrature nodes (midpoints) and the ordinates at nodes
    for (h = 1; h <= this.Nq; h++) {
      this.Xh[h] = Number(TMIN) + Number(WIDTH) * (h - 0.5);
      // Yh[h] = (1/Math.sqrt(2 * PI)) * Math.exp(-0.5 * this.Xh[h] ^ 2)
      Yh[h] =
        (1 / Math.sqrt(2 * PI)) * Math.exp(-0.5 * this.square(this.Xh[h]));
    }

    // Get the sum of the ordinate values
    SUMY = 0;
    for (h = 1; h <= this.Nq; h++) {
      SUMY = Number(Yh[h]) + Number(SUMY);
    }

    // Get the quadrature weights at the nodes
    for (h = 1; h <= this.Nq; h++) {
      this.AXh[h] = Yh[h] / SUMY;
    }

    // Perform the necessary checks
    SUMA = 0;
    SUMXA = 0;
    SUMXSQRA = 0;
    for (h = 1; h <= this.Nq; h++) {
      SUMA = Number(SUMA) + Number(this.AXh[h]);
      SUMXA = Number(SUMXA) + Number(this.Xh[h]) * this.AXh[h];
      // SUMXSQRA = Number(SUMXSQRA) + this.AXh[h] * this.Xh[h] ^ 2;
      SUMXSQRA = Number(SUMXSQRA) + this.AXh[h] * this.square(this.Xh[h]);
    }

    for (i = 1; i <= this.NPool; i++) {
      for (h = 1; h <= this.Nq; h++) {
        for (
          k = 0;
          k <= 1;
          k++ // simplified to 1 on 5-10-03 for dichotomous models
        ) {
          // Compute option response probabilities at quad point and save in array for EAP estimation
          // Note changed needed for use with dichotomous model that gives only positive response prob.

          Ptemp = this.computeProbPositiveResponse(this.Xh[h], i);
          if (k === 1) {
            this.Pikh[i][k][h] = Ptemp;
          } else {
            this.Pikh[i][k][h] = 1 - Ptemp;
          }
        }
      }
    }
  }

  addItems() {
    let iNumber = 0;

    for (
      let i = 1;
      i < Object.keys(this.currentQuestionnaire.questions).length;
      i++
    ) {
      iNumber++;

      const currentQuestion = this.currentQuestionnaire.questions[i];

      // TODO - are these relevant
      // /////////////////////////////
      this.strGLC[iNumber] = "TEST";
      this.strCC[iNumber] = "MAIN";
      this.ID[iNumber] = `Q${iNumber}`;
      // Content(iNumber) = Stimuli.Content
      // ///////////////////////////////////

      // We have our own content engine so no need for adding content

      this.A[iNumber] = Number(currentQuestion.a);
      this.B[iNumber] = Number(currentQuestion.b);
      this.C[iNumber] = Number(currentQuestion.c);

      const correctResponseIndex = Number(currentQuestion.correctresponseindex);

      // Populate the Scoring Values
      // This array is Zero Based!
      // We populate all 4 Keys regardless what the NCATi value is
      this.Key[iNumber] = Array(this.NCAT);

      for (let j = 0; j < this.NCAT; j++) {
        this.Key[iNumber][0] = 0;

        if (j + 1 !== correctResponseIndex) {
          this.Key[iNumber][j + 1] = 0;
        } else {
          this.Key[iNumber][j + 1] = 1;
        }
      }
    }
  }

  recoverAdaptiveTest(itemsUsed, itemResponses) {
    for (let i = 1; i < itemsUsed.length; i++) {
      if (itemsUsed[i].qid.length > 0) {
        const currentUsedItemIndex = this.getQuestionIndex(itemsUsed[i].qid);
        const currentResponseIndex = itemResponses[i];

        this.Availability[currentUsedItemIndex] = false;
        this.UsedItemIndex[i] = currentUsedItemIndex;

        this.ScoredItemResponse = this.Key[currentUsedItemIndex][
          currentResponseIndex
        ];

        this.U[i] = this.ScoredItemResponse;
      }
    }

    this.ItemNumber = itemsUsed.length - 1;
    this.U[0] = this.ItemNumber - 1;
  }

  getQuestionIndex(qid) {
    const currentQ = this.currentQuestionnaire;

    for (let i = 1; i <= Object.keys(currentQ.questions).length; i++) {
      if (
        this.currentQuestionnaire.questions[i].qid.toLowerCase() ===
        qid.toLowerCase()
      ) {
        return i;
      }
    }

    return -1;
  }

  processQuestionResponse(ResponseIndex) {
    // Determine the ScoredItemResponse
    const selectedItemId = this.SelectedItemIndex;
    this.ScoredItemResponse = this.Key[selectedItemId][ResponseIndex];

    // Increment the number of items successfully administered
    if (this.U[0] === undefined) {
      this.U[0] = 0;
    }

    if (this.EAPTrace[0] === undefined) {
      this.EAPTrace[0] = 0;
    }

    this.U[0] = this.U[0] + 1;

    // Save value next in element of Private module-scope array U_ni().
    this.U[this.ItemNumber] = this.ScoredItemResponse;

    // CALL ROUTINE TO CALCULATE RESPONDENTS SCORE BASED ON ITEMS ANSWERED SO FAR.
    this.getEAPandPSD(this.ItemNumber);

    this.EAPTrace[this.ItemNumber] = this.EAP;

    // Are we Finsihed?
    if (this.ItemNumber === this.Nitems) {
      this.Completed = true;

      // Start the Scoring Code
      this.scoreExam();
    } else {
      // Increment the item number
      this.ItemNumber += 1;
    }
  }

  getEAPandPSD(NIadmin) {
    /* ' -------------------------------------------------------------------------------------
				' LOCAL VARIABLE DECLARATIONS
				' -------------------------------------------------------------------------------------*/

    let I;
    let K;
    let H; // indexes for items, options, quadpts
    const LHSTART = 1.0e300; // KLUGE USED TO PREVENT UNDERFLOW FOR LONG RESPONSE PATTERNS
    let MARGINAL; // marginal likelihood value
    let TERM; // used to calculate EAP and PSD values for person j
    let NumerSum;
    let LHI; // cond liklihd of response,L(u=1|Xh), itemI,quadptH
    let LHIPROD; // use to compute product of ORF probabilities over items, quadpt H
    const LH = Array(this.Nq); // cond likelihood of response pattern,L(U|Xh) at quadpt H
    const JOINTh = Array(this.Nq); // joint prob at quad point H; values now saved in array
    let SUM; // used to sum joint density values to obtain marginal
    let Ptemp; // used to screen out perfect 0,1 probabilities

    // Compute the conditional likelihood of response pattern, L(U|Xh).
    for (H = 1; H <= this.Nq; H++) {
      LHIPROD = LHSTART; // can start with huge value to prevent underflow

      /* '-------------------------------------------------------------------------------------
						'MODIFICATION FOR CAT CONTEXT: LOOP ONLY OVER ITEMS ADMINISTERED SO FAR
						'-------------------------------------------------------------------------------------*/

      for (I = 1; I <= NIadmin; I++) {
        // MADE TO LOOP OVER NUMBER OF ITEMS ADMINISTERED ON 5-10-03

        /* 'Get log liklihd of examinee's response on item I, quad pt H
							'Care must be taken to prevent MISSING DATA coded as
							'something other than 0,1 from affecting the likelihood. */

        if (this.U[I] === 9) {
          // CODE FOR MISSING; SET P=1 SO LIKELIHOOD NOT AFFECTED
          LHI = 1;
          Ptemp = 1;
        } else if (this.U[I] < 0 || this.U[I] > 1) {
          // CODE INVALID; SAME IDEA 'modified 5-9-03 for dichotomous model
          LHI = 1;
          Ptemp = 1;
        } else {
          // HAVE VALID RESPONSE, GET ORF VALUE FOR ENDORSED CATEGORY.
          // "CORRECT" FOR PERFECT 1 OR 0 PROBABILITIES
          if (this.Pikh[this.UsedItemIndex[I]][this.U[I]][H] > 0.999999)
            // modification for CAT
            Ptemp = 0.999999;
          // used only items administered
          else if (this.Pikh[this.UsedItemIndex[I]][this.U[I]][H] < 0.000001)
            // in order they were administered
            Ptemp = 0.000001;
          else Ptemp = this.Pikh[this.UsedItemIndex[I]][this.U[I]][H];

          LHI = Ptemp;
        }

        LHIPROD *= LHI;
      } // I

      // Result after loop is likelihood of response patterns across items and
      // tests at quad pt H
      LH[H] = LHIPROD; // save in array; this is cond likelihood at quad pt H
    } // H

    MARGINAL = 0.0;
    SUM = 0.0;

    //--------------------------------------------------------------------------------------
    TERM = 0.0;
    NumerSum = 0.0; // added for EAP statistics
    this.EAP = 0.0;
    //--------------------------------------------------------------------------------------

    // COMPUTE THE MARGINAL LIKELIHOOD, the denominator of the EAP and PSD statistics.

    for (H = 1; H <= this.Nq; H++) {
      JOINTh[H] = LH[H] * this.AXh[H]; // joint density at quad pt Xh, indexed H
      SUM += JOINTh[H];

      /* '-------------------------------------------------------------------------------
						'Additional line of code added for EAP estimation -- numerator term
						'COMPUTE THE NUMERATOR OF THE EAP STATISTIC: Sum (H=1,Q): {Xh*L(Xh)*A(Xh)} */

      TERM = this.Xh[H] * JOINTh[H];
      NumerSum += TERM;

      //-------------------------------------------------------------------------------
    }

    MARGINAL = SUM;

    this.EAP = NumerSum / MARGINAL; // resulting EAP statistic for person J

    //--------------------------------------------------------------------------------------
    TERM = 0.0;
    NumerSum = 0.0; // added for PSD statistics
    this.PSD = 0.0;
    //--------------------------------------------------------------------------------------

    // COMPUTE THE NUMERATOR OF THE PSD once EAP value is known:
    // Sum over quad points: {(EAP-Xh)^2 * JOINTh(H)}
    for (H = 1; H <= this.Nq; H++) {
      // TERM = (this.Xh[H] - this.EAP) ^ 2 * JOINTh[H]; //joint density at quad pt Xh, indexed H
      TERM = this.square(this.Xh[H] - this.EAP) * JOINTh[H];
      NumerSum += TERM;
    } // next quad point H

    this.PSD = Math.sqrt(NumerSum / MARGINAL); // resulting PSD for EAP statistic for person J
  }

  initializeArrays() {
    this.ID = [];
    this.strGLC = [];
    this.strCC = [];
    this.Content = [];
    this.A = [];
    this.B = [];
    this.C = [];
    this.Key = [];
  }

  setMiscVariablesFromSettings() {
    this.LowerPctStart = Number(this.currentQuestionnaire.lowerpctstart);
    this.UpperPctStart = Number(this.currentQuestionnaire.upperpctstart);
    this.LowerPctMaxCut = Number(this.currentQuestionnaire.lowerpctmaxcut);
    this.UpperPctMaxCut = Number(this.currentQuestionnaire.upperpctmaxcut);
    this.PctStep = Number(this.currentQuestionnaire.pctstep);
    this.MaximumNumberOfItemsPerDimensions = Number(
      this.currentQuestionnaire.maxitemsperdimension
    );
  }

  getNextQuestion() {
    // Based on the Current Value of SelectedItemIndex, grab the next question
    this.boolItemSelected = false;

    if (this.recoveryItemIDToSelect.length < 1) {
      this.selectItem();
    } else {
      this.selectItemSpecific();
    }

    return this.SelectedItemIndex;
  }

  selectItem() {
    let tval; // EAP, rounded to two decimal places (or EAP +/-.5 for first two items)
    let MaxInfo; // maximum possible information at tval
    let PctMax; // current percentage of maximum info value required

    // This has now been moved into the Exam Settings
    // var PctStep :Number;= 0.05# //step value for finding percent of maximum information at tval

    let PctMaxCut; // percent of maximum for stopping selection at tval
    let PctStart; // starting percentage of MaxInfo for selecting items
    let InfoCrit; // info criterion for choosing items = pctmax*maxinfo
    let Info; // item information value at tval
    let p; // index for referencing items in pool
    let L;
    const Temp = []; // 1-d array: save positions in total pool for items satisfying info criterion
    const AcceptableItemPositions = []; // 1-d array: save positions in pool for items satisfying infocrit and available; items drawn randomly from these
    //-------------------------------------------------------------------------------------
    // added 10-26-03 to implement selection within content areas
    const intCCselected = 1; // integer construct code currently selected
    let nc; // index for referencing items in content area
    //-------------------------------------------------------------------------------------

    // VARIABLES USED TO GENERATE RANDOM NUMBERS (FOR EXPOSURE CONTROL) (see Deitel VB.NET nc.206)
    //   Note:   You may provide a seed (any integer) to repeatedly generate the same sequence of numbers.
    //           Or, you can leave seed unspecified (ie, ()) to draw one from clock automatically.

    let rnum; // random number

    // OTHER VARIABLES
    let j; // used as extra loop index for error checking

    /*
			'***********************************************************************************
			'EXPOSURE CONTROL FOR FIRST TWO ITEMS:
			'   Look for items that provide info at tval = EAP +/- 0.5.
			'*********************************************************************************** */

    if (
      this.config.respodentIsFromSA &&
      this.ItemNumber <= 2 &&
      adaptiveTestVersions["Abstract"].indexOf(
        this.currentQuestionnaire.testId
      ) !== -1
    ) {
      rnum = this.getRandomNumber(-151, -111) / 100; // randomObject.NextDouble
      tval = this.EAP + rnum;
    } else if (
      this.config.respodentIsFromSA &&
      this.ItemNumber <= 2 &&
      adaptiveTestVersions["Numerical"].indexOf(
        this.currentQuestionnaire.testId
      ) !== -1
    ) {
      rnum = this.getRandomNumber(-142, -102) / 100; // randomObject.NextDouble
      tval = this.EAP + rnum;
    } else if (
      this.config.respodentIsFromSA &&
      this.ItemNumber <= 2 &&
      adaptiveTestVersions["Verbal"].indexOf(
        this.currentQuestionnaire.testId
      ) !== -1
    ) {
      rnum = this.getRandomNumber(-116, -76) / 100; // randomObject.NextDouble
      tval = this.EAP + rnum;
    } else if (this.ItemNumber <= 2) {
      rnum = this.getRandomNumber(-50, 50) / 100; // randomObject.NextDouble - 0.5#;   //scale so -0.5 < rnum < 0.5
      tval = this.EAP + rnum;
    } else tval = this.EAP;

    MaxInfo = 0;
    nc = 0; // count number of available items in particular content area
    p = 0; // count number of available items in pool, regardless of content area

    // TODO - remove
    // if(this.ItemNumber == 1)
    // tval = 0.1925;

    for (L = 1; L <= this.NPool; L++) {
      // If item is available, get the information value from the lookup table.
      // Save index in temporary array.
      if (this.Availability[L] === true) {
        p++;
      }

      if (this.Availability[L] === true && this.intCC[L] === intCCselected) {
        // Get IIF value from lookup table.
        // Check whether item provided max info of those examined so far.
        // If so, save the info and index values in MaxInfo and MaxInfoIndex
        Info = this.getIIFvalueFromLookUpTable(L, tval); // 09-18-07: modified to use routine that only returns ItemInfo
        if (Info > MaxInfo) {
          MaxInfo = Info;
        }
        // trace(nc+":"+Info);

        // Increment counter for available items.  Save index in array Temp()
        nc++;
        Temp[nc] = L;
      }
    }

    /* '***********************************************************************************
				'EARLY TERMINATION:
				'Terminate item selection immediately if no items of any kind are available (p=0).
				'If (p<>0), but nc=0, then select a new content area.
				'boolItemSelected flag will stay false and test administration will terminate for this simulee.
				'Otherwise continue with item selection below, using exposure control when possible.
				'*********************************************************************************** */
    if (p === 0) return;
    // else if (nc == 0)
    // GoTo PickCC;

    /* '***********************************************************************************
			'AT LEAST ONE AVAILABLE ITEM WAS FOUND, SO...
			'   EXPOSURE CONTROL:
			'   Vary starting and final information criteria for first three items
			'*********************************************************************************** */
    // Vary the starting percentage depending on item number.
    if (this.ItemNumber <= this.min(3, this.Nitems * 0.2)) {
      // 09-18-07: use ItemNumber now
      PctStart = this.LowerPctStart;
      PctMaxCut = this.LowerPctMaxCut;
    } else {
      PctStart = this.UpperPctStart;
      PctMaxCut = this.UpperPctMaxCut; // changed from .45 on 10-26-03 (increase if info is more impt than exposure control)
    }
    PctMax = PctStart;
    InfoCrit = PctMax * MaxInfo;

    /* '***********************************************************************************
			'BEGIN LOOP FOR SELECTING ITEMS: Exposure control used when number in pool permits
			'*********************************************************************************** */

    while (PctMax > PctMaxCut && this.boolItemSelected === false) {
      nc = 0;
      for (L = 1; L < Temp.length; L++) {
        Info = this.getIIFvalueFromLookUpTable(Temp[L], tval);

        // Note: InfoCrit = PctMax * information value for most informative available item
        if (Info > InfoCrit) {
          // no need to check availability, because checking only available items
          nc += 1;
          AcceptableItemPositions[nc] = Temp[L];
          // trace(AcceptableItemPositions[nc]+"/"+L);
        }
      }

      /* '-----------------------------------------------------------------------------------------------
							'Administer an item that will satisfy the current info criterion, if either:
							'1) 3 or more items found
							'2) Fewer than 3 found, but info criterion is at its minimum acceptable value
							'-----------------------------------------------------------------------------------------------*/
      if (nc >= 3 || PctMax - PctMaxCut <= this.PctStep) {
        // At least one acceptable item was found
        rnum = this.getRandomNumber(1, nc); // randomObject.Next(1, nc + 1)

        // TODO Remove
        // rnum=1

        // Get the subscripts for the selected stimuli
        this.SelectedItemIndex = AcceptableItemPositions[rnum];

        // trace(this.SelectedItemIndex);

        // Update module scope arrays to track used items
        this.UsedItemIndex[this.ItemNumber] = this.SelectedItemIndex; // 09-18-07:  modified to use ItemNumber
        this.Availability[this.SelectedItemIndex] = false;
        this.boolItemSelected = true;
      } else {
        /*
								'-----------------------------------------------------------------------------------------------
								'Fewer than 3 items were found satisfying info criterion, but pctmax is still above
								'the minimum allowed.  So reduce the info crit and search again
								'-----------------------------------------------------------------------------------------------                PctMax = PctMax - PctStep */

        PctMax -= this.PctStep;
        InfoCrit = PctMax * MaxInfo;
      }
    } // Reevaluate info criterion and value of boolItemSelected flag
  }

  min(number1, number2) {
    return number1 < number2 ? number1 : number2;
  }

  getIIFvalueFromLookUpTable(ItemIndex, ThetaVal) {
    // Round theta value to same number of decimal places as used in lookup tables
    ThetaVal = parseInt(ThetaVal * 100) / 100;

    for (let h = 1; h <= this.Ntheta; h++) {
      // simplified to 1 on 5-10-03 for dichotomous models
      if (ThetaVal === this.IIF[0][h]) {
        // find index of current thetaval for respondent
        return this.IIF[ItemIndex][h]; // look up corresponding IIF value
      }
    }

    return 0;
  }

  getRandomNumber(startVal, endVal) {
    return Math.floor(Math.random() * (endVal - startVal)) + startVal;
  }

  selectItemSpecific() {
    this.recoveryItemIDToSelect = "";
    this.SelectedItemIndex = this.UsedItemIndex[this.ItemNumber];
    this.boolItemSelected = true;
  }
}
