import { CAdaptiveTestNew } from "./CAdaptiveTestNew";

export class QuestionnaireEngine {
  constructor(questionnaire, config) {
    this.questionnaire = questionnaire;
    this.config = config;
  }

  prepareQuestionnaireForStartup(EAPpriorMean, recoveryItemIDToSelect) {
    this.currentQuestionnaire = new CAdaptiveTestNew(
      EAPpriorMean,
      recoveryItemIDToSelect
    );
    this.currentQuestionnaire.initialize(this.questionnaire, this.config);
  }
}
