import {
  FETCH_QUESTIONNAIRE_CONFIG,
  FETCH_QUESTIONNAIRE_CONFIG_ERROR,
  FETCH_QUESTIONNAIRE_CONFIG_SUCCESS,
} from '../actions/testDetailsActions';

export function testDetailsReducer(
  state = {
    isFetching: true,
    isError: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_QUESTIONNAIRE_CONFIG:
      return { ...state, isFetching: true };
    case FETCH_QUESTIONNAIRE_CONFIG_ERROR:
      return { ...state, isFetching: false, isError: true };
    case FETCH_QUESTIONNAIRE_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        items: action.payload.items,
        instructions: action.payload.instructions,
        config: {
          ...(action.payload.isImageMatching && { isImageMatching: true }),
          ...(action.payload.isSJT && { isSJT: true }),
          ...(action.payload.is360 && { is360: true }),
          ...(action.payload.isWAI && { isWAI: true }),
          ...(action.payload.isMEX && { isMEX: true }),
          ...(action.payload.isAdaptive && {
            isAdaptive: true,
            numberofquestions: action.payload.numberOfQuestions,
            numberofresponses: action.payload.numberOfResponses,
            lowerpctstart: action.payload.lowerpctstart,
            upperpctstart: action.payload.upperpctstart,
            lowerpctmaxcut: action.payload.lowerpctmaxcut,
            upperpctmaxcut: action.payload.upperpctmaxcut,
            pctstep: action.payload.pctstep,
            maxitemsperdimension: action.payload.maxitemsperdimension,
          }),
          ...(action.payload.isItemBanked && {
            isItemBanked: true,
            itemsingroups: action.payload.itemsingroups,
          }),
          ...(action.payload.isResponseRequired && {
            isResponseRequired: true,
          }),
          ...(action.payload.isAbstract && { isAbstract: true }),
          showQuestionCounter: action.payload.showQuestionCounter,
          showQuestionProgressCounter: action.payload.showQuestionProgressCounter,
          videoURLS: action.payload.videoURLS,
          numberOfResponses: action.payload.numberOfResponses,
          timeAllowed: action.payload.timeAllowed,
          type: action.payload.type,
          svgUrls: action.payload.isImageMatching && action.payload.items.map((question) => question.template.values.images),
          testDetails: action.payload.test
        },
      };
    default:
      return state;
  }
}