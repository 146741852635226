import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { recognizeRespondent } from '../../../../store/actions/questionnaireDetailsActions';
import { administeredSessionRespondentStatuses, INVITED, NOT_INVITED, AUTHORIZED } from './statuses.util';

import { Spinner } from '../../Spinner';
import { ContentHolder, Container, LogoWrapper, Title } from '../../imported-from-admin/styles';
import { FormikInput } from '../../imported-from-admin/FormikInput';
import { CustomButton } from '../../imported-from-admin/Button';
import { ReactComponent as Logo } from '../../../../assets/images/logo.svg';
import { errorMessages } from '../../constants/errorMessages';

const createValidationSchema = (errorMessages) =>
  Yup.object().shape(
    {
      email: Yup.string().trim().required(errorMessages.inputField).email(errorMessages.emailField),
    },
    ['email'],
  );

export const UserRecognitionForm = ({ goToRespondentForm, goToPending, onError, setEmail }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setLoadingState] = useState(false);

  const {
    managedGroup: {
      sessionData: { accountID, userID, accessCode },
    },
  } = useSelector((state) => state.questionnaireDetails);

  const validationSchema = createValidationSchema(errorMessages(t));

  const { values, errors, setErrors, handleSubmit, isSubmitting, handleChange, touched } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const data = { accountId: accountID, userId: userID, ...values };
      setLoadingState(true);
      dispatch(
        recognizeRespondent(data, accessCode, (e, statusId) => {
          setLoadingState(false);
          const status = administeredSessionRespondentStatuses[Number(statusId)];
          setEmail(values.email);
          if (!e) {
            if (status === NOT_INVITED) goToRespondentForm();
            if (status === INVITED || status === AUTHORIZED) goToPending();
          } else {
            onError();
          }
        }),
      );
    },
  });

  const onInputChange = (props) => {
    setErrors({});
    handleChange(props);
  };

  return (
    <Container withBackground>
      {isLoading && <Spinner isFullScreen />}
      <StyledContentHolder>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Title>{t('confirmsessiondetails')}</Title>
        <Description>{t('enterEmail')}</Description>
        <form>
          <StyledInput
            inputName={t('Email address')}
            name="email"
            errors={errors}
            touched={touched}
            id="email"
            value={values.email}
            onChange={onInputChange}
            disabled={Boolean(values.respondentId)}
          />
          <StyledButton onClick={handleSubmit} disabled={isSubmitting || isLoading}>
            {t('continue')}
          </StyledButton>
        </form>
      </StyledContentHolder>
    </Container>
  );
};

UserRecognitionForm.propTypes = {
  goToRespondentForm: func.isRequired,
  goToPending: func.isRequired,
  onError: func.isRequired,
  setEmail: func.isRequired,
};

const StyledContentHolder = styled(ContentHolder)`
  height: auto;
`;

const Description = styled.p`
  color: ${(props) => props.theme.colors.darkBlue};
  margin: 0 0 2.4rem 0;
`;

const StyledInput = styled(FormikInput)`
  width: 100%;
`;

const StyledButton = styled(CustomButton)`
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.regular};
  margin: 3.2rem 0 0 0;
`;
