import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as workerTimers from "worker-timers";
import { saveSessions } from "../../store/actions/questionnaireDetailsActions";

export function useSaveSessionTimer({
  isUserReturnScreenVisible,
  isResultSubmitted,
  is360,
  timeout = 10000,
}) {
  const [sessionTimerId, setSessionTimerId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUserReturnScreenVisible && !isResultSubmitted) {
      const intervalId = workerTimers.setInterval(() => {
        dispatch(saveSessions(is360));
      }, timeout);

      setSessionTimerId(intervalId);
    }

    return () => {
      if (sessionTimerId) {
        workerTimers.clearInterval(sessionTimerId);
      }
    };
  }, [isUserReturnScreenVisible, isResultSubmitted]);

  const clearSaveSessionTimer = useCallback(() => {
    workerTimers.clearInterval(sessionTimerId);
  }, [sessionTimerId]);

  return { clearSaveSessionTimer };
}
