import React from "react";
import { useTranslation } from "react-i18next";
import {
  QuestionTextStyled,
  FeedbackStyled,
  FullHeight,
} from "./shared/components";
import { CardsList } from "../shared/CardsList";
import { orderedViewReversed } from "../../utils/orderedViewReversed";

export const DefaultItem = ({
  values: { mainitem, feedback, responses },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  className,
  testId,
  is360,
  details,
}) => {
  const testClass = testId.toLowerCase();
  const { i18n } = useTranslation();

  const question = is360
    ? `${orderedViewReversed(i18n.language, [
        details.selfFirstName,
        details.selfFamilyName,
      ]).join(" ")} ${mainitem}`
    : mainitem;

  return (
    <>
      <FullHeight>
        <QuestionTextStyled
          dangerouslySetInnerHTML={{
            __html: question,
          }}
          className={className}
        />
        {!!feedback && (
          <FeedbackStyled
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </FullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className="for-text"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
