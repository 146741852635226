import React from 'react';
import styled from 'styled-components';
import { FullHeight } from '../shared/components';
import { MazeCardsList } from './MazeCardList';
import Images from '../images/vac';

const TemplateStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: 100%;

  @media (orientation: landscape) and (max-height: ${({ theme }) => theme.viewports.xs}) {
    height: 100%;
  }
`;

const HolderStyled = styled.div`
  display: flex;
  justify-content: center;
  max-height: 500px;
  height: 100%;
  width: 100%;

  svg {
    height: 100%;
    max-width: 100%;
    flex: 1;
  }
`;

export const MazeQuestion = ({
  values: { responses, image, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
}) => {
  const ImageComponent = Images[image];
  const FeedbackComponent = feedback ? Images[feedback] : null;

  return (
    <>
      <FullHeight>
        <TemplateStyled>
          <HolderStyled>
            <MazeCardsList
              isAbstract={config.isAbstract}
              items={responses}
              selectedResponse={selectedResponse}
              onResponse={onResponse}
              config={config}
              isBlockModalVisible={isBlockModalVisible}
              isHelpVisible={isHelpVisible}
              isSidePanelOpen={isSidePanelOpen}
            />
            <ImageComponent preserveAspectRatio="xMinYMin" />
          </HolderStyled>
        </TemplateStyled>
        {!!feedback && <FeedbackComponent style={{ minHeight: '160px' }} />}
      </FullHeight>
    </>
  );
};
