import React from "react";
import { useTranslation } from "react-i18next";
import { SliderIcon } from "./icons";
import { KeysHelpHolder } from "./KeysHelpHolder";

export function SliderBar({ ...props }) {
    const { t } = useTranslation();
    return (
        <KeysHelpHolder
            desktop
            title={t("1. Select or change your answers")}
            subtitle={t("By using the mouse and the slider bar")}
            {...props}
        >
            <SliderIcon />
        </KeysHelpHolder>
    );
}