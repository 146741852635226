import React, { useState } from 'react';
import { func, oneOfType, instanceOf, string, bool, object } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import { format } from 'date-fns';
import * as allLocales from 'react-date-range/dist/locale';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-icon.svg';
import { CustomInput } from './Input';

const localLocalesMap = {
  ua: 'uk',
};

const DateSelect = ({ className, onDateChange, selectedDate, label, error, isBottom, calendarProps }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const formattedDate = selectedDate ? format(selectedDate, 'dd/M/y') : '';
  const [isCalendarVisible, setCalendarVisibilityStatus] = useState(false);
  const dateChangeHandler = (newDate) => {
    onDateChange(newDate);
    setCalendarVisibilityStatus(false);
  };
  const toggleCalendar = () => setCalendarVisibilityStatus((prev) => !prev);
  return (
    <OutsideClickHandler onOutsideClick={() => setCalendarVisibilityStatus(false)} style={{ width: '100%' }}>
      <OuterContainer className={className}>
        <InputsContainer>
          <CalendarWrapper>
            <Icon />
            <StyledInput
              value={formattedDate}
              onContainerClick={toggleCalendar}
              isCalendarVisible={isCalendarVisible}
              inputName={label}
              error={error}
            />
            {isCalendarVisible && (
              <StyledCalendar
                locale={allLocales[localLocalesMap[currentLocale] || currentLocale]}
                isBottom={isBottom}
                date={selectedDate}
                onChange={dateChangeHandler}
                weekdayDisplayFormat="EEEEE"
                {...calendarProps}
              />
            )}
          </CalendarWrapper>
        </InputsContainer>
        {isCalendarVisible && <BgDrop onClick={() => setCalendarVisibilityStatus(false)} />}
      </OuterContainer>
    </OutsideClickHandler>
  );
};

DateSelect.propTypes = {
  onDateChange: func.isRequired,
  selectedDate: oneOfType([string, instanceOf(Date)]),
  label: string.isRequired,
  className: string,
  error: string,
  isBottom: bool,
  calendarProps: object,
};

DateSelect.defaultProps = {
  selectedDate: null,
  className: '',
  error: '',
  isBottom: false,
  calendarProps: {},
};
const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CalendarWrapper = styled.div`
  position: relative;
`;

const Icon = styled(CalendarIcon)`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledCalendar = styled(Calendar)`
  position: absolute;
  left: ${(props) => (props.isBottom ? '2rem' : 0)};
  top: ${(props) => (props.isBottom ? '3.9rem' : '-30.5rem')};
  border: 1px solid #cccccc;
  border-radius: 0.6rem;
  box-shadow: ${(props) => props.theme.shadows.default};
  width: auto;
  min-width: 15.625rem;
  z-index: 9;

  & .rdrNextPrevButton {
    margin: 0 0.3em;
    min-width: 24px;
    background-color: ${(props) => props.theme.colors.lightBlue1};
    transition: background-color 0.2s;

    :hover {
      background-color: ${(props) => props.theme.colors.lightBlue};
    }
  }

  & .rdrMonthAndYearPickers * {
    transition: background-color 0.2s;
  }

  & .rdrMonthAndYearPickers select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 6'%3E%3Cdefs/%3E%3Cpath fill='%25230E242F' fill-opacity='.368716' fill-rule='nonzero' width='9px' height='10px' d='M1.280245.2395281C.987305-.0764653.512432-.0763834.219585.2397111c-.292846.3160944-.29277.8285028.00017 1.1444963L4.498801 6l4.281444-4.6183793c.29294-.3159934.293016-.8284018.00017-1.1444963-.292847-.3160944-.76772-.3161763-1.06066-.000183L4.498801 3.7113733 1.280245.2395282z'/%3E%3C/svg%3E");
    background-size: 10px;
    background-repeat: no-repeat;
  }

  & .rdrMonth {
    box-sizing: border-box;
    width: 25rem;
    padding: 0 0.5em 1.5em 0.5em;
  }

  & .rdrMonthAndYearWrapper {
    width: 100%;
  }

  & .rdrWeekDays {
    width: auto;
  }

  & .rdrDay {
    width: 2.8em;

    &.rdrDayHovered {
      background-color: ${(props) => props.theme.colors.new.lightBlue};
    }
  }

  & .rdrMonthPicker select {
    padding: 10px 20px 10px 10px;
  }
  & .rdrMonthPicker select::-ms-expand {
    display: none;
  }

  & .rdrYearPicker select::-ms-expand {
    display: none;
  }

  & .rdrYearPicker select {
    padding: 10px 23px 10px 10px;
  }

  & .rdrNextButton {
    margin: 0 0.8em 0 0;
  }
  & .rdrPprevButton {
    margin: 0 0 0 0.8em;
  }

  & .rdrDayStartPreview.rdrDayEndPreview {
    display: none;
  }

  & .rdrDayHovered .rdrDayNumber:after {
    display: none;
  }

  & .rdrDayHovered {
    border-radius: 1.042em;
    transition: background-color 0.1s;
    background-color: ${(props) => props.theme.colors.lightBlue1};
  }

  & .rdrSelected {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const StyledInput = styled(CustomInput)`
  width: 25rem;
  margin-right: 1rem;

  input {
    margin-left: 2.5rem;
    border-radius: ${(props) => (props.isCalendarVisible ? '7px 7px 0 0' : '7px')};
  }
  span {
    padding-left: 2.5rem;
  }
  span:last-child {
    padding-left: 0;
  }
`;

const BgDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
`;

export default React.memo(DateSelect, (prev, next) => {
  return prev.selectedDate === next.selectedDate && prev.error === next.error;
});
