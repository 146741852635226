import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { object, func, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DragDropContext } from 'react-beautiful-dnd';

import initialData from './initial-data';

import Column from './components/Column';
import Stack from './components/Stack';
import RatingColumn from './components/RatingColumn';
import { ButtonNext, ButtonPrevious, Navigation } from '../../shared/Navigation';

import {
  updateMexAssessmentCurrentState,
  pushMexAssessmentHistoryItem,
} from '../../../store/actions/questionnaireDetailsActions';
import { useTimeSpentPerQuestion } from '../../../utils/hooks/useTimeSpentPerQuestion';

const STEPS = ['INITIAL-SORTING', 'UNIMPORTANT', 'IMPORTANT'];
const MIN_ITEMS_IN_COLUMN = 2;
const MAX_ITEMS_IN_COLUMN = 8;

const populateDndDataTree = (items, columnNames, activePageIndex = 0, scenarioId) => {
  const cards = {};
  items.forEach((item) => {
    cards[item.id] = item.weight ? item : { ...item, weight: 0 };
  });
  const stack = {
    ...initialData.stack,
    cardsIds: items.filter((item) => item.weight === undefined || item.weight === 0).map((item) => item.id),
  };
  const columns = {};
  columnNames.forEach((name, index) => {
    columns[`column-${index + 1}`] = {
      id: `column-${index + 1}`,
      cardsIds: items.filter((item) => item.weight === index - 1).map((item) => item.id),
      columnWeight: index - 1,
    };
  });

  return { ...initialData, cards, stack, columns, questionIndex: activePageIndex, scenarioId };
};

// IMPORTANT!!!!!!
// CARDS SCORES/WEIGHTS
// 0 - still in stack
// -1 - unimportant
// 0 - depends
// 1 - important

// COLUMNS
// first one - unimportant/negative
// second - depends/neutral
// third - important/positive

export const DndStacks = ({ values, handleNextStep, activePageIndex, page, isCountingBlocked }) => {
  const dispatch = useDispatch();
  const {
    mexAssessment: { currentDndState, history },
    timeSpentPerQuestion,
  } = useSelector((state) => state.questionnaireDetails);
  const { t } = useTranslation();
  const { cards, description, description1, instruction, sortingBoxes, sortingBoxes1, sortingBoxes2 } = values;

  const [, setResults] = useState(history);
  const [dndState, setDndState] = useState(
    currentDndState ?? populateDndDataTree(cards, sortingBoxes, activePageIndex, page.id),
  );
  const timeSpentForCurrentQuestion = timeSpentPerQuestion[activePageIndex * STEPS.length + dndState.stepIndex];
  useTimeSpentPerQuestion({
    questionIndex: activePageIndex * STEPS.length + dndState.stepIndex,
    spentTime: timeSpentForCurrentQuestion,
    isCountingBlocked,
  });

  const cardOnTopOfTheStack = dndState.stack.cardsIds.length ? dndState.cards[dndState.stack.cardsIds[0]] : null;
  useEffect(() => {
    if (!cards?.length || activePageIndex === currentDndState?.questionIndex) return;
    setDndState(populateDndDataTree(cards, sortingBoxes, activePageIndex, page.id));
  }, [activePageIndex]);

  useEffect(() => {
    dispatch(updateMexAssessmentCurrentState(dndState));
  }, [dndState]);

  // console.log('dndState', dndState);

  const returnCardToStack = (columnId, cardId) => {
    setDndState((prev) => ({
      ...prev,
      cards: { ...prev.cards, [cardId]: { ...prev.cards[cardId], weight: 0 } },
      stack: { ...prev.stack, cardsIds: [cardId, ...prev.stack.cardsIds] },
      columns: {
        ...prev.columns,
        [columnId]: {
          ...prev.columns[columnId],
          cardsIds: prev.columns[columnId].cardsIds.filter((id) => id !== cardId),
        },
      },
    }));
  };

  const takeCardFromStack = (columnId) => {
    setDndState((prev) => {
      const column = prev.columns[columnId];
      if (!prev.stack.cardsIds.length || column.cardsIds.length >= 8) return prev;
      const cardId = prev.stack.cardsIds[0];
      const newCards = {
        ...prev.cards,
        [cardId]: { ...dndState.cards[cardId], weight: prev.columns[columnId].columnWeight },
      };
      const columnNewCardsIds = [...prev.columns[columnId].cardsIds, cardId];
      return {
        ...prev,
        cards: newCards,
        columns: {
          ...prev.columns,
          [columnId]: {
            ...prev.columns[columnId],
            cardsIds: columnNewCardsIds,
          },
        },
        stack: { ...prev.stack, cardsIds: prev.stack.cardsIds.slice(1) },
      };
    });
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (destination.droppableId === source.droppableId) {
      if (destination.droppableId === 'initial-stack') return;
      const column = dndState.columns[source.droppableId];
      const newCardsIds = [...column.cardsIds];
      newCardsIds.splice(source.index, 1);
      newCardsIds.splice(destination.index, 0, draggableId);

      const newColumn = { ...column, cardsIds: newCardsIds };
      return setDndState((prev) => ({ ...prev, columns: { ...prev.columns, [newColumn.id]: newColumn } }));
    }

    if (source.droppableId === 'initial-stack') {
      const column = dndState.columns[destination.droppableId];
      const newCardsIds = [...column.cardsIds];
      newCardsIds.splice(destination.index, 0, draggableId);
      const newColumn = { ...column, cardsIds: newCardsIds };
      const newStack = {
        ...dndState.stack,
        cardsIds: dndState.stack.cardsIds.slice(1),
      };
      const newCards = {
        ...dndState.cards,
        [draggableId]: { ...dndState.cards[draggableId], weight: column.columnWeight },
      };
      return setDndState((prev) => ({
        ...prev,
        cards: newCards,
        columns: { ...prev.columns, [newColumn.id]: newColumn },
        stack: newStack,
      }));
    }

    if (destination.droppableId === 'initial-stack') {
      const column = dndState.columns[source.droppableId];
      return returnCardToStack(column.id, draggableId);
    }

    const columnFrom = dndState.columns[source.droppableId];
    const columnTo = dndState.columns[destination.droppableId];

    if (columnTo.cardsIds.length >= 8) return;

    const columnFromNewCardsIds = columnFrom.cardsIds.filter((id) => id !== draggableId);
    const columnToNewCardsIds = [...columnTo.cardsIds];
    columnToNewCardsIds.splice(destination.index, 0, draggableId);
    setDndState((prev) => ({
      ...prev,
      cards: { ...prev.cards, [draggableId]: { ...prev.cards[draggableId], weight: columnTo.columnWeight } },
      columns: {
        ...prev.columns,
        [columnFrom.id]: { ...columnFrom, cardsIds: columnFromNewCardsIds },
        [columnTo.id]: { ...columnTo, cardsIds: columnToNewCardsIds },
      },
    }));
  };

  const onNextClick = () => {
    if (dndState.stepIndex === 2) {
      setResults((prev) => [...prev, dndState]);
      dispatch(pushMexAssessmentHistoryItem(dndState));
      handleNextStep();
      setDndState((prev) => ({ ...prev, stepIndex: 0 }));
    } else {
      setDndState((prev) => ({ ...prev, stepIndex: prev.stepIndex + 1 }));
    }
  };

  // types = ['positive', 'negative']
  const onCardSelect = (type, cardId) => {
    setDndState((prev) => {
      const card = prev.cards[cardId];

      const selectedCardsCount = Object.keys(prev.cards).filter(
        (id) => prev.cards[id].weight === (type === 'positive' ? 2 : -2),
      ).length;
      if (selectedCardsCount >= 3 && Math.abs(card.weight) === 1) return prev;

      const negativeOption = card.weight === -2 ? -1 : -2;
      const positiveOption = card.weight === 2 ? 1 : 2;
      return {
        ...prev,
        cards: {
          ...prev.cards,
          [cardId]: {
            ...card,
            weight: type === 'positive' ? positiveOption : negativeOption,
          },
        },
      };
    });
  };

  const allCardsQuantity = Object.keys(dndState.cards).length;
  const evaluatedCardsQuantity = Object.entries(dndState.columns).reduce(
    (acc, [, value]) => acc + value.cardsIds.length,
    0,
  );

  const isNextButtonDisabled = useMemo(() => {
    if (!dndState.stepIndex) {
      const emptyCols = Object.keys(dndState.columns).filter(
        (colName) => dndState.columns[colName].cardsIds.length < MIN_ITEMS_IN_COLUMN,
      );
      const fullColumns = Object.keys(dndState.columns).filter(
        (colName) => dndState.columns[colName].cardsIds.length > MAX_ITEMS_IN_COLUMN,
      );

      return dndState.stack.cardsIds.length || emptyCols.length || fullColumns.length;
    }
    if (dndState.stepIndex === 1) {
      const allItems = Object.keys(dndState.cards).filter((id) => dndState.cards[id].weight === -1);
      const selectedItems = Object.keys(dndState.cards).filter((id) => dndState.cards[id].weight === -2);
      if (!allItems.length) return false;
      return selectedItems.length < 1 || selectedItems.length > 3;
    }
    if (dndState.stepIndex === 2) {
      const allItems = Object.keys(dndState.cards).filter((id) => dndState.cards[id].weight === 1);
      const selectedItems = Object.keys(dndState.cards).filter((id) => dndState.cards[id].weight === 2);
      if (!allItems.length) return false;
      return selectedItems.length < 1 || selectedItems.length > 3;
    }
  }, [dndState]);

  const content = useMemo(() => {
    switch (STEPS[dndState.stepIndex]) {
      case 'INITIAL-SORTING': {
        return (
          <DragDropContext onDragEnd={onDragEnd}>
            <DescriptionRow>
              <Title>{t('dndQuestion', { index: activePageIndex + 1 })}</Title>
              <p>{description}</p>
            </DescriptionRow>
            <StackRow>
              <Stack
                cardOnTop={cardOnTopOfTheStack}
                stack={dndState.stack}
                evaluatedCardsQuantity={evaluatedCardsQuantity}
                allCardsQuantity={allCardsQuantity}
              />
            </StackRow>
            <ColumnsWrapper>
              {dndState.columnOrder.map((colId, index) => {
                const column = dndState.columns[colId];
                const cards = column.cardsIds.map((id) => dndState.cards[id]);
                return (
                  <Column
                    key={column.id}
                    column={column}
                    cards={cards}
                    onDeleteCard={(cardId) => returnCardToStack(column.id, cardId)}
                    title={sortingBoxes[index]}
                    onTitleClick={() => takeCardFromStack(column.id)}
                  />
                );
              })}
            </ColumnsWrapper>
          </DragDropContext>
        );
      }
      case 'UNIMPORTANT':
        return (
          <>
            <DescriptionRow>
              <Title>{t('dndQuestion', { index: activePageIndex + 1 })}</Title>
              <p>{description1}</p>
            </DescriptionRow>
            <RatingColumn
              title={sortingBoxes1}
              cards={dndState.columns['column-1'].cardsIds.map((id) => dndState.cards[id])}
              onCardSelect={(cardId) => onCardSelect('negative', cardId)}
              type="negative"
            />
          </>
        );
      case 'IMPORTANT':
        return (
          <>
            <DescriptionRow>
              <Title>{t('dndQuestion', { index: activePageIndex + 1 })}</Title>
              <p>{description1}</p>
            </DescriptionRow>
            <RatingColumn
              title={sortingBoxes2}
              cards={dndState.columns['column-3'].cardsIds.map((id) => dndState.cards[id])}
              onCardSelect={(cardId) => onCardSelect('positive', cardId)}
              type="positive"
            />
          </>
        );
      default:
        return null;
    }
  }, [dndState]);

  return (
    <>
      <Navigation hideInvisibleBg>
        <ButtonPrevious disabled />
        <ButtonNext
          onClick={onNextClick}
          className={clsx({
            active: false,
          })}
          disabled={isNextButtonDisabled}
        />
      </Navigation>
      <Container>
        <InstructionsLabel>{instruction}</InstructionsLabel>
        {content}
      </Container>
    </>
  );
};

DndStacks.propTypes = {
  values: object.isRequired,
  handleNextStep: func.isRequired,
  activePageIndex: number.isRequired,
  page: object.isRequired,
};

const Container = styled.div`
  padding: 6.2rem;
  flex-direction: column;
  display: flex;
`;

const InstructionsLabel = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;

  @media (min-width: ${({ theme }) => theme.viewports.lgx}) {
    margin-bottom: 4rem;
    margin-top: 0;
  }
`;

const DescriptionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.viewports.lgx}) {
    margin-bottom: 3.4rem;
  }
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0.8rem 0;
  color: rgba(0, 0, 0, 0.88);
`;

const StackRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
