import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { CustomButton } from '../../imported-from-admin/Button';
import { Container, ContentHolder, LogoWrapper, Title } from '../../imported-from-admin/styles';
import { ReactComponent as Logo } from '../../../../assets/images/logo.svg';
import { ReactComponent as ArrowBack } from '../../../../assets/images/arrow-back.svg';

export const RespondentNotFound = ({ onBack, email }) => {
  const { t } = useTranslation();

  return (
    <Container withBackground>
      <StyledContentHolder>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <StyledTitle>{t('noRecords')}</StyledTitle>

        <Trans i18nKey="emailDoesntMatch" values={{ email }} components={{ bold: <strong /> }} />

        <ButtonsWrapper>
          <StyledButton handler={onBack} variant="secondary">
            <StyledBackArrow />
            {t('back')}
          </StyledButton>
        </ButtonsWrapper>
      </StyledContentHolder>
    </Container>
  );
};

RespondentNotFound.propTypes = {
  onBack: func.isRequired,
  email: string,
};

RespondentNotFound.defaultProps = {
  email: '',
};

const StyledContentHolder = styled(ContentHolder)`
  height: auto;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 2.4rem;
`;

const StyledButton = styled(CustomButton)`
  width: 15rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.regular};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.2rem;
`;

const StyledBackArrow = styled(ArrowBack)`
  margin-right: 0.5rem;
`;
