import styled from 'styled-components';
import { shake, slideInUp } from '../../../utils/animations';

export const StyledFooter = styled.div`
  padding: 30px 5% 20px 5%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(249, 249, 249, 0) 0%,
    rgba(249, 249, 249, 1) 30%,
    rgba(249, 249, 249, 1) 100%
  );
  height: 137px;
  min-height: 137px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    &:before {
      content: ' ';
      position: absolute;
      width: 98%;
      background: linear-gradient(to bottom, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 1) 100%);
      height: 50px;
      display: flex;
      top: -49px;
    }
  }
  label {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 400px) {
    padding: 0;
    position: relative;
    max-width: calc(1024px + 10%);
    height: 20vh;
    min-height: 130px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    align-items: center;
    justify-content: space-between;
    label {
      display: flex;
    }
  }
`;

export const FullHeight = styled.div`
  width: 92%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto 5%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5;
  display: flex;
  position: relative;
  @media (min-width: 700px) {
    height: 100%;
  }
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding-top: 40px;
  }
  @media (max-height: 414px) {
    justify-content: flex-start;
  }
  @media (max-height: 414px) and (max-width: 818px) {
    padding-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    //padding-top: 0;
  }
`;

export const GlyphsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-grow: 1;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    height: 100%;
    min-width: 60px;
  }

  ${({ theme }) => theme.xs`
    justify-content: flex-end;
  `};

  &.whatComesNext {
    ${({ theme }) => theme.xs`
      flex-grow: 0; 
  `};
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    justify-content: flex-end;
  }
  @media (max-height: 414px) {
    justify-content: center;
  }

  .text {
    width: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    min-width: 70px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.458333px;
    color: ${({ theme }) => theme.colors.black};

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      min-width: 70px;
    }

    @media (min-width: ${({ theme }) => theme.viewports.sm}) and (min-height: 700px) {
      min-width: 90px;
    }

    @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
        theme.viewports.mdx}) and (orientation: portrait) {
      min-width: calc(11.71875vw - 30px);
    }

    @media (min-width: ${({ theme }) => theme.viewports.lg}) and (min-height: 800px) {
      min-width: 100px;
    }

    @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const HalfGlyphStyled = styled.div`
  display: flex;
`;

export const SubTitleStyled = styled.div`
  color: ${(props) => props.theme.colors.medGray};
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const GlyphStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: ${(props) => props.theme.colors.darkFontColor};
  border-radius: 4px;
  margin: ${(props) => (props.marginless ? 0 : '0 10px')};

  &.question {
    border-color: ${({ theme }) => theme.colors.light};
  }

  &.question.whatComesNext {
    @media (max-width: ${({ theme }) => theme.viewports.xs}) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.viewports.xs}) and (orientation: portrait) {
    margin: 0 5px;
  }

  span {
    svg {
      width: auto;
      height: 100%;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        width: 100%;
      }
    }
  }

  ${({ theme, marginless }) =>
    !marginless &&
    theme.xs`
    margin-bottom: 1rem;
  `}
`;

export const GlyphsContainerStyled = styled.div`
  display: flex;

  padding-bottom: 20px;

  ${({ theme }) => theme.xs`
    flex-wrap: wrap;
    justify-content: space-around;
  `}

  ${({ theme }) => theme.md`
    padding-bottom: 10px;
  `}

  &.slideInUp {
    opacity: 0;
    transform: translateY(100%) translateZ(0);
    will-change: transform, opacity;
    animation: ${slideInUp} 300ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  }

  @-webkit-keyframes autofill {
    to {
      color: inherit;
      background: transparent;
    }
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 35px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.xxs}) {
    margin-bottom: 0;
  }

  &.shake {
    animation: 0.3s both ${shake};

    hr:after {
      background-color: ${({ theme }) => theme.colors.error};
    }
  }
`;

export const QuestionTextStyled = styled.div`
  color: ${(props) => props.theme.colors.darkFontColor};

  //justify-content: center;
  //align-items: center;
  //display: flex;
  //flex-direction: column;

  display: block;
  padding-top: 22px;
  width: 100%;
  padding-bottom: 22px;

  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.46px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.67px;
    text-align: center;
    height: auto;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 20px;
    padding: 10px 0;
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) and (max-height: 414px) {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 0;
  }
`;

export const VideoQuestionStyled = styled.div`
  color: ${(props) => props.theme.colors.darkFontColor};

  //justify-content: center;
  //align-items: center;
  //display: flex;
  //flex-direction: column;

  display: block;
  padding-top: 22px;
  width: 100%;
  padding-bottom: 22px;

  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.46px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: -0.67px;
    text-align: center;
    height: auto;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 20px;
    padding: 10px 0;
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) and (max-height: 414px) {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 0;
  }
`;

export const FeedbackStyled = styled.div`
  display: inline-flex;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: 18px;
  color: #008ac0;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    //font-size: 14px;
    //line-height: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 13px;
    line-height: 16px;
  }

  &:after {
    display: inline-flex;
    min-width: 10px;
    content: '';
    order: -1;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      min-width: 18px;
    }
  }

  &:before {
    height: 28px;
    min-width: 28px;
    content: '!';
    color: #86bdf6;
    border: 3px solid #86bdf6;
    font-size: 20px;
    margin-top: 2px;
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    order: -2;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      height: 34px;
      min-width: 34px;
      font-size: 24px;
    }
  }
`;

export const Divider = styled.span`
  content: ' ';
  display: inline-block;
  min-width: 20px;
`;

export const IconStyled = styled.span`
  font-size: 40px;

  ${({ theme, fontSize }) =>
    fontSize &&
    theme.xs`
    font-size: ${fontSize};
  `};

  ${({ theme }) =>
    theme.xxs`
    font-size: 50px;
  `};

  ${({ theme, isAnswer }) =>
    isAnswer &&
    theme.xs`
    font-size: 80px;
  `};

  @media (max-width: ${({ theme }) => theme.viewports.xs}) and (orientation: portrait) {
    font-size: 18.3vw;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: ${({ isImage }) => (isImage ? '13vw' : '13vw')};
  }

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    font-size: ${({ fontSize }) => fontSize || '10vw'};
  }

  @media (max-width: 900px) and (max-height: 450px) and (orientation: landscape) {
    font-size: 8vw;
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    font-size: ${({ isImageBorder }) => (isImageBorder ? '200px' : '120px')};
  }

  &.icon-question {
    color: ${({ theme }) => theme.colors.light};

    &.rtl {
      transform: scale(-1, 1);
    }
  }

  &.art {
    font-size: 70px;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 70px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.md}) and (min-height: 700px) {
      font-size: 90px;
    }
    @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
        theme.viewports.mdx}) and (orientation: portrait) {
      font-size: calc(13.671875vw - 20px);
    }
    @media (min-width: ${({ theme }) => theme.viewports.lg}) and (min-height: 800px) {
      font-size: 130px;
    }
  }

  &.adaptga {
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 90px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.md}) and (min-height: 700px) {
      font-size: 110px;
    }
    @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
        theme.viewports.mdx}) and (orientation: portrait) {
      font-size: calc(13.671875vw - 20px);
    }
    @media (min-width: ${({ theme }) => theme.viewports.lg}) and (min-height: 800px) {
      font-size: 130px;
    }
  }

  &.grt1a,
  &.grt2a,
  &.irt3a {
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 57px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.md}) {
      font-size: 86px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.lg}) {
      font-size: 94px;
    }
  }

  &.irt4a {
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 85px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.md}) {
      font-size: 115px;
    }
    @media (min-width: ${({ theme }) => theme.viewports.lg}) {
      font-size: 150px;
    }
  }

  &.mainshape {
    font-size: 120px;

    ${({ theme }) =>
      theme.xxs`
      font-size: 140px;
    `};

    ${({ theme }) =>
      theme.xs`
      font-size: 210px;
    `};

    @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
      font-size: 10vw;
    }

    @media only screen and (max-width: 818px) and (max-height: 815px) and (orientation: landscape) {
      font-size: 8vw;
    }

    @media (max-width: ${({ theme }) => theme.viewports.mdx}) and (orientation: portrait) {
      font-size: 120px;
    }

    @media (max-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 20vw;
    }

    @media (max-width: ${({ theme }) => theme.viewports.mdx}) and (min-width: ${({ theme }) =>
        theme.viewports.md}) and (orientation: landscape) {
      font-size: 120px;
    }
  }
  &.mainshape.grt2ma {
    @media (max-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 19.2vw;
    }
  }
`;