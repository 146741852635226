import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { configure } from "mobx";

import { App } from "./App";
import { configureStore } from "./store/store";
import { Spinner } from "./components/shared/Spinner";
import "./i18n";
import { envVars } from "./utils/hooks/useEnvVars";
import { LoggerService } from "./utils/Logger.service";

import "react-toastify/dist/ReactToastify.css";
import "rodal/lib/rodal.css";

configure({ useProxies: "ifavailable" });

if (envVars.isProd) {
  LoggerService.init();
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <React.Suspense
      fallback={
        <Spinner
          isFullScreen
          // text="Loading application"
        />
      }
    >
      <App />

      <ToastContainer autoClose={3000} position="top-center" hideProgressBar />
    </React.Suspense>
  </Provider>,
  document.getElementById("root")
);
