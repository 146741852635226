/* eslint-disable */
/**
 * Item banked questions may be grouped and have a predefined itemsingroups parameter
 * for example itemsingroups = "6,5,5,8,9,4,6,6",
 * that means the questions:
 *
 * First six (6): 1 - 6 should select the same group, previously selected randomly, for example 1c, 2c, 3, 4c, 5c, 6c
 * Next five (5): 7 - 11 should select the same group, previously selected randomly, for example 7b, 8b, 9b, 10b, 11b
 * Next five (5): 12 - 16 should select the same group, previously selected randomly, for example 12d, 13d, 14d, 15d, 16d
 * and so on (8)
 *           (9)
 *           (4)
 *           (6)
 *           (6)
 *
 * Each group within range is selected randomly but remains the same until new range
 *
 * @param index - index of item in array of questions
 * @param itemsingroups - predefined ranges for groups
 *
 * @returns {boolean} value indicates if new range is started and random function should be triggered
 */

export const checkIfFirstInGroup = (itemsingroups, index) => {
  const ranges = itemsingroups.split(",").map((item) => Number(item));
  for (let i = 0, sum = 0; i < ranges.length; i++) {
    if (index < sum) {
      return false;
    }
    if (index === sum) {
      return true;
    }
    sum += ranges[i];
  }
  return false;
};
