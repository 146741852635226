import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { QuestionnaireEngine } from './QuestionnaireEngine';
import { AdaptiveTest } from './AdaptiveTest';
import { InstructionsPlayer } from '../InstructionsPlayer';

const parseAdaptResponse = (response) => {
  try {
    return {
      AdaptItems: response.AdaptItems.split(',').reduce((acc, next) => {
        const [key, value] = next.split(':');

        if (key !== undefined && value !== undefined) {
          acc[key] = value;
        }

        return acc;
      }, {}),
      res: response.RIdx,
    };
  } catch (e) {
    throw new Error('Can not parse response for adaptive test.');
  }
};

const AdaptiveTestPlayerComponent = ({ items, answers, config, isStarted, session, details}) => {
  const [qEngine, setEngine] = React.useState(null);
  const qData = {
    questionnaire: {
      numberofquestions: config.numberofresponses,
      responserequired: config.isResponseRequired,
      timeallowed: config.timeAllowed,
      reviewanswers: 'false',
      numberofresponses: config.numberOfResponses,
      lowerpctstart: config.lowerpctstart,
      upperpctstart: config.upperpctstart,
      lowerpctmaxcut: config.lowerpctmaxcut,
      upperpctmaxcut: config.upperpctmaxcut,
      pctstep: config.pctstep,
      maxitemsperdimension: config.maxitemsperdimension,
      testId: details.testId,
    },
  };

  /* eslint-disable */
  React.useEffect(() => {
    const updatedQuestionsIndex = items.reduce((acc, item, index) => {
      acc[index + 1] = item;
      return acc;
    }, {});

    qData.questionnaire.questions = updatedQuestionsIndex;
    const engine = new QuestionnaireEngine(qData.questionnaire, {
      respodentIsFromSA: details.filterName === 'sa',
    });

    if (!answers.length) {
      engine.prepareQuestionnaireForStartup();
    } else {
      const itemsUsed = answers.map((answer) => parseAdaptResponse(answer));
      const itemResponses = itemsUsed.map((item) => item.res);
      const lastItemUsed = itemsUsed[itemsUsed.length - 1].AdaptItems;

      engine.prepareQuestionnaireForStartup(Number(lastItemUsed.EAP), lastItemUsed.qid);
      engine.currentQuestionnaire.recoverAdaptiveTest(
        [{}, ...itemsUsed.map((i) => i.AdaptItems)],
        [0, ...itemResponses],
      );
    }

    engine.currentQuestionnaire.getNextQuestion();
    setEngine(engine);
  }, []);
  /* eslint-enable */

  if (!qEngine) return null;

  return (
    <>
      {config.type !== 'svg-single' && (
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${process.env.PUBLIC_URL}/css/${session.testId.toLowerCase()}.css`}
          />
        </Helmet>
      )}

      {isStarted ? (
        <AdaptiveTest qEngine={qEngine} />
      ) : (
        <InstructionsPlayer />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: { answers, isStarted, session, details },
    testDetails: { items, config },
  } = state;

  return {
    isStarted,
    session,
    items,
    answers,
    config,
    details,
  };
};

export const AdaptiveTestPlayer = connect(mapStateToProps)(AdaptiveTestPlayerComponent);
