import { ReactComponent as eg } from "./eg.svg";
import { ReactComponent as egAns } from "./egAns.svg";
import { ReactComponent as egAnsPart1 } from "./egAns(part1).svg";
import { ReactComponent as egAnsPart2 } from "./egAns(part2).svg";
import { ReactComponent as egAnsPart2ar } from "./egAns(part2)ar.svg";
import { ReactComponent as graphic1 } from "./1.svg";
import { ReactComponent as graphic2 } from "./2.svg";
import { ReactComponent as graphic3 } from "./3.svg";
import { ReactComponent as graphic4 } from "./4.svg";
import { ReactComponent as graphic5 } from "./5.svg";
import { ReactComponent as graphic6 } from "./6.svg";
import { ReactComponent as graphic7 } from "./7.svg";
import { ReactComponent as graphic8 } from "./8.svg";
import { ReactComponent as graphic9 } from "./9.svg";
import { ReactComponent as graphic10 } from "./10.svg";
import { ReactComponent as graphic11 } from "./11.svg";
import { ReactComponent as graphic12 } from "./12.svg";
import { ReactComponent as graphic13 } from "./13.svg";
import { ReactComponent as graphic14 } from "./14.svg";
import { ReactComponent as graphic15 } from "./15.svg";

// eslint-disable-next-line import/no-default-export
export default {
  eg,
  egAns,
  egAnsPart1,
  egAnsPart2,
  egAnsPart2ar,
  graphic1,
  graphic2,
  graphic3,
  graphic4,
  graphic5,
  graphic6,
  graphic7,
  graphic8,
  graphic9,
  graphic10,
  graphic11,
  graphic12,
  graphic13,
  graphic14,
  graphic15,
};
