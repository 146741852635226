import React from 'react';
import styled, { css } from 'styled-components';
import info from '../../assets/images/info.svg';
import burgerMobile from '../../assets/images/burger-mobile.svg';
import burgerOpened from '../../assets/images/close-state-2.svg';
import { LangDirectionContext } from '../../context/LangDirectionContext';

const InfoIconStyled = styled.div`
  min-width: 40px;
  height: 34px;
  border: none;
   background-image: url("${burgerMobile}");
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: auto;
  background-color: transparent;
  margin-left: -5px;
  cursor: pointer;
  padding: 0 0 0 40px;
  outline: none;
  
  ${(props) =>
    props.direction === 'rtl' &&
    css`
      margin-left: auto;
      margin-right: -5px;
      background-position: right;
      padding-right: 40px;
      padding-left: 0;
    `}
  }
  
  &.active {
    background-image: url("${burgerOpened}");
  }
  
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    background-image: url("${info}");
    display: inline-flex;
    height: 40px;
    min-width: 160px;
    margin-right: 20px;
    
    ${(props) =>
      props.direction === 'rtl' &&
      css`
        margin-right: initial;
        margin-left: 20px;
      `}
  }
  
  span {
    font-weight: bold;
    color: white;
    min-height: 40px;
    align-items: center;
    display: flex;
    
    @media only screen and (min-width: ${({ theme }) => theme.viewports.sm})  and (max-device-width: ${({ theme }) =>
  theme.viewports.mdx})  and (orientation: portrait) {
      font-weight: normal;
    }

    &:last-child:after {
      content: ${(props) => (props.percentless ? '' : '%')};
    }
    
  ${(props) =>
    props.direction === 'rtl' &&
    css`
      flex-direction: row-reverse;
    `}    
  }
`;

export const InfoIcon = ({ onClick, children, ...props }) => {
  const direction = React.useContext(LangDirectionContext);
  return (
    <InfoIconStyled tabIndex="-1" onClick={onClick} {...props} direction={direction}>
      {children}
    </InfoIconStyled>
  );
};
