const namespace = "GenSys";

const storage = sessionStorage;

export class LocalStorageService {
  static setSession(questionnaireDetails, value) {
    storage.setItem(
      LocalStorageService.composeSessionKey(questionnaireDetails),
      JSON.stringify(value)
    );
  }

  static removeSession(questionnaireDetails) {
    storage.removeItem(
      LocalStorageService.composeSessionKey(questionnaireDetails)
    );
  }

  static getSession(questionnaireDetails) {
    const item = storage.getItem(
      LocalStorageService.composeSessionKey(questionnaireDetails)
    );

    return item ? JSON.parse(item) : {};
  }

  static composeSessionKey({ accountId, invitationId, password }) {
    return `${namespace}-${accountId}-${invitationId}-${password}`;
  }

  static saveToken(token) {
    storage.setItem("accessToken", token);
  }

  static getToken() {
    return storage.getItem("accessToken");
  }

  static removeToken() {
    storage.removeItem("accessToken");
  }
}
