import React from 'react';
import styled from 'styled-components';
import { FeedbackStyled, FullHeight, QuestionTextStyled } from './shared/components';
import { CardsList } from '../shared/CardsList';

const TemplateStyled = styled.div`
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 37vh;
      max-height: 350px;
      @media (min-width: ${({ theme }) => theme.viewports.xl}) {
        height: 43vh;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    display: flex;
    margin-bottom: 40px;

    .image-container {
      order: 1;
    }
  }
`;

const QuestionWhichTextStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.67;
  z-index: 5;
  position: relative;

  @media (min-width: ${({ theme }) => theme.viewports.md}) {
    font-size: 24px;
    line-height: 1.67;
    margin-right: 20%;
    text-align: left;
  }
`;

// TODO: rm and use TextOnly
export const WhichOfImage = ({
  values: { mainitem, responses, image, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  iconsModule,
}) => {
  const ImageComponent = iconsModule[image];

  return (
    <>
      <FullHeight>
        <TemplateStyled>
          <div className="image-container">
            <ImageComponent />
          </div>

          <QuestionWhichTextStyled
            dangerouslySetInnerHTML={{
              __html: mainitem,
            }}
          />
        </TemplateStyled>

        {!!feedback && (
          <FeedbackStyled
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </FullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        // isImage
        isImageBorder
        className="which-of-three which-of-image for-text"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
      />
    </>
  );
};
