import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  NumEight,
  NumFive,
  NumFour,
  NumNine,
  NumOne,
  NumSeven,
  NumSix,
  NumThree,
  NumTwo,
  NumZero,
} from "./icons";
import { KeysHelpHolder } from "./KeysHelpHolder";

const KeysStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  direction: ltr;

  div {
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }

  svg {
    width: auto;
    height: 5vw;
    min-height: 44px;
    max-height: 56px;
    &:hover {
      filter: none;
    }
  }
`;

export function NumKeys() {
  const { t } = useTranslation();
  return (
    <KeysHelpHolder
      title={t("selectOrChangeYourAnswers")}
      subtitle={t("byUsingTheMouse")}
      desktop
    >
      <KeysStyled>
        <div>
          <NumOne />
          <NumTwo />
          <NumThree />
          <NumFour />
          <NumFive />
          <NumSix />
          <NumSeven />
          <NumEight />
          <NumNine />
          <NumZero />
        </div>
      </KeysStyled>
    </KeysHelpHolder>
  );
}
