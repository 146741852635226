import React from "react";

export const NumFive = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8388 19.018C22.2574 19.018 23.2888 19.2747 23.9328 19.788C24.5861 20.292 24.9128 21.0013 24.9128 21.916C24.9128 22.4853 24.7774 22.994 24.5068 23.442C24.2454 23.89 23.8488 24.2447 23.3168 24.506C22.7848 24.758 22.1314 24.884 21.3568 24.884C20.6568 24.884 19.9894 24.772 19.3548 24.548C18.7294 24.3147 18.2254 24.0067 17.8428 23.624L18.3328 22.826C18.6594 23.162 19.0888 23.4373 19.6208 23.652C20.1528 23.8573 20.7268 23.96 21.3428 23.96C22.1548 23.96 22.7801 23.778 23.2188 23.414C23.6668 23.05 23.8908 22.5647 23.8908 21.958C23.8908 21.286 23.6388 20.7773 23.1348 20.432C22.6401 20.0867 21.8001 19.914 20.6148 19.914H18.5288L19.0328 15H24.3948V15.896H19.9148L19.5788 19.018H20.8388Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
