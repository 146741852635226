import React, { useRef } from "react";
import { func, bool, string, object } from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

export const CustomTextArea = ({
  inputName,
  value,
  onChange,
  errors,
  touched,
  disabled,
  className,
  onContainerClick,
  name,
  ...rest
}) => {
  const inputRef = useRef(null);
  const onContainerFocus = () => {
    inputRef.current.focus();
  };

  const isTouched = get(touched, name);
  const error = get(errors, name);

  return (
    <Container
      className={className}
      onFocus={onContainerFocus}
      tabIndex="-1"
      onClick={onContainerClick}
    >
      <InputWrapper error={error && isTouched} disabled={disabled}>
        {value && <AbsoluteLabel>{inputName}</AbsoluteLabel>}
        <TextArea
          ref={inputRef}
          value={value}
          onChange={onChange}
          placeholder={inputName}
          disabled={disabled}
          readOnly={!onChange}
          {...rest}
        />
      </InputWrapper>
      {isTouched && <ErrorMsg>{error}</ErrorMsg>}
    </Container>
  );
};

CustomTextArea.propTypes = {
  inputName: string.isRequired,
  name: string.isRequired,
  value: string,
  onChange: func,
  disabled: bool,
  // eslint-disable-next-line react/forbid-prop-types
  errors: object,
  // eslint-disable-next-line react/forbid-prop-types
  touched: object,
  className: string,
  onContainerClick: func,
};

CustomTextArea.defaultProps = {
  disabled: false,
  errors: {},
  touched: {},
  className: "",
  onChange: undefined,
  onContainerClick: undefined,
  value: "",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
  padding-top: 1.2rem;
  padding-left: 1.2rem;
  height: 8rem;
  max-height: 8rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${(props) => props.theme.colors.darkBlue2};
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.red : props.theme.colors.grey2};
  border-radius: 0.4rem;

  &:focus-within {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }

  :hover {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

const TextArea = styled.textarea`
  height: 100%;
  padding-right: 1.2rem;
  padding-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  border: none;
  outline: none;
  resize: none;

  ::placeholder {
    color: ${(props) => props.theme.colors.grey5};
  }
`;

const AbsoluteLabel = styled.span`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: 1rem;
  font-weight: 600;
`;

const ErrorMsg = styled.span`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.red};
  font-weight: 600;
  margin-top: 0.5rem;
`;
