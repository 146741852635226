import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { QuestionsPlayer } from './QuestionsPlayer/QuestionsPlayer';
import { InstructionsPlayer } from './InstructionsPlayer';

import { VideoPrealoader } from './VideoPrealoader';
import { SVGPrealoader } from './SVGPrealoader';

import { sameInstructionQuestionnaires } from '../routes/constants';

const TestPlayerComponent = ({ session, isStarted, config, is360, isLandscapeOnly }) => {
  const { batteryDependencies = "", testId } = session;
  const isSingleTest = batteryDependencies === "";
  const isFirstQuestionnaire = batteryDependencies.split(';')[0] === testId;
  const hasSimilarInstructions = sameInstructionQuestionnaires.includes(testId);
  const shouldShowInstructions = isSingleTest || (hasSimilarInstructions ? isFirstQuestionnaire : true);
  return (
    <>
      {config.type !== 'svg-single' && (
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href={`${process.env.PUBLIC_URL}/css/${session.testId.toLowerCase()}.css`}
          />
        </Helmet>
      )}
      {config.isSJT && <VideoPrealoader videos={config.videoURLS} />}
      {config.isImageMatching && <SVGPrealoader />}
      {!shouldShowInstructions || isStarted ? (
        <QuestionsPlayer is360={is360} isLandscapeOnly={isLandscapeOnly} />
      ) : (
        <InstructionsPlayer isLandscapeOnly={isLandscapeOnly} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: { session, isStarted },
    testDetails: { config },
  } = state;

  return {
    session,
    config,
    isStarted,
  };
};

export const TestPlayer = connect(mapStateToProps)(TestPlayerComponent);
