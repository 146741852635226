import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Terms } from '../../pages/Terms';
import { Spinner } from '../Spinner';
import { RespondentForm } from '../ManagedGroup/LoginFlow/RespondentForm';

// SCREENS
const TERMS = 'TERMS';
const RESPONDENT_FORM = 'RESPONDENT_FORM';

const OpenGroupPreTestComponent = ({ welcomeText }) => {
  // const onCancelHandler = React.useCallback(() => {
  //   history.push(is360 ? '/Start360' : '/Start');
  // }, [history, is360]);
  // const { managedGroupData } = useSelector((state) => state.questionnaireDetails);

  const [activeScreen, setActiveScreen] = useState(TERMS);

  const {
    openGroup: { langID },
  } = useSelector((state) => state.questionnaireDetails);

  const onCreateRespondent = ({ accountID, inviteId, password }) => {
    const paddedAccountId = String(accountID).padStart(5, '0');
    const paddedInviteId = String(inviteId).padStart(7, '0');
    const link = `${window.location.origin}/login/${paddedAccountId}${paddedInviteId}/${password}/${langID}`;
    document.location.assign(link);
  };

  // useEffect(() => {
  //   const { isWelcomeTextPassed, isTermsAccepted, isBioEdited } = state;
  //   if (isWelcomeTextPassed && isTermsAccepted && isBioEdited) {
  //     onComplete();
  //   }
  // }, [state, onComplete]);

  return React.useMemo(() => {
    switch (activeScreen) {
      case TERMS:
        return (
          <Terms
            onCancel={() => console.log('zzz cancel')}
            removeCloseButton
            onNext={() => setActiveScreen(RESPONDENT_FORM)}
          />
        );
      case RESPONDENT_FORM:
        return <RespondentForm onSuccess={onCreateRespondent} isOpenGroup />;
      default:
        return <Spinner />;
    }
  }, [activeScreen, welcomeText]);
};

export const OpenGroupPreTest = withRouter(OpenGroupPreTestComponent);
