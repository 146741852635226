import React from "react";

export function CheckMarkImage() {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53 103C25.3925 103 3 80.6075 3 53C3 25.3925 25.3925 3 53 3C80.6075 3 103 25.3925 103 53C103 80.6075 80.6075 103 53 103Z"
        stroke="#1BAAE1"
        strokeWidth="6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.2142 39.8585C67.8829 39.1965 68.7856 38.824 69.7271 38.8214C70.6686 38.8188 71.5733 39.1864 72.2456 39.8446C72.9179 40.5028 73.3037 41.3989 73.3198 42.339C73.3358 43.2791 72.9807 44.1877 72.3313 44.8684L53.2582 68.6777C52.9304 69.0303 52.5348 69.3132 52.095 69.5097C51.6552 69.7061 51.1802 69.8119 50.6985 69.8208C50.2168 69.8297 49.7383 69.7415 49.2915 69.5615C48.8447 69.3815 48.4388 69.1133 48.0982 68.7731L35.4608 56.148C35.1088 55.8204 34.8264 55.4253 34.6305 54.9864C34.4347 54.5474 34.3294 54.0735 34.3209 53.593C34.3124 53.1125 34.4009 52.6353 34.5811 52.1897C34.7613 51.7441 35.0296 51.3393 35.3699 50.9995C35.7101 50.6597 36.1154 50.3918 36.5616 50.2118C37.0078 50.0318 37.4858 49.9434 37.9669 49.9519C38.448 49.9604 38.9225 50.0656 39.3621 50.2612C39.8017 50.4567 40.1973 50.7387 40.5253 51.0903L50.5301 61.0769L67.1235 39.9634C67.1531 39.9265 67.185 39.8914 67.219 39.8585H67.2142Z"
        fill="#1BAAE1"
      />
    </svg>
  );
}
