import React from "react";
import styled from "styled-components";

const RadioButtonGroupStyled = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  height: auto;
  flex-direction: column;
  display: flex;
  min-height: 100px;
  // @media (min-width: ${({ theme }) => theme.viewports.md}) {
  //   height: ${({ theme }) => theme.formElements.height};
  //   flex-direction: row;
  // }

  ~ label {
    color: white;
    font-size: 18px;
    line-height: 22px;
  }

  .outer {
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    display: block;
    float: left;
    border: ${({ theme }) => theme.formElements.border};
    border-radius: 50%;
    background-color: rgba(248, 248, 248, 0.9);
  }

  .inner {
    transition: all 0.25s ease-in-out;
    height: 14px;
    width: 14px;
    transform: scale(0);
    display: block;
    margin: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.light};
    opacity: 0;
  }
`;

const InputRadioStyled = styled.input`
  position: relative;
  cursor: pointer;
  height: 1px;
  width: 1px;
  opacity: 0;
  &:after {
    display: inline-block;
    content: "";
    height: auto;
    width: 10px;
  }
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primaryBlue};
    + .outer .inner {
      transform: scale(0.5);
      opacity: 0.5;
      background-color: ${({ theme }) => theme.colors.light};
    }

    + .outer {
      border: 1px solid ${({ theme }) => theme.formElements.hoverBackground};
    }
  }

  &:checked + .outer {
    border: 1px solid ${({ theme }) => theme.formElementsChecked};
    .inner {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const LabelStyled = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:after {
    content: "";
    width: 24px;
    display: inline-block;
  }

  &:last-of-type:after {
    content: none;
  }

  .label {
    display: flex;

    &:before {
      content: "";
      width: 8px;
      display: inline-block;
    }
  }
`;

export const RadioGroup = ({ options, id, modern, ...props }) => {
  return (
    <RadioButtonGroupStyled modern={modern}>
      {options.map((option) => (
        <LabelStyled key={option.value}>
          <InputRadioStyled
            {...props}
            type="radio"
            value={option.value}
            defaultChecked={option.value === props.value}
          />
          <span className="outer">
            <span className="inner" />
          </span>
          <span className="label">{option.label}</span>
        </LabelStyled>
      ))}
    </RadioButtonGroupStyled>
  );
};
