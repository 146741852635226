const initialData = {
  cards: {},
  stack: {
    id: 'initial-stack',
    cardsIds: [],
  },
  columns: {
    'column-1': {
      id: 'column-1',
      cardsIds: [],
      columnWeight: -1,
    },
    'column-2': {
      id: 'column-2',
      cardsIds: [],
      columnWeight: 0,
    },
    'column-3': {
      id: 'column-3',
      cardsIds: [],
      columnWeight: 1,
    },
  },
  columnOrder: ['column-1', 'column-2', 'column-3'],
  questionIndex: 0,
  stepIndex: 0
};

export default initialData;
