import styled from "styled-components";

export const GeneralSliderStyled = styled.div`
  width: 30%;
  display: flex;

  .rc-slider {
    position: relative;
    width: 50%;
    height: 14px;
    padding: 5px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    cursor: pointer;
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #e9e9e9;
    border-radius: 6px;
  }
  .rc-slider-track {
    position: absolute;
    height: 4px;
    background-color: rgba(205, 205, 205, 0.6);
    border-radius: 6px;
  }
  .rc-slider-handle {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(0, 66, 192, 0.25) !important;
    margin-top: -5px;
    border: solid 2px rgba(45, 183, 245,0.5);
    border-radius: 50%;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    opacity: 0.8;
  }
  .rc-slider-mark {
    color: transparent;
  }
  .rc-slider-mark-text {
    color: transparent;
  } 
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }
   .rc-slider-dot {
    position: absolute;
    bottom: -3px;
    width: 1.1rem !important;
    height: 1.1rem !important;
    vertical-align: middle;
    background-color: #fff;
    border: 2px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer;
  }

  @media (max-width: ${({ theme }) => theme.viewports.sm}){
      width: 100%;
      display: flex;
      justify-content: center;
    }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
`;

export const QuestionStyledContainer = styled.div`
  background-color: rgba(26, 170, 225, 0.11);
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  padding: 5px 10px;
  margin: 5px;
  min-height: 6rem;

  @media (max-width: ${({ theme }) => theme.viewports.sm}){
    width: 100%;  
    min-height: 9rem;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
    width: 90%;  
    min-height: 7rem;
  }
  
  @media (min-width: ${({ theme }) => theme.viewports.lg}) and (max-width: 4000px) {
    width: 127rem;  
  }
`;

export const QuestionStyledItem = styled.div`
  width: 60%;
  margin-left: 40px;
  margin-right: 22px;

  @media (max-width: ${({ theme }) => theme.viewports.sm}){
      width: 100%;
    }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
      width: 100%;
    }
`;

export const QuestionStyled = styled.p`   
  margin: 0 !important;
  font-size: 1.3rem;
  font-weight: 450;

  @media (max-width: ${({ theme }) => theme.viewports.sm}){
    font-size: 1rem;
    max-width: 600px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
    max-width: 700px;
    font-size: 1.1rem;
  }
`;

export const StyledLabel = styled.p`
  margin-left: 50px;
`;
