import {
  FETCH_QUESTIONNAIRE_DATA_SUCCESS,
  FETCH_QUESTIONNAIRE_DATA_ERROR,
  FETCH_MANAGED_GROUP_DETAILS_SUCCESS,
  FETCH_OPEN_GROUP_DETAILS_SUCCESS,
} from '../actions/questionnaireDetailsActions';
import i18n from '../../i18n';

export const updateLang = (store) => (next) => (action) => {
  if (
    action.type === FETCH_QUESTIONNAIRE_DATA_SUCCESS ||
    action.type === FETCH_MANAGED_GROUP_DETAILS_SUCCESS ||
    action.type === FETCH_OPEN_GROUP_DETAILS_SUCCESS
  ) {
    const result = next(action);
    const {
      questionnaireDetails: { details },
    } = store.getState();
    const lang =
      action.type === FETCH_MANAGED_GROUP_DETAILS_SUCCESS || action.type === FETCH_OPEN_GROUP_DETAILS_SUCCESS
        ? action.payload.langID.toLowerCase()
        : details.langId.toLowerCase();
    i18n.changeLanguage(lang);

    return result;
  }

  if (action.type === FETCH_QUESTIONNAIRE_DATA_ERROR) {
    if (!i18n.language) {
      i18n.changeLanguage('en'); // Fallback to english in case of error
    }
  }

  return next(action);
};
