import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { QuestionTextStyled, FeedbackStyled, FullHeight, GlyphStyled, GlyphsWrapperStyled } from './shared/components';
import { CardsList } from '../shared/CardsList';
import { InstructionModal } from '../shared/InstructionModal';
import { GlyphSet } from './shared/GlyphSet';

import { isMobile } from '../shared/utils/isMobile';

const TextQuestionWrapper = styled.div`
  //flex-grow: 1;
  ${({ theme }) => theme.xs`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  `};

  @media (max-width: ${({ theme }) => theme.viewports.md}) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const Feedback = ({ feedback }) => {
  if (!feedback || isMobile) {
    return null;
  }

  return (
    <FeedbackStyled
      dangerouslySetInnerHTML={{
        __html: feedback,
      }}
    />
  );
};

const ImageQuestion = ({ image, testClass, feedback }) => (
  <GlyphsWrapperStyled>
    <GlyphStyled>
      <GlyphSet className={`icon icon-${image} ${testClass}`} />
    </GlyphStyled>

    <Feedback feedback={feedback} />
  </GlyphsWrapperStyled>
);

const TextQuestion = ({ mainitem, feedback }) => (
  <TextQuestionWrapper>
    <QuestionTextStyled
      dangerouslySetInnerHTML={{
        __html: mainitem,
      }}
    />
    <Feedback feedback={feedback} />
  </TextQuestionWrapper>
);

export const TextOnly = ({
  values: { mainitem, responses, feedback, image },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
}) => {
  const testClass = testId.toLowerCase();
  const hasImage = Boolean(image);

  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);

  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <FullHeight>
        {hasImage ? (
          <ImageQuestion image={image} testClass={testClass} feedback={feedback} />
        ) : (
          <TextQuestion mainitem={mainitem} feedback={feedback} />
        )}
      </FullHeight>

      <CardsList
        isAbstract={config.isAbstract}
        className={clsx({ 'svg-image': config.isAbstract, textOnly: true })}
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
