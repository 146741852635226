import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './reducers/rootReducer';
import { saveSession, updateLang } from './middlewares';

export const configureStore = () =>
  createStore(
    rootReducer(),
    composeWithDevTools(
      applyMiddleware(
        thunk,
        // logger,
        saveSession,
        updateLang
      )
    )
  );
