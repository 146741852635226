import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Bowser from 'bowser';
import { NumKeys } from '../shared/keys/NumKeys';
import { EnterKey } from '../shared/keys/EnterKey';
import { NavigationKeys } from '../shared/keys/NavigationKeys';
import { Page } from '../shared/layout';
import { Answers } from '../shared/keys/Answers';
import { NextKey } from '../shared/keys/NextKey';
import { NextBackKey } from '../shared/keys/NextBackKey';

export const PageStyled = styled(Page)`
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledMobileHelp = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  z-index: 4;
  position: relative;
  padding: 95px 5% 60px 5%;
  text-align: center;
  margin: 0 5%;
`;

export const StyledDesktopHelp = styled.div`
  color: ${({ theme }) => theme.colors.white};
  z-index: 4;

  margin: 0 5%;
  padding: 60px 7% 20px 7%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: calc(900px + 10%);
  -webkit-overflow-scrolling: touch;
`;

const Desktop = styled.div`
  width: 100%;
  svg {
    z-index: 2;
    position: relative;
    filter: drop-shadow(${(props) => props.theme.shadows.modalShadow});
    margin: initial;
    margin-right: 5px;
    //width: auto; //not working in IE
    height: 5vw;
    min-height: 44px;
    max-height: 56px;
    &:hover {
      filter: none;
    }
  }
`;

const NotDesktop = styled.div`
  svg {
    z-index: 2;
    position: relative;
    filter: none;
    margin: auto;
    width: initial;
    height: initial;
    min-height: initial;
    max-height: initial;
    &:hover {
      filter: none;
    }
  }
`;

const browser = Bowser.getParser(window.navigator.userAgent);
const platform = browser.getPlatform();
const isDesktop = platform.type === 'desktop';

const HelpComponent = ({ children, config, ...props }) => {
  const isSkippable = !config.isAdaptive && !config.isWAI;

  return (
    <PageStyled withBlurOpacity {...props}>
      {!isDesktop ? (
        <StyledMobileHelp>
          <NotDesktop>
            <Answers />
            <NextKey />
            {isSkippable && <NextBackKey />}
          </NotDesktop>
        </StyledMobileHelp>
      ) : (
        <StyledDesktopHelp>
          <Desktop>
            <NumKeys />
            <EnterKey />
            {isSkippable && <NavigationKeys />}
          </Desktop>
        </StyledDesktopHelp>
      )}
      {children}
    </PageStyled>
  );
};

const mapStateToProps = (state) => {
  const {
    testDetails: { config },
  } = state;

  return {
    config,
  };
};

export const Help = connect(mapStateToProps)(HelpComponent);
