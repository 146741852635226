import styled, { css } from "styled-components";
import { Page } from "../layout";

export const ProgressInicatorStyled = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    margin-bottom: initial;
  }
`;

export const HelpButtonStyled = styled.div`
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  display: none;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    display: flex;
    min-width: 150px;
  }
  &:after {
    content: "";
    display: inline-flex;
    width: 10px;
    order: -1;
  }

  &:before {
    content: "?";
    color: #008ac0;
    display: inline-flex;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
    justify-content: center;
    align-items: center;
    order: -2;
    font-weight: bold;
    font-size: 16px;
  }
`;

export const PageStyled = styled(Page)`
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  ${(props) =>
    props.isResponsesAvailable &&
    css`
      height: auto;
      padding-bottom: 0;
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        margin-top: 0;
      }
    `}
`;

export const RunOutOfTimeStyled = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`;
