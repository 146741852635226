import React from "react";
import styled from "styled-components";

const answers = [
  { title: "Biologist" },
  { title: "Social Worker" },
  { title: "Police Officer" },
];

const Switch = () => {
  return (
    <ButtonsGroupStyled>
      <span>
        <input name="mostStronglyAgree" />
        <label>Least like</label>
      </span>
      <span>
        <input name="mostStronglyDisagree" />
        <label>Most like</label>
      </span>
    </ButtonsGroupStyled>
  );
};

export const WaiHelp = () => {
  return (
    <ContainerStyled>
      <TitleStyled>
        1. Click the button in the MOST and LEAST columns
      </TitleStyled>
      <SubTitleStyled>Which corresponds to the job...</SubTitleStyled>
      <LabelWrapper>
        <LabelStyled>
          <span>Least like</span>
          <span>Most like</span>
        </LabelStyled>
      </LabelWrapper>
      <SectionStyled>
        {answers.map((answer, index) => (
          <ItemStyled>
            <div>{`${index + 1}. ${answer.title}`}</div>
            <Switch />
          </ItemStyled>
        ))}
      </SectionStyled>
    </ContainerStyled>
  );
};

const LabelWrapper = styled.div`
  max-width: 680px;
  display: flex;
  justify-content: flex-end;
`;

const LabelStyled = styled.div`
  width: 280px;
  display: flex;
  align-self: flex-end;
  justify-content: space-around;
  height: auto;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  
  @media (max-width: 760px) {
    width: 175px;
    font-size: 14px;
  }
  
  span {
    padding: 10px 0;
    width: 35%;
    text-align: center;
  }
`;

const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
`;

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const ButtonsGroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 280px;
  width: 100%;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background: #babab9;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-top: 4px;

  span {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkFontColor};
    position: relative;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #e7e7e7;
    background: rgba(73, 73, 73, 0.1);
    width: 100%;
    border-radius: 50%;
    height: 100%;
    color: transparent;
    text-transform: uppercase;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};

  svg {
    z-index: 2;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    margin-bottom: 35px;
  }
`;

const TitleStyled = styled.b`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const SubTitleStyled = styled.span`
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 18px;
    line-height: 28px;
  }
`;
