import React from 'react';
import styled from 'styled-components';
import { QuestionTextStyled } from './shared/components';

// TODO: unused -> remove
export const FreeTextItem = ({ values, className, onResponse, selectedResponse, onBlur, onFocus }) => {
  const handleTextChange = React.useCallback(
    (evt) => {
      onResponse(evt.target.value);
    },
    [onResponse],
  );

  React.useEffect(() => {
    const response = typeof selectedResponse === 'string' ? selectedResponse : '';
    onResponse(response);
  }, [onResponse, selectedResponse]);

  const value = typeof selectedResponse === 'string' ? selectedResponse : '';

  const mainitem = values.mainitem;
  const instruction = values.instruction;

  return (
    <OuterWrapperStyled>
      <InnerWrapperStyled>
        {instruction ? (
          <InstructionTextStyled
            dangerouslySetInnerHTML={{
              __html: instruction,
            }}
          />
        ) : (
          ''
        )}
        <></>
        <QuestionTextStyled
          dangerouslySetInnerHTML={{
            __html: mainitem,
          }}
          className={className}
        />
        <TextAreaWrapper>
          <TextAreaStyled onChange={handleTextChange} value={value} onBlur={onBlur} onFocus={onFocus} />
        </TextAreaWrapper>
      </InnerWrapperStyled>
    </OuterWrapperStyled>
  );
};

const TextAreaStyled = styled.textarea`
  z-index: 10;
  position: relative;
  border: 2px solid #1aabe1;
  outline: none;
  border-radius: 4px;
  min-width: 560px;
  height: 200px;
  flex-shrink: 0;

  @media (max-width: ${({ theme }) => theme.viewports.md}) {
    min-width: auto;
    width: 90%;
  }
`;

const TextAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
`;

const InstructionTextStyled = styled.div`
  width: 100%;

  * {
    background-color: transparent !important;
  }
`;

const OuterWrapperStyled = styled.div`
  padding: 0 20px;
  padding-top: 64px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const InnerWrapperStyled = styled.div`
  align-self: center;
  max-width: 1100px;
  justify-self: start;
`;
