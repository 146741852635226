import React from "react";
import { compose } from "redux";
import { Form, withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import clsx from "clsx";
import { connect } from "react-redux";
import { ButtonsHolder, Page, Title } from "../shared/layout";
import { ContainerStyled } from "../templates/shared/components";
import { Input } from "../shared/formElements";
import { FormField } from "../shared/FormField";
import { Button, ButtonVariants, ButtonTypes } from "../shared/Button";
import { Logo } from "../shared/Logo";
import { Spinner } from "../shared/Spinner";
import { ModalBox } from "../shared/layout/Page";
import { setRespondentData } from "../../store/actions/questionnaireDetailsActions";
import { assessmentApi, self360Api } from "../../utils/agent";
import { self360Store } from "../pages/Self360/store";

const LoginPage = styled(Page)`
  justify-content: center;
`;

const LogoStyled = styled(Logo)`
  svg {
    fill: ${({ theme }) => theme.colors.medGray};
    height: 80px;
  }
`;

const ErrorStyled = styled.div`
  color: ${({ theme }) => theme.colors.error};
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  width: 100%;
  max-width: 280px;
  height: 40px;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    justify-content: initial;
    min-height: 60px;
    height: auto;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 3px solid;
    font-size: 25px;
    font-weight: 600;
    order: -2;
  }

  &:before {
    display: inline-flex;
    min-width: 10px;
    content: "";
    order: -1;
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

const LoginComponent = ({
  match,
  isSubmitting,
  status,
  setStatus,
  values,
  mode,
}) => {
  const { lang } = match.params;
  const { t, i18n } = useTranslation();

  const l10nConfig = {
    login: {
      title: "assessmentsessiondetails",
      error: "invalidsessiondetails",
    },
    login360: {
      title: "assessmentsessiondetails",
      error: "invalidsessiondetails",
    },
    auto360: {
      title: "Assessment Details",
      error: "Invalid Details",
    },
  };

  const l10n = l10nConfig[mode];

  React.useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  const isLoginFailed = typeof status !== "undefined" && status.isLoginFailed;
  const isEmptyFields = !values.invitationId || !values.respondentId;

  return (
    <LoginPage withBackground>
      <ModalBox>
        <LogoStyled />
        <Title>{t(l10n.title)}</Title>
        <ErrorStyled>
          {isLoginFailed && !isSubmitting && (
            <>
              <span>!</span>
              {t(l10n.error)}
            </>
          )}
        </ErrorStyled>
        <Form
          autoComplete="off"
          onChange={() => {
            setStatus({ isLoginFailed: false });
          }}
        >
          <ContainerStyled>
            <FormField
              name="invitationId"
              label={t("invitationid")}
              component={Input}
              autoComplete="off"
              disabled={isSubmitting}
              modern
              type="text"
              className={clsx({
                shake: isLoginFailed && !isSubmitting,
              })}
              autoFocus={isLoginFailed && !isSubmitting} // TODO (not working)
            />
            <FormField
              name="respondentId"
              label={t("respondentid")}
              component={Input}
              autoComplete="off"
              disabled={isSubmitting}
              modern
              type="text"
              className={clsx({
                shake: isLoginFailed && !isSubmitting,
              })}
            />
            <ButtonsHolder>
              <ButtonStyled
                variant={ButtonVariants.PRIMARY}
                type={ButtonTypes.SUBMIT}
                disabled={isSubmitting || isEmptyFields}
                autoFocus
              >
                {isSubmitting ? <Spinner small /> : t("continue")}
              </ButtonStyled>
            </ButtonsHolder>
          </ContainerStyled>
        </Form>
      </ModalBox>
    </LoginPage>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRespondentData: (data) => {
      dispatch(setRespondentData(data));
    },
  };
};

export const Login = compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({ invitationId: "", respondentId: "" }),
    handleSubmit: (values, { setSubmitting, setStatus, props }) => {
      const { history, mode, match, setRespondentData } = props;
      const { invitationId, respondentId } = values;

      const loginStrategies = {
        login: {
          apiAction: assessmentApi.login,
          apiSuccess: (data) => setRespondentData(data),
          routePath: "Start",
        },
        login360: {
          apiAction: assessmentApi.login360,
          apiSuccess: (data) => setRespondentData(data.qInvitation),
          routePath: "Start360",
        },
        auto360: {
          apiAction: self360Api.fetchInvitation,
          apiSuccess: self360Store.setInvitationDetails,
          routePath: "Auto360",
        },
      };

      const loginStrategy = loginStrategies[mode];

      const setInvalidAssesmentDetails = () => {
        setSubmitting(false);
        setStatus({ isLoginFailed: true });
      };

      loginStrategy
        .apiAction(invitationId, respondentId)
        .then(({ data }) => {
          if (typeof data === "object" && data !== "a") {
            loginStrategy.apiSuccess(data);

            history.push(
              `/${loginStrategy.routePath}/${invitationId}/${respondentId}/${
                match.params.lang ? match.params.lang : ""
              }`
            );
          } else {
            setInvalidAssesmentDetails();
          }
        })
        .catch(() => {
          setInvalidAssesmentDetails();
        });
    },
  })
)(LoginComponent);
