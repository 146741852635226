import * as React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import {
  Header,
  Footer,
  Body,
  BodyItem,
  BodyList,
  BodyListItem,
} from "../components";
import { CustomButton } from "../../../shared/Buttons/Button";
import { useSelf360RouteConfig } from "../hooks/useSelf360RouteConfig";
import { useSelf360Store } from "../hooks/useSelf360Store";

export const WelcomePage = observer(() => {
  const { t } = useTranslation();
  const store = useSelf360Store();
  const invitationDetails = store.invitationDetails.invitation;
  const history = useHistory();
  const routeConfig = useSelf360RouteConfig(store.routeDetails);

  return (
    <div>
      <Header
        title={`${t("Welcome", {
          firstName: invitationDetails.firstName,
          familyName: invitationDetails.familyName,
        })} 
        `}
      />

      <Body>
        <BodyItem>
          {t(
            "As part of your 360 degree appraisal you have been asked to specify which of your colleagues will rate you"
          )}
        </BodyItem>

        <BodyItem>
          {t("While selecting your raters please ensure that you")}
        </BodyItem>

        <BodyList>
          <BodyListItem>
            {t(
              "Pick a selection of your colleagues from all available role where possible"
            )}
          </BodyListItem>
          <BodyListItem>
            {t(
              "Make sure your colleagues are aware that you have selected them and that they are happy to take part in this process They will be receiving an invitation to complete an online questionnaire shortly"
            )}
          </BodyListItem>
          <BodyListItem>
            {t("Complete this process prior to your appraisal deadline")}
          </BodyListItem>
        </BodyList>

        <BodyItem>
          {t(
            "Once you have selected your raters you may submit them for validation by your administrator"
          )}
        </BodyItem>
      </Body>

      <Footer isSingleItem>
        <CustomButton
          width={124}
          onClick={() => history.push(routeConfig.addRaters())}
        >
          {t("next")}
        </CustomButton>
      </Footer>
    </div>
  );
});
