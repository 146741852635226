import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import parse from 'url-parse';

const { pathname } = parse(window.location.href);
const pathnameParts = pathname.split('/');
const preloadLang = pathnameParts[pathnameParts.length - 1];
const isoLangRegexp =
  /^(af|chhk|du|fl|it|pt|th|ar|chtr|en|fr|ko|pt-br|tr|az|cz|enus|gr|mk|ro|ua|bg|de|es|hi|he|no|ru|ur|ch|dk|es-latam|in|pl|sr|vn|bs|hu|hr|me|sl|mn|)$/gi;
const isLangAvailable = isoLangRegexp.test(preloadLang);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ...(isLangAvailable && { lng: preloadLang.toLowerCase() }),
    fallbackLng: 'en',
    // debug: true,
    debug: false, // TODO return to "true" later
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
