import React from "react";
import { compose } from "redux";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import clsx from "clsx";
import { FormField } from "../shared/FormField";
import { Input } from "../shared/formElements";
import { ButtonsHolder, Page, Title } from "../shared/layout";
import { Button, ButtonTypes, ButtonVariants } from "../shared/Button";
import { CheckMarkImage } from "../shared/CheckMarkImage";
import { ContainerStyled } from "../templates/shared/components";
import { Spinner } from "../shared/Spinner";
import { ModalBox } from "../shared/layout/Page";
import { assessmentApi } from "../../utils/agent";
import { LocalStorageService } from "../../utils/LocalStorage.service";

const MessageStyled = styled.div`
  margin-bottom: 20px;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.error};
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 20px;
`;

const TitleStyled = styled(Title)`
  margin-top: 50px;
`;

const ModalBoxStyled = styled(ModalBox)`
  padding: 0 40px;
`;

const PinPage = styled(Page)`
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const PinComponent = ({ isSubmitting, status, setStatus, values }) => {
  const { t } = useTranslation();

  const isEmptyField = !values.pin;
  const isPinFailed = typeof status !== "undefined" && status.isPinFailed;

  return (
    <PinPage withBackground>
      <ModalBoxStyled>
        <CheckMarkImage />
        <TitleStyled>{t("assessmentCompleted")}</TitleStyled>
        <MessageStyled>{t("pleaseCallAdministrator")}</MessageStyled>
        <Form
          autoComplete="off"
          onChange={() => {
            setStatus({ isPinFailed: false });
          }}
        >
          <ContainerStyled>
            <FormField
              name="pin"
              id="pin"
              label={t("PIN")}
              type="tel"
              component={Input}
              autoComplete="off"
              className={clsx(
                {
                  shake: isPinFailed && !isSubmitting,
                },
                "numeric-password"
              )}
              disabled={isSubmitting}
              modern
              autoFocus
              onFocus={() => {
                setStatus({ isPinFailed: false });
              }}
            />
            <ButtonsHolder>
              <ButtonStyled
                variant={ButtonVariants.PRIMARY}
                type={ButtonTypes.SUBMIT}
                disabled={isSubmitting || isEmptyField}
              >
                {isSubmitting ? <Spinner small /> : t("ok")}
              </ButtonStyled>
            </ButtonsHolder>
          </ContainerStyled>
        </Form>
      </ModalBoxStyled>
    </PinPage>
  );
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: { details },
  } = state;

  return {
    userId: details.userId,
  };
};

export const Pin = compose(
  connect(mapStateToProps),
  withRouter,
  withFormik({
    mapPropsToValues: () => ({ pin: "" }),
    handleSubmit: (values, { setErrors, setSubmitting, setStatus, props }) => {
      const { userId } = props;
      const token = LocalStorageService.getToken();

      const setInvalidPin = () => {
        setSubmitting(false);
        setStatus({ isPinFailed: true });
        setErrors({
          pin: "Invalid PIN",
        });
      };

      assessmentApi
        .pin(userId, values.pin, token)
        .then(({ data }) => {
          if (
            typeof data === "string" &&
            data === "Error creating authentication token"
          ) {
            setInvalidPin();
            return;
          }
          LocalStorageService.removeToken();
          document.location.replace(
            `${window._env_.REACT_APP_MANAGEMENT_APP_URL}/auth/${data.token}/refresh/${data.refreshToken}`
          );
        })
        .catch(() => {
          setInvalidPin();
        });
    },
    validationSchema: () => {
      return Yup.object().shape({
        pin: Yup.string().required("This field is required"),
      });
    },
  })
)(PinComponent);
