import React from "react";

export const NumNine = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.014 15C22.3113 15 23.3053 15.4293 23.996 16.288C24.6866 17.1373 25.032 18.3413 25.032 19.9C25.032 20.992 24.8453 21.916 24.472 22.672C24.0986 23.428 23.576 24.002 22.904 24.394C22.232 24.7767 21.448 24.968 20.552 24.968C19.5813 24.968 18.8066 24.7907 18.228 24.436L18.634 23.624C19.1006 23.932 19.7353 24.086 20.538 24.086C21.6206 24.086 22.47 23.7453 23.086 23.064C23.702 22.3733 24.01 21.3747 24.01 20.068C24.01 19.8253 23.996 19.5593 23.968 19.27C23.7253 19.802 23.338 20.2173 22.806 20.516C22.274 20.8053 21.6626 20.95 20.972 20.95C20.328 20.95 19.754 20.8287 19.25 20.586C18.7553 20.3433 18.368 20.0027 18.088 19.564C17.808 19.116 17.668 18.598 17.668 18.01C17.668 17.4127 17.8126 16.8853 18.102 16.428C18.3913 15.9707 18.788 15.6207 19.292 15.378C19.8053 15.126 20.3793 15 21.014 15ZM21.126 20.096C21.6206 20.096 22.0546 20.0027 22.428 19.816C22.8106 19.6293 23.1093 19.3727 23.324 19.046C23.5386 18.7193 23.646 18.3553 23.646 17.954C23.646 17.5807 23.5433 17.2353 23.338 16.918C23.142 16.6007 22.848 16.344 22.456 16.148C22.064 15.952 21.5973 15.854 21.056 15.854C20.3466 15.854 19.768 16.05 19.32 16.442C18.8813 16.8247 18.662 17.338 18.662 17.982C18.662 18.626 18.8813 19.1393 19.32 19.522C19.768 19.9047 20.37 20.096 21.126 20.096Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
