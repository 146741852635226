import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  KeyArrowsUpDown,
  KeyPageDown,
  KeyPageUp,
  KeyArrowLeft,
  KeyArrowRight,
} from "./icons";
import { KeysHelpHolder } from "./KeysHelpHolder";
import { Divider } from "../../templates/shared/components";

export function NavigationKeys() {
  const { t } = useTranslation();
  const KeysStyled = styled.div`
    direction: ltr;
  `;
  const KeyArrowsUpDownStyled = styled(KeyArrowsUpDown)`
    filter: none !important;
  `;

  return (
    <KeysHelpHolder
      title={t("moveBackwardsAndForwards")}
      subtitle={t("usingTheArrowsKeys")}
      desktop
    >
      <KeysStyled>
        <KeyPageUp />
        <KeyPageDown />
        <Divider />
        <KeyArrowLeft />
        <KeyArrowsUpDownStyled />
        <KeyArrowRight />
      </KeysStyled>
    </KeysHelpHolder>
  );
}
