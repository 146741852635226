import React from "react";

export const KeyPageUp = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.271 40H2.02951C0.913279 40 0 39.0886 0 37.9747V2.02532C0 0.911392 0.913279 0 2.02951 0H39.271C40.3872 0 41.3005 0.911392 41.3005 2.02532V37.9747C41.3005 39.0886 40.3872 40 39.271 40Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M11.2037 10.01C12.2637 10.01 13.037 10.2133 13.5237 10.62C14.0104 11.0267 14.2537 11.6533 14.2537 12.5C14.2537 13.3467 14.0037 13.9667 13.5037 14.36C13.0104 14.7533 12.2504 14.95 11.2237 14.95H9.98371V17H8.80371V10.01H11.2037ZM11.3537 13.88C12.007 13.88 12.4504 13.7567 12.6837 13.51C12.9237 13.2567 13.0437 12.89 13.0437 12.41C13.0437 11.9233 12.8937 11.58 12.5937 11.38C12.2937 11.18 11.8237 11.08 11.1837 11.08H9.98371V13.88H11.3537Z"
        fill="white"
      />
      <path
        d="M19.692 17H18.672V16.29C18.232 16.8167 17.642 17.08 16.902 17.08C16.3487 17.08 15.8887 16.9233 15.522 16.61C15.162 16.2967 14.982 15.88 14.982 15.36C14.982 14.8333 15.1753 14.44 15.562 14.18C15.9487 13.92 16.472 13.79 17.132 13.79H18.582V13.59C18.582 12.8833 18.1887 12.53 17.402 12.53C16.9087 12.53 16.3953 12.71 15.862 13.07L15.362 12.37C16.0087 11.8567 16.742 11.6 17.562 11.6C18.1887 11.6 18.6987 11.76 19.092 12.08C19.492 12.3933 19.692 12.89 19.692 13.57V17ZM18.572 15.02V14.57H17.312C16.5053 14.57 16.102 14.8233 16.102 15.33C16.102 15.59 16.202 15.79 16.402 15.93C16.602 16.0633 16.8787 16.13 17.232 16.13C17.592 16.13 17.9053 16.03 18.172 15.83C18.4387 15.63 18.572 15.36 18.572 15.02Z"
        fill="white"
      />
      <path
        d="M26.1711 11.68V16.32C26.1711 17.26 25.9145 17.9633 25.4011 18.43C24.8945 18.9033 24.2411 19.14 23.4411 19.14C22.6411 19.14 21.9178 18.89 21.2711 18.39L21.8011 17.54C22.3211 17.9333 22.8445 18.13 23.3711 18.13C23.9045 18.13 24.3245 17.9933 24.6311 17.72C24.9445 17.4467 25.1011 17.01 25.1011 16.41V15.73C24.9345 16.0433 24.6945 16.2933 24.3811 16.48C24.0745 16.6667 23.7278 16.76 23.3411 16.76C22.6345 16.76 22.0545 16.5133 21.6011 16.02C21.1478 15.5267 20.9211 14.9133 20.9211 14.18C20.9211 13.4467 21.1478 12.8333 21.6011 12.34C22.0545 11.8467 22.6311 11.6 23.3311 11.6C24.0378 11.6 24.6111 11.89 25.0511 12.47V11.68H26.1711ZM22.0211 14.14C22.0211 14.5733 22.1545 14.95 22.4211 15.27C22.6945 15.5833 23.0578 15.74 23.5111 15.74C23.9711 15.74 24.3411 15.5867 24.6211 15.28C24.9078 14.9733 25.0511 14.5933 25.0511 14.14C25.0511 13.68 24.9078 13.2933 24.6211 12.98C24.3345 12.66 23.9645 12.5 23.5111 12.5C23.0578 12.5 22.6945 12.6633 22.4211 12.99C22.1545 13.3167 22.0211 13.7 22.0211 14.14Z"
        fill="white"
      />
      <path
        d="M32.7441 14.83H28.5741C28.6008 15.2167 28.7774 15.53 29.1041 15.77C29.4308 16.0033 29.8041 16.12 30.2241 16.12C30.8908 16.12 31.3974 15.91 31.7441 15.49L32.3841 16.19C31.8108 16.7833 31.0641 17.08 30.1441 17.08C29.3974 17.08 28.7608 16.8333 28.2341 16.34C27.7141 15.84 27.4541 15.1733 27.4541 14.34C27.4541 13.5 27.7208 12.8333 28.2541 12.34C28.7874 11.8467 29.4141 11.6 30.1341 11.6C30.8608 11.6 31.4774 11.82 31.9841 12.26C32.4908 12.6933 32.7441 13.2933 32.7441 14.06V14.83ZM28.5741 13.95H31.6241C31.6241 13.5033 31.4841 13.16 31.2041 12.92C30.9241 12.68 30.5774 12.56 30.1641 12.56C29.7574 12.56 29.3908 12.6867 29.0641 12.94C28.7374 13.1867 28.5741 13.5233 28.5741 13.95Z"
        fill="white"
      />
      <path
        d="M15.8425 27.4C16.1625 27.7733 16.5958 27.96 17.1425 27.96C17.6891 27.96 18.1225 27.7733 18.4425 27.4C18.7625 27.0267 18.9225 26.52 18.9225 25.88V22.01H20.1025V25.93C20.1025 26.9367 19.8258 27.7133 19.2725 28.26C18.7191 28.8 18.0091 29.07 17.1425 29.07C16.2758 29.07 15.5658 28.8 15.0125 28.26C14.4591 27.7133 14.1825 26.9367 14.1825 25.93V22.01H15.3625V25.88C15.3625 26.52 15.5225 27.0267 15.8425 27.4Z"
        fill="white"
      />
      <path
        d="M24.6382 23.6C25.3516 23.6 25.9616 23.8467 26.4682 24.34C26.9749 24.8333 27.2282 25.49 27.2282 26.31C27.2282 27.13 26.9749 27.7967 26.4682 28.31C25.9682 28.8233 25.3816 29.08 24.7082 29.08C24.0416 29.08 23.4416 28.7867 22.9082 28.2V30.94H21.7882V23.68H22.9082V24.61C23.3482 23.9367 23.9249 23.6 24.6382 23.6ZM22.8882 26.35C22.8882 26.8567 23.0416 27.2733 23.3482 27.6C23.6549 27.92 24.0282 28.08 24.4682 28.08C24.9082 28.08 25.2916 27.92 25.6182 27.6C25.9449 27.28 26.1082 26.8667 26.1082 26.36C26.1082 25.8467 25.9482 25.4233 25.6282 25.09C25.3082 24.75 24.9249 24.58 24.4782 24.58C24.0316 24.58 23.6549 24.75 23.3482 25.09C23.0416 25.4233 22.8882 25.8433 22.8882 26.35Z"
        fill="white"
      />
    </svg>
  );
};
