import media, { viewports } from './media';

export const theme = {
  widthWrapper: '1268px',

  colors: {
    primary: '#375563',
    dark: '#07668A',
    medium: '#008AC0',
    light: '#1BAAE1',
    ultraLight: '#D1EEF9',
    white: '#FFFFFF',
    whiteFontColor: '#FFFFFF',
    grey: '#303134',
    grey2: '#CDCDCD',
    black: '#000000',
    font: '#375563',
    error: '#ff574c',
    success: '#008000',
    darkFontColor: '#06182E',

    gray: '#e6e6e6',
    medGray: '#375563',
    lightGray: '#a2abab',
    primaryBlue: '#17a9e1',
    lightBlue: '#95d7f1',
    darkBlue: '#295368',
    mediumBlue: '#008AC0',
    primaryDark: '#295368',
    borderColor: '#a2abab80',
    modalBackgroundColor: '#f9f9f9',
    modalBackgroundColorTransparent: 'rgba(0, 0, 0, 0.5)',
    progressBar: '#b6c1c5',
    bg: '#F9F9F9',
    primaryHover: '#DDEDFC',
    secondaryLightBlue: '#40C4FF',
    secondaryYellow: '#FFEC40',
    red: '#FF574C',
    secondaryRed: '#DC2323',
    bgFooter: '#1AAAE1',
    gray2: '#ACACAC',
    grey5: '#5B5B5B',

    new: {
      lightBlue: '#DFF6FF',
      darkBlue: '#07668A',
      darkBlue2: '#295368',
      grey1: '#F9F9F9',
    },
  },

  button: {
    SidebarBackgroundActive: '#07668a',
    Height: '44px',
    PrimaryColor: '#FFFFFF',
    Primary: '#17A9E1',
    PrimaryHover: '#138BB9',
    PrimaryActive: '#07668a',
    SecondaryColor: '#17A9E1',
    Secondary: '#FFFFFF',
    SecondaryHover: '#17A9E11A',
    SecondaryActive: '#17A9E133',
    Disabled: '#D1EEF9',
    DisabledColor: '#ffffff',
    DisabledBackground: '#e0e5e8',
    FontSize: '14px',
    FontWeight: 'bold',
    MinWidth: '140px',
  },

  heightHeaderMobile: '60px',

  shadows: {
    modalShadow: '0 24px 44px rgba(0, 0, 0, 0.25)',
    boxShadowCard: '1px 1px 0px #000000',
  },

  viewports,

  header: {
    height: '40px',
  },

  footer: {
    height: '54px',
    heightDesktop: '80px',
    backgroundColor: '#1AAAE1',
  },

  formElements: {
    height: '52px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    fontSize: '18px',
    fontColor: '#375563',
    background: '#FFFFFF',
    focusBorderColor: '#41b8e6',
    focusBackground: '#e7f6fc',
    hoverBackground: '#138bb9',
    checked: '#949494',
    notSelected: '#ACACAC',
    error: '#FF312A',
  },

  fontSizes: {
    small: '1.2rem',
    regular: '1.4rem',
    normal: '1.6rem',
    medium: '1.8rem',
    large: '2.2rem',
  },

  fontWeight: {
    bold: 700,
    semiBold: 600,
  },
  ...media,
};
