import React from "react";

export const NumThree = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6088 19.2C21.6355 19.256 22.4148 19.536 22.9468 20.04C23.4881 20.5347 23.7588 21.1833 23.7588 21.986C23.7588 22.546 23.6235 23.0453 23.3528 23.484C23.0821 23.9133 22.6808 24.254 22.1488 24.506C21.6168 24.758 20.9681 24.884 20.2028 24.884C19.4935 24.884 18.8215 24.772 18.1868 24.548C17.5615 24.3147 17.0575 24.0067 16.6748 23.624L17.1648 22.826C17.4915 23.162 17.9255 23.4373 18.4668 23.652C19.0081 23.8573 19.5868 23.96 20.2028 23.96C21.0055 23.96 21.6261 23.7873 22.0648 23.442C22.5035 23.0873 22.7228 22.602 22.7228 21.986C22.7228 21.3793 22.5035 20.9033 22.0648 20.558C21.6261 20.2127 20.9635 20.04 20.0768 20.04H19.3768V19.298L22.0928 15.896H17.0808V15H23.3808V15.714L20.6088 19.2Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
