import React from "react";

export const NumTwo = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9414 23.988V24.884H18.0674V24.17L22.1414 20.18C22.6548 19.676 23.0001 19.242 23.1774 18.878C23.3641 18.5047 23.4574 18.1313 23.4574 17.758C23.4574 17.1793 23.2568 16.7313 22.8554 16.414C22.4634 16.0873 21.8988 15.924 21.1614 15.924C20.0134 15.924 19.1221 16.288 18.4874 17.016L17.7734 16.4C18.1561 15.952 18.6414 15.6067 19.2294 15.364C19.8268 15.1213 20.4988 15 21.2454 15C22.2441 15 23.0328 15.238 23.6114 15.714C24.1994 16.1807 24.4934 16.8247 24.4934 17.646C24.4934 18.15 24.3814 18.6353 24.1574 19.102C23.9334 19.5687 23.5088 20.1053 22.8834 20.712L19.5514 23.988H24.9414Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
