import React from "react";
import styled from "styled-components";
import Timecode from "react-timecode";

const TimerWrapperStyled = styled.div`
  min-width: 110px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  width: auto;
  flex-grow: 1;
  
  svg {
    fill: #00A8E3;
    opacity: 0.5;
  }
  
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    svg {
      opacity: 1;
    }
  }

  }
`;

const TimerStyled = styled.span`
  font-size: 18px;
  font-weight: 600;
  min-width: 60px;
  color: #00a8e3;

  &:before {
    display: inline-block;
    content: " ";
    min-width: 9px;
  }

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 14px;
    min-width: 75px;
    &:before {
      min-width: 4px;
    }
  }
`;

export const Timer = ({ innerTime }) => {
  return (
    <TimerWrapperStyled>
      <svg width="24.038" height="24.206">
        <path d="M7.067 1.839L5.523 0 0 4.633l1.544 1.839zM24.038 4.639L18.515.006l-1.544 1.839 5.523 4.633zM12.013 2.572a10.817 10.817 0 1010.823 10.817A10.809 10.809 0 0012.013 2.572zm.006 19.231a8.413 8.413 0 118.413-8.413 8.415 8.415 0 01-8.413 8.413z" />
        <path d="M12.624 7.38h-1.8v7.213l5.7 3.431.907-1.484-4.807-2.847z" />
      </svg>
      <TimerStyled>
        <Timecode time={innerTime * 1000} format="mm:ss" />
      </TimerStyled>
    </TimerWrapperStyled>
  );
};
