import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Title } from './layout';

const BlockMobileScreenStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BlockMobileScreen = () => {
  const { t } = useTranslation();
  return (
    <BlockMobileScreenStyled>
      <svg width="93" height="178" viewBox="0 0 93 178" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.5658 106.175H74.2149L46.8904 59.3333L19.5658 106.175ZM49.3744 98.7793H44.4063V93.8486H49.3744V98.7793ZM49.3744 88.9178H44.4063V79.0563H49.3744V88.9178Z"
          fill="#1BAAE1"
        />
        <path
          d="M20.1903 0H72.2163C78.5337 0 81.135 0.741667 83.3647 1.85417C85.5944 3.3375 87.4525 5.19167 88.9389 7.41667C90.0538 9.64167 90.797 12.2375 90.797 18.5417V159.458C90.797 165.762 90.0538 168.358 88.9389 170.583C87.8241 172.808 85.966 174.662 83.3647 176.146C81.135 177.258 78.5337 178 72.2163 178H20.1903C13.8729 178 11.2716 177.258 9.0419 176.146C6.81222 175.033 4.95415 172.808 3.46769 170.583C2.35285 168.358 1.60962 165.762 1.60962 159.458V18.5417C1.60962 12.2375 2.35285 9.64167 3.46769 7.41667C4.58253 5.19167 6.4406 3.3375 9.0419 1.85417C11.6432 0.370833 13.5013 0 20.1903 0ZM22.7916 5.5625H19.8187C15.3593 5.5625 13.8729 5.93333 12.3864 7.04583C10.9 7.7875 9.41351 9.27083 8.67029 10.7542C7.55544 12.2375 7.18383 13.7208 7.18383 18.1708V159.829C7.18383 164.279 7.55544 165.762 8.67029 167.246C9.41351 168.729 10.9 170.212 12.3864 170.954C13.8729 171.696 15.731 172.438 19.8187 172.438H72.5879C77.0473 172.438 78.5337 172.067 80.0202 170.954C81.5066 170.212 82.9931 168.729 83.7363 167.246C84.4796 165.762 85.2228 163.908 85.2228 159.829V18.1708C85.2228 13.7208 84.8512 12.2375 83.7363 10.7542C82.9931 9.27083 81.5066 7.7875 80.0202 7.04583C78.5337 6.30417 76.6757 5.5625 72.5879 5.5625H69.615C69.2434 5.93333 68.8718 6.30417 68.8718 7.04583C68.8718 8.52917 67.3853 11.8667 63.6692 11.8667C59.953 11.8667 31.7104 11.8667 27.9942 11.8667C24.6497 11.8667 22.7916 8.52917 22.7916 7.04583C23.1632 6.30417 23.1632 5.93333 22.7916 5.5625Z"
          fill="#008AC0"
        />
      </svg>
      <Title>{t('pleaseUseTablet')}</Title>
    </BlockMobileScreenStyled>
  );
};

const RotatePhoneScreenStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @keyframes phone-rotate {
    6% {
      transform: rotateZ(0deg);
    }
    30% {
      transform: rotateZ(90deg);
    }
    70% {
      transform: rotateZ(90deg);
    }
    94% {
      transform: rotateZ(0deg);
    }
  }

  & #phone {
    animation: phone-rotate 3s ease-in-out infinite;
  }
`;

export const RotateToLandscapeScreen = () => {
  const { t } = useTranslation();
  return (
    <RotatePhoneScreenStyled>
      <svg id="phone" width="93" height="178" viewBox="0 0 93 178" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64.6559 86.656C64.6559 95.7298 58.144 103.31 49.5483 104.972V110L35.8805 102.163L49.5483 94.3259V98.929C54.8728 97.3871 58.7782 92.4705 58.7782 86.6558C58.7782 79.61 53.0458 73.8776 45.9999 73.8776C38.954 73.8776 33.2216 79.61 33.2216 86.6558C33.2216 88.5536 33.6268 90.3786 34.4262 92.0806L29.106 94.5795C27.9367 92.0898 27.3439 89.4241 27.3439 86.656C27.3441 76.3692 35.7132 68 46.0001 68C56.287 68 64.6559 76.3692 64.6559 86.656Z"
          fill="#1BAAE1"
        />
        <path
          d="M20.1903 0H72.2163C78.5337 0 81.135 0.741667 83.3647 1.85417C85.5944 3.3375 87.4525 5.19167 88.9389 7.41667C90.0538 9.64167 90.797 12.2375 90.797 18.5417V159.458C90.797 165.762 90.0538 168.358 88.9389 170.583C87.8241 172.808 85.966 174.662 83.3647 176.146C81.135 177.258 78.5337 178 72.2163 178H20.1903C13.8729 178 11.2716 177.258 9.0419 176.146C6.81222 175.033 4.95415 172.808 3.46769 170.583C2.35285 168.358 1.60962 165.762 1.60962 159.458V18.5417C1.60962 12.2375 2.35285 9.64167 3.46769 7.41667C4.58253 5.19167 6.4406 3.3375 9.0419 1.85417C11.6432 0.370833 13.5013 0 20.1903 0ZM22.7916 5.5625H19.8187C15.3593 5.5625 13.8729 5.93333 12.3864 7.04583C10.9 7.7875 9.41351 9.27083 8.67029 10.7542C7.55544 12.2375 7.18383 13.7208 7.18383 18.1708V159.829C7.18383 164.279 7.55544 165.762 8.67029 167.246C9.41351 168.729 10.9 170.212 12.3864 170.954C13.8729 171.696 15.731 172.438 19.8187 172.438H72.5879C77.0473 172.438 78.5337 172.067 80.0202 170.954C81.5066 170.212 82.9931 168.729 83.7363 167.246C84.4796 165.762 85.2228 163.908 85.2228 159.829V18.1708C85.2228 13.7208 84.8512 12.2375 83.7363 10.7542C82.9931 9.27083 81.5066 7.7875 80.0202 7.04583C78.5337 6.30417 76.6757 5.5625 72.5879 5.5625H69.615C69.2434 5.93333 68.8718 6.30417 68.8718 7.04583C68.8718 8.52917 67.3853 11.8667 63.6692 11.8667C59.953 11.8667 31.7104 11.8667 27.9942 11.8667C24.6497 11.8667 22.7916 8.52917 22.7916 7.04583C23.1632 6.30417 23.1632 5.93333 22.7916 5.5625Z"
          fill="#008AC0"
        />
      </svg>

      <Title>{t('onlyLandscapeModePleaseRotateYourPhone')}</Title>
    </RotatePhoneScreenStyled>
  );
};
