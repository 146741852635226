import React from "react";
import styled from "styled-components";
import { ReactComponent as Close } from "./icons/close.svg";

export const CloseIcon = ({ clickHandler, className }) => {
  return <StyledIcon onClick={clickHandler} className={className} />;
};

const StyledIcon = styled(Close)`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  path {
    transition: all 0.2s;
  }

  path {
    fill: ${(props) => props.theme.colors.darkBlue};
  }

  :hover {
    path {
      fill: ${(props) => props.theme.colors.mediumBlue};
    }
  }
`;
