import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import {
  GlyphsWrapperStyled,
  GlyphStyled,
  FeedbackStyled,
  HalfGlyphStyled,
  GlyphsContainerStyled,
} from './shared/components';
import { CardsList } from '../shared/CardsList';
import { InstructionModal } from '../shared/InstructionModal';
import { GlyphSet } from './shared/GlyphSet';
import { LangDirectionContext } from '../../context/LangDirectionContext';
import { isMobile } from '../shared/utils/isMobile';

const MobileFullWidthDiv = styled.span`
  &.text {
    ${({ theme }) => theme.xs`
       line-height: 20px !important;
       font-weight: 600 !important;
       flex-basis: 100%;
       margin: 3rem 0;
    `}
  }
`;

const CustomGlyphsWrapper = styled(GlyphsWrapperStyled)`
  ${({ theme }) => theme.xs`
    justify-content: center;
  `}
`;

const BoldText = styled.span`
  &.text {
    ${({ theme }) => theme.xs`
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      margin: 0 1rem;
  `}
  }
`;

const StyledGlyphSet = styled(GlyphSet)`
  @media (max-width: ${({ theme }) => theme.viewports.xs}) and (orientation: portrait) {
    font-size: 19.3vw;
  }
`;

export const XIsToY = ({
  values: { responses, as, to0, to, isto, xtoy1, xtoy2, xtoy3, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
}) => {
  const testClass = testId.toLowerCase();
  const direction = React.useContext(LangDirectionContext);

  const [isInstructionModalVisible, setInstructionModalState] = useState(false);
  useEffect(() => {
    if (!isMobile) return;
    if (feedback && !isInstructionModalVisible) setInstructionModalState(true);
    if (!feedback && isInstructionModalVisible) setInstructionModalState(false);
  }, [feedback, isMobile]);

  return (
    <>
      {isMobile && (
        <InstructionModal
          isVisible={isInstructionModalVisible}
          onClose={() => setInstructionModalState(false)}
          instruction={feedback}
        />
      )}
      <CustomGlyphsWrapper>
        <GlyphsContainerStyled>
          <HalfGlyphStyled>
            {to0 && <div className="text">{to0}</div>}
            <GlyphStyled marginless>
              <StyledGlyphSet className={`icon icon-${xtoy1} ${testClass}`} />
            </GlyphStyled>
            <BoldText className="text">{to}</BoldText>
            <GlyphStyled marginless>
              <StyledGlyphSet className={`icon icon-${xtoy2} ${testClass}`} />
            </GlyphStyled>
          </HalfGlyphStyled>
          <MobileFullWidthDiv className="text">{as}</MobileFullWidthDiv>
          <HalfGlyphStyled>
            <GlyphStyled marginless>
              <StyledGlyphSet className={`icon icon-${xtoy3} ${testClass}`} />
            </GlyphStyled>
            <BoldText className="text">{isto}</BoldText>
            <GlyphStyled className="question" marginless>
              <StyledGlyphSet className={`icon icon-question ${testClass} ${direction}`} noPath />
            </GlyphStyled>
          </HalfGlyphStyled>
        </GlyphsContainerStyled>
        {!!feedback && !isMobile && (
          <FeedbackStyled
            dangerouslySetInnerHTML={{
              __html: feedback,
            }}
          />
        )}
      </CustomGlyphsWrapper>
      <CardsList
        isAbstract={config.isAbstract}
        className={clsx({ 'svg-image': config.isAbstract, xIsToY: true })}
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
        testId={testClass}
      />
    </>
  );
};
