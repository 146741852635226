import React, { useState } from 'react';
import { string, object, number, bool, func } from 'prop-types';
import styled from 'styled-components';

import { Draggable } from 'react-beautiful-dnd';

import { ReactComponent as RemoveIcon } from '../../../../assets/images/mex-close.svg';

const DraggableCard = ({ card, index, unremovable, onDelete, className }) => {
  const [showCloseIcon, setCloseIconState] = useState(false);

  return (
    <Draggable draggableId={card?.id} index={index}>
      {(provided, snapshot) => (
        <CardContainer
          className={className}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          onMouseOver={() => setCloseIconState(true)}
          onMouseLeave={() => setCloseIconState(false)}
        >
          {!unremovable && showCloseIcon && <CloseIcon onClick={onDelete} />}
          {card?.content}
        </CardContainer>
      )}
    </Draggable>
  );
};

DraggableCard.propTypes = {
  card: object.isRequired,
  index: number.isRequired,
  onDelete: func,
  unremovable: bool,
  className: string,
};

DraggableCard.defaultProps = {
  className: '',
  unremovable: false,
  onDelete: undefined,
};

const CardContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 28rem;
  min-height: 6rem;
  max-height: 6rem;
  padding: 1.6rem;
  background-color: ${(props) => (props.isDragging ? '#4dadd3' : '#008ac0')};
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0.8rem;
  color: #ffffff;
`;

const CloseIcon = styled(RemoveIcon)`
  box-sizing: content-box;
  position: absolute;
  min-height: 0.8rem;
  min-width: 0.8rem;
  top: 0.3rem;
  right: 0.3rem;
  padding: 0.5rem;

  :hover {
    cursor: pointer;
  }
`;

export default DraggableCard;
