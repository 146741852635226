import React from 'react';

const getOrientation = () => {
  if (window.matchMedia('(orientation: portrait) and (max-width: 576px)').matches) {
    return 'portrait';
  }
  return 'landscape';
};

export const useOrientation = () => {
  const [orientation, setOrientation] = React.useState(getOrientation);

  React.useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener('resize', handleOrientationChange);
    return () => window.removeEventListener('resize', handleOrientationChange);
  }, []);

  return orientation;
};
