import styled from "styled-components";
import { Page, Title } from "../../shared/layout";
import { Logo } from "../../shared/Logo";
import { Button } from "../../shared/Button";

export const WelcomePage = styled(Page)`
  justify-content: center;
  margin: auto;
  padding-bottom: 20px;
  position: relative;
`;

export const FieldStyled = styled.div`
  display: flex;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.colors.medGray};
  width: 100%;
  flex-direction: column;
  align-items: center;
  line-height: 30px;
  font-size: 1.6rem;

  &.last {
    padding-bottom: 30px;
  }

  b {
    color: ${({ theme }) => theme.colors.light};
  }

  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export const TitleStyled = styled(Title)`
  margin-bottom: 30px;
`;

export const LogoStyled = styled(Logo)`
  display: block;
  padding-top: 20px;

  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    padding: 0;
  }

  svg {
    fill: ${({ theme }) => theme.colors.medGray};
    height: 80px;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
`;

export const InfoFieldStyled = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.dark};
  display: flex;
  margin-top: 16px;
  align-items: center;

  a {
    color: ${({ theme }) => theme.colors.dark};
    text-decoration: none;
    font-weight: bold;
  }

  &:after {
    display: inline-flex;
    min-width: 10px;
    content: "";
    order: -1;
  }

  &:before {
    height: 28px;
    min-width: 28px;
    content: "!";
    color: ${({ theme }) => theme.colors.light};
    border: 3px solid ${({ theme }) => theme.colors.light};
    font-size: 20px;
    display: inline-flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    order: -2;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      height: 34px;
      min-width: 34px;
      font-size: 24px;
    }
  }
`;

export const TextStyled = styled.span`
   {
    height: 300px;
    display: flex;
    align-items: center;
    line-height: 1.5;
    color: #375563;
    text-align: center;
    font-size: 1.4rem;
  }
`;
