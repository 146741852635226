import styled from 'styled-components';
import bg from '../../templates/images/bg.jpg';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.regular};
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    background-image: url('${bg}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh; // fix for mobile browser address bar appearing disappearing
  }
`;

export const ContentHolder = styled.div`
  position: relative;
  width: 42rem;
  height: 51rem;
  display: flex;
  flex-direction: column;
  padding: 4rem 3.2rem;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.darkBlue};
  font-weight: bold;
  font-size: 24px;
  margin: 0 0 0.8rem 0;
`;
