import * as React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { Footer, Header } from "../components";
import { ConfirmationModal } from "../../../shared/modal/ConfirmationModal";
import { Modal } from "../../../shared/modal/Modal";
import { CustomSelect, FormikInput } from "../../../shared/FormComponents";
import { CustomButton } from "../../../shared/Buttons/Button";
import { errorMessages } from "../../../shared/constants/errorMessages";
import { Spinner } from "../../../shared/Spinner";
import { useSelf360RouteConfig } from "../hooks/useSelf360RouteConfig";
import { useSelf360Store } from "../hooks/useSelf360Store";

export const Body = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BodyItem = styled.div`
  max-width: 400px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 10px;
`;

const RatersTableTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 22px;
`;

const RatersTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const RatersTableBody = styled.tbody``;
const RatersTableHead = styled.thead``;

const RatersTableRow = styled.tr`
  display: flex;
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #c4c4c4;
`;

const RatersTableHeaderCell = styled.th`
  font-weight: 500;
  font-size: 12px;
  line-height: 42px;
  color: #07668a;
  text-align: left;
  width: ${({ w = 100 }) => `${w}px`};
`;

const RatersTableCell = styled.td`
  font-weight: 500;
  font-size: 12px;
  line-height: 42px;
  color: #295368;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${({ w }) => `${w}px`};

  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

const RaterRolesEnum = {
  0: "Self",
  1: "Manager",
  2: "Peer",
  3: "DirectReport",
  4: "Other",
};

const RaterCompleteStatusEnum = {
  NotDone: 0,
  Done: 1,
  0: "x",
  1: "✓",
};

const RaterCompleteStatusIcon = styled.span`
  font-size: 16px;
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${({ status, theme }) =>
    status === 0 ? theme.colors.error : theme.colors.success};
`;

export const RaterRole = ({ role }) => {
  return <div>{RaterRolesEnum[role]}</div>;
};

export const RaterCompleteStatus = ({ status }) => {
  return (
    <div>
      <RaterCompleteStatusIcon status={status}>
        {RaterCompleteStatusEnum[status]}
      </RaterCompleteStatusIcon>
    </div>
  );
};

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 46rem;
  max-height: 43rem;
`;

const AddRaterForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledInput = styled(FormikInput)`
  margin-bottom: 2rem;
  width: 100%;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledSelect = styled(CustomSelect)`
  margin-bottom: 2rem;
  width: 50%;
`;

const AddRaterButton = styled(CustomButton)`
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const PageFooter = observer(() => {
  const { t } = useTranslation();
  const store = useSelf360Store();
  const routeConfig = useSelf360RouteConfig(store.routeDetails);
  const history = useHistory();

  return (
    <Footer>
      <CustomButton
        variant="secondary"
        width={124}
        onClick={() => history.push(routeConfig.welcome())}
      >
        {t("back")}
      </CustomButton>

      <CustomButton
        variant="secondary"
        width={124}
        isLoading={store.submitInvitationDetails.isLoading}
        disabled={store.submitInvitationDetails.isLoading}
        onClick={async () => {
          store.setSubmitInvitationDetails({
            isModalOpen: true,
          });
        }}
      >
        {t("submit")}
      </CustomButton>
    </Footer>
  );
});

const RemoveRaterModal = observer(() => {
  const { t } = useTranslation();
  const store = useSelf360Store();

  return (
    <ConfirmationModal
      onConfirm={async () => {
        await store.deleteRater();

        store.setDeleteRaterDetails({
          isModalOpen: false,
          raterId: null,
        });

        store.fetchRaters();
      }}
      title={t("Delete rater from the list")}
      description={t(
        "Are you sure you wish to delete this rater? This cannot be undone"
      )}
      isVisible={store.deleteRaterDetails.isModalOpen}
      width="57rem"
      onClose={() =>
        store.setDeleteRaterDetails({
          isModalOpen: false,
          raterId: null,
        })
      }
    />
  );
});

const SubmitInvitationModal = observer(() => {
  const { t } = useTranslation();
  const store = useSelf360Store();
  const routeConfig = useSelf360RouteConfig(store.routeDetails);
  const history = useHistory();

  return (
    <ConfirmationModal
      onConfirm={async () => {
        if (store.submitInvitationDetails.isLoading) {
          return;
        }
        await store.submitInvitation();
        store.setSubmitInvitationDetails({
          isModalOpen: false,
        });
        history.push(routeConfig.submit());
      }}
      title={t("Attention")}
      description={t(
        "This will notify you appraisal administrator that you have selected your raters If you have fully completed this task please continue Otherwise please press the Back buttons and go back to step 1"
      )}
      isVisible={store.submitInvitationDetails.isModalOpen}
      width="57rem"
      onClose={() =>
        store.setSubmitInvitationDetails({
          isModalOpen: false,
        })
      }
    />
  );
});

const useValidationSchema = (errorMessages) =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(errorMessages.inputField)
      .max(200, errorMessages.tooLong(200)),
    familyName: Yup.string()
      .trim()
      .required(errorMessages.inputField)
      .max(200, errorMessages.tooLong(200)),
    email: Yup.string()
      .trim()
      .required(errorMessages.inputField)
      .email(errorMessages.emailField)
      .max(150, errorMessages.tooLong(150)),
    role: Yup.string().required(errorMessages.inputField),
  });

const useRoleSelectOptions = (t) => [
  {
    name: "manager",
    value: 1,
    label: t("Manager"),
  },
  {
    name: "peer",
    value: 2,
    label: t("Peer"),
  },
  {
    name: "directReport",
    value: 3,
    label: t("Direct report"),
  },
  {
    name: "other",
    value: 4,
    label: t("Other"),
  },
];

const AddRaterModal = observer(() => {
  const { t } = useTranslation();
  const store = useSelf360Store();
  const validationSchema = useValidationSchema(errorMessages(t));
  const {
    validateForm,
    handleChange,
    handleSubmit,
    setFieldError,
    errors,
    touched,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: "",
      familyName: "",
      email: "",
      role: 1,
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const roleSelectOptions = useRoleSelectOptions(t);

  const textInputHandler = (name) => (e) => {
    setFieldError(name, "");
    handleChange(e);
  };

  const renderTextInput = useCallback((name, label) => (
    <StyledInput
      name={name}
      id={name}
      inputName={label}
      onChange={textInputHandler(name)}
      value={values[name]}
      errors={errors}
      touched={touched}
    />
  ));

  async function onFormSubmit(e) {
    try {
      e.preventDefault();
      handleSubmit(e);

      const error = await validateForm();

      if (Object.keys(error).length) {
        return;
      }

      await store.addRater(values);

      store.setAddRaterDetails({ isModalOpen: false });

      resetForm();

      store.fetchRaters();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return (
    <StyledModal
      onClose={() => {
        store.setAddRaterDetails({ isModalOpen: false });
        resetForm();
      }}
      isVisible={store.addRaterDetails.isModalOpen}
      title={t("Add rater")}
    >
      <AddRaterForm onSubmit={onFormSubmit} autoComplete="off">
        {renderTextInput("firstName", t("firstname"))}
        {renderTextInput("familyName", t("lastname"))}
        {renderTextInput("email", t("Email address"))}

        <>
          <StyledSelect
            placeholder={t("Role")}
            options={roleSelectOptions}
            selectProps={{
              onChange: (option) => setFieldValue("role", option.value),
              value:
                roleSelectOptions.find((item) => item.value === values.role) ||
                null,
              menuPosition: "fixed",
            }}
          />
        </>

        <SubmitButtonWrapper>
          <CustomButton
            type="submit"
            width={150}
            height={44}
            isLoading={store.addRaterDetails.isLoading}
            disabled={store.addRaterDetails.isLoading}
          >
            {t("Add rater")}
          </CustomButton>
        </SubmitButtonWrapper>
      </AddRaterForm>
    </StyledModal>
  );
});

export const AddRatersPage = observer(() => {
  const { t } = useTranslation();
  const store = useSelf360Store();

  useEffect(() => {
    store.fetchRaters();
  }, []);

  return (
    <>
      <Header title={`${t("Step 1")}`} />

      <Body>
        <BodyItem>{t("Please add yor raters")}</BodyItem>

        <BodyItem>
          {t(
            "Remember - Please try to select at least one rater per role where possible"
          )}
        </BodyItem>

        <ButtonWrapper>
          <AddRaterButton
            onClick={() => store.setAddRaterDetails({ isModalOpen: true })}
          >
            {t("Add Rater")}
          </AddRaterButton>
        </ButtonWrapper>
      </Body>

      <RatersTableTitle>{t("Your raters")}</RatersTableTitle>

      {store.ratersDetails.isLoading ? (
        <Spinner />
      ) : (
        <RatersTable>
          <RatersTableHead>
            <RatersTableRow>
              <RatersTableHeaderCell w={120}>
                {t("First name")}
              </RatersTableHeaderCell>
              <RatersTableHeaderCell w={120}>
                {t("Family name")}
              </RatersTableHeaderCell>
              <RatersTableHeaderCell w={160}>
                {t("Email address")}
              </RatersTableHeaderCell>
              <RatersTableHeaderCell w={80}>{t("Role")}</RatersTableHeaderCell>
              <RatersTableHeaderCell w={60}>
                {t("Complete")}
              </RatersTableHeaderCell>
              <RatersTableHeaderCell w={100} />
            </RatersTableRow>
          </RatersTableHead>

          <RatersTableBody>
            {store.ratersDetails.raters.map((rater) => {
              return (
                <RatersTableRow key={rater.raterID}>
                  <RatersTableCell w={120}>{rater.firstName}</RatersTableCell>
                  <RatersTableCell w={120}>{rater.familyName}</RatersTableCell>
                  <RatersTableCell w={160}>{rater.email}</RatersTableCell>
                  <RatersTableCell w={80}>
                    <RaterRole role={rater.role} />
                  </RatersTableCell>
                  <RatersTableCell w={60} centered>
                    <RaterCompleteStatus status={rater.completeStatus} />
                  </RatersTableCell>
                  <RatersTableCell w={100} centered>
                    <CustomButton
                      size="s"
                      width={72}
                      onClick={() =>
                        store.setDeleteRaterDetails({
                          isModalOpen: true,
                          raterId: rater.raterID,
                        })
                      }
                    >
                      {t("Delete")}
                    </CustomButton>
                  </RatersTableCell>
                </RatersTableRow>
              );
            })}
          </RatersTableBody>
        </RatersTable>
      )}

      <RemoveRaterModal />

      <AddRaterModal />

      <SubmitInvitationModal />

      <PageFooter />
    </>
  );
});
