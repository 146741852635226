import React from "react";

export const KeyEnter = () => {
  return (
    <svg
      width="96"
      height="40"
      viewBox="0 0 96 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.6036 40H2.39645C1.0784 40 0 39.0886 0 37.9747V2.02532C0 0.911392 1.0784 0 2.39645 0H93.6036C94.9216 0 96 0.911392 96 2.02532V37.9747C96 39.0886 94.9216 40 93.6036 40Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M59.3845 13.962H53.5465C53.5838 14.5033 53.8312 14.942 54.2885 15.278C54.7458 15.6047 55.2685 15.768 55.8565 15.768C56.7898 15.768 57.4992 15.474 57.9845 14.886L58.8805 15.866C58.0778 16.6967 57.0325 17.112 55.7445 17.112C54.6992 17.112 53.8078 16.7667 53.0705 16.076C52.3425 15.376 51.9785 14.4427 51.9785 13.276C51.9785 12.1 52.3518 11.1667 53.0985 10.476C53.8452 9.78532 54.7225 9.43999 55.7305 9.43999C56.7478 9.43999 57.6112 9.74799 58.3205 10.364C59.0298 10.9707 59.3845 11.8107 59.3845 12.884V13.962ZM53.5465 12.73H57.8165C57.8165 12.1047 57.6205 11.624 57.2285 11.288C56.8365 10.952 56.3512 10.784 55.7725 10.784C55.2032 10.784 54.6898 10.9613 54.2325 11.316C53.7752 11.6613 53.5465 12.1327 53.5465 12.73Z"
        fill="white"
      />
      <path
        d="M62.6768 12.968V17H61.1088V9.55199H62.6768V10.91C62.9288 10.4527 63.2741 10.0933 63.7128 9.83199C64.1608 9.57066 64.6415 9.43999 65.1548 9.43999C65.9948 9.43999 66.6715 9.69666 67.1848 10.21C67.7075 10.7233 67.9688 11.4653 67.9688 12.436V17H66.4008V12.912C66.4008 11.54 65.8315 10.854 64.6928 10.854C64.1515 10.854 63.6801 11.036 63.2788 11.4C62.8775 11.7547 62.6768 12.2773 62.6768 12.968Z"
        fill="white"
      />
      <path
        d="M71.9959 10.812V14.592C71.9959 14.9467 72.0892 15.2313 72.2759 15.446C72.4626 15.6513 72.7239 15.754 73.0599 15.754C73.3959 15.754 73.7179 15.5907 74.0259 15.264L74.6699 16.37C74.1192 16.8647 73.5079 17.112 72.8359 17.112C72.1732 17.112 71.6039 16.8833 71.1279 16.426C70.6612 15.9593 70.4279 15.334 70.4279 14.55V10.812H69.4899V9.55199H70.4279V7.21399H71.9959V9.55199H73.9559V10.812H71.9959Z"
        fill="white"
      />
      <path
        d="M82.8455 13.962H77.0075C77.0448 14.5033 77.2921 14.942 77.7495 15.278C78.2068 15.6047 78.7295 15.768 79.3175 15.768C80.2508 15.768 80.9601 15.474 81.4455 14.886L82.3415 15.866C81.5388 16.6967 80.4935 17.112 79.2055 17.112C78.1601 17.112 77.2688 16.7667 76.5315 16.076C75.8035 15.376 75.4395 14.4427 75.4395 13.276C75.4395 12.1 75.8128 11.1667 76.5595 10.476C77.3061 9.78532 78.1835 9.43999 79.1915 9.43999C80.2088 9.43999 81.0721 9.74799 81.7815 10.364C82.4908 10.9707 82.8455 11.8107 82.8455 12.884V13.962ZM77.0075 12.73H81.2775C81.2775 12.1047 81.0815 11.624 80.6895 11.288C80.2975 10.952 79.8121 10.784 79.2335 10.784C78.6641 10.784 78.1508 10.9613 77.6935 11.316C77.2361 11.6613 77.0075 12.1327 77.0075 12.73Z"
        fill="white"
      />
      <path
        d="M88.4757 11.022C87.7011 11.022 87.1177 11.274 86.7257 11.778C86.3338 12.282 86.1377 12.9587 86.1377 13.808V17H84.5697V9.55199H86.1377V11.05C86.3897 10.5833 86.7304 10.2007 87.1597 9.90199C87.5984 9.60332 88.0604 9.44932 88.5457 9.43999L88.5597 11.022C88.5317 11.022 88.5037 11.022 88.4757 11.022Z"
        fill="white"
      />
      <path
        d="M73 29.9626L77.0399 25.9252V29.271H86.616V22H88V30.6542H77.0399V34L73 29.9626Z"
        fill="white"
      />
    </svg>
  );
};
