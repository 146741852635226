import { fetchQuestionnaireConfigSuccess } from './testDetailsActions';
import { concatAdaptResponses } from '../../components/shared/adaptive/utils';
import { assessmentApi, managementApi, testApi } from '../../utils/agent';
import { LocalStorageService } from '../../utils/LocalStorage.service';

import { arrayToObject } from '../../components/shared/utils/arrayToObject';

export const FETCH_QUESTIONNAIRE_DATA = 'FETCH_QUESTIONNAIRE_DATA';
export const FETCH_QUESTIONNAIRE_DATA_ERROR = 'FETCH_QUESTIONNAIRE_DATA_ERROR';
export const FETCH_QUESTIONNAIRE_DATA_SUCCESS = 'FETCH_QUESTIONNAIRE_DATA_SUCCESS';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_ACTIVE_QUESTION_ID = 'UPDATE_ACTIVE_QUESTION_ID';
export const SET_STARTED = 'SET_STARTED';
export const UPDATE_REMAINING_TIME = 'UPDATE_REMAINING_TIME';

export const updateRemainingTime = (remainingTime) => {
  return {
    type: UPDATE_REMAINING_TIME,
    payload: remainingTime,
  };
};

export const setStarted = () => {
  return {
    type: SET_STARTED,
  };
};

export const updateActiveQuestionId = (id) => {
  return { type: UPDATE_ACTIVE_QUESTION_ID, payload: id };
};

const fetchQuestionnaireData = () => {
    return {
    type: FETCH_QUESTIONNAIRE_DATA,
  };
};

const fetchQuestionnaireDataError = (err) => {
  return {
    type: FETCH_QUESTIONNAIRE_DATA_ERROR,
    err,
  };
};

const fetchQuestionnaireDataSuccess = (data) => {
  return {
    type: FETCH_QUESTIONNAIRE_DATA_SUCCESS,
    payload: data,
  };
};

export const setActiveQuestion = (id) => {
  return {
    type: SET_ACTIVE_QUESTION,
    payload: id,
  };
};

export const fetchQuestionnaireDetails = (accountWithInvitation, password, is360) => {
  return (dispatch) => {
    dispatch(fetchQuestionnaireData());

    return assessmentApi[is360 ? 'admin360' : 'admin'](accountWithInvitation, password)
      .then(({ data }) => {
        if (typeof data === 'string') {
          dispatch(
            fetchQuestionnaireDataError({
              message: data,
            }),
          );
        } else {
          dispatch(fetchQuestionnaireDataSuccess({ ...data, accountWithInvitation }));
          if (is360) dispatch(fetchQuestionnaireConfigSuccess({ ...data.test, is360: true }));
        }
      })
      .catch((e) => {
        console.log('e', e);
        // TODO: throw error
        dispatch(
          fetchQuestionnaireDataError({
            message: '',
          }),
        );
      });
  };
};

export const FETCH_MANAGED_GROUP_DETAILS_START = 'FETCH_MANAGED_GROUP_DETAILS_START';
export const FETCH_MANAGED_GROUP_DETAILS_SUCCESS = 'FETCH_MANAGED_GROUP_DETAILS_SUCCESS';
export const FETCH_MANAGED_GROUP_DETAILS_FAILURE = 'FETCH_MANAGED_GROUP_DETAILS_FAILURE';

const fetchManagedGroupStart = () => ({ type: FETCH_MANAGED_GROUP_DETAILS_START });
const fetchManagedGroupSuccess = (data) => ({ type: FETCH_MANAGED_GROUP_DETAILS_SUCCESS, payload: data });
const fetchManagedGroupFailure = (message) => ({ type: FETCH_MANAGED_GROUP_DETAILS_FAILURE, message });

export const fetchManagedGroupDetails = (accessCode) => {
  return (dispatch) => {
    dispatch(fetchManagedGroupStart());

    return assessmentApi
      .managedGroupLogin(accessCode)
      .then(({ data }) => {
        if (typeof data === 'string') {
          dispatch(
            fetchManagedGroupFailure({
              message: data,
            }),
          );
        } else {
          dispatch(fetchManagedGroupSuccess({ ...data }));
        }
      })
      .catch((e) => {
        console.log('e', e);
        // TODO: throw error
        dispatch(
          fetchQuestionnaireDataError({
            message: '',
          }),
        );
      });
  };
};

export const FETCH_OPEN_GROUP_DETAILS_START = 'FETCH_OPEN_GROUP_DETAILS_START';
export const FETCH_OPEN_GROUP_DETAILS_SUCCESS = 'FETCH_OPEN_GROUP_DETAILS_SUCCESS';
export const FETCH_OPEN_GROUP_DETAILS_FAILURE = 'FETCH_OPEN_GROUP_DETAILS_FAILURE';

const fetchOpenGroupStart = () => ({ type: FETCH_OPEN_GROUP_DETAILS_START });
const fetchOpenGroupSuccess = (data) => ({ type: FETCH_OPEN_GROUP_DETAILS_SUCCESS, payload: data });
const fetchOpenGroupFailure = (message) => ({ type: FETCH_OPEN_GROUP_DETAILS_FAILURE, payload: message });

export const fetchOpenGroupDetails = (accessCode) => async (dispatch) => {
  try {
    dispatch(fetchOpenGroupStart());
    const response = await assessmentApi.openGroupLogin(accessCode);
    if (response.data === 'string') dispatch(fetchOpenGroupFailure({ message: response.data }));
    dispatch(fetchOpenGroupSuccess({ ...response.data }));
  } catch (e) {
    dispatch(fetchOpenGroupFailure({ message: 'Something went wrong' }));
  }
};

export const updateAnswer = (answer) => {
  return {
    type: UPDATE_ANSWER,
    payload: { answer },
  };
};

export const SUBMIT_RESULTS_START = 'SUBMIT_RESULTS_START';
export const SUBMIT_RESULTS_SUCCESS = 'SUBMIT_RESULTS_SUCCESS';
export const SUBMIT_RESULTS_ERROR = 'SUBMIT_RESULTS_ERROR';

export const submitResultsStart = () => {
  return {
    type: SUBMIT_RESULTS_START,
  };
};

export const submitResultsSuccess = (data) => {
  return {
    type: SUBMIT_RESULTS_SUCCESS,
    payload: data,
  };
};

export const submitResultsError = (err) => {
  return {
    type: SUBMIT_RESULTS_ERROR,
    payload: { err },
  };
};

export const submitResults = (
  {
    session: { invitationId, accountId, userId, password, testId, batteryId },
    answers,
    config,
    qEngine = null,
    formatItems,
  },
  callback,
) => {
  return async (_, getState) => {
    const { questionnaireDetails } = getState();
    const { isDirectFeedbackReport, projectId } = questionnaireDetails.details;
    const { accountWithInvitation, timeSpentPerQuestion } = questionnaireDetails;

    const mexAssessmentData = questionnaireDetails.mexAssessment.history;
    // eslint-disable-next-line prefer-const
    let rawCheckingAssessmentData = questionnaireDetails.checkingAssessment.results;
    
    if (testId === 'GCAT') {
      rawCheckingAssessmentData = questionnaireDetails.formatedResults.results;
    }
    
    const mappedCheckingAssessmentData = arrayToObject(rawCheckingAssessmentData);

    const items = config.isAdaptive
      ? concatAdaptResponses(qEngine, answers)
      : answers.filter((a) => Boolean(a.Id) && a.Id[0].toLowerCase() !== 'e');
    const itemTimings = timeSpentPerQuestion.join(',');

    await testApi[config.is360 ? 'session360' : 'session']({
      accountId: questionnaireDetails.details.accountId,
      userId: questionnaireDetails.details.userId,
      invitationId: questionnaireDetails.details.invitationId,
      ...(config.isMEX ? { updatedMexItems: mexAssessmentData } : { updatedItems: questionnaireDetails.answers }),
      remainingTime: questionnaireDetails.remainingTime,
      itemTimings,
      ...(config.is360 && { password: questionnaireDetails.details.password }),
    }
    );

    // TODO: async/await
    return testApi[config.is360 ? 'results360' : 'results']({
      invitationId,
      accountId,
      userId,
      password,
      testId,
      batteryId,
      itemTimings,
      items: typeof formatItems === 'function' ? formatItems(items) : items,
      mexItems: mexAssessmentData,
      testResults: mappedCheckingAssessmentData
    })
      .then(({ data }) => {
        LocalStorageService.removeSession(questionnaireDetails.details);

        submitResultsSuccess(data);

        if (isDirectFeedbackReport) {
          managementApi.directFeedback(accountWithInvitation, password);
        }
        if (projectId) {
          managementApi.assessmentProjectFeedback(accountWithInvitation, password);
        }

        if (callback) callback(null, data);
      })
      .catch((err) => {
        submitResultsError(err);
        if (callback) callback(err);
      });
  };
};

export const saveSessions = (cb) => async (dispatch, getState) => {
  const {
    questionnaireDetails,
    testDetails: { config },
  } = getState();
  const mexAssessmentData = [
    ...questionnaireDetails.mexAssessment.history,
    questionnaireDetails.mexAssessment.currentDndState,
  ];

  testApi[config.is360 ? 'session360' : 'session']({
    accountId: questionnaireDetails.details.accountId,
    userId: questionnaireDetails.details.userId,
    invitationId: questionnaireDetails.details.invitationId,
    ...(config.isMEX ? { updatedMexItems: mexAssessmentData } : { updatedItems: questionnaireDetails.answers }),
    remainingTime: questionnaireDetails.remainingTime,
    itemTimings: questionnaireDetails.timeSpentPerQuestion.join(','),
    ...(config.is360 && { password: questionnaireDetails.details.password }),
  }).catch(() => {
        if (typeof cb === 'function' ) cb();
    });
};

export const SAVE_SESSION_START = 'SAVE_SESSION_START';
export const SAVE_SESSION_SUCCESS = 'SAVE_SESSION_SUCCESS';
export const SAVE_SESSION_ERROR = 'SAVE_SESSION_ERROR';

export const saveSessionStart = () => {
  return {
    type: SAVE_SESSION_START,
  };
};

export const saveSessionSuccess = (data) => {
  return {
    type: SAVE_SESSION_SUCCESS,
    payload: data,
  };
};

export const saveSessionError = (err) => {
  return {
    type: SAVE_SESSION_ERROR,
    payload: { err },
  };
};

export const SAVE_RESPONDENT_DATA = 'SAVE_RESPONDENT_DATA';

export const setRespondentData = (data) => {
  return {
    type: SAVE_RESPONDENT_DATA,
    payload: data,
  };
};

export const SAVE_TIME_SPENT_ON_SINGLE_QUESTION = 'SAVE_TIME_SPENT_ON_SINGLE_QUESTION';

export const saveTimeSpentOnQuestion = (questionIndex, time) => ({
  type: SAVE_TIME_SPENT_ON_SINGLE_QUESTION,
  payload: {
    questionIndex,
    time,
  },
});

export const SET_ICONS_MODULE = 'SET_ICONS_MODULE';
export const setIconsModule = (iconsModule) => {
  return {
    type: SET_ICONS_MODULE,
    payload: iconsModule,
  };
};

export const UPDATE_MEX_ASSESSMENT_CURRENT_STATE = 'UPDATE_MEX_ASSESSMENT_CURRENT_STATE';
export const updateMexAssessmentCurrentState = (data) => {
  return {
    type: UPDATE_MEX_ASSESSMENT_CURRENT_STATE,
    payload: data,
  };
};

export const PUSH_MEX_ASSESSMENT_HISTORY_ITEM = 'PUSH_MEX_ASSESSMENT_HISTORY_ITEM';
export const pushMexAssessmentHistoryItem = (data) => {
  return {
    type: PUSH_MEX_ASSESSMENT_HISTORY_ITEM,
    payload: data,
  };
};

export const SET_MANAGED_GROUP_RESPONDENT_DATA = 'SET_MANAGED_GROUP_RESPONDENT_DATA';
export const setManagedGroupRespondentData = (data) => ({ type: SET_MANAGED_GROUP_RESPONDENT_DATA, payload: data });

export const SET_MANAGED_GROUP_INVITE_ID = 'SET_MANAGED_GROUP_INVITE_ID';
export const setManagedGroupInviteId = (inviteId) => ({ type: SET_MANAGED_GROUP_INVITE_ID, payload: inviteId });

export const SET_MANAGED_GROUP_PASSWORD = 'SET_MANAGED_GROUP_PASSWORD';
export const setManagedGroupPassword = (password) => ({ type: SET_MANAGED_GROUP_PASSWORD, payload: password });

export const RECOGNIZE_RESPONDENT_START = 'RECOGNIZE_RESPONDENT_START';
export const RECOGNIZE_RESPONDENT_SUCCESS = 'RECOGNIZE_RESPONDENT_SUCCESS';
export const RECOGNIZE_RESPONDENT_FAILURE = 'RECOGNIZE_RESPONDENT_FAILURE';

const recognizeRespondentStart = () => ({ type: RECOGNIZE_RESPONDENT_START });
const recognizeRespondentSuccess = () => ({ type: RECOGNIZE_RESPONDENT_SUCCESS });
const recognizeRespondentFailure = () => ({ type: RECOGNIZE_RESPONDENT_FAILURE });

export const recognizeRespondent = (data, accessCode, cb) => async (dispatch) => {
  dispatch(recognizeRespondentStart());
  try {
    const response = await testApi.checkRespondent(data);
    if (response.status === 200) {
      const statusResponse = await testApi.checkRespondentStatus({ ...data, accessCode });
      if (statusResponse.status === 200) {
        const { password, inviteId, statusId } = statusResponse.data;
        dispatch(recognizeRespondentSuccess());
        dispatch(setManagedGroupRespondentData(response.data));
        dispatch(setManagedGroupInviteId(inviteId));
        if (password) dispatch(setManagedGroupPassword(password));
        if (cb) cb(null, statusId);
      }
    }
  } catch (e) {
    dispatch(recognizeRespondentFailure());
    if (cb) cb(e);
  }
};

export const CREATE_NEW_RESPONDENT_START = 'CREATE_NEW_RESPONDENT_START';
export const CREATE_NEW_RESPONDENT_SUCCESS = 'CREATE_NEW_RESPONDENT_SUCCESS';
export const CREATE_NEW_RESPONDENT_FAILURE = 'CREATE_NEW_RESPONDENT_FAILURE';

const createNewRespondentStart = () => ({ type: CREATE_NEW_RESPONDENT_START });
const createNewRespondentSuccess = () => ({ type: CREATE_NEW_RESPONDENT_SUCCESS });
const createNewRespondentFailure = () => ({ type: CREATE_NEW_RESPONDENT_FAILURE });

export const createNewRespondent = (data, cb) => async (dispatch) => {
  dispatch(createNewRespondentStart());
  try {
    const response = await testApi.createRespondent(data);
    if (response.status === 200) {
      dispatch(createNewRespondentSuccess());
      dispatch(setManagedGroupRespondentData(response.data));
      if (cb) cb(null, response.data);
    }
  } catch (e) {
    dispatch(createNewRespondentFailure());
    if (cb) cb(e);
  }
};

export const CREATE_NEW_OPEN_GROUP_RESPONDENT_START = 'CREATE_NEW_OPEN_GROUP_RESPONDENT_START';
export const CREATE_NEW_OPEN_GROUP_RESPONDENT_SUCCESS = 'CREATE_NEW_OPEN_GROUP_RESPONDENT_SUCCESS';
export const CREATE_NEW_OPEN_GROUP_RESPONDENT_FAILURE = 'CREATE_NEW_OPEN_GROUP_RESPONDENT_FAILURE';

const createNewOpenGroupRespondentStart = () => ({ type: CREATE_NEW_OPEN_GROUP_RESPONDENT_START });
const createNewOpenGroupRespondentSuccess = () => ({ type: CREATE_NEW_OPEN_GROUP_RESPONDENT_SUCCESS });
const createNewOpenGroupRespondentFailure = () => ({ type: CREATE_NEW_OPEN_GROUP_RESPONDENT_FAILURE });

export const createNewOpenGroupRespondent = (data, cb) => async (dispatch) => {
  dispatch(createNewOpenGroupRespondentStart());
  try {
    const response = await testApi.createOpenGroupRespondent(data);
    if (response.status === 200) {
      dispatch(createNewOpenGroupRespondentSuccess());
      if (cb) cb(null, response.data);
    }
  } catch (e) {
    dispatch(createNewOpenGroupRespondentFailure());
    if (cb) cb(e);
  }
};

export const UPDATE_RESPONDENT_START = 'UPDATE_RESPONDENT_START';
export const UPDATE_RESPONDENT_SUCCESS = 'UPDATE_RESPONDENT_SUCCESS';
export const UPDATE_RESPONDENT_FAILURE = 'UPDATE_RESPONDENT_FAILURE';

const updateRespondentStart = () => ({ type: UPDATE_RESPONDENT_START });
const updateRespondentSuccess = () => ({ type: UPDATE_RESPONDENT_SUCCESS });
const updateRespondentFailure = () => ({ type: UPDATE_RESPONDENT_FAILURE });

export const updateRespondent = (respondentId, data, cb) => async (dispatch) => {
  dispatch(updateRespondentStart());
  try {
    const response = await testApi.updateRespondent(respondentId, data);
    if (response.status === 200) {
      dispatch(updateRespondentSuccess());
      dispatch(setManagedGroupRespondentData(response.data));
      if (cb) cb(null, response.data);
    }
  } catch (e) {
    dispatch(updateRespondentFailure());
    if (cb) cb(e);
  }
};

export const INVITE_RESPONDENT_TO_SESSION_START = 'INVITE_RESPONDENT_TO_SESSION_START';
export const INVITE_RESPONDENT_TO_SESSION_SUCCESS = 'INVITE_RESPONDENT_TO_SESSION_SUCCESS';
export const INVITE_RESPONDENT_TO_SESSION_FAILURE = 'INVITE_RESPONDENT_TO_SESSION_FAILURE';

const inviteRespondentToSessionStart = () => ({ type: INVITE_RESPONDENT_TO_SESSION_START });
const inviteRespondentToSessionSuccess = ({ inviteId }) => ({
  type: INVITE_RESPONDENT_TO_SESSION_SUCCESS,
  payload: inviteId,
});
const inviteRespondentToSessionFailure = () => ({ type: INVITE_RESPONDENT_TO_SESSION_FAILURE });

export const inviteRespondentToSession = (data, cb) => async (dispatch) => {
  dispatch(inviteRespondentToSessionStart());
  try {
    const response = await testApi.inviteRespondent(data);
    if (response.status === 200) {
      dispatch(inviteRespondentToSessionSuccess(response.data));
      if (cb) cb();
    }
  } catch (e) {
    dispatch(inviteRespondentToSessionFailure());
    if (cb) cb(e);
  }
};

export const FETCH_ADMINISTERED_SESSION_PASSWORD_START = 'FETCH_ADMINISTERED_SESSION_PASSWORD_START';
export const FETCH_ADMINISTERED_SESSION_PASSWORD_SUCCESS = 'FETCH_ADMINISTERED_SESSION_PASSWORD_SUCCESS';
export const FETCH_ADMINISTERED_SESSION_PASSWORD_FAILURE = 'FETCH_ADMINISTERED_SESSION_PASSWORD_FAILURE';

const fetchAdministeredSessionPasswordStart = () => ({ type: FETCH_ADMINISTERED_SESSION_PASSWORD_START });
const fetchAdministeredSessionPasswordSuccess = () => ({ type: FETCH_ADMINISTERED_SESSION_PASSWORD_SUCCESS });
const fetchAdministeredSessionPasswordFailure = () => ({ type: FETCH_ADMINISTERED_SESSION_PASSWORD_FAILURE });

export const fetchAdministeredSessionPassword = (data, cb) => async (dispatch) => {
  dispatch(fetchAdministeredSessionPasswordStart());
  try {
    const response = await testApi.fetchSessionPassword(data);
    if (response.status === 200) {
      dispatch(fetchAdministeredSessionPasswordSuccess());
      if (cb) cb(null, response.data.password);
    }
  } catch (e) {
    dispatch(fetchAdministeredSessionPasswordFailure());
    if (cb) cb(e);
  }
};

export const SET_CHECKING_ASSESSMENT_RESULTS = 'SET_CHECKING_ASSESSMENT_RESULTS';
export const setCheckingAssessmentResults = (data) => {
  return {
    type: SET_CHECKING_ASSESSMENT_RESULTS,
    payload: data,
  };
};

export const SET_FORMATED_ASSESSMENT_RESULTS = 'SET_FORMATED_ASSESSMENT_RESULTS';
export const setFormatedAssessmentResults = (data) => {
  return {
    type: SET_FORMATED_ASSESSMENT_RESULTS,
    payload: data,
  };
};