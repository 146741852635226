import React from "react";
import Rodal from "rodal";
import styled, { css, keyframes } from "styled-components";

const rodalFadeLeave = keyframes`
  to {
    opacity: 0;
  }
`;

const ModalStyled = styled(Rodal)`
  &.rodal,
  .rodal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }

  &.rodal {
    position: fixed;
    outline: 0;
  }

  .rodal-mask {
    position: absolute;
    background: ${(props) => props.theme.colors.modalBackgroundColor};
    backdrop-filter: blur(5px);

    ${(props) =>
      props.isTranspared &&
      css`
        background: ${(props) =>
          props.theme.colors.modalBackgroundColorTransparent};
      `}
  }

  .rodal-dialog {
    display: flex;
    position: relative;
    z-index: 101;
    height: 100% !important;
    margin: auto;
    align-items: center;
    justify-content: center;
    background: none;
    box-shadow: none;

    @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
      max-width: 100% !important;
    }
  }

  .rodal-dialog:focus {
    outline: none;
  }

  &.rodal-fade-leave {
    animation: ${rodalFadeLeave} both ease-out;
  }
`;

const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.whiteFontColor};
  padding: 19px 5%;
  width: 100%;
  text-align: center;
  text-transform: capitalize;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding: 16px 32px;
    justify-content: center;
  }
`;

const ModalBodyStyled = styled.div`
  width: 100%;
  padding: 20px;
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const ModalFooterStyled = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(249, 249, 249, 1) 100%
  );
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 143px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 20px;

  @media (min-width: ${({ theme }) => theme.viewports.xxs}) {
    display: block;
    position: relative;
    height: auto;
  }
`;

export const ModalHeader = ({ children, ...props }) => {
  return <ModalHeaderStyled {...props}>{children}</ModalHeaderStyled>;
};

export const ModalFooter = ({ children, ...props }) => {
  return <ModalFooterStyled {...props}>{children}</ModalFooterStyled>;
};

export const ModalBody = React.forwardRef(({ children, ...props }, ref) => (
  <ModalBodyStyled ref={ref} {...props}>
    {children}
  </ModalBodyStyled>
));

const ModalWrapperStyled = styled.div`
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: ${(props) => props.theme.shadows.modalShadow};
  border-radius: ${({ theme }) => theme.formElements.borderRadius};
  height: auto;
  max-height: 100%;
  justify-content: space-between;
  align-content: center;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    max-height: 90vh;
    max-width: 90vw;
  }

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
`;

export const Modal = ({
  isVisible,
  onClose,
  children,
  width,
  onAnimationEnd,
  isTranspared,
  customStyles = {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "90%",
  },
}) => {
  return (
    <ModalStyled
      visible={isVisible}
      onClose={onClose}
      showCloseButton={false}
      closeOnEsc={false}
      customStyles={customStyles}
      width={width}
      onAnimationEnd={onAnimationEnd}
      isTranspared={isTranspared}
    >
      <ModalWrapperStyled>{children}</ModalWrapperStyled>
    </ModalStyled>
  );
};
