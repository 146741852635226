import React from 'react';
import { object, array, func, string } from 'prop-types';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import DraggableCard from './DraggableCard';
import { ReactComponent as ArrowDown } from '../../../../assets/images/arrow-down-circled.svg';

const Column = ({ column, cards, onDeleteCard, title, onTitleClick }) => {
  const { t } = useTranslation();
  return (
    <ColumnContainer>
      <ColumnTitle>
        <span>{title}</span>
        <StyledArrowDown onClick={onTitleClick} />
      </ColumnTitle>
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => {
          const placeholdersCount = snapshot.draggingFromThisWith ? 6 : 5;
          return (
            <CardsList {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
              {cards.map((cardObj, index) => (
                <DraggableCard
                  key={cardObj.id}
                  index={index}
                  card={cardObj}
                  onDelete={() => onDeleteCard(cardObj.id)}
                />
              ))}
              {cards.length < placeholdersCount &&
                new Array(placeholdersCount - cards.length)
                  .fill(true)
                  .map((item, index) => (
                    <Placeholder isRequired={cards.length + index <= 1}>
                      {cards.length + index <= 1 && t('required')}
                    </Placeholder>
                  ))}
              {provided.placeholder}
            </CardsList>
          );
        }}
      </Droppable>
    </ColumnContainer>
  );
};

Column.propTypes = {
  column: object.isRequired,
  cards: array.isRequired,
  onDeleteCard: func.isRequired,
  onTitleClick: func.isRequired,
  title: string.isRequired,
};

const ColumnContainer = styled.div`
  width: 32rem;
  height: 40rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: #ffffff;
  margin-right: 4rem;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.viewports.lgx}) {
    height: 45rem;
  }
`;

const ColumnTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 2.4rem;
  font-size: 18px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.88);
  text-align: left;
`;

const StyledArrowDown = styled(ArrowDown)`
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  position: relative;
  min-width: 28rem;
  min-height: 6rem;
  max-height: 6rem;
  padding: 1.6rem;
  background-color: ${(props) => (props.isRequired ? '#b5e2fa' : '#F2F2F2')};
  border-radius: 4px;
  margin-bottom: 0.8rem;
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizes.small};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
`;

const CardsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  border-radius: 4px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`;

export default Column;
