import React from 'react';
import styled from 'styled-components';
import { FeedbackStyled, FullHeight, QuestionTextStyled as _QuestionTextStyled } from './shared/components';
import { CardsList } from '../shared/CardsList';
import * as images from './images/mrti';

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const QuestionTextLayout = styled.div`
  flex: 2;
`;

const QuestionTextStyled = styled(_QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.67;
  z-index: 5;
  position: relative;
  text-align: left;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 18px;
    line-height: 1.67;
  }
`;

const QuestionImageLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;

  flex: 3;

  svg {
    width: 100%;
  }
`;

const ControlsWrapper = styled.div`
  width: 100%;

  .control-list-mobile-small {
    flex-wrap: nowrap;
    gap: 8px;
  }
`;

export const WhichOfThreeV2Horizontal = ({
  values: { mainitem, responses, image, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
}) => {
  const ImageComponent = images[image];

  return (
    <>
      <FullHeight>
        <QuestionWrapper>
          <QuestionTextLayout>
            <QuestionTextStyled
              dangerouslySetInnerHTML={{
                __html: mainitem,
              }}
            />
            {!!feedback && (
              <FeedbackStyled
                dangerouslySetInnerHTML={{
                  __html: feedback,
                }}
              />
            )}
          </QuestionTextLayout>

          <QuestionImageLayout>
            <ImageComponent />
          </QuestionImageLayout>
        </QuestionWrapper>
      </FullHeight>
      <ControlsWrapper>
        <CardsList
          isAbstract={config.isAbstract}
          className="which-of-three"
          listClassName="control-list-mobile-small"
          items={responses}
          selectedResponse={selectedResponse}
          onResponse={onResponse}
          config={config}
          isBlockModalVisible={isBlockModalVisible}
          isHelpVisible={isHelpVisible}
          isSidePanelOpen={isSidePanelOpen}
        />
      </ControlsWrapper>
    </>
  );
};
