import { normalize } from "styled-normalize";
import { createGlobalStyle } from "styled-components";
import { slideInUp } from "../../utils/animations";

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    display: flex;
    width: 100%;  
    min-height: 100%;
    //height: auto;
    height: 100%; // for IE support
    overflow-x: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    background: #F9F9F9;
    user-select: none;
    font-size: 62.5%;
  }
  
  body {
    font-family: "Montserrat", Helvetica, Arial, sans-serif, -apple-system!important;
    font-weight: 400;
  }
  
  #root > .lang-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
 
  #root > .ltr {
    direction: ltr;
  }
   
  #root > .rtl {
    direction: rtl;
  }

  .box {
    max-width: 800px;
  }
  
  .visiblyHidden {
    opacity: 0;
  }
  
  .float-words {
    display: flex;
    justify-content: center;
    
    .float-l, .float-r {
      margin: 0 20px; 
      
      &:before {
        content: "\\00AB";
      }
      
      &:after {
        content: "\\00BB";
      }
    }
  }
  
  .noPadding {
    padding: 0;
  }
  
  .fullHeight {
    height: 100%;  
  }
  
  .slideInUp {
    opacity: 0;
    transform: translateY(100%) translateZ(0);
    will-change: transform, opacity;
    animation: ${slideInUp} 300ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  }
  
  @-webkit-keyframes autofill {
    to {
      color: inherit;
      background: transparent;
    }
  }
`;
