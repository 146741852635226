import React from "react";

export const SliderIcon = () => {
    return (
        <svg
            width="96"
            height="40"
            viewBox="-2 0 19 19"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.546 6.201a.554.554 0 0 1-.554.554h-1.79a2.22 2.22 0 0 1-4.298 0H1.008a.554.554 0 0 1 0-1.108h6.896a2.22 2.22 0 0 1 4.299 0h1.789a.554.554 0 0 1 .554.554zm0 3.7a.554.554 0 0 1-.554.554H6.987a2.22 2.22 0 0 1-4.298 0h-1.68a.554.554 0 0 1 0-1.108h1.68a2.22 2.22 0 0 1 4.298 0h7.005a.554.554 0 0 1 .554.554zm0 3.7a.554.554 0 0 1-.554.555h-1.79a2.22 2.22 0 0 1-4.298 0H1.008a.554.554 0 0 1 0-1.109h6.896a2.22 2.22 0 0 1 4.299 0h1.789a.554.554 0 0 1 .554.554zm-8.597-3.7a1.11 1.11 0 1 0-1.111 1.111 1.112 1.112 0 0 0 1.11-1.11zm5.215-3.7a1.111 1.111 0 1 0-1.11 1.11 1.112 1.112 0 0 0 1.11-1.11zm0 7.4a1.111 1.111 0 1 0-1.11 1.111 1.112 1.112 0 0 0 1.11-1.11z"
                fill="white"
                fillOpacity="0.7"
            />
        </svg>
    );
};