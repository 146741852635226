import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84%;
  transform: translateY(8%);
  min-width: 40px;
  margin-right: 4px;
`;

const CardHolderStyled = styled.div`
  height: 18%;
`;

const CircleStyled = styled.div`
  width: 100%;
  height: 100%;
  /* max-height: 82%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #66bfe9;
  color: #ffffff;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      background: #ff9900;
    `}
`;

export const MazeCardsList = ({
  items,
  selectedResponse,
  onResponse,
  isAbstract,
  className,
  testId,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
}) => {
  const handleResponse = React.useCallback(
    (id) => {
      onResponse(id);
    },
    [onResponse],
  );

  const handleKeyboardEvent = (event) => {
    const key = event.which;

    if (isBlockModalVisible || isHelpVisible || isSidePanelOpen) return;

    if (key >= 49 && key <= 48 + items.length) {
      handleResponse(key - 48);
    }

    if (key >= 97 && key <= 96 + items.length) {
      handleResponse(key - 96);
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyboardEvent);

    return () => {
      window.removeEventListener('keydown', handleKeyboardEvent);
    };
  });

  return (
    <Wrapper>
      {items.map((item, i) => (
        <CardHolderStyled key={item.RIdx} className={className}>
          <CircleStyled
            key={item.RIdx}
            className={className}
            isAbstract={isAbstract}
            content={item.value} // TODO: Text or Images
            onClick={() => {
              handleResponse(item.RIdx);
            }}
            selected={selectedResponse === item.RIdx}
            testId={testId}
          >
            {i + 1}
          </CircleStyled>
        </CardHolderStyled>
      ))}
    </Wrapper>
  );
};
