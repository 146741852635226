import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Page } from '../shared/layout';
import { Button, ButtonTypes, ButtonVariants } from '../shared/Button';
import { ModalBox } from '../shared/layout/Page';
import { StyledFooter } from '../templates/shared/components';

export const WelcomeText = ({ text, onNext }) => {
  const { t } = useTranslation();
  const buttonRefNext = React.createRef();

  React.useLayoutEffect(() => {
    buttonRefNext.current.focus();
  }, [buttonRefNext]);

  return (
    <WelcomePage withBackground>
      <ModalBoxStyled>
        <WelcomeTextBodyStyled dangerouslySetInnerHTML={{ __html: text }} />
        <StyledFooter>
          <ButtonStyled ref={buttonRefNext} variant={ButtonVariants.PRIMARY} type={ButtonTypes.BUTTON} onClick={onNext}>
            {t('continue')}
          </ButtonStyled>
        </StyledFooter>
      </ModalBoxStyled>
    </WelcomePage>
  );
};

export const WelcomeTextBodyStyled = styled.div`
  width: 100%;
  padding-bottom: 140px;
  flex-direction: column;

  p {
    word-wrap: break-word;
    word-break: break-word;
  }
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    //padding-top: 35px;
    padding-bottom: 20px;
    flex-grow: 1;
    display: flex;
    width: 100%;

    &:after {
      content: ' ';
      position: absolute;
      width: 95%;
      background: transparent linear-gradient(180deg, #f9f9f9 0%, #f9f9f900 100%) 0 0 no-repeat padding-box;
      height: 50px;
      display: flex;
      top: 50px;
      left: 0;
    }

    /* ScrollBar Start */
    /* width */
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.gray};
      opacity: 0.5;
      border-left: 9px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      border-right: 9px solid ${({ theme }) => theme.colors.modalBackgroundColor};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.primaryBlue};
      border-left: 8px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      border-right: 8px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      transition: all 0.2s;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      border-left: 7px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      border-right: 7px solid ${({ theme }) => theme.colors.modalBackgroundColor};
      transition: all 0.2s;
    }
    /* ScrollBar End */
  }
`;

export const WelcomePage = styled(Page)`
  justify-content: center;
  margin: auto;
  padding-bottom: 20px;
  position: relative;
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  max-width: 300px;
  margin: auto;
`;

export const ModalBoxStyled = styled(ModalBox)`
  max-width: 1024px;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow: auto;
  justify-content: flex-start;
  align-items: baseline;
  overflow-x: hidden;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    height: 80vh;
    width: 90%;
    padding-top: 50px;
    padding-bottom: 0;
    padding-right: 30px;
  }
`;
