import axios from 'axios';
import qs from 'query-string';

axios.defaults.baseURL = window._env_.REACT_APP_BASE_URL;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  },
);

const assessmentApi = {
  login: (assessmentId, password) => axios.post(`/api/login/${assessmentId}/${password}`),
  login360: (assessmentId, password) =>
    axios.get(`${window._env_.REACT_APP_BASE_360_URL}/360/api/login/${assessmentId}/${password}`),
  pin: (userId, pin, token) =>
    axios.post(`${window._env_.REACT_APP_MANAGEMENT_API_URL}/api/v2/authorize/pin/`, {
      userId,
      pin,
      token,
    }),
  admin: (accountWithInvitation, password) => {
    // accountWithInvitation
    const accountId = accountWithInvitation.slice(0, 5);
    const invitationId = accountWithInvitation.slice(5);

    return axios.get(`/admin/${accountId}/${invitationId}/${password}?timestamp=${new Date().getTime()}`);
  },
  admin360: (accountWithInvitation, password) => {
    return axios.get(
      `${
        window._env_.REACT_APP_BASE_360_URL
      }/360/api/login/${accountWithInvitation}/${password}?&timestamp=${new Date().getTime()}`,
    );
  },
  managedGroupLogin: (accessCode) => {
    return axios.post(`/api/login/${accessCode}/`);
  },
  openGroupLogin: (accessCode) => axios.post(`/api/login-open-group-link/${accessCode}`),
};

// TODO: remove in future after regression
let sessionCancelToken;
const REQUEST_CANCEL_MESSAGE = 'Operation canceled due to new request.';

const testApi = {
  preTest: (respondentData) => axios.post('/api/pretest', respondentData),
  getQuestionnaireData: (langId, testId) => axios.get('/itemData', { params: { langId, testId } }),
  session: (requestBody, config) => {
    if (typeof sessionCancelToken !== 'undefined') {
      sessionCancelToken.cancel(REQUEST_CANCEL_MESSAGE);
    }
    sessionCancelToken = axios.CancelToken.source();

    return axios.post('/api/session', requestBody, {
      ...config,
    });
  },
  session360: (requestBody) => axios.post(`${window._env_.REACT_APP_BASE_360_URL}/360/api/sessions`, requestBody),

  getQuestionnaireConfig: (langId, testId) => import(`../data/${langId}/${testId}.json`),
  results: (requestBody) => axios.post('/api/results', requestBody),
  results360: (requestBody) => axios.post(`${window._env_.REACT_APP_BASE_360_URL}/360/api/results`, requestBody),
  deleteSession: (requestBody) => axios.post('/api/deleteSession', requestBody),
  checkRespondent: (data) => axios.get(`/api/respondents?${qs.stringify(data)}`),
  createRespondent: (data) => axios.post('/api/respondents', data),
  createOpenGroupRespondent: (data) => axios.post('/api/open-group-link/respondents', data),
  updateRespondent: (respondentId, data) => axios.put(`/api/respondents/${respondentId}`, data),
  inviteRespondent: (data) => axios.post('/api/respondents/invite', data),
  checkRespondentStatus: (data) => axios.get(`/api/respondents/status?${qs.stringify(data)}`),
  fetchSessionPassword: (data) => axios.get(`/api/respondents/invite/password?${qs.stringify(data)}`),
};

const managementApi = {
  directFeedback: (invitationId, password) =>
    axios.post(
      `${window._env_.REACT_APP_MANAGEMENT_API_URL}/api/v2/Reports/directfeedback/${invitationId}/${password}`,
    ),
  assessmentProjectFeedback: (invitationId, password) =>
    axios.post(
      `${window._env_.REACT_APP_MANAGEMENT_API_URL}/api/v2/projects/assessments/submit-results/${invitationId}/${password}`,
    ),
  fetchBiodataTemplates: (userId) =>
    axios.get(
      `${window._env_.REACT_APP_MANAGEMENT_API_URL}/api/v2/user/settings/biodata?userId=${userId}`),
};

const self360Api = {
  fetchInvitation: (invitationId, respondentId) =>
    axios.get(`${window._env_.REACT_APP_BASE_360_URL}/360/api/specify-raters/${invitationId}/${respondentId}`),
  fetchRaters: (invitationId, respondentId) =>
    axios.get(`${window._env_.REACT_APP_BASE_360_URL}/360/api/specify-raters/${invitationId}/${respondentId}/raters`),
  addRater: (invitationId, respondentId, rater) =>
    axios.post(
      `${window._env_.REACT_APP_BASE_360_URL}/360/api/specify-raters/${invitationId}/${respondentId}/raters`,
      rater,
    ),
  removeRater: (invitationId, respondentId, raterId) => {
    return axios.delete(
      `${window._env_.REACT_APP_BASE_360_URL}/360/api/specify-raters/${invitationId}/${respondentId}/raters/${raterId}`,
    );
  },

  submitInvitation: (invitationId, respondentId) =>
    axios.post(`${window._env_.REACT_APP_BASE_360_URL}/360/api/specify-raters/${invitationId}/${respondentId}`),
};

export { assessmentApi, testApi, managementApi, self360Api };