import React from "react";
import styled from "styled-components";
import { func, bool, string } from "prop-types";

import { useTranslation } from "react-i18next";
import { Modal } from "./Modal";
import { CustomButton } from "../Buttons/Button";

export const ConfirmationModal = ({
  isVisible,
  onClose,
  onConfirm,
  onReject,
  description,
  title,
  width,
}) => {
  const { t } = useTranslation();

  const onCancelClick = () => {
    if (onReject) onReject();
    onClose();
  };

  return (
    <StyledModal
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      width={width}
    >
      <Description>{description}</Description>
      <ButtonsWrapper>
        <StyledButton onClick={onCancelClick} variant="secondary">
          {t("No")}
        </StyledButton>
        <StyledButton onClick={onConfirm} variant="primary">
          {t("Yes")}
        </StyledButton>
      </ButtonsWrapper>
    </StyledModal>
  );
};

ConfirmationModal.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  description: string.isRequired,
  title: string,
  onReject: func,
};

ConfirmationModal.defaultProps = {
  title: "Genesys Online",
  onReject: undefined,
};

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  max-width: ${(props) => props.width || "80rem"};
`;

const Description = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${(props) => props.theme.colors.primary};
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
  min-width: 10rem;
`;
