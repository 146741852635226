import React from "react";

export const KeyArrowsUpDown = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0259 40H1.63836C0.790974 40 0.0976562 39.3081 0.0976562 38.4625V23.587C0.0976562 22.7413 0.790974 22.0494 1.63836 22.0494H30.0259C30.8733 22.0494 31.5666 22.7413 31.5666 23.587V38.4625C31.5666 39.3081 30.8733 40 30.0259 40Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M30.0259 17.9892H1.63836C0.790974 17.9892 0.0976562 17.2973 0.0976562 16.4517V1.57616C0.0976562 0.730521 0.790974 0.0386353 1.63836 0.0386353H30.0259C30.8733 0.0386353 31.5666 0.730521 31.5666 1.57616V16.4517C31.5666 17.2973 30.8733 17.9892 30.0259 17.9892Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M15.9459 5.32123L19.468 11.4849H12.4238L15.9459 5.32123Z"
        fill="#B6B7B6"
      />
      <path
        d="M15.9447 34.3755L12.4226 28.2118H19.4668L15.9447 34.3755Z"
        fill="#B6B7B6"
      />
    </svg>
  );
};
