import {
  UPDATE_REMAINING_TIME,
  UPDATE_ANSWER,
  SAVE_TIME_SPENT_ON_SINGLE_QUESTION,
  UPDATE_MEX_ASSESSMENT_CURRENT_STATE,
  PUSH_MEX_ASSESSMENT_HISTORY_ITEM,
  SET_CHECKING_ASSESSMENT_RESULTS
} from '../actions/questionnaireDetailsActions';
import { LocalStorageService } from '../../utils/LocalStorage.service';

const defaultActions = [UPDATE_ANSWER, UPDATE_REMAINING_TIME, SAVE_TIME_SPENT_ON_SINGLE_QUESTION];
const mexActions = [UPDATE_MEX_ASSESSMENT_CURRENT_STATE, PUSH_MEX_ASSESSMENT_HISTORY_ITEM];
const checkingActions = [SET_CHECKING_ASSESSMENT_RESULTS];

const actionsToTrack = [...defaultActions, ...mexActions, ...checkingActions];

export const saveSession = (store) => (next) => (action) => {
  if (actionsToTrack.includes(action.type)) {
    const result = next(action);
    const {
      questionnaireDetails: { details, answers, timeSpentPerQuestion, mexAssessment, checkingAssessment, formatedResults },
    } = store.getState();

    LocalStorageService.setSession(details, {
      answers,
      timeSpentPerQuestion,
      ...(action.type === UPDATE_REMAINING_TIME && {
        remainingTime: action.payload,
      }),
      ...(mexAssessment.currentDndState && {
        mexAssessmentState: mexAssessment,
      }),
      ...(checkingAssessment.results && {
        checkingAssessmentState: checkingAssessment,
      }),
      ...(formatedResults.results && {
        formatedResultsState: formatedResults,
      }),
    });

    return result;
  }

  return next(action);
};