import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal, ModalBody } from "./Modal";
import { ButtonsHolder, Title } from "./layout";
import { Button, ButtonTypes, ButtonVariants } from "./Button";
import { ErrorImage } from "./ErrorImage";

const ErrorImageStyled = styled(ErrorImage)`
  width: 100%;
`;

const ModalBodyStyled = styled(ModalBody)`
  padding: 38px 5% 30px 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    padding: 38px 60px 30px 60px;
  }
`;
const MessageStyled = styled.div`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.font};
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  margin-top: 20px;
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  max-width: 100%;
  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    max-width: 200px;
  }
`;

export const BlockScreenModal = ({
  isBlockModalVisible,
  setBlockModalVisible,
}) => {
  const { t } = useTranslation();

  const buttonRefNext = React.createRef();

  React.useEffect(() => {
    if (isBlockModalVisible) {
      setTimeout(() => {
        try {
          buttonRefNext.current.focus();
        } catch (e) {
          // console.log("Focus is not available");
        }
      }, 0);
    }
  }, [buttonRefNext, isBlockModalVisible]);

  return (
    <Modal
      isVisible={isBlockModalVisible}
      onClose={() => {}}
      onAnimationEnd={() => {}}
      isTranspared
      width={550}
    >
      <ModalBodyStyled>
        <ErrorImageStyled />
        <Title>{t("responseIsRequired")}</Title>
        <MessageStyled>{t("aResponseIsRequired")}</MessageStyled>
        <ButtonsHolder>
          <ButtonStyled
            ref={buttonRefNext}
            variant={ButtonVariants.PRIMARY}
            type={ButtonTypes.BUTTON}
            onClick={() => {
              setBlockModalVisible(false);
            }}
            autoFocus
          >
            {t("ok")}
          </ButtonStyled>
        </ButtonsHolder>
      </ModalBodyStyled>
    </Modal>
  );
};
