export const applyLocalizationFilter = (values, filter) => {
  if (!values) return;

  const aggregatedValues = { ...values };

  if (typeof values.mainitem !== 'undefined') {
    aggregatedValues.mainitem = values.mainitem[filter] ? values.mainitem[filter] : values.mainitem.default;

    if (typeof values.mainitem.instruction !== 'undefined') {
      aggregatedValues.instruction = values.mainitem.instruction;
    }
  }

  if (typeof values.feedback !== 'undefined') {
    aggregatedValues.feedback = values.feedback[filter] ? values.feedback[filter] : values.feedback.default;
  }

  if (typeof values.table !== 'undefined') {
    aggregatedValues.table = values.table[filter] ? values.table[filter] : values.table.default;
  }

  if (typeof values.newsclip !== 'undefined') {
    aggregatedValues.newsclip = values.newsclip[filter] ? values.newsclip[filter] : values.newsclip.default;
  }

  if (typeof values.responses !== 'undefined') {
    aggregatedValues.responses = values.responses.map((response) => {
      return {
        ...response,
        value: response.value[filter] ? response.value[filter] : response.value.default,
      };
    });
  }
  return aggregatedValues;
};
