import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Bowser from 'bowser';

import { useCurrentDeviceLayout } from './utils/useCurrentDeviceLayout';
import { DefaultQuestion } from './DefaultQuestion';
import { Instruction } from './Instruction';
import { Cover } from './Cover';
import { Help } from './Help';
import { SliderHelp } from './SliderHelp';
import { DefaultItem } from './DefaultItem';
import { NewsClipItem } from './NewsClipItem';
import { SwitcherWrapper as Switcher } from './Switcher';
import { TableItem } from './TableItem';
import { VideoItem } from './VideoItem';
import { TextAndTable } from './TextAndTable';
import { TextOnly } from './TextOnly';
import { WhatComesNext } from './WhatComesNext';
import { WhatComesNextSVG } from './WhatComesNextSVG';
import { WhatFits } from './WhatFits';
import { WhichOfThree } from './WhichOfThree';
import { WhichOfThreeV2 } from './WhichOfThreeV2';
import { WhichOfThreeV2Horizontal } from './WhichOfThreeV2Horizontal';
import { XIsToY } from './XIsToY';
import { HowFitsTogether } from './HowFitsTogether';
import { FreeTextItem } from './FreeTextItem';
import { MazeQuestion } from './MazeQuestion';
import { applyLocalizationFilter } from './utils/applyLocalizationFilter';
import { WhichOfImage } from './WhichOfImage';
import { WhichOfImageSRTi } from './WhichOfImageSRTi';
import { WhichOfImageSRTiHorizontal } from './WhichOfImageSRTiHorizontal';
import { DndStacks } from './DndStacks/DndStacks';
import { ImageMatching } from './ImageMatching';

const PageTemplateStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    //height: calc(100vh - 108px);
  }
  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-width: ${({ theme }) => theme.viewports.lg}) {
    //height: calc(100vh - 108px);
  }
  @media (min-width: ${({ theme }) => theme.viewports.lg}) {
    //height: calc(100vh - 54px);
  }
  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    //height: calc(100vh - 54px);
  }

  @media (max-width: ${({ theme }) => theme.viewports.xs}) {
    padding: 0;
    height: auto;
  }

  + div {
    width: 100%;
  }
`;

const views = {
  Instruction,
  DefaultQuestion,
  Cover,
  Help,
  SliderHelp,
  DefaultItem,
  NewsClipItem,
  Switcher,
  TableItem,
  VideoItem,
  TextAndTable,
  TextOnly,
  WhatComesNext,
  WhatComesNextSVG,
  WhatFits,
  WhichOfThree,
  WhichOfThreeV2,
  WhichOfThreeV2Horizontal,
  WhichOfImage,
  XIsToY,
  HowFitsTogether,
  FreeTextItem,
  MazeQuestion,
  WhichOfImageSRTi,
  WhichOfImageSRTiHorizontal,
  DndStacks,
  ImageMatching
};

const PageTemplateComponent = ({ page, className, gender, filterName, ...props }) => {
  // Browser check
  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();
  const isMobile = platform.type === 'mobile';
  // Gender check
  const filtersList = {
    mobile: isMobile,
    male: gender === 'M' && page.type === 'item',
    female: gender === 'F' && page.type === 'item',
    sa: filterName === 'sa',
    au: filterName === 'au',
  };

  const filter = Object.keys(filtersList).reduce((accFilter, nextFilter) => {
    return filtersList[nextFilter] ? nextFilter : accFilter;
  }, 'default');
  const deviceLayout = useCurrentDeviceLayout();

  const values = applyLocalizationFilter(page.template.values, filter);
  const View = React.useMemo(() => {
    if (page.template.responsiveView?.[deviceLayout]) {
      return views[page.template.responsiveView?.[deviceLayout]];
    }
    return views[page.template.view];
  }, [views, page, deviceLayout]);

  return (
    <PageTemplateStyled className={className}>
      <View page={page} values={values} {...props} />
    </PageTemplateStyled>
  );
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: {
      remainingTime,
      isStarted,
      session: { testId },
      details: { sex, filterName },
      icons: iconsModule,
    },
    testDetails: { config },
  } = state;

  return { remainingTime, isStarted, config, testId, gender: sex, filterName, iconsModule };
};

export const PageTemplate = connect(mapStateToProps)(PageTemplateComponent);