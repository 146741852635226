import React from "react";

export const NumSix = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0219 19.018C21.6659 19.018 22.2352 19.1393 22.7299 19.382C23.2339 19.6247 23.6259 19.97 23.9059 20.418C24.1859 20.8567 24.3259 21.37 24.3259 21.958C24.3259 22.5553 24.1812 23.0827 23.8919 23.54C23.6026 23.9973 23.2012 24.352 22.6879 24.604C22.1839 24.8467 21.6146 24.968 20.9799 24.968C19.6826 24.968 18.6886 24.5433 17.9979 23.694C17.3072 22.8353 16.9619 21.6267 16.9619 20.068C16.9619 18.976 17.1486 18.052 17.5219 17.296C17.8952 16.54 18.4179 15.9707 19.0899 15.588C19.7619 15.196 20.5459 15 21.4419 15C22.4126 15 23.1872 15.1773 23.7659 15.532L23.3599 16.344C22.8932 16.036 22.2586 15.882 21.4559 15.882C20.3732 15.882 19.5239 16.2273 18.9079 16.918C18.2919 17.5993 17.9839 18.5933 17.9839 19.9C17.9839 20.1333 17.9979 20.3993 18.0259 20.698C18.2686 20.166 18.6559 19.7553 19.1879 19.466C19.7199 19.1673 20.3312 19.018 21.0219 19.018ZM20.9379 24.114C21.6472 24.114 22.2212 23.9227 22.6599 23.54C23.1079 23.148 23.3319 22.63 23.3319 21.986C23.3319 21.342 23.1079 20.8287 22.6599 20.446C22.2212 20.0633 21.6239 19.872 20.8679 19.872C20.3732 19.872 19.9346 19.9653 19.5519 20.152C19.1786 20.3387 18.8846 20.5953 18.6699 20.922C18.4552 21.2487 18.3479 21.6127 18.3479 22.014C18.3479 22.3873 18.4459 22.7327 18.6419 23.05C18.8472 23.3673 19.1459 23.624 19.5379 23.82C19.9299 24.016 20.3966 24.114 20.9379 24.114Z"
        fill="white"
      />
      <path
        d="M40.0942 40H2.85275C1.73652 40 0.823242 39.0886 0.823242 37.9747V2.02532C0.823242 0.911392 1.73652 0 2.85275 0H40.0942C41.2104 0 42.1237 0.911392 42.1237 2.02532V37.9747C42.1237 39.0886 41.2104 40 40.0942 40Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};
