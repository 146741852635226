import React from "react";

export function ErrorImage() {
  return (
    <svg
      width="72"
      height="62"
      viewBox="0 0 72 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.45216 56.0041C-0.0831925 58.6708 1.84157 62 4.91865 62H67.0814C70.1584 62 72.0832 58.6708 70.5478 56.0041L39.4665 2.02074C37.928 -0.651448 34.072 -0.651444 32.5335 2.02075L1.45216 56.0041ZM39.4545 51.5789H32.5455V44.6316H39.4545V51.5789ZM39.4545 37.6842H32.5455V23.7895H39.4545V37.6842Z"
        fill="#FF574C"
      />
    </svg>
  );
}
