/* eslint-disable jsx-a11y/media-has-caption */
import React, {useRef} from 'react'
import styled from 'styled-components';

const StyledVideo = styled.div`
    display: none;
`;

const VideoPrealoaderItem = ({ video }) => {
    const videoRef = useRef()
    return (
        <video ref={videoRef} src={video} controls> </video>
);
}

export const VideoPrealoader = ({ videos }) => {
    return (
        <StyledVideo>
            {videos.map((videoItem) => {
                return (
                    <VideoPrealoaderItem key={videoItem} video={videoItem}  />
                );
            })}
        </StyledVideo>
  )
}

