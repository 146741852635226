import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import random from 'lodash/random';
import clsx from 'clsx';
import useKey from '@rooks/use-key';
import { useTranslation } from 'react-i18next';
import { useTimer } from '../../../utils/hooks/useTimer';
import { checkIfFirstInGroup } from '../../../utils/itemBankedHelpers';
import { submitResults, updateAnswer, updateRemainingTime, setCheckingAssessmentResults, setFormatedAssessmentResults } from '../../../store/actions/questionnaireDetailsActions';
import { PageHeader } from '../PageHeader';
import { InfoIcon } from '../InfoIcon';
import { Main } from '../layout';
import { ButtonNext, ButtonPrevious, Navigation } from '../Navigation';
import { PageTemplate } from '../../templates/PageTemplateComponent';
import { LangDirectionContext } from '../../../context/LangDirectionContext';
import { UserReturnModal } from '../UserReturnModal';
import { HelpModal } from '../HelpModal';
import { PostTest } from '../../pages/PostTest';
import { Error } from '../Error';
import { PageStyled, RunOutOfTimeStyled, ProgressInicatorStyled, HelpButtonStyled } from './QuestionsPlayer.styled';
import { Review } from '../../pages/Review';
import { Footer } from '../Footer';
import { ProgressBar } from '../ProgressBar';
import { SidePanel } from '../SidePanel';
import { BlockScreenModal } from '../BlockScreenModal';
import { useSaveSessionTimer } from '../../../utils/hooks/useSaveSessionTimer';
import { useTimeSpentPerQuestion } from '../../../utils/hooks/useTimeSpentPerQuestion';
import { useOrientation } from '../utils/isLandscape';
import { RotateToLandscapeScreen } from '../BlockMobileScreen';

import { LocalStorageService } from '../../../utils/LocalStorage.service';
import { Spinner } from '../Spinner';


export const QuestionsPlayer = ({ is360, isLandscapeOnly }) => {
  const { t } = useTranslation();
  const direction = React.useContext(LangDirectionContext);
  /**
   * Redux State
   */
  const dispatch = useDispatch();
  const { config, items } = useSelector((state) => state.testDetails);
  const checkingTestResults = useSelector((state) => state.questionnaireDetails.checkingAssessment.results);

  let showProgressCount = true && config.showQuestionProgressCounter;
  if ((true && config.showQuestionProgressCounter) == null) { showProgressCount = true; }

  const {
    remainingTime,
    answers,
    session,
    isResultSubmitted,
    details,
    isSessionSaving,
    timeSpentPerQuestion,
    mexAssessment,
  } = useSelector((state) => state.questionnaireDetails);

  /**
   * Component State
   */
  const [isRunOutOfTimeVisible, setRunOutOfTimeVisible] = useState(false);
  const [isNextInBattery, setNextInBattery] = useState(false);
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [isHelpVisible, setHelpVisible] = useState(false);
  const [isBlockModalVisible, setBlockModalVisible] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [showPostTest, setShowPostTest] = useState(false);
  const [isUserReturnScreenVisible, setUserReturnScreenStatus] = useState(
    config.isMEX ? Boolean(mexAssessment.currentDndState) : Boolean(answers.length),
  );
  const [isPending, setPending] = useState(false);
  const [isTestFinished, setTestFinished] = useState(false);
  /**
   *
   * Getting Active Question
  */
  const qAnswers = answers.filter((a) => a.Id?.toLowerCase()[0] !== 'e');
  const activeQuestion = React.useMemo(() => {
    if (config.isMEX) return mexAssessment.history.length;
    if (!qAnswers || !qAnswers.length) return 0;

    const idInInteger = (id) => parseInt(config.isItemBanked ? id.slice(1, -1) : id.slice(1), 10);

    const sortedQAnswers = qAnswers.sort((a, b) => idInInteger(a.Id) - idInInteger(b.Id));

    const lastInArray = sortedQAnswers[sortedQAnswers.length - 1];
    const lasIndexInArray = sortedQAnswers[sortedQAnswers.length - 1].Index;

    //  const lastAnswered = config.isWAI
    //  ? lastInArray.WaiItems.split(',').filter((i) => i === '2').length === 2
    //  : lastInArray.RIdx;

    const lastItemResponded = config.isWAI
      ? lastInArray.WaiItems.split(',').filter((i) => i === '2').length === 2
      : sortedQAnswers.findIndex((item) => item.Index === lasIndexInArray);

    const activeQuestionMapped = config.isWAI
      ? idInInteger(lastInArray.Id) - 1
      : lastItemResponded
    // return lastAnswered ? idInInteger(lastInArray.Id) : idInInteger(lastInArray.Id) - 1;
    return activeQuestionMapped;
  }, [qAnswers, config.isWAI, config.isItemBanked]);

  const [activePageIndex, setActivePageIndex] = useState(
    activeQuestion >= items.length ? items.length - 1 : activeQuestion,
  );

  const [checkingResponseId, setCheckingResponseId] = useState('');
  const [shufledImages, setShufledImages] = useState(null);
  const [selectedResponse, setSelectedResponse] = useState('');

  let selectedCheckingTestResponses = {};

  /**
   * Getting Page Data
   */
  const pageData = useMemo(() => {
    switch (true) {
      case activePageIndex >= items.length:
        return {};
      case config.isItemBanked:
        const answered = answers.find((a) => `g${a.Id.slice(1, -1)}` === items[activePageIndex].id);
        if (answered) {
          return {
            ...items[activePageIndex].items[answered.ItemBankId || 0],
            ItemBankId: answered.ItemBankId || 0,
          };
        }
        const ifFirstInGroup = config.itemsingroups ? checkIfFirstInGroup(config.itemsingroups, activePageIndex) : true;
        const nextItemBankedIndex = ifFirstInGroup
          ? random(items[activePageIndex].items.length - 1)
          : answers[activePageIndex - 1].ItemBankId || 0;
        return {
          ...items[activePageIndex].items[nextItemBankedIndex],
          ItemBankId: nextItemBankedIndex,
        };
      default:
        return items[activePageIndex];
    }
  }, [activePageIndex, items, config.isItemBanked, config.itemsingroups, answers]);

  const getResponseInfo = (responseId) => {
    const response = pageData.template.values.responses.filter((item) => item.RId === responseId)
    return response[0] || '';
  }

  /**
   * Page Answer
   */
  const is360FreeTextItem = is360 && pageData.template.view === 'FreeTextItem';
  const defaultPageAnswer = useMemo(() => {
    switch (true) {
      case is360FreeTextItem:
        return '';
      case config.isWAI:
        return [2, 2, 2, 2];
      default:
        return 0;
    }
  }, [is360FreeTextItem, config, activePageIndex]);

  const [activePageAnswer, setActivePageAnswer] = useState(defaultPageAnswer);
  const isDefaultPageAnswer = config.isWAI
    ? activePageAnswer.filter((a) => a === 2).length !== 2
    : activePageAnswer === defaultPageAnswer;

  useEffect(() => {
    setActivePageAnswer(defaultPageAnswer);
  }, [defaultPageAnswer]);

  /**
   * Modals
   */
  const [showPostTestModal, setShowPostTestModal] = useState(activeQuestion >= items.length);

  // registering time spent on every question
  const timeSpentForCurrentQuestion = timeSpentPerQuestion[activePageIndex];
  const isCountingBlocked =
    isRunOutOfTimeVisible || isBlockModalVisible || showPostTest || showPostTestModal || isUserReturnScreenVisible;
  useTimeSpentPerQuestion({
    questionIndex: activePageIndex,
    spentTime: timeSpentForCurrentQuestion,
    isCountingBlocked,
    isMex: config.isMEX,
  });

  useEffect(() => {
    setActivePageAnswer(activePageAnswer);
    setActivePageIndex(activePageIndex);
    if (config.isImageMatching && checkingTestResults[activePageIndex]) {
      setCheckingResponseId(qAnswers[activePageIndex]?.RId || '');
      const selectedResponseId = Object.keys(checkingTestResults[activePageIndex])[0];
      setSelectedResponse(getResponseInfo(selectedResponseId))
    }
    else if (details.testId === 'GCAT') {
      const selectedResponseId = qAnswers[activePageIndex]?.RId || '';
      setCheckingResponseId(selectedResponseId);
      setSelectedResponse(getResponseInfo(selectedResponseId));
    }
    else if (config.isSJT) {
      // Used for identify the SJT responses
    }
    else {
      setSelectedResponse(activePageAnswer)
    }
    return () => { };
  }, [activePageAnswer, activePageIndex]);

  /**
   * Save new question data
   */
  const ctItemImages = pageData.template.values.images;

  if (ctItemImages !== null) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (config.isImageMatching) {

        selectedCheckingTestResponses = getResponseInfo(checkingResponseId);

        if (selectedCheckingTestResponses) {
          const extractRegex = /^([a-zA-Z]+\d+-[a-zA-Z]+\d+)/;
          const key = `${selectedCheckingTestResponses?.RId}`;
          const finalKey = key.substring(0, key.length - 2);
          const itemId = String(finalKey).match(extractRegex);
          const value = `r${selectedCheckingTestResponses?.RIdx}`;
          dispatch(setCheckingAssessmentResults({ itemResult: { [itemId[1]]: value }, resultIndex: activePageIndex }));
        }
      }

      if (details.testId === 'GCAT') {
        const { itemId } = pageData.template.values;
        dispatch(setFormatedAssessmentResults({ itemResult: { [itemId]: checkingResponseId }, resultIndex: activePageIndex }));
      }

      if (shufledImages !== null && activePageAnswer !== 0) {
        setSelectedResponse(config.isImageMatching ? selectedCheckingTestResponses : activePageAnswer)
      }

      return () => { }
    }, [activePageAnswer, activePageIndex, checkingResponseId]);
  }


  const updateCurrentPageAnswer = () => {

    if (details.testId === 'GCAT') {
      const { itemId } = pageData.template.values;
      const isNumericalOrVerbal = String(itemId).startsWith('n') || String(itemId).startsWith('v');
      if (isNumericalOrVerbal) {
        dispatch(setFormatedAssessmentResults({ itemResult: { [itemId]: `r${activePageAnswer || 0}` }, resultIndex: activePageIndex }));
      }
      else {
        dispatch(setFormatedAssessmentResults({ itemResult: { [itemId]: checkingResponseId }, resultIndex: activePageIndex }));
      }
    }

    if (isResultSubmitted || isSessionSaving || (activePageAnswer === 0 && is360FreeTextItem)) return;
    if (isResultSubmitted || isSessionSaving || ((activePageAnswer === 0 || activePageAnswer === undefined) && config.isSJT)) return;
    const hasShuffledResponses = details.testId === 'GCAT' || config.isImageMatching;
    dispatch(
      updateAnswer({
        Index: activePageIndex,
        Id: pageData.id,
        ...(!config.isWAI &&
          !is360FreeTextItem && {
          RId: hasShuffledResponses ? checkingResponseId || `r${activePageAnswer}` : activePageAnswer !== 0 ? `r${activePageAnswer}` : '',
          RIdx: config.isSJT ? activePageIndex : activePageAnswer,
        }),
        ...(is360FreeTextItem && {
          Value: String(activePageAnswer) || '',
        }),
        ...(config.isWAI && { WaiItems: activePageAnswer.join() }),
        ...(config.isItemBanked && {
          ItemBankId: String(pageData.ItemBankId),
        }),
        ...(config.isSJT && { sjtItems: activePageAnswer }),
      }),
    );
  };

  useEffect(() => {
    const answered = answers.find((a) => a.Id === pageData.id);

    if (pageData.id && !answered) {
      updateCurrentPageAnswer();
    }
  }, [answers, pageData.id, activePageAnswer]);

  /**
   * Navigation Callbacks
   */
  const moveToNextActivePage = (shouldResetAnswer, dir) => {
    if (activePageIndex >= items.length - 1 && dir === 1) {
      setShowPostTestModal(true);
      return;
    }

    if (shouldResetAnswer) {
      setActivePageAnswer(defaultPageAnswer);
    }

    if (dir === 1) {
      setActivePageIndex(activePageIndex + 1);
      setActivePageIndex(activePageIndex + dir);
    }
    if (dir === -1) {

      setActivePageIndex(activePageIndex - 1);
    }
  };

  const handlePreviousStep = () => {
    if (activePageIndex > 0) {
      updateCurrentPageAnswer();
      moveToNextActivePage(false, -1);
    }
  };

  const handleNextStep = () => {
    const isResponseUnselected = activePageAnswer === undefined || activePageAnswer === 0 || activePageAnswer === '';

    if (config.isWAI && !is360FreeTextItem && config.isResponseRequired && !isHelpVisible && isDefaultPageAnswer) {
      setBlockModalVisible(true);
      return;
    }

    // if (!is360FreeTextItem && config.isResponseRequired && !isHelpVisible && isDefaultPageAnswer) {
    if (!is360FreeTextItem && config.isResponseRequired && !isHelpVisible && isResponseUnselected) {
      setBlockModalVisible(true);
      return;
    }

    if (!config.isResponseRequired || (config.isResponseRequired && !isDefaultPageAnswer) || is360FreeTextItem) {
      updateCurrentPageAnswer();
      moveToNextActivePage(true, 1);
    }
  };

  useEffect(() => {
    if (pageData.id) {
      const answered = answers.find((a) => a.Id === pageData.id);
      if (answered) {
        if (is360FreeTextItem) {
          setActivePageAnswer(answered.Value);
        } else if (config.isWAI) {
          setActivePageAnswer(answered.WaiItems.split(',').map((i) => parseInt(i, 10)));
        } else if (config.isSJT) {
          setActivePageAnswer(answered.SjtItems);
        } else {
          setActivePageAnswer(answered.RIdx);
        }
      }
    }

    return () => {
      setActivePageAnswer(defaultPageAnswer);
    };
  }, [pageData.id, answers.length, is360FreeTextItem, config, defaultPageAnswer, setActivePageAnswer]);

  /**
   * Timers Section, Timer Handlers
   */
  useEffect(() => {
    if (isRunOutOfTimeVisible && !isTestFinished) {
      updateCurrentPageAnswer();

      if (answers.length < items.length) {
        moveToNextActivePage(true, 1);
      } else {
        setTestFinished(true);
      }
    }
  }, [isRunOutOfTimeVisible, items.length, answers.length, isTestFinished]);

  useEffect(() => {
    if (isTestFinished && isRunOutOfTimeVisible) {
      dispatch(
        submitResults(
          {
            answers,
            session,
            config,
          },
          (err, data) => {
            setPending(false);
            setNextInBattery(data && typeof data === 'string');
          },
        ),
      );
    }
  }, [isTestFinished, isRunOutOfTimeVisible, dispatch, session, config, setPending, setNextInBattery, answers]);

  const { clearSaveSessionTimer } = useSaveSessionTimer({
    isUserReturnScreenVisible,
    isResultSubmitted,
    is360,
  });

  const {
    time,
    start,
    reset: clearSaveRemainingTimer,
  } = useTimer({
    initialTime: remainingTime !== null ? remainingTime : config.timeAllowed * 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeRunOut: () => {
      setPending(true);
      setActivePageAnswer(activePageAnswer);
      setRunOutOfTimeVisible(true);
      clearSaveSessionTimer();
    },
  });

  useEffect(() => {
    if (time !== 0 && !isUserReturnScreenVisible) {
      dispatch(updateRemainingTime(time));
    }
  }, [time, dispatch, isUserReturnScreenVisible]);

  useEffect(() => {
    if (config.timeAllowed && !isUserReturnScreenVisible && !isRunOutOfTimeVisible) {
      start();
    }
  }, [isUserReturnScreenVisible, isRunOutOfTimeVisible, config.timeAllowed, start]);

  const handleNextAfterRunOutOfTime = () => {
    if (isNextInBattery) {
      window.location.reload();
    } else {
      setShowPostTest(true);
    }
  };

  /**
   * Progress Bar
   */
  const [maxProgressIndex, setMaxProgressIndex] = useState(
    qAnswers.filter((qa) =>
      config.isWAI ? qa.WaiItems.split(',').filter((i) => i === '2').length === 2 : qa.RIdx !== 0,
    ).length + 1,
  );

  useEffect(() => {
    if (activePageIndex && maxProgressIndex <= activePageIndex) {
      setMaxProgressIndex(activePageIndex + 1);
    }
  }, [activePageIndex, setMaxProgressIndex, maxProgressIndex]);

  /**
   * Keyboard Support
   */
  const isKeyboardNavigationBlocked =
    activePageIndex >= items.length ||
    isRunOutOfTimeVisible ||
    isBlockModalVisible ||
    isHelpVisible ||
    isUserReturnScreenVisible ||
    showPostTestModal ||
    showPostTest ||
    isSidePanelOpen ||
    isEditing;

  useKey(['ArrowRight'], () => {
    if (!isKeyboardNavigationBlocked) {
      if (direction === 'rtl') {
        handlePreviousStep();
      } else {
        handleNextStep();
      }
    }
  });

  useKey(['ArrowLeft'], () => {
    if (!isKeyboardNavigationBlocked && !config.isWAI) {
      if (direction === 'rtl') {
        handleNextStep();
      } else {
        handlePreviousStep();
      }
    }
  });
  useKey(['PageDown', 'Enter'], () => {
    if (!isKeyboardNavigationBlocked) {
      handleNextStep();
    }
  });

  useKey(['PageUp', 'Backspace'], () => {
    if (!isKeyboardNavigationBlocked && !config.isWAI) {
      handlePreviousStep();
    }
  });

  useKey(['F1'], () => {
    if (!isKeyboardNavigationBlocked) {
      setHelpVisible(true);
    }
  });

  const isLandscape = useOrientation() === 'landscape';
  const orientationAllowed = isLandscapeOnly ? isLandscape : true;

  /**
   * User Recovery Session Modal
   */
  if (isUserReturnScreenVisible) {
    return <UserReturnModal onClose={() => setUserReturnScreenStatus(false)} testName={session.testId} />;
  }

  /**
   * Review Modal
   */
  if (showPostTestModal && !isRunOutOfTimeVisible) {
    return (
      <Review
        onReview={() => setShowPostTestModal(false)}
        isRunOutOfTimeVisible={showPostTestModal}
        onReset={() => {
          clearSaveRemainingTimer();
          clearSaveSessionTimer();
        }}
        onSubmit={() => {
          setShowPostTestModal(false);
          setShowPostTest(true);
        }}
      />
    );
  }

  /**
   * Help Modal
   */
  if (isHelpVisible) {
    return <HelpModal onClose={() => setHelpVisible(false)} />;
  }

  /**
   * Redirect to Post Test Page
   */
  if (showPostTest) {
    return <PostTest />;
  }

  /**
   * User Recovery Session Modal
   */
  if (isRunOutOfTimeVisible) {
    return (
      <RunOutOfTimeStyled>
        <Error title={t('testfinished')} onNext={handleNextAfterRunOutOfTime} isPending={isPending}>
          {t('outOfTime')}
        </Error>
      </RunOutOfTimeStyled>
    );
  }

  /**
   * Rotate device screen
   */
  if (!orientationAllowed) {
    return <RotateToLandscapeScreen />;
  }
  const isResponsesAvailable = pageData.template.values && pageData.template.values.responses;

  /** 
  * Answers treatment for SJT Templates
  */
  const { accountId, invitationId, password } = details || '';

  const {
    answers: apiAnswers
  } = LocalStorageService.getSession({
    accountId,
    invitationId,
    password,
  });

  if (apiAnswers === undefined) return <Spinner text={t('loadingAssessmentDetails')} />;

  return (
    <PageStyled
      isResponsesAvailable={isResponsesAvailable}
    >
      {!config.isMEX && (
        <PageHeader pageData={pageData} activePageIndex={activePageIndex} innerTime={time} itemsLength={items.length}>
          <InfoIcon
            onClick={() => {
              setSidePanelOpen(!isSidePanelOpen);
            }}
            className={clsx('button', { active: isSidePanelOpen })}
          />
        </PageHeader>
      )}
      <Main>
        {!config.isMEX && (
          <Navigation hideInvisibleBg={config.isMEX}
            isSJT={config.isSJT}>
            <ButtonPrevious onClick={handlePreviousStep} disabled={activePageIndex === 0 || config.isWAI} />
            <ButtonNext
              onClick={handleNextStep}
              className={clsx({
                active: !isDefaultPageAnswer,
              })}
            />
          </Navigation>
        )}
        <PageTemplate
          page={pageData}
          onResponse={setActivePageAnswer}
          selectedResponse={selectedResponse}
          onResponseId={setCheckingResponseId}
          onShufledImages={setShufledImages}
          config={config}
          isBlockModalVisible={isBlockModalVisible}
          isHelpVisible={isHelpVisible}
          isSidePanelOpen={isSidePanelOpen}
          handleNextStep={handleNextStep}
          activePageIndex={activePageIndex}
          isCountingBlocked={isCountingBlocked}
          is360={is360}
          isSJT={config.isSJT}
          details={details}
          onBlur={() => {
            setEditing(false);
          }}
          onFocus={() => {
            setEditing(true);
          }}
        />
      </Main>
      <Footer>
        <ProgressInicatorStyled
          onClick={
            config.isWAI || config.isMEX
              ? null
              : () => {
                setSidePanelOpen(!isSidePanelOpen);
              }
          }
        >
          <InfoIcon className={clsx('button', { active: isSidePanelOpen })} percentless>
            <span>{t('Progress')}&nbsp;&nbsp;</span>
            <span>
              {showProgressCount && config.isMEX && `${activePageIndex + 1} / ${items.length}`}
              {showProgressCount && !config.isMEX &&
                Math.floor(
                  (100 * (config.isImageMatching
                    ? (activePageIndex + 1)
                    : (qAnswers.filter((qa) =>
                      config.isWAI ? qa.WaiItems.split(',').filter((i) => i === '2').length === 2 : qa.RIdx !== 0,
                    ).length))
                    / items.length),
                )}
              {showProgressCount && !config.isMEX && '%'}
            </span>
          </InfoIcon>
        </ProgressInicatorStyled>
        {!config.isMEX && (
          <ProgressBar
            className={clsx({ visiblyHidden: isSidePanelOpen })}
            max={items.length}
            current={activePageIndex + 1}
            progress={maxProgressIndex < items.length ? maxProgressIndex : items.length}
            onChange={() => { }}
            allowOverlap={false}
          />
        )}
        <HelpButtonStyled
          onClick={() => {
            setSidePanelOpen(false);
            setHelpVisible(true);
          }}
        >
          {t('help')}
        </HelpButtonStyled>
      </Footer>
      <SidePanel
        isOpen={isSidePanelOpen}
        activeQuestion={activePageIndex}
        questionsLength={items.length}
        onClose={() => {
          setSidePanelOpen(false);
        }}
        onNavigate={(item) => {
          updateCurrentPageAnswer();
          setActivePageIndex(item);
          setSidePanelOpen(false);
        }}
        answers={answers}
        config={config.isResponseRequired}
      />
      <BlockScreenModal isBlockModalVisible={isBlockModalVisible} setBlockModalVisible={setBlockModalVisible} />
    </PageStyled>
  );
};