import React, { useEffect } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ButtonsHolder } from "../../shared/layout";
import { Button, ButtonTypes, ButtonVariants } from "../../shared/Button";
import { Divider } from "../../templates/shared/components";
import { ReviewImage } from "../../shared/ReviewImage";
import {
  ExtraMessageStyled,
  MessageStyled,
  ReviewModalPage,
  ModalBoxStyled,
  TitleStyled,
} from "./Review.styled";
import { submitResults, saveSessions } from "../../../store/actions/questionnaireDetailsActions";
import { Spinner } from "../../shared/Spinner";

const ErrorResponseStyled = styled.div`
  color: ${({ theme }) => theme.colors.error};
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 280px;
  min-height: 60px;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 3px solid;
    font-size: 25px;
    font-weight: 600;
    order: -2;
  }

  &:before {
    display: inline-flex;
    min-width: 10px;
    content: "";
    order: -1;
  }
`;

const ErrorResponse = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <ErrorResponseStyled>
      <>
        <span>!</span>
        {message}
      </>
    </ErrorResponseStyled>
  );
};

const ReviewComponent = ({
  answers,
  session,
  onReview,
  isRunOutOfTimeVisible,
  onSubmit,
  config,
  qEngine,
  qItems,
  submitResults,
  onReset,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const buttonRefNext = React.createRef();
  const buttonRefPrev = React.createRef();

  const reviewIsNotPossible = config.isAdaptive || config.isWAI;
  const [isPending, setPending] = React.useState(false);
  const [isSessionPending, SetSessionPending] = React.useState(false);
  const [responseError, setResponseError] = React.useState("");

  React.useEffect(() => {
    if (isRunOutOfTimeVisible) {
      setTimeout(() => {
        try {
          buttonRefNext.current.focus();
        } catch (e) {
          // console.log("Focus is not available");
        }
      }, 0);
    }
  }, [buttonRefNext, isRunOutOfTimeVisible]);

  const handleNavKeys = (event) => {
    const key = event.which;

    if (key === 39) {
      buttonRefNext.current.focus();
    } else if (key === 37) {
      buttonRefPrev.current.focus();
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleNavKeys);

    return () => {
      window.removeEventListener("keydown", handleNavKeys);
    };
  });

  const formatItems = (items) =>
    config.is360
      ? items.map((item) => {
        const qItem = qItems.find((i) => i.id === item.Id);
        return {
          ...item,
          ...(qItem && {
            ItemId: qItem.itemId,
          }),
        };
      })
      : items;

  const handleSubmit = () => {
    setPending(true);
    onReset();

    submitResults(
      {
        answers,
        session,
        config,
        qEngine,
        formatItems,
      },
      (err, data) => {
        if (err) {
          setPending(false);
          setResponseError(err.message);
          return;
        }

        if (data && typeof data === "string") {
          window.location.reload();
        } else {
          onSubmit();
        }
      }
    );
  };

  const { testId, batteryDependencies } = session;
  const is360 = testId === '360D';
  const isBattery = !is360 && batteryDependencies !== '';
  const testsInBattery = batteryDependencies ? batteryDependencies.split(';') : [];
  const totalTests = batteryDependencies === '' ? 0 : testsInBattery.length;
  const currentTestIndex = batteryDependencies === '' ? 0 : testsInBattery.indexOf(testId) + 1;
  const shouldRenderContinueButton = currentTestIndex !== totalTests;

  const ButtonText = () => {
    return shouldRenderContinueButton ? t("continue") : t("submit")
  }

  useEffect(() => {
    dispatch(saveSessions(() => {
      SetSessionPending(true)
    }
    ));
    return () => { };
  }, []);


  return (
    <ReviewModalPage withBackground>
      <ModalBoxStyled>
        <ReviewImage />
        <TitleStyled dangerouslySetInnerHTML={{ __html: t("testfinished") }} />
        {!isSessionPending && (<MessageStyled
          dangerouslySetInnerHTML={{
            __html: reviewIsNotPossible
              ? t("youHaveReached")
              : t(
                "wouldyouliketoreviewyouranswersbeforesubmittingorsubmitimmediately"
              ),
          }}
        />)}
        {isSessionPending && (<MessageStyled
          dangerouslySetInnerHTML={{
            __html:
              t("SomethingIsNotRightPleasCheckYourResponses")
          }}
        />)}
        {isBattery && <ExtraMessageStyled
          dangerouslySetInnerHTML={{
            __html: t("TestsCompleted", { current: currentTestIndex, total: totalTests })
          }}
        />}
        <ErrorResponse message={responseError} />

        <ButtonsHolder>
          {!reviewIsNotPossible && (
            <>
              <Button
                ref={buttonRefPrev}
                variant={ButtonVariants.SECONDARY}
                type={ButtonTypes.BUTTON}
                onClick={onReview}
                disabled={isPending}
              >
                {t("review")}
              </Button>
              <Divider />
            </>
          )}
          <Button
            ref={buttonRefNext}
            variant={ButtonVariants.PRIMARY}
            type={ButtonTypes.BUTTON}
            onClick={handleSubmit}
            disabled={isPending || isSessionPending}
          >
            {isPending ? <Spinner small /> : <ButtonText />}
          </Button>
        </ButtonsHolder>
      </ModalBoxStyled>
    </ReviewModalPage>
  );
};

const mapStateToProps = (state) => {
  const {
    testDetails: { config, items },
    questionnaireDetails: { details, session, answers, remainingTime },
  } = state;

  return { answers, session, details, config, remainingTime, qItems: items };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitResults: (results, cb) => {
      dispatch(submitResults(results, cb));
    },
  };
};

export const Review = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewComponent);
