import React from "react";
import { IconStyled } from "./components";

export const GlyphSet = ({ className, noPath, fontSize }) => {
  return (
    <IconStyled className={className} fontSize={fontSize}>
      {!noPath && (
        <>
          <i className="icon-path path1" />
          <i className="icon-path path2" />
          <i className="icon-path path3" />
          <i className="icon-path path4" />
        </>
      )}
    </IconStyled>
  );
};
