export const arrayToObject = (array) => {
  const result = {};
  const filteredItems = array.filter((item) => item !== null);

  filteredItems.forEach(item => {
    if (item) { 
      const key = Object.keys(item)[0];
      const value = item[key];
      result[key] = value;
    }
  });

  return result;
}