import React from "react";

export const KeyArrowLeft = () => {
  return (
    <svg
      width="32"
      height="40"
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9282 40H1.5407C0.693317 40 0 39.3081 0 38.4625V23.587C0 22.7413 0.693317 22.0494 1.5407 22.0494H29.9282C30.7756 22.0494 31.4689 22.7413 31.4689 23.587V38.4625C31.4689 39.3081 30.7756 40 29.9282 40Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M12.4316 30.686L17.6122 27.695L17.6122 33.6769L12.4316 30.686Z"
        fill="white"
      />
    </svg>
  );
};
