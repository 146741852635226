import React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

const ButtonsHolderStyled = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 980px;
  margin: 60px 3% 0 3%;
  height: 83vh;
    -webkit-overflow-scrolling: touch;
   padding-bottom: 35px;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    margin: 10px 5% 0 5%;
    padding: 0;

    /* ScrollBar Start */
    /* width */
    ::-webkit-scrollbar {
      width: 20px;
      
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background-color: rgba(255,255,255,0.2);
      opacity: 0.5;
      border-left: 9px solid ${({ theme }) => theme.colors.bgFooter};
      border-right: 9px solid ${({ theme }) => theme.colors.bgFooter};
      
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.white};
      border-left: 8px solid ${({ theme }) => theme.colors.bgFooter};
      border-right: 8px solid ${({ theme }) => theme.colors.bgFooter};
      transition: all 0.2s;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      border-left: 7px solid ${({ theme }) => theme.colors.bgFooter};
      border-right: 7px solid ${({ theme }) => theme.colors.bgFooter};
      transition: all 0.2s;
    }
      /* ScrollBar End */
    }
  }
  
  @media only screen and (min-width: ${({ theme }) =>
    theme.viewports.sm})  and (max-device-width: ${({ theme }) =>
  theme.viewports.mdx})  and (orientation: portrait) {
    margin: 10px 11% 0 11%;
  }
`;

const NavButtonStyled = styled.button`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;  
  width: 40px;
  height: 40px;
  margin: 4px;
  border-radius: 50%;
  padding: 0;
  border: none;
  background-color: #49bbe7;
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  
  ${(props) =>
    !!props.completed &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: #19aae1;
      border: 0;
    `}

  ${(props) =>
    !!props.active &&
    css`
      background-color: ${({ theme }) => theme.button.SidebarBackgroundActive};
      color: ${({ theme }) => theme.colors.whiteFontColor};

      &:hover {
        opacity: 1;
        cursor: default;
      }

      &:disabled {
        background-color: ${({ theme }) =>
          theme.button.SidebarBackgroundActive};
        color: ${({ theme }) => theme.colors.whiteFontColor};
      }
    `}
  
    ${(props) =>
      !!props.skipped &&
      css`
        background-color: #ffcb8e;
        color: #19aae1;
      `}
  
    &:not([disabled]):hover {
      box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.2);
    }
  
    &:disabled {
      border: 0;
      background-color: rgba(255, 255, 255, 0.2);
      color: #85ddff;
      cursor: not-allowed;
      opacity: 1;
    }
`;

const QuestionnaireNavigatorComponent = ({
  activeQuestion,
  items,
  onNavigate,
  answers,
}) => {
  const questionsIndexesArray = Array(items.length)
    .fill()
    .map((e, i) => {
      return {
        id: i,
        answer: answers.find(
          (answer) => answer.Id.replace(/\w?(\d+)\w?/gi, "$1") === `${i + 1}`
        ),
      };
    });

  const handleNavigate = React.useCallback(
    (item) => {
      if (item !== activeQuestion) {
        onNavigate(item);
      }
    },
    [activeQuestion, onNavigate]
  );

  return (
    <ButtonsHolderStyled>
      {questionsIndexesArray.map((item) => {
        return (
          <NavButtonStyled
            key={item.id}
            active={activeQuestion === item.id}
            completed={item.answer && item.answer.RIdx}
            disabled={item.id > answers.length - 1}
            onClick={() => {
              handleNavigate(item.id);
            }}
          >
            {item.id + 1}
          </NavButtonStyled>
        );
      })}
    </ButtonsHolderStyled>
  );
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: { answers },
    testDetails: { items, config },
  } = state;

  return {
    answers,
    items,
    config,
  };
};

export const QuestionnaireNavigator = connect(mapStateToProps)(
  QuestionnaireNavigatorComponent
);
