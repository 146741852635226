import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FeedbackStyled, FullHeight } from "../shared/components";

/*
  WAI test, are tests with some peculiarities in saving results.

  Usually there are 4 question with ability to select "Strongly Agree" and "Strongly Disagree" options,
  only one SA and SD option may be selected for set of 4 questions

  In database SA is saved as "3" and SD as "1" and others are "2"
  so finally result is saved as set of 4 numbers, e.g. "1,2,2,3" or "3,1,2,2" and so on.
 */
export const mostStronglyAgreeValue = 3;
export const mostStronglyDisagreeValue = 1;
export const neutralValue = 2;

export const Switcher = ({
  values: { moststronglyagree, moststronglydisagree, responses, feedback },
  handleSelection,
  valuesSet,
}) => {
  const { t } = useTranslation();

  return (
    <FullHeightStyled>
      <LabelStyled>
        <span dangerouslySetInnerHTML={{ __html: moststronglyagree }} />
        <span dangerouslySetInnerHTML={{ __html: moststronglydisagree }} />
      </LabelStyled>

      {responses.map((response, index) => {
        return (
          <QuestionStyled key={response.RIdx}>
            <TextStyled className="switch">
              <IndexStyled>{index + 1}</IndexStyled>
              <ValueStyled
                dangerouslySetInnerHTML={{ __html: response.value }}
              />
            </TextStyled>
            <ButtonsGroupStyled>
              <span>
                <input
                  name="mostStronglyAgree"
                  className="green"
                  type="checkbox"
                  checked={valuesSet[index] === mostStronglyAgreeValue}
                  onChange={() => {
                    handleSelection(index, mostStronglyAgreeValue);
                  }}
                  tabIndex="-1"
                />
                <label>{t("sa")}</label>
              </span>
              <span>
                <input
                  name="mostStronglyDisagree"
                  className="red"
                  type="checkbox"
                  checked={valuesSet[index] === mostStronglyDisagreeValue}
                  onChange={() => {
                    handleSelection(index, mostStronglyDisagreeValue);
                  }}
                  tabIndex="-1"
                />
                <label>{t("sd")}</label>
              </span>
            </ButtonsGroupStyled>
          </QuestionStyled>
        );
      })}
      {!!feedback && (
        <FeedbackStyled
          dangerouslySetInnerHTML={{
            __html: feedback,
          }}
        />
      )}
    </FullHeightStyled>
  );
};

const ValueStyled = styled.span``;

const FullHeightStyled = styled(FullHeight)`
  display: flex;
  z-index: 5;
  position: relative;
`;

const LabelStyled = styled.div`
  width: 280px;
  display: flex;
  align-self: flex-end;
  justify-content: space-around;
  height: auto;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.darkFontColor};
  text-transform: capitalize;

  @media (max-width: 760px) {
    width: 175px;
    font-size: 14px;
    line-height: 16px;
  }
  
  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 12px;
    line-height: 14px;
    width: 135px;
  }

  span {
    padding: 10px 0;
    width: 35%;
    text-align: center;
    @media (max-width: 760px) {
      word-break: break-word;
    }
  }
`;

const TextStyled = styled.div`
  margin-right: 17px;
  width: 100%;
  align-items: center;
  display: flex;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.darkFontColor};
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    font-size: 12px;
    line-height: 14px;
    padding: 5px 0;
  }
  @media (max-width: ${({ theme }) => theme.viewports.xs}) {
    font-size: 14px;
    line-height: 16px;
    padding: 5px 0;
  }
`;

const QuestionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &:first-of-type {
    border-top: 2px solid #e7e7e7;
    background-color: red;
  }
`;

const ButtonsGroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 280px;
  width: 100%;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background: #e8e8e8;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-top: 4px;
  align-self: center;

  span {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkFontColor};
    position: relative;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #c5c5c5;
    background: rgba(73, 73, 73, 0.1);
    width: 100%;
    border-radius: 50%;
    height: 100%;
    color: transparent;
    text-transform: uppercase;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
  }

  input.green:checked + label {
    background-color: #21d33a;
    border-color: #17af2c;
    color: white;
  }

  input.red:checked + label {
    background-color: #ff7171;
    border-color: #ff3939;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const IndexStyled = styled.span`
  padding: 10px;
  padding-right: 20px;
  min-width: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkFontColor};
  @media (max-width: ${({ theme }) => theme.viewports.xs}) {
    display: none;
  }
`;
