import React, { useState } from 'react';
import { object, func, string, bool } from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as RemoveIcon } from '../../../../assets/images/plus-outlined.svg';
import { ReactComponent as Checked } from '../../../../assets/images/checked.svg';

const StaticCard = ({ card, className, isSelected, onCardClick }) => {
  const [showCloseIcon, setCloseIconState] = useState(false);

  return (
    <CardContainer
      onMouseOver={() => setCloseIconState(true)}
      onMouseLeave={() => setCloseIconState(false)}
      className={className}
      isSelected={isSelected}
      onClick={onCardClick}
    >
      {isSelected && showCloseIcon && <CloseIcon />}
      {isSelected && !showCloseIcon && <CheckedIcon />}
      {card?.content}
    </CardContainer>
  );
};

StaticCard.propTypes = {
  card: object.isRequired,
  onCardClick: func.isRequired,
  isSelected: bool.isRequired,
  className: string,
};

StaticCard.defaultProps = {
  className: '',
};

const CardContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 28rem;
  min-height: 6rem;
  max-height: 6rem;
  padding: 1.6rem;
  background-color: ${(props) => (props.isSelected ? '#005A9C' : '#008ac0')};
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 0.8rem;
  color: #ffffff;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#005A9C' : '#4dadd3')};
    cursor: pointer;
  }
`;

const CloseIcon = styled(RemoveIcon)`
  box-sizing: content-box;
  position: absolute;
  min-height: 0.8rem;
  min-width: 0.8rem;
  top: 0.2rem;
  right: 0.2rem;
  padding: 0.4rem;

  &:hover {
    opacity: 0.8;
  }
`;

const CheckedIcon = styled(Checked)`
  box-sizing: content-box;
  position: absolute;
  min-height: 0.8rem;
  min-width: 0.8rem;
  top: 0.2rem;
  right: 0.2rem;
  padding: 0.4rem;

  &:hover {
    opacity: 0.8;
  }
`;

export default StaticCard;
