import React from "react";
import styled, { css } from "styled-components";

export const ButtonTypes = {
  BUTTON: "button",
  RESET: "reset",
  SUBMIT: "submit",
};

export const ButtonVariants = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const ButtonSizes = {
  Sm: "sm",
};

const ButtonStyled = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
  min-width: ${(props) => props.theme.button.MinWidth};
  height: ${(props) => props.theme.button.Height};
  z-index: 2;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  font-weight: ${(props) => props.theme.button.FontWeight};
  font-size: ${(props) => props.theme.button.FontSize};
  transition: all 0.2s;
  border-radius: ${({ theme }) => theme.formElements.borderRadius};
  letter-spacing: 1px;
  outline: 0 !important;

  @media (max-width: ${({ theme }) => theme.viewports.xxs}) {
    min-width: 110px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    width: auto;
  }

  ${(props) =>
    props.variant === ButtonVariants.PRIMARY &&
    css`
      background-color: ${({ theme }) => theme.button.Primary};
      color: ${({ theme }) => theme.button.PrimaryColor};

      &:hover {
        background-color: ${({ theme }) => theme.button.PrimaryHover};
        outline: none;
      }

      &:active {
        background-color: ${({ theme }) => theme.button.PrimaryActive};
        outline: none;
      }
    `}

  ${(props) =>
    props.variant === ButtonVariants.SECONDARY &&
    css`
      background-color: ${(props) => props.theme.button.Secondary};
      color: ${(props) => props.theme.button.SecondaryColor};
      border: 1px solid ${(props) => props.theme.button.SecondaryColor};

      &:hover,
      &:focus {
        background-color: ${(props) => props.theme.button.SecondaryHover};
      }

      &:active {
        background-color: ${(props) => props.theme.button.SecondaryActive};
      }
    `} 
  
  ${(props) =>
    props.size === ButtonSizes.Sm &&
    css`
      min-width: 72px;
      width: 72px;
      height: 28px;
      font-size: 12px;
      line-height: 44px;
      text-transform: none;

      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        width: 80px;
      }
    `} 
  
  &:disabled {
    background-color: ${({ theme }) => theme.button.Disabled};
    color: ${({ theme }) => theme.button.DisabledColor};
    border: none;
    cursor: not-allowed;
  }
`;

export const Button = React.forwardRef(
  (
    { type, variant, onClick, disabled, autoFocus, children, ...props },
    ref
  ) => (
    <ButtonStyled
      ref={ref}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
      {...props}
    >
      {children}
    </ButtonStyled>
  )
);
