import React from "react";

export function ReviewImage() {
  return (
    <svg
      width="106"
      height="102"
      viewBox="0 0 106 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.4324 25.5913L102.557 25.6588C104.42 25.6588 105.932 24.1468 105.932 22.2838C105.932 20.4208 104.42 18.9088 102.557 18.9088L38.4324 18.8413C36.5694 18.8413 35.0574 20.3533 35.0574 22.2163C35.0574 24.0793 36.5694 25.5913 38.4324 25.5913Z"
        fill="#1BAAE1"
      />
      <path
        d="M102.625 52.6251L38.4999 52.5576C36.6369 52.5576 35.1249 54.0696 35.1249 55.9326C35.1249 57.7956 36.6369 59.3076 38.4999 59.3076L102.625 59.3751C104.488 59.3751 106 57.8631 106 56.0001C106 54.1371 104.488 52.6251 102.625 52.6251Z"
        fill="#1BAAE1"
      />
      <path
        d="M102.625 86.3751L38.4999 86.3076C36.6369 86.3076 35.1249 87.8196 35.1249 89.6826C35.1249 91.5456 36.6369 93.0576 38.4999 93.0576L102.625 93.1251C104.488 93.1251 106 91.6131 106 89.7501C106 87.8871 104.488 86.3751 102.625 86.3751Z"
        fill="#1BAAE1"
      />
      <path
        d="M3.5 21.5C3.5 15.976 7.97595 11.5 13.5 11.5C19.024 11.5 23.5 15.976 23.5 21.5C23.5 27.024 19.024 31.5 13.5 31.5C7.97595 31.5 3.5 27.024 3.5 21.5Z"
        stroke="#1BAAE1"
        strokeWidth="5"
      />
      <path
        d="M3.5 55.5C3.5 49.976 7.97595 45.5 13.5 45.5C19.024 45.5 23.5 49.976 23.5 55.5C23.5 61.024 19.024 65.5 13.5 65.5C7.97595 65.5 3.5 61.024 3.5 55.5Z"
        stroke="#1BAAE1"
        strokeWidth="5"
      />
      <path
        d="M3.5 89.5C3.5 83.976 7.97595 79.5 13.5 79.5C19.024 79.5 23.5 83.976 23.5 89.5C23.5 95.024 19.024 99.5 13.5 99.5C7.97595 99.5 3.5 95.024 3.5 89.5Z"
        stroke="#1BAAE1"
        strokeWidth="5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9899 0.836342C27.5385 0.30253 28.2792 0.00210302 29.0517 1.0999e-05C29.8242 -0.00208102 30.5665 0.29433 31.1182 0.825163C31.6698 1.356 31.9864 2.0786 31.9996 2.83674C32.0127 3.59489 31.7214 4.32766 31.1885 4.87663L15.5388 24.0776C15.2698 24.362 14.9452 24.5902 14.5843 24.7486C14.2235 24.907 13.8338 24.9923 13.4385 24.9995C13.0433 25.0067 12.6506 24.9356 12.284 24.7904C11.9174 24.6452 11.5844 24.429 11.3049 24.1546L0.935801 13.9731C0.646929 13.7089 0.415232 13.3903 0.254533 13.0362C0.0938336 12.6822 0.00742304 12.3001 0.000457573 11.9126C-0.0065079 11.5251 0.0661146 11.1402 0.213991 10.7809C0.361867 10.4215 0.581969 10.0951 0.861166 9.82103C1.14036 9.54699 1.47293 9.33095 1.83904 9.18581C2.20515 9.04066 2.59729 8.96938 2.99207 8.97622C3.38685 8.98305 3.77619 9.06787 4.13685 9.2256C4.49751 9.38333 4.82211 9.61075 5.09128 9.89429L13.3003 17.9479L26.9154 0.920997C26.9397 0.89119 26.9659 0.862915 26.9938 0.836342H26.9899Z"
        fill="#008AC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9899 68.8363C27.5385 68.3025 28.2792 68.0021 29.0517 68C29.8242 67.9979 30.5665 68.2943 31.1182 68.8252C31.6698 69.356 31.9864 70.0786 31.9996 70.8367C32.0127 71.5949 31.7214 72.3277 31.1885 72.8766L15.5388 92.0776C15.2698 92.362 14.9452 92.5902 14.5843 92.7486C14.2235 92.907 13.8338 92.9923 13.4385 92.9995C13.0433 93.0067 12.6506 92.9356 12.284 92.7904C11.9174 92.6452 11.5844 92.429 11.3049 92.1546L0.935801 81.9731C0.646929 81.7089 0.415232 81.3903 0.254533 81.0362C0.0938336 80.6822 0.00742304 80.3001 0.000457573 79.9126C-0.0065079 79.5251 0.0661146 79.1402 0.213991 78.7809C0.361867 78.4215 0.581969 78.0951 0.861166 77.821C1.14036 77.547 1.47293 77.331 1.83904 77.1858C2.20515 77.0407 2.59729 76.9694 2.99207 76.9762C3.38685 76.9831 3.77619 77.0679 4.13685 77.2256C4.49751 77.3833 4.82211 77.6108 5.09128 77.8943L13.3003 85.9479L26.9154 68.921C26.9397 68.8912 26.9659 68.8629 26.9938 68.8363H26.9899Z"
        fill="#008AC0"
      />
    </svg>
  );
}
