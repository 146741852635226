import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Page, Title } from '../shared/layout';
import { ModalBox } from '../shared/layout/Page';
import { CheckMarkImage } from '../shared/CheckMarkImage';
import { SubTitleStyled } from '../templates/shared/components';

const LogoutPage = styled(Page)`
  justify-content: center;
`;

const ModalBoxStyled = styled(ModalBox)`
  justify-content: space-between;
  padding: 100px 58px 40px 58px;
  min-height: 43rem;
`;

const SubTitleReStyled = styled(SubTitleStyled)`
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const CenteringDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logout = () => {
  const { t } = useTranslation();

  const buttonRefNext = React.createRef();

  React.useEffect(() => {
    setTimeout(() => {
      try {
        buttonRefNext.current.focus();
      } catch (e) {
        // console.log("Focus is not available");
      }
    }, 0);
  });

  return (
    <LogoutPage withBackground>
      <ModalBoxStyled>
        <CenteringDiv>
          <CheckMarkImage />
          <Title>{t('assessmentCompleted')}</Title>
        </CenteringDiv>
        <SubTitleReStyled>{t('You may now close the browser tab')}</SubTitleReStyled>
      </ModalBoxStyled>
    </LogoutPage>
  );
};
