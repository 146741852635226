import React from 'react';
import styled from 'styled-components';
import { Range, getTrackBackground } from 'react-range';
import { LangDirectionContext } from '../../context/LangDirectionContext';

const ProgressBarStyled = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const ProgressBarBoundStyled = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.33px;
  color: #fdfdfd;
  display: flex;

  &:first-child {
    width: auto;

    &:after {
      width: 16px;
      display: inline-block;
      content: ' ';
    }
  }

  &:last-child {
    &:before {
      width: 16px;
      display: inline-block;
      content: ' ';
    }
  }
`;

const ProgressBarWrapperStyled = styled.div`
  flex-grow: 1;
`;

const ThumbStyled = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  display: none;
  position: relative;

  &:first-child {
    display: block;
    outline: none;
  }
`;

const ThumbLabelStyled = styled.div`
  width: 44px;
  position: absolute;
  height: 24px;
  background-color: #005392;
  top: -38px;
  left: -14px;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.33px;
  color: #fdfdfd;
  display: none;
  visibility: hidden;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #005392;
    position: absolute;
    bottom: -8px;
    left: 14px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    visibility: visible;
    ${ThumbStyled}:hover & {
      display: block;
    }
  }
`;

export const ProgressBar = ({ min = 0, max, current, progress, onChange, onFinalChange, allowOverlap, className }) => {
  const direction = React.useContext(LangDirectionContext);
  return (
    <ProgressBarStyled direction={direction} className={className}>
      <ProgressBarBoundStyled>{min}</ProgressBarBoundStyled>
      <ProgressBarWrapperStyled>
        <Range
          allowOverlap={allowOverlap}
          rtl={direction === 'rtl'}
          step={1}
          min={min}
          max={max}
          values={[current, progress]}
          onChange={onChange}
          onFinalChange={onFinalChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '4px',
                width: '100%',
                background: getTrackBackground({
                  values: [current, progress],
                  colors: ['#ffffff', '#005392', 'rgba(255, 255, 255, 0.25)'],
                  min,
                  max,
                  rtl: direction === 'rtl',
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <ThumbStyled
              {...props}
              style={{
                ...props.style,
              }}
            >
              <ThumbLabelStyled
                style={{
                  ...(isDragged && { display: 'block' }),
                }}
              >
                {current}
              </ThumbLabelStyled>
            </ThumbStyled>
          )}
        />
      </ProgressBarWrapperStyled>
      <ProgressBarBoundStyled>{max}</ProgressBarBoundStyled>
    </ProgressBarStyled>
  );
};
