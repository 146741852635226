import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as workerTimers from 'worker-timers';

import { Container, ContentHolder, LogoWrapper, Title } from '../../imported-from-admin/styles';
import { ReactComponent as Logo } from '../../../../assets/images/logo.svg';
import { ReactComponent as HourglassPicture } from '../../../../assets/images/hourglass.svg';

import { fetchAdministeredSessionPassword } from '../../../../store/actions/questionnaireDetailsActions';

export const PendingScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    managedGroup: {
      sessionData: { accountID, userID, langID },
      inviteId,
      password,
    },
  } = useSelector((state) => state.questionnaireDetails);

  const paddedAccountId = String(accountID).padStart(5, '0');
  const paddedInviteId = String(inviteId).padStart(7, '0');

  const generateLink = (pass) =>
    `${window.location.origin}/login/${paddedAccountId}${paddedInviteId}/${pass}/${langID}`;

  useEffect(() => {
    if (password) {
      document.location.assign(generateLink(password));
      return;
    }

    const intervalId = workerTimers.setInterval(() => {
      const data = { inviteId, userId: userID, accountId: accountID };

      if (password) {
        document.location.assign(generateLink(password));
        return;
      }

      dispatch(
        fetchAdministeredSessionPassword(data, (e, password) => {
          if (!e) {
            const urlToRedirect = generateLink(password);
            document.location.assign(urlToRedirect);
          }
        }),
      );
    }, 5000);

    return () => workerTimers.clearInterval(intervalId);
  }, []);

  return (
    <Container withBackground>
      <StyledContentHolder>
        <StyledLogoWrapper>
          <Logo />
        </StyledLogoWrapper>
        <HourglassWrapper>
          <HourglassPicture />
        </HourglassWrapper>
        <StyledTitle>{t('standBy')}</StyledTitle>
        <Message>
          {t('administratorReview')}
        </Message>
      </StyledContentHolder>
    </Container>
  );
};

PendingScreen.propTypes = {};

const StyledContentHolder = styled(ContentHolder)`
  height: auto;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0.8rem;
  text-align: center;
`;

const StyledLogoWrapper = styled(LogoWrapper)`
  margin-bottom: 5rem;
`;

const HourglassWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.6rem;
`;

const Message = styled.p`
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: ${(props) => props.theme.fontSizes.regular};
  text-align: center;
`;
