import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { ButtonVariants, ButtonTypes } from '../../shared/Button';
import { ButtonsHolder } from '../../shared/layout/ButtonHolder';
import { ModalBox } from '../../shared/layout/Page';
import {
  FieldStyled,
  InfoFieldStyled,
  LogoStyled,
  WelcomePage,
  ButtonStyled,
  TitleStyled,
  TextStyled,
} from './Welcome.styled';
import { orderedViewReversed } from '../../../utils/orderedViewReversed';
import { setRespondentData } from '../../../store/actions/questionnaireDetailsActions';
import { Spinner } from '../../shared/Spinner';
import { Error } from '../../shared/Error';
import { assessmentApi } from '../../../utils/agent';

export const Welcome = ({ is360 }) => {
  const { t, i18n } = useTranslation();
  const buttonRefNext = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isError, setError] = useState(false);
  const respondent = useSelector((state) => state.questionnaireDetails.respondent);
  const [isRedirected, setRedirected] = useState(false);

  useEffect(() => {
    const focusOnButton = () => {
      try {
        buttonRefNext.current.focus();
        // eslint-disable-next-line no-empty
      } catch {}
    };
    if (!respondent) {
      const { accountWithInvitation, password } = params;
      assessmentApi[is360 ? 'login360' : 'login'](accountWithInvitation, password)
        .then(({ data }) => {
          if (typeof data === 'object') {
            dispatch(setRespondentData(is360 ? data.qInvitation : data));
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    } else {
      focusOnButton();
    }
  }, [respondent, params, is360, dispatch]);

  const redirectToQuestionnaire = useCallback(() => {
    const { accountId, invitationId: inviteId, password } = respondent;

    window.open(
      `/${is360 ? 'login360' : 'login'}/${String(accountId).padStart(5, '0')}${String(inviteId).padStart(
        7,
        '0',
      )}/${password}`,
      'GeneSys2020 – online integrated psychometric assessment. Measurement of aptitude, ability, personality interests, values, 360 degree appraisal.',
      'menubar=0,toolbar=0,location=0,status=0',
    );

    setRedirected(true);
  }, [is360, respondent]);
  if (isError)
    return (
      <Error
        title={t('invalidInvitation')}
        onNext={() => {
          history.push(`${is360 ? '/Start360' : '/Start'}`);
        }}
      >
        {t('SomethingIsNotRightPleasCheckYourInvitation')}
      </Error>
    );

  if (!respondent && !isError) return <Spinner />;

  return (
    <WelcomePage withBackground>
      <ModalBox>
        <LogoStyled />

        {!isRedirected ? (
          <>
            <TitleStyled>{t('confirmsessiondetails')}</TitleStyled>
            {orderedViewReversed(i18n.language, [
              <FieldStyled key="firstname">
                <span>{t('firstname')}</span>
                <b>{respondent.firstName}</b>
              </FieldStyled>,
              <FieldStyled key="lastname">
                <span>{t('lastname')}</span>
                <b>{respondent.familyName}</b>
              </FieldStyled>,
            ])}
            <ButtonsHolder>
              <ButtonStyled
                ref={buttonRefNext}
                variant={ButtonVariants.PRIMARY}
                type={ButtonTypes.BUTTON}
                onClick={redirectToQuestionnaire}
              >
                {t('continue')}
              </ButtonStyled>
            </ButtonsHolder>
          </>
        ) : (
          <TextStyled>{t('techDifficulties')}</TextStyled>
        )}
        <InfoFieldStyled>
          <span>
            {t('contactifproblems')}&nbsp;
            <a href={`mailto:${respondent.userName}`}>{respondent.userName}</a>
          </span>
        </InfoFieldStyled>
      </ModalBox>
    </WelcomePage>
  );
};
